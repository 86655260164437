import React, { useState, useEffect } from 'react';
//import { useLocation } from 'react-router-dom';

import ReactHtmlParser/*, { processNodes, convertNodeToElement, htmlparser2 }*/ from 'react-html-parser';
import parse from 'html-react-parser';
//import { Helmet } from 'react-helmet';

import * as Hosts from '../../../Hosts';
import { reqGET, content_language, set_language, repo_img_link } from '../../../Utils';

import Loader from '../../../loader';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
// import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { language } from '../WEBContent/Language';

import AssecImage from "../ASSEC_Image/Image"

export default function NewsCards(props) {
    //const { pathname } = useLocation();

    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState('pt');
    const [lang_id, set_lang_id] = useState('1');

    // const data_interface = {
    //     id: '',
    //     info: {},
    //     content: {}
    // }
    // const [content, set_content] = useState(data_interface);
    const [list_content, set_list_content] = useState([]);

    useEffect(() => {
        /* console.log('aaaa') */
        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                set_lang_id(el.id)
                }
            })
        }

        try {
            let getInfoList = async () => {
                await reqGET(`${Hosts.SIMCore_API}/web/content/type/${Hosts.companycode}/news`)
                    .then(res => { 
                        if (res.data.length > 0) {

                            var hoje = new Date();
                            let aux = []
                            res.data.forEach((v) => {
                              if(v.info.date.published !== undefined && v.info.date.published !== '') {
                                  var date_p = new Date(v.info.date.published)
                    
                                  if(hoje >= date_p) {
                                    if(v.info.date.avaiable !== '') {
                                        var date_a = new Date(v.info.date.avaiable);

                                        if(hoje <= date_a) {
                                            aux.push(v)
                                        }
                                    }
                                    else {
                                        aux.push(v)
                                    }
                                  }
                              }
                            })
                            set_list_content(aux)
                            //set_list_content(res.data)
                            //console.log('NOTICIAS', res.data)
                  
                            setLoading(false)
                          }
                    })
                    .catch(erro => console.log('ERROR', erro))
            }
            getInfoList()
 
            return

        } catch {
            console.log('nada')

			return
		}
        
    }, []);

    return (isloading ?

		<Loader />
		
		:
        <>
            <Navbar />
            <Breadcrumb />
            <main className="news-cards">
                <section id="news-cards" className="content-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 offset-lg-1 col-lg-10 py-3 py-md-5">
                                <div className="text-center">
                                    <h3 className="titulo-pp">{language[lang].news_turistrela}</h3>
                                </div>
                                
                                <div className="row">
                                    {
                                    list_content.map((content) => {
                                        return (
                                            content.content.map((el, k) => {  
                                                return (
                                                    <div key={k} className="col-12 col-md-6 col-lg-4 col-xl-4 mb-5">
                                                        <div className="card new-item hover-animate shadow-sm">
                                                            <a href={'./article/' + (content.info['slug'] !== undefined && content.info.slug !== '' ? content.info.slug : content.id)} title={language[lang].keep_reading}>
                                                                <div className="div-image"> 
                                                                    {
                                                                    el['media'] !== undefined && el['media']['image'] !== undefined && el['media']['image'].length > 0 ?
                                                                        <AssecImage 
                                                                            linkImage={el['media']['image'][0]['link']}
                                                                            width="450"
                                                                            height="300"
                                                                            fit='crop'
                                                                            type='img'
                                                                            sClass="img-fluid" 
                                                                            alt=""
                                                                        />
                                                                    :
                                                                        <img src={repo_img_link('nophoto.png')} className="img-fluid" alt="" />
                                                                    }
                                                                </div>
                                                            </a>
                                                            <div className="card-body p-4">
                                                                <div className="div-source pb-1">{parse(content.info.date.published.split('T')[0])}</div>
                                                                <div className="card-title">
                                                                    {(lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title))}
                                                                </div>
                                                                <div className="card-text">
                                                                {ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.abstract : (el.language[lang_id] === undefined || el.language[lang_id].abstract === undefined || el.language[lang_id].abstract === '' ? el.abstract : el.language[lang_id].abstract))}
                                                                </div> 
                                                            </div>
                                                            <div className="px-3 mb-3 text-right">
                                                                <a className="btn btn-text" href={'./article/' + + (content.info['slug'] !== undefined && content.info.slug !== '' ? content.info.slug : content.id)}>{language[lang].keep_reading}<i className="bi bi-chevron-right"></i></a>
                                                            </div>

                                                            <div className="card-footer text-muted d-none">
                                                            <i className="bi bi-tag pr-2" />
                                                                {
                                                                content.info.tags.map((v, k) => {
                                                                    return (
                                                                        <span key={k} className="pill-tag">{v.text}</span>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div> 
                                                )
                                            })
                                        )
                                    })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}