const language = {
    'pt': 
        {
            buy:                        'Comprar', 
            finalize_order:             'Finalizar',  
            comprar:                    'Comprar',
            voltar:                     'Voltar',
            precos:                     'Preços',
            quantidade:                 'Quantidade',

            accept_terms:       'Aceito os termos e condições.',
            advice:             'Os conteúdos utilizados na elaboração desta plataforma são meramente ilustrativos, tendo sido retirados (na sua maioria) do website: <a href="https://open.umn.edu/opentextbooks/" target="_blank">https://open.umn.edu/opentextbooks/</a><br> Estes conteúdos são licenciados por autores e editoras para serem livremente utilizados e adaptados.',
            agree_terms_conditions: 'Concordo com os ', 
            agree_return_policy:    ' e a ',
            cancel:             'Cancelar',
            confirm_purchase_cancel:    'Cancelar finalização',
            confirm_purchase_confirm:   'Finalizar',
            confirm_purchase_error:     'Erro a processar a encomenda.',
            confirm_purchase_success:   'Encomenda processada com sucesso.',
            confirm_purchase_text:      'Pretende finalizar a encomenda?',
            confirm_purchase_title:     'Confirmar encomenda',
            dados_cidade:               'Cidade',
            dados_cliente:              'Dados do cliente',
            dados_codigo_postal:        'Código-postal',
            dados_email:                'E-mail',
            dados_faturacao:            'Dados de faturação',
            dados_tipo_pagamento:       'Tipo de pagamento',
            dados_localidade:           'Localidade',
            dados_mobile_phone:         'Telemóvel',
            dados_morada:               'Morada',
            dados_nome:                 'Nome',
            dados_pais:                 'País',
            dados_vat:                  'NIF',
            equipamentos:               'Equipamentos', 
            my_order:                   'A minha encomenda',
            next:                       'Continuar',
            pista_aluguer:              'Aluguer da Pista',
            prev:                       'Voltar',
            product:                    'Produto',
            qtd:                        'Qtd',
            subtotal:                   'Subtotal',
            total:                      'Total',
            terms_conditions:   'Termos e condições',          
            terms_conditions_link:   'termos e condições.',
            return_policy_link:      'política de devolução e reembolso',
            terms_conditions_required: 'Os campos assinalados com (*) são de preenchimento obrigatório. O endereço de email permite um contacto mais rápido em caso de necessidade de esclarecimentos adicionais. A informação que submeter não será usada ou distribuída por outra razão que não o processamento do seu pedido..',
            unit_price:                 'Preço Unitário',
            vat:                        'IVA',
            confirm_login_error:        'O registo é obrigatório.',
            confirm_form_error:         'Os campos assinalados com (*) são de preenchimento obrigatório. O endereço de email permite um contacto mais rápido em caso de necessidade de esclarecimentos adicionais. A informação que submeter não será usada ou distribuída por outra razão que não o processamento do seu pedido...',
            confirm_agree_error:        'Tem de ler e aceitar os termos e condições para finalizar a compra.',
            contact_form:               'Nota de Encomenda',
            name:                       'Nome',
            dados_pagamento:            'Dados de pagamento',
            entity:                     'Entidade',
            reference:                  'Referência',
            estado:                     'Estado',
            aguardar_pagamento:         'A aguardar pagamento',
            num_encomenda:              'N.º da encomenda',
            data_limite:                'Data limite de pagamento',
            data_limite_txt:            'Caso não efectue o pagamento dentro da data limite, a sua encomenda será anulada.<br />Depois do pagamento, receberá a fatura dentro de 72 horas.',
            encomenda_finalizada:       'Encomenda registada com sucesso, consulte o seu e-mail para finalizar a encomenda com sucesso!<br><br>Irá ser redirecionado para a página inicial.',
            encomenda_finalizada_cc:    'Encomenda registada com sucesso, consulte o seu e-mail para finalizar a encomenda com sucesso!<br><br>Irá ser redirecionado para a página de pagamento.',
            encomenda_finalizada_error: 'Algo correu mal a finalizar a sua encomenda.',

            encomendar:                 'Encomendar',
            encomenda:                  'Encomenda',
            dados_pessoais:             'Dados pessoais',
            pagamento:                  'Pagamento',

            label_eliminar_produto: 'Tem a certeza que pretende eliminar o produto?',
            label_confirmar: 'Confirmar',
            label_cancelar: 'Cancelar',
        },
    'en':
        {
            buy:                        'To buy', 
            finalize_order:             'Finalize order',  
            comprar:                    'To buy',
            voltar:                     'Back',
            precos:                     'Prices',
            quantidade:                 'Quantity',


            accept_terms:               'I accept the terms and conditions.',
            advice:                     'Os conteúdos utilizados na elaboração desta plataforma são meramente ilustrativos, tendo sido retirados (na sua maioria) do website: <a href="https://open.umn.edu/opentextbooks/" target="_blank">https://open.umn.edu/opentextbooks/</a><br> Estes conteúdos são licenciados por autores e editoras para serem livremente utilizados e adaptados.',
            agree_terms_conditions:     'I agree with ', 
            agree_return_policy:        ' and the ',
            cancel:                     'Cancel',
            confirm_purchase_cancel:    'Cancel',
            confirm_purchase_confirm:   'Finalize',
            confirm_purchase_error:     'Erro a processar a encomenda.',
            confirm_purchase_success:   'Encomenda processada com sucesso.',
            confirm_purchase_text:      'Pretende finalizar a encomenda?',
            confirm_purchase_title:     'Confirmar encomenda',
            dados_cidade:               'City',
            dados_cliente:              'Dados do cliente',
            dados_codigo_postal:        'Zip Code',
            dados_email:                'E-mail',
            dados_faturacao:            'Billing information',
            dados_tipo_pagamento:       'Payment method',
            dados_localidade:           'City',
            dados_mobile_phone:         'Mobile Number',
            dados_morada:               'Address',
            dados_nome:                 'Name',
            dados_pais:                 'Country',
            dados_vat:                  'NIF',
            equipamentos:               'Equipamentos', 
            my_order:                   'My order',
            next:                       'Next',
            pista_aluguer:              'Aluguer da Pista',
            prev:                       'Back',
            product:                    'Product',
            qtd:                        'Qtd',
            subtotal:                   'Subtotal',
            total:                      'Total',
            terms_conditions:           'Terms and conditions',          
            terms_conditions_link:      'terms and conditions.',
            return_policy_link:         'return and refund policy',
            terms_conditions_required:  'The fields marked with (*) are required. The email address allows for faster contact in the event of further clarification. The information you submit will not be used or distributed for any other reason other than the processing of your request.',
            unit_price:                 'Unit Price',
            vat:                        'IVA',
            confirm_login_error:        'Registration is mandatory.',
            confirm_form_error:         'The fields marked with (*) are required. The email address allows for faster contact in the event of further clarification. The information you submit will not be used or distributed for any other reason other than the processing of your request.',
            confirm_agree_error:        'You must read and accept the terms and conditions to finalize your purchase.',
            contact_form:               'Order',
            name:                       'Name',
            dados_pagamento:            'Payment data',
            entity:                     'Entity',
            reference:                  'Referenc',
            estado:                     'Estado',
            aguardar_pagamento:         'A aguardar pagamento',
            num_encomenda:              'N.º da encomenda',
            data_limite:                'Data limite de pagamento',
            data_limite_txt:            'Caso não efectue o pagamento dentro da data limite, a sua encomenda será anulada.<br />Depois do pagamento, receberá a fatura dentro de 72 horas.',
            encomenda_finalizada:       'Encomenda registada com sucesso, consulte o seu e-mail para finalizar a encomenda com sucesso!<br><br>Irá ser redirecionado para a página inicial.',
            encomenda_finalizada_cc:    'Encomenda registada com sucesso, consulte o seu e-mail para finalizar a encomenda com sucesso!<br><br>Irá ser redirecionado para a página de pagamento.',
            encomenda_finalizada_error: 'Something went wrong finalizing your order.',

            encomendar:                 'To order',
            encomenda:                  'Order',
            dados_pessoais:             'Personal data',
            pagamento:                  'Payment',

            label_eliminar_produto:     'Are you sure you want to dispose of the product?',
            label_confirmar:            'Confirm',
            label_cancelar:             'Cancel',

        },
    'es':
        {
            buy:                        'Comprar', 
            finalize_order:             'Finalizar',  
            comprar:                    'Comprar',
            voltar:                     'Volver',
            precos:                     'Precios',
            quantidade:                 'Cantidade',

            accept_terms:               'Aceito os termos e condições.',
            advice:                     'Os conteúdos utilizados na elaboração desta plataforma são meramente ilustrativos, tendo sido retirados (na sua maioria) do website: <a href="https://open.umn.edu/opentextbooks/" target="_blank">https://open.umn.edu/opentextbooks/</a><br> Estes conteúdos são licenciados por autores e editoras para serem livremente utilizados e adaptados.',
            agree_terms_conditions:     'Concordo com os ', 
            agree_return_policy:        ' e a ',
            cancel:                     'Cancelar',
            confirm_purchase_cancel:    'Cancelar finalização',
            confirm_purchase_confirm:   'Finalizar',
            confirm_purchase_error:     'Erro a processar a encomenda.',
            confirm_purchase_success:   'Encomenda processada com sucesso.',
            confirm_purchase_text:      'Pretende finalizar a encomenda?',
            confirm_purchase_title:     'Confirmar encomenda',
            dados_cidade:               'Ciudad',
            dados_cliente:              'Datos del cliente',
            dados_codigo_postal:        'Código-postal',
            dados_email:                'E-mail',
            dados_faturacao:            'Datos de facturación',
            dados_tipo_pagamento:       'Tipo de pago',
            dados_localidade:           'Localidad',
            dados_mobile_phone:         'Teléfono móvil',
            dados_morada:               'Dirección',
            dados_nome:                 'Nombre',
            dados_pais:                 'País',
            dados_vat:                  'NIF',
            equipamentos:               'Equipamentos', 
            my_order:                   'Mi pedido',
            next:                       'Continuar',
            pista_aluguer:              'Aluguer da Pista',
            prev:                       'Volver',
            product:                    'Produto',
            qtd:                        'Qtd',
            subtotal:                   'Subtotal',
            total:                      'Total',
            terms_conditions:           'Términos y condiciones',          
            terms_conditions_link:      'términos y condiciones.',
            return_policy_link:         'política de devolução e reembolso',
            terms_conditions_required:  'Los campos marcados con (*) son obligatorios. La dirección de correo electrónico le permite ponerse en contacto con nosotros más rápidamente si necesita más aclaraciones. La información que envíe no se utilizará ni distribuirá por ningún otro motivo que no sea tramitar su solicitud.',
            unit_price:                 'Preço Unitário',
            vat:                        'IVA',
            confirm_login_error:        'El registro es obligatorio.',
            confirm_form_error:         'Los campos marcados con (*) son obligatorios. La dirección de correo electrónico le permite ponerse en contacto con nosotros más rápidamente si necesita más aclaraciones. La información que envíe no se utilizará ni distribuirá por ningún otro motivo que no sea tramitar su solicitud.',
            confirm_agree_error:        'Debe leer y aceptar los términos y condiciones para finalizar su compra.',
            contact_form:               'Orden de compra',
            name:                       'Nombre',
            dados_pagamento:            'Datos de pago',
            entity:                     'Entidade',
            reference:                  'Referência',
            estado:                     'Estado',
            aguardar_pagamento:         'A aguardar pagamento',
            num_encomenda:              'N.º da encomenda',
            data_limite:                'Data limite de pagamento',
            data_limite_txt:            'Caso não efectue o pagamento dentro da data limite, a sua encomenda será anulada.<br />Depois do pagamento, receberá a fatura dentro de 72 horas.',
            encomenda_finalizada:       'Pedido registrado con éxito, compruebe su correo electrónico para finalizar el pedido con éxito!<br><br>Será redirigido a la página de inicio.',
            encomenda_finalizada_cc:    'Pedido registrado con éxito, compruebe su correo electrónico para finalizar el pedido con éxito!<br><br>Será redirigido a la página de pago.',
            encomenda_finalizada_error: 'Algo ha ido mal al finalizar el pedido.',

            encomendar:                 'Orden',
            encomenda:                  'Orden',
            dados_pessoais:             'Datos personales',
            pagamento:                  'Forma de pago',

            label_eliminar_produto:     '¿Está seguro de que desea eliminar el producto?',
            label_confirmar:            'Confirmar',
            label_cancelar:             'Cancelar',
        }
}

module.exports = {
    language
};