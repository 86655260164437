import React, { useState, useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';
// import { sha256 } from 'js-sha256';

// import uniqid from 'uniqid';
import Swal from 'sweetalert2'

import moment from 'moment-timezone';

import "../Pagina.css";
import "../content-styles.css"

import { getTokenInfo } from '../../../UseToken';

import * as Hosts from '../../../Hosts';
import { translate_generic_store, translate_horas, ordem_de_compra } from '../GenericStore/utils';
import useContextProvider from '../GenericStore/Providers/ContextProvider';
import { nome_empresa } from '../GenericStore/HOSTS';
import { network } from '../GenericStore/UseService';
import {
    //set_language,
    reqGET,
    reqPOST,
    reqPUT,

    repo_site_assets_link,
    // functionSendMail,

} from '../../../Utils';

import './Cart.css';

import Loader from '../../../loader'

import Navbar from "../Navbar/Navbar";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Footer from "../Footer/Footer";

import { language } from './Language';
import func_ws from '../GenericStore/comunication_websocket';
import { usarToken } from '../GenericStore/UseToken';

const CartDesk = () => {
    const [, set_dados] = useState(null);
    const { updateprodnegative, removeprod, get_lista_carrinho, get_numeroProdutos, get_lista, get_ws_info, get_is_logged, get_id_user, set_lista_carrinho, update_timer, get_linguagem, refreshlista } = useContextProvider()

    const nota_de_encomenda = useRef({});
    const user_info = useRef({});

    const [user_profile, set_user_profile] = useState('');

    //const lista_produtos = useRef([]);

    // const checkoutInstance = startCheckout(manifest, [options])

    const [isloading, setLoading] = useState(true);
    const [isRedirect, setRedirect] = useState(false);

    const [lang, set_lang] = useState('pt');
    // const [lang_id, set_lang_id] = useState('1');

    const [lista_qr, /*set_lista_qr*/] = useState([]);

    const [subtotal, /* set_subtotal */] = useState(0.00);
    const [total_vat, /* set_total_vat */] = useState([]);
    // const [total, set_total] = useState(0.00);

    const [step, set_step] = useState('0');

    const [agree, setAgree] = useState(false);

    const [/*mbway_mobile_phone*/, set_mbway_mobile_phone] = useState('')

    //const [refresh, setRefresh] = useState(0);

    const checkboxHandler = () => {
        // if agree === true, it will be set to false
        // if agree === false, it will be set to true
        setAgree(!agree);
        // Don't miss the exclamation mark
    }

    const checkboxHandler_reload = (agree) => {
        //console.log('AGREE', agree)
        if (agree === true)
            return true
        else
            return false
        // if agree === false, it will be set to true
        //setAgree(!agree);
        // Don't miss the exclamation mark
    }

    const get_data_func = () => {
        let data = moment().tz("Europe/Lisbon").format('YYYY-MM-DDTHH:mm:ss.SSSZ')
        return data.substring(0, data.length - 6) + 'Z'
    }

    useEffect(() => {
        (async () => {

            let temp_token_info = await usarToken().getToken()
            let token_info = JSON.parse(temp_token_info)

            if(token_info !== undefined && token_info !== null) {
                if(token_info.user_info.profile !== 'Colaborador') {
                    setRedirect(true)
                }

                set_user_profile(token_info.user_info.profile)
            }

            set_lang(localStorage.getItem('lang'))

            // set_user_info(usarToken().getToken().user_info)


            set_dados(user_info.current = {
                name: '',
                email: '',
                vat: '',
                obs: '',
                addresses: [{
                    address_detail: ''
                }],
                contacts: [{
                    email: '',
                    mobile_phone: ''
                }]
            })

            console.log('get_numeroProdutos --->', get_numeroProdutos())
            console.log('get_is_logged --->', get_is_logged())
            console.log('get_id_user --->', get_id_user())
            //console.log('usarToken().getToken().user_info --->', usarToken().getToken().user_info)

            console.log('get_lista --->', get_lista())

            let clean_data = () => {
            }

            let getClientInfo = async (id_user) => {
                return await reqGET(`${Hosts.SIMCore_API}/entities/${Hosts.companycode}/${id_user}`)
                    .then(res => {
                        console.log('set_user_info', res.data[0])

                        // set_dados(user_info.current = res.data[0])
                        // set_dados(user_info.current.notifications = [])

                        // set_dados(dados.current.info.documento.user_info.id_user = id_user)
                        // set_dados(dados.current.info.documento.user_info.username = res.data[0].info.username)
                        // set_dados(dados.current.info.documento.user_info.name = res.data[0].info.name)
                        // //set_dados(dados.current.info.documento.user_info.vat = getTokenInfo().iat)
                        // set_dados(dados.current.info.documento.user_info.vat = res.data[0].info.vat)
                        // set_dados(dados.current.info.documento.user_info.contacts[0].email = res.data[0].contacts[0].email)
                        // set_dados(dados.current.info.documento.user_info.contacts[0].mobile_phone = res.data[0].contacts[0].mobile_phone)
                        // set_dados(dados.current.info.documento.user_info.addresses[0].address_detail = res.data[0].addresses[0].address_detail)
                        // set_dados(dados.current.info.documento.user_info.addresses[0].localidade = res.data[0].addresses[0].localidade)
                        // set_dados(dados.current.info.documento.user_info.addresses[0].postal_code = res.data[0].addresses[0].postal_code)
                        // set_dados(dados.current.info.documento.user_info.addresses[0].country = res.data[0].addresses[0].country)

                        setLoading(false)
                    })
                    .catch(erro => console.log(erro))
            }
            if (get_id_user() !== undefined && get_id_user() !== null && get_id_user() !== '') {
                getClientInfo(get_id_user())
            }

            // if(get_numeroProdutos() === 0) {
            //     setRedirect(true)
            // }

            clean_data()
        })()

        // reqPOST(`${Hosts.SIMCore_API}/store/payment/notification/status_stock`)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lang]);

    const handleSubmit = async e => {
        e.preventDefault();
    }

    const handle_change_info_documento_userinfo = (e) => {
        const { name, value } = e.target;

        set_dados(user_info.current[name] = value)


    }

    const handle_change_info_documento_userinfo_addresses = (e) => {
        const { name, value } = e.target;

        set_dados(user_info.current.addresses[0][name] = value)

    }

    const handle_change_info_documento_userinfo_contacts = (e) => {
        const { name, value } = e.target;

        if (name === 'email')
            set_dados(user_info.current[name] = value)

        set_dados(user_info.current.contacts[0][name] = value)

    }

    const validate_step = (i) => {

        // console.log('i', i)

        if (i === '1') {
            if (get_is_logged()) {
                set_step(i)
            }
            else {
                Swal.fire('', language[lang].confirm_login_error, 'info')
            }
        }
        if (i === '2') {
            if (user_info.current.name !== ''
                && user_info.current.vat !== ''
                && user_info.current.contacts[0].email !== ''
                && agree) {

                // set_step(i)

                set_payment('desk', '-2')

            }
            else if (!agree) {
                Swal.fire('', language[lang].confirm_agree_error, 'info')
            }
            // else if(dados.current.info.documento.user_info.addresses[0].address_detail === '') {
            // }
            // else if(dados.current.info.documento.user_info.addresses[0].localidade === '') {
            // }
            // else if(dados.current.info.documento.user_info.addresses[0].postal_code === '') {
            // }
            // else if(dados.current.info.documento.user_info.addresses[0].country === '') {
            // }
            else {
                Swal.fire('', language[lang].confirm_form_error, 'info')
            }
        }
        if (i === '3') {
            set_step(i)
        }

    }

    const set_payment = async (type, id) => {
        // console.log(type)

        // set_dados(nota_de_encomenda.current.info['metodo_de_pagamento'] = id)


        let aux_data = get_data_func()
        let id_pagamento_2 = ''
        try {
            id_pagamento_2 = Date.now() + '' + get_id_user()

            console.log('get_lista()', get_lista())

            set_dados(nota_de_encomenda.current = await ordem_de_compra(get_lista(), get_id_user(), id_pagamento_2, aux_data))

            // set_dados(nota_de_encomenda.current.info.documento['user_info']['id_user'] = user_info.current.id)
            // set_dados(nota_de_encomenda.current.info.documento['user_info']['name'] = user_info.current.name)
            // set_dados(nota_de_encomenda.current.info.documento['user_info']['vat'] = user_info.current.vat)
            // set_dados(nota_de_encomenda.current.info.documento['user_info']['username'] = user_info.current.username)
            // set_dados(nota_de_encomenda.current.info.documento['user_info']['obs'] = '')

            // set_dados(nota_de_encomenda.current.info.documento['user_info']['addresses'] = user_info.current.addresses)
            // set_dados(nota_de_encomenda.current.info.documento['user_info']['contacts'] = user_info.current.contacts)

            set_dados(nota_de_encomenda.current.info['id_usado_para_gerar_pagamento'] = parseInt(id_pagamento_2))

            console.log('%%%%%%%%%%%%%%%%%%%%%%%% nota_de_encomenda', nota_de_encomenda)
        } catch (error) {
            console.log('handle_send_encomenda_to_db, erro:', error)
        }

        console.log('nota_de_encomenda', nota_de_encomenda)
        console.log('nota_de_encomenda.current', nota_de_encomenda.current)
        console.log('nota_de_encomenda.current.info', nota_de_encomenda.current.info)
        console.log('nota_de_encomenda.current.info.documento', nota_de_encomenda.current.info.documento)
        console.log('nota_de_encomenda.current.info.documento.total_documento', nota_de_encomenda.current.info.documento.total_documento)

        nota_de_encomenda.current.info.documento.user_info = user_info.current

        console.log('user_info.current', user_info.current)
        console.log('nota_de_encomenda.current.info.documento.user_info', nota_de_encomenda.current.info.documento.user_info)

        if(parseFloat(nota_de_encomenda.current.info.documento.total_documento) > 0.00) {

            await Swal.fire({
                title: language[lang].confirm_purchase_title,
                text: language[lang].confirm_purchase_text,
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#0095e8',
                cancelButtonColor: '#d9214e',
                confirmButtonText: language[lang].confirm_purchase_confirm,
                cancelButtonText: language[lang].confirm_purchase_cancel
            })
                .then(async (result) => {
                    if (result.isConfirmed) {

                        set_dados(nota_de_encomenda.current.info['qr'] = lista_qr)
                        set_dados(nota_de_encomenda.current.info['metodo_de_pagamento'] = id)

                        set_dados(nota_de_encomenda.current.info['companycode'] = Hosts.companycode)

                        let date_today = new Date()
                        let timestamp_aux = get_data_func()

                        set_dados(nota_de_encomenda.current.info.data = date_today.getFullYear() + '-' + (date_today.getMonth() + 1).toString().padStart(2, '0') + '-' + date_today.getDate().toString().padStart(2, '0'))
                        set_dados(nota_de_encomenda.current.info.timestamp = timestamp_aux)

                        if (nota_de_encomenda.current.info.documento.user_info.vat === '')
                            set_dados(nota_de_encomenda.current.info.documento.user_info.vat = '999999990')

                        await reqPOST(`${Hosts.SIMCore_API}/store/order`, nota_de_encomenda.current)

                        // let res_aux = JSON.parse(JSON.stringify({ id: '', method: 'desk' }))
                        let res_aux = JSON.stringify({ id: '', method: 'desk' })

                        let aux = {
                            key: id_pagamento_2,
                            status: 'success',
                            ref: 'desk',
                            data: date_today.getFullYear() + '-' + (date_today.getMonth() + 1).toString().padStart(2, '0') + '-' + date_today.getDate().toString().padStart(2, '0'),
                            response: res_aux
                        }

                        await reqPOST(`${Hosts.SIMCore_API}/store/payment/notification/status/`, aux)

                        await get_payment_data('desk', '')

                        console.log('passsouiuuuuu11111')
                        func_ws().comprar_prod(get_id_user(), get_lista_carrinho(), get_ws_info())
                        refreshlista()
                        console.log('passsouiuuuuu22222222')

                        console.log('window.open(' + Hosts.WEB_SITE_URI + ", '_self')")
                        // window.location.replace(Hosts.WEB_SITE_URI)
                        window.open(Hosts.WEB_SITE_URI, '_self')

                        await Swal.fire({
                            icon: 'info',
                            html: '<div className="d-flex justify-content-center p-5">' +
                                '<div className="spinner-border" role="status">' +
                                '<span className="visually-hidden">Loading...</span>' +
                                '</div>' +
                            '</div>',
                            showConfirmButton: false,
                            timer: 3000
                        })
        
                        return

                                // refreshlista()

                                // LINPAR CARRINHO
                                // my_local_storage.removeItem('cart')

                                // Swal.fire(language[lang].confirm_purchase_success, '', 'info')
                                //props.history.push(endpoints.list) 

                        //}
                    }
                })

            console.log('akiiiiiii 777777777777')

        }
        else {
           // Swal.fire('', language[lang].encomenda_finalizada_error, 'info')

           await Swal.fire({
                icon: 'info',
                html: language[lang].encomenda_finalizada_error,
                showConfirmButton: false,
                timer: 3000
            })

            setRedirect(true)

        }

    }

    const get_payment_data = async (type, mbw_number) => {

        let body = {
            key: nota_de_encomenda.current.info.id_usado_para_gerar_pagamento,
            value: parseFloat(nota_de_encomenda.current.info.documento.total_documento),
            method: type,
            phone: mbw_number
        }

        let res = await reqPOST(`${Hosts.SIMCore_API}/store/payment/checkout/`, body)
        if (res.data !== undefined && res.data !== null) {
            console.log('res', res)

            await reqPOST(`${Hosts.SIMCore_API}/store/purchase/payment`, { id: '', info: { id: '' + nota_de_encomenda.current.info.id_usado_para_gerar_pagamento + '', companycode: '' + nota_de_encomenda.current.info.companycode + '', obj: res.data } })

            console.log('DONE!!!')

            // await reqGET(`${Hosts.SIMCore_API}/store/order/payment/${Hosts.companycode}/${nota_de_encomenda.current.info.id_usado_para_gerar_pagamento}`)

            console.log('passsouiuuuuu11111')
            refreshlista()
            func_ws().comprar_prod(get_id_user(), get_lista_carrinho(), get_ws_info())
            console.log('passsouiuuuuu22222222')


                // Mensagem de sucesso
                console.log('Desk', nota_de_encomenda.current.info['metodo_de_pagamento'])
                // compose_email(res_2.data[0].id, type, '', '', data_limite)

                // Swal.fire('', language[lang].encomenda_finalizada, 'info')

                await Swal.fire({
                    icon: 'info',
                    html: language[lang].encomenda_finalizada,
                    showConfirmButton: false,
                    timer: 3000
                })

                console.log('$$$$')
                // setRedirect(true)

                setTimeout(() => {
                    // console.log('Hello, World!')
                    console.log('window.open(' + Hosts.WEB_SITE_URI + ", '_self')")
                    window.location.replace(Hosts.WEB_SITE_URI)
                    // window.open(Hosts.WEB_SITE_URI, '_self')
                }, 3000);

            }


    }

    const handler_countdown = (obj) => { // trata de mostrar o tempo a passar
        try {

            let obj_aux = get_lista_carrinho().find((ele) => parseInt(ele.id_stock) === parseInt(obj.info.produto.details[nome_empresa].id_stock))

            if (obj_aux !== undefined) {

                if ((obj_aux.tempo).toString() === '00:00') { // tempo chegou ao fim 

                    removeprod(obj)

                    return ''

                } else {
                    return obj_aux.tempo
                }

            }

        } catch (error) {
            console.log('handler_countdown error', error)
            return ''
        }
    }

    const handler_remover_produto = (obj) => {

        try {

            let ws = get_ws_info()

            if (parseInt(obj.quantidade) === 1) {

                let prod_nao_cativar = {
                    id_prod: obj.produto.id,
                    quantidade: 1,
                    prod_timer: 0,
                    id_user: get_id_user(),
                    timestamp: obj.produto.details[nome_empresa].id_event,
                    id_stock: obj.produto.details[nome_empresa].id_stock
                }

                let prod_lista = [prod_nao_cativar]

                // distinguir se estou a apagar um produto ou a lista toda dos produtos
                if (prod_lista.length === 1) { // caso o utilizador pressione o botao de apagar todos os produtos do carrinho, mas essa lista apenar ter um produto

                    ws.send(JSON.stringify({ cativar: false, data: prod_lista }))

                } else { // caso te ter clicado em apagar tudo da lista de carrinho sendo que esta tem mais do que um produto

                    ws.send(JSON.stringify({ id_user: get_id_user(), cativar: false, data: prod_lista }))

                }

                ws.onmessage = async (event) => {

                    let data_parse = JSON.parse(event.data)
                    let type = data_parse.type
                    console.log('data_parse5', data_parse)

                    // type existe pois é uma forma de identificar se o server esta a enviar 
                    // informacao para atualizar carrinho e lista de stockes ou se esta a atualizar o countdown de um produto 
                    switch (type) {
                        case 'update_list': {
                            try {
                                if (parseInt(get_id_user()) === parseInt(data_parse.info.id_client)) {
                                    if (get_lista_carrinho().length > 0 && data_parse.info.carrinho.length === 0) {

                                        window.location.reload()

                                    }
                                    let temp = []//await func_ws().repor_prods_from_carrinho(data_parse.info.carrinho)

                                    set_lista_carrinho(data_parse.info.carrinho, false, temp)
                                }
                                //set_lista_events(data_parse.lista_produtos)
                            } catch (erro) {
                                console.log('erro cativar_prod211111', erro)
                            }
                            break;
                        }
                        case 'temporal_segundo': {
                            //  console.error('temporal_segundo5', data_parse.data.tempo)

                            try {

                                if (parseInt(get_id_user()) === parseInt(data_parse.data.id_user)) {

                                    if ((data_parse.data.tempo).toString() === '00:00') { // verificar se o countdown do produto chegou a zero
                                        let aux_prod_que_foi_eliminado = await network().get_produtos_by_id(data_parse.data.id_prod)
                                        //alert('O produto ' + aux_prod_que_foi_eliminado.nome + ' foi removido do seu carrinho') // enviar alert para qualquer ecra
                                        Swal.fire({
                                            title: '',
                                            text: language[lang].label_introducao_frase_de_remocao_de_prod + aux_prod_que_foi_eliminado.nome + language[lang].label_final_frase_de_remocao_de_prod,

                                            icon: 'warning',
                                            showCancelButton: false,
                                            confirmButtonColor: '#0095e8',
                                            confirmButtonText: language[lang].label_confirmar,
                                        })
                                            .then((result) => {
                                                window.location.reload()

                                            })
                                    } else {
                                        update_timer(data_parse.data)
                                    }

                                }
                            } catch (erro) {
                                console.log('erro cativar_prod12224', erro)
                            }
                            break;
                        }
                        case 'erro': { // mensagem de erro caso o obj nao ter sido bem atualizado na bd
                            try {

                                if (parseInt(data_parse.id_user) === parseInt(get_id_user())) {

                                    /*   alert(translate_generic_store(data_parse.data, (get_linguagem() !== null ? get_linguagem().toLowerCase() : 'pt'))) */
                                    Swal.fire({
                                        title: '',
                                        text: translate_generic_store(data_parse.data, (get_linguagem() !== null ? get_linguagem().toLowerCase() : 'pt')),
                                        icon: 'error',
                                        showCancelButton: false,
                                        confirmButtonColor: '#0095e8',
                                        confirmButtonText: language[lang].label_confirmar,
                                    })
                                        .then((result) => {
                                            console.log('Swal1')
                                            window.location.reload()
                                        })
                                }

                            } catch (error) {
                                console.log('switch erro handler_remover_produto error', error)
                            }
                            break;
                        }
                        case 'sucesso': { // mensagem de sucesso caso o obj ter sido bem atualizado na bd
                            console.log('sucesso8')

                            try {

                                if (parseInt(data_parse.id_user) === parseInt(get_id_user())) {

                                    //alert(translate_generic_store(data_parse.data, (get_linguagem() !== null ? get_linguagem().toLowerCase() : 'pt')))
                                    Swal.fire({
                                        title: '',
                                        text: translate_generic_store(data_parse.data, (get_linguagem() !== null ? get_linguagem().toLowerCase() : 'pt')),
                                        icon: 'success',
                                        showCancelButton: false,
                                        confirmButtonColor: '#0095e8',
                                        confirmButtonText: language[lang].label_confirmar,
                                    })
                                        .then((result) => {
                                            window.location.reload()
                                        })
                                    removeprod(obj)

                                }
                            } catch (error) {
                                console.log('switch sucesso handler_remover_produto error', error)
                            }
                            break;
                        }
                        default: break;
                    }
                };
            } else {// qtd mais do que 1

                let prod_nao_cativar = {
                    id_prod: obj.produto.id,
                    quantidade: 1,
                    prod_timer: 0,
                    id_user: get_id_user(),
                    timestamp: obj.produto.details[nome_empresa].id_event,
                    id_stock: obj.produto.details[nome_empresa].id_stock
                }

                let id_user = get_id_user()
                let prod_lista = [prod_nao_cativar]

                try {

                    // distinguir se estou a apagar um produto ou a lista toda dos produtos
                    if (prod_lista.length === 1) { // caso o utilizador pressione o botao de apagar todos os produtos do carrinho, mas essa lista apenar ter um produto
                        ws.send(JSON.stringify({ cativar: false, data: prod_lista }))
                    } else { // caso te ter clicado em apagar tudo da lista de carrinho sendo que esta tem mais do que um produto
                        ws.send(JSON.stringify({ id_user: id_user, cativar: false, data: prod_lista }))
                    }

                    ws.onmessage = async (event) => {

                        let data_parse = JSON.parse(event.data)
                        let type = data_parse.type
                        console.log('data_parse6', data_parse)

                        // type existe pois é uma forma de identificar se o server esta a enviar 
                        // informacao para atualizar carrinho e lista de stockes ou se esta a atualizar o countdown de um produto 
                        switch (type) {
                            case 'update_list':
                                try {
                                    if (parseInt(id_user) === parseInt(data_parse.info.id_client)) {
                                        if (get_lista_carrinho().length > 0 && data_parse.info.carrinho.length === 0) {

                                            window.location.reload()

                                        }
                                    let temp = []//await func_ws().repor_prods_from_carrinho(data_parse.info.carrinho)

                                        set_lista_carrinho(data_parse.info.carrinho, false, temp)
                                    }
                                    // set_lista_events(data_parse.lista_produtos)
                                } catch (erro) {
                                    console.log('erro cativar_prod2', erro)
                                }

                                break;
                            case 'temporal_segundo':
                                // console.error('temporal_segundo6', data_parse)

                                //func_ws().func_catch_timer(event, set_lista_carrinho, get_lista_carrinho, id_user)
                                try {

                                    if (parseInt(get_id_user()) === parseInt(data_parse.data.id_user)) {

                                        if ((data_parse.data.tempo).toString() === '00:00') { // verificar se o countdown do produto chegou a zero
                                            let aux_prod_que_foi_eliminado = await network().get_produtos_by_id(data_parse.data.id_prod)
                                            //alert('O produto ' + aux_prod_que_foi_eliminado.nome + ' foi removido do seu carrinho') // enviar alert para qualquer ecra
                                            Swal.fire({
                                                title: '',
                                                text: language[lang].label_introducao_frase_de_remocao_de_prod + aux_prod_que_foi_eliminado.nome + language[lang].label_final_frase_de_remocao_de_prod,
                                                icon: 'warning',
                                                showCancelButton: false,
                                                confirmButtonColor: '#0095e8',
                                                confirmButtonText: language[lang].label_confirmar,
                                            })
                                                .then((result) => {
                                                    window.location.reload()

                                                })
                                        } else {
                                            update_timer(data_parse.data)
                                        }

                                    }
                                } catch (erro) {
                                    console.log('erro cativar_prod12224', erro)
                                }

                                break;
                            case 'erro':  // mensagem de erro caso o obj nao ter sido bem atualizado na bd
                                try {

                                    if (parseInt(data_parse.id_user) === parseInt(get_id_user())) {

                                        //  alert(translate_generic_store(data_parse.data, (get_linguagem() !== null ? get_linguagem().toLowerCase() : 'pt')))
                                        Swal.fire({
                                            title: '',
                                            text: translate_generic_store(data_parse.data, (get_linguagem() !== null ? get_linguagem().toLowerCase() : 'pt')),
                                            icon: 'error',
                                            showCancelButton: false,
                                            confirmButtonColor: '#0095e8',
                                            confirmButtonText: language[lang].label_confirmar,
                                        })
                                            .then((result) => {
                                                window.location.reload()
                                            })
                                    }
                                } catch (error) {
                                    console.log('switch erro1 handler_remover_produto error', error)
                                }

                                break;
                            case 'sucesso':  // mensagem de sucesso caso o obj ter sido bem atualizado na bd
                                console.log('sucesso9')

                                try {

                                    if (parseInt(data_parse.id_user) === parseInt(get_id_user())) {
                                        Swal.fire({
                                            title: '',
                                            text: translate_generic_store(data_parse.data, (get_linguagem() !== null ? get_linguagem().toLowerCase() : 'pt')),
                                            icon: 'success',
                                            showCancelButton: false,
                                            confirmButtonColor: '#0095e8',
                                            confirmButtonText: language[lang].label_confirmar,
                                        })
                                            .then((result) => {
                                                //ws.destroy()
                                                window.location.reload()
                                            })
                                        //alert(translate_generic_store(data_parse.data, (get_linguagem() !== null ? get_linguagem().toLowerCase() : 'pt')))
                                        updateprodnegative(obj.produto.details[nome_empresa].id_stock)

                                    }
                                } catch (error) {
                                    console.log('switch sucesso 1handler_remover_produto error', error)
                                }

                                break;
                            default:
                                break;
                        }
                    };
                } catch (error) {
                    console.log('nao_cativar_prod error', error)
                }

            }

        } catch (error) {
            console.log('handler_remover_produto eror', error)
        }
    }

    const alert_handler_remover_produto = (el) => {
        try {
            return (
                Swal.fire({
                    title: '',
                    text: language[lang].label_eliminar_produto,
                    icon: 'question',

                    showCancelButton: true,
                    confirmButtonColor: '#0095e8',
                    cancelButtonColor: '#d9214e',

                    confirmButtonText: language[lang].label_confirmar,
                    cancelButtonText: language[lang].label_cancelar
                })
                    .then((result) => {
                        if (result.isConfirmed === true) {
                            handler_remover_produto(el.info)
                        }
                    })
            )
        } catch (error) {
            console.log('alert_handler_remover_produto error', error)
        }
    }

    return isRedirect ?
        <Redirect to="/" />
        :
        isloading ?

            <Loader />

            :
            <>
                <Navbar />

                <Breadcrumb />

                <div id="cart" className="container-fluid my-5">
                    <div className="row">
                        <div className="container">

                            <div className='row'>
                                <div className='col-12 col-lg-10'>

                                    <div>
                                        <ul className="timeline d-none">
                                            <li className="completed">
                                                <div className="timestamp">
                                                    <span className="author"></span>
                                                    <span className="date"></span>
                                                </div>
                                                <div className="status">
                                                    <p>Order</p>
                                                </div>
                                            </li>

                                            <li className={step === '1' || step === '2' ? "completed" : ""}>
                                                <div className="timestamp">
                                                    <span className="author"></span>
                                                    <span className="date"></span>
                                                </div>
                                                <div className="status">
                                                    <p>Personal Data</p>
                                                </div>
                                            </li>

                                            <li className={step === '2' ? "completed" : ""}>
                                                <div className="timestamp">
                                                    <span className="author"></span>
                                                    <span className="date"></span>
                                                </div>
                                                <div className="status">
                                                    <p>Payment</p>
                                                </div>
                                            </li>

                                        </ul>

                                        <div className="timeline">
                                            <div className='step'>
                                                <span className={"dot mr-3" + (step === '0' ? " active" : " completed")}>
                                                    {
                                                        step === '0' ?
                                                            <span>1</span>
                                                            :
                                                            <i className="bi bi-check-lg"></i>
                                                    }
                                                </span>
                                                <span className={"label" + (step === '0' ? " active" : " completed")}>{language[lang].encomenda}</span>
                                                <span className={"line mx-3" + (step === '0' ? " active" : " completed")}></span>
                                            </div>

                                            <div className='step'>
                                                <span className={"dot mr-3" + (step === '1' ? " active" : (step === '2' ? " completed" : ""))}>
                                                    {
                                                        step === '0' || step === '1' ?
                                                            <span>2</span>
                                                            :
                                                            <i className="bi bi-check-lg"></i>
                                                    }
                                                </span>
                                                <span className={"label" + (step === '1' ? " active" : (step === '2' ? " completed" : ""))}>{language[lang].dados_pessoais}</span>
                                                <span className={"line mx-3" + (step === '1' ? " active" : (step === '2' ? " completed" : ""))}></span>
                                            </div>
                                            <div className='step'>

                                                <span className={"dot mr-3" + (step === '2' ? " active" : "")}>
                                                    <span>3</span>
                                                </span>
                                                <span className={"label" + (step === '2' ? " active" : "")}>{language[lang].pagamento}</span>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="tab-content">
                                        <div className={step === '0' ? "tab-pane show active" : 'tab-pane'} role="tabpanel">
                                            <div className="row mb-5">
                                                <div className="col-12">
                                                    <h1 className="vertical-title-right">{language[lang].encomendar}</h1>
                                                </div>

                                                <div className="col-12">

                                                    <div className='client-order'>
                                                        <div className="card card-flush py-4 flex-row-fluid overflow-hidden border-0">
                                                            <div className="card-body pt-0">
                                                                <div className="my-3">
                                                                    <h3 className="titulo-primario">{language[lang].my_order}</h3>
                                                                </div>

                                                                <div className="table-responsive">
                                                                    <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">

                                                                        <thead>
                                                                            <tr className="subtitulo-secundario p-4 w-100 bg-color-gray-light">
                                                                                <th className="border-0">{language[lang].product}</th>
                                                                                <th className="text-center border-0">{language[lang].qtd}</th>
                                                                                <th className="text-right border-0">{language[lang].vat}</th>
                                                                                <th className="text-right border-0">{language[lang].total}</th>
                                                                                <th className="text-right border-0"></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody className="fw-bold text-gray-600">
                                                                            {
                                                                                get_lista().length > 0 ?
                                                                                    get_lista().sort((a, b) => {
                                                                                        let aux1 = a.info.produto.id
                                                                                        let aux2 = b.info.produto.id
                                                                                        if (aux1 < aux2) {
                                                                                            return -1;
                                                                                        }
                                                                                        if (aux1 > aux2) {
                                                                                            return 1;
                                                                                        }
                                                                                        return 0;
                                                                                    }).map((el, key) => {
                                                                                        let prod_timestamp = el.info.produto.id_event

                                                                                       // let prod_timestamp = el.info.produto.details[nome_empresa].id_event
                                                                                       // let prod_principal = el.info.produto.composicao.find((el) => el.principal === true)
                                                                                        return (
                                                                                            <tr key={'c_' + key}>
                                                                                                <td>
                                                                                                    <div className="d-flex align-items-center">
                                                                                                        <div className="ms-5">
                                                                                                            <span>
                                                                                                                <strong>{translate_generic_store(el.info.produto.linha.subname_trad, (get_linguagem() !== null ? get_linguagem().toLowerCase() : 'pt'))}</strong>
                                                                                                            </span>
                                                                                                            <br />
                                                                                                            <small className='mr-3'>
                                                                                                                <strong>{translate_generic_store(el.info.produto.linha.observation_trad, (get_linguagem() !== null ? get_linguagem().toLowerCase() : 'pt'))}</strong>
                                                                                                            </small>
                                                                                                            <small className='mr-3'>
                                                                                                                {
                                                                                                                    el.info.produto.data
                                                                                                                   /*  ' ' + el.info.produto.details.goldenrock.dia.split('-')[2] +
                                                                                                                    '-' + el.info.produto.details.goldenrock.dia.split('-')[1] +
                                                                                                                    '-' + el.info.produto.details.goldenrock.dia.split('-')[0] */
                                                                                                                }
                                                                                                            </small>
                                                                                                            <small>
                                                                                                                {translate_horas((get_linguagem() !== null ? get_linguagem() : 'PT'), el.info.produto.linha.details[nome_empresa].validade_data_hora_ini_to_show)}
                                                                                                            </small>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td className="text-center" style={{ fontVariantNumeric: 'tabular-nums' }}>
                                                                                                    {el.quantidade === 0 ? '1' : el.quantidade}
                                                                                                </td>
                                                                                                <td className="text-right" style={{ fontVariantNumeric: 'tabular-nums' }}>
                                                                                                    {isNaN(el.info.produto.linha.taxa_iva) === true ? 0 : parseFloat(el.info.produto.linha.taxa_iva).toFixed(0)} %
                                                                                                </td>
                                                                                                <td className="text-right" style={{ fontVariantNumeric: 'tabular-nums' }}>
                                                                                                    {isNaN(el.info.produto.linha.total_liquido) === true ? 0 : (parseFloat(el.info.produto.linha.total_liquido).toFixed(2) * parseInt((el.quantidade === 0 ? '1' : el.quantidade))).toFixed(2).replace('.', ',')} €
                                                                                                    <br />
                                                                                                    <small style={{ fontVariantNumeric: 'tabular-nums' }}>
                                                                                                        {prod_timestamp === 0 || prod_timestamp === 'null' ? '' : handler_countdown(el)}
                                                                                                    </small>
                                                                                                </td>
                                                                                                <td className="text-right" style={{width: '50px'}}>
                                                                                                    <button className="btn btn-sm btn-secondary px-1 py-0" onClick={() => alert_handler_remover_produto(el)}>
                                                                                                        <i className="bi bi-x"></i>
                                                                                                    </button>
                                                                                                </td>
                                                                                            </tr>

                                                                                        )

                                                                                    })
                                                                                    :
                                                                                    <tr>
                                                                                        <td colSpan="5" className="fs-3 text-dark text-right">
                                                                                            <div className="d-flex justify-content-center p-5">
                                                                                                <div className="spinner-border" role="status">
                                                                                                    <span className="visually-hidden"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                            }

                                                                            <tr className='d-none'>
                                                                                <td colSpan="3" className="text-right">{language[lang].subtotal}</td>
                                                                                <td className="text-right" style={{ fontVariantNumeric: 'tabular-nums' }}>
                                                                                    {parseFloat(subtotal).toFixed(2).replace('.', ',')} €
                                                                                </td>
                                                                                <td></td>
                                                                            </tr>
                                                                            {
                                                                                total_vat.map((el_total_vat, key_total_vat) => {
                                                                                    return (
                                                                                        <tr key={key_total_vat} className="d-none">
                                                                                            <td colSpan="4" className="text-right" style={{ fontVariantNumeric: 'tabular-nums' }}>
                                                                                                {language[lang].vat} ({el_total_vat.id}%)
                                                                                            </td>
                                                                                            <td className="text-right" style={{ fontVariantNumeric: 'tabular-nums' }}>
                                                                                                {parseFloat(el_total_vat.value).toFixed(2).replace('.', ',')} €
                                                                                            </td>
                                                                                            <td></td>
                                                                                        </tr>
                                                                                    )

                                                                                })
                                                                            }
                                                                            <tr>
                                                                                <td colSpan="3" className="fs-3 text-dark text-right">{language[lang].total}</td>
                                                                                <td className="text-dark fs-3 fw-boldest text-right" style={{ fontVariantNumeric: 'tabular-nums' }}>
                                                                                    {parseFloat(get_lista().reduce((accumulator, currentValue) => { return accumulator + (parseInt(currentValue.quantidade) * parseFloat(currentValue.info.produto.linha.total_liquido)) }, 0)).toFixed(2).replace('.', ',')} €
                                                                                </td>
                                                                                <td></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>

                                                                <div className='col-12 text-right mt-3'>
                                                                    <a href={Hosts.WEB_SITE_URI + "desk"} className="btn btn-secondary px-4 mr-3"><i className="bi bi-arrow-left mr-2"></i> {language[lang].prev}</a>
                                                                    <button className="btn btn-secondary px-4 mr-3 d-none" onClick={() => set_step('0')}><i className="bi bi-arrow-left mr-2"></i> {language[lang].prev}</button>
                                                                    <button className="btn btn-primary px-4" onClick={() => { (getTokenInfo() !== undefined && getTokenInfo() !== null && getTokenInfo().profile === 'Cliente') && agree ? set_step('1') : validate_step('1') }}>{language[lang].next} <i className="bi bi-arrow-right ml-2"></i> </button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className={step === '1' ? "tab-pane show active" : 'tab-pane'} role="tabpanel">
                                            <div className="row mb-5">
                                                <div className="col-12">
                                                    <h1 className="vertical-title-right">Encomendar</h1>
                                                </div>

                                                <div className="col-12">

                                                    <div className="client-info">
                                                        <div className="card card-flush py-4 border-0">
                                                            <div className="card-body pt-0">

                                                                <div>
                                                                    <div className="my-3">
                                                                        <h3 className="titulo-primario">{language[lang].dados_faturacao}</h3>
                                                                    </div>
                                                                    <div className="row mb-3">
                                                                        <div className="col-8">
                                                                            <label>{language[lang].dados_nome} (*)</label>
                                                                            <input type="text" name="name" className="form-control" placeholder="" value={user_info.current.name} onChange={handle_change_info_documento_userinfo} />
                                                                        </div>

                                                                        <div className="col-4">
                                                                            <label>{language[lang].dados_vat} (*)</label>
                                                                            <input type="text" name="vat" className="form-control" placeholder="" value={user_info.current.vat} onChange={handle_change_info_documento_userinfo} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mb-3">
                                                                        <div className="col-8">
                                                                            <label>{language[lang].dados_email} (*)</label>
                                                                            <input type="email" name="email" className="form-control" placeholder="" value={(user_info.current.contacts[0].email !== '' ? user_info.current.contacts[0].email : user_info.current.email)} onChange={handle_change_info_documento_userinfo_contacts} />
                                                                        </div>

                                                                        <div className="col-4">
                                                                            <label>{language[lang].dados_mobile_phone}</label>
                                                                            <input type="text" name="mobile_phone" className="form-control" placeholder="" value={user_info.current.contacts[0].mobile_phone} onChange={handle_change_info_documento_userinfo_contacts} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mb-3">
                                                                        <div className="col-8">
                                                                            <label>{language[lang].dados_morada}</label>
                                                                            <textarea type="text" name="address_detail" className="form-control" placeholder="" value={user_info.current.addresses[0].address_detail} onChange={handle_change_info_documento_userinfo_addresses} />
                                                                        </div>

                                                                        <div className="col-4">
                                                                            <label className="form-label">{language[lang].dados_localidade}</label>
                                                                            <input type="text" name="localidade" className="form-control" placeholder="" value={user_info.current.addresses[0].localidade} onChange={handle_change_info_documento_userinfo_addresses} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mb-3">
                                                                        <div className="col-6">
                                                                            <label>{language[lang].dados_codigo_postal}</label>
                                                                            <input type="text" name="postal_code" className="form-control" placeholder="" value={user_info.current.addresses[0].postal_code} onChange={handle_change_info_documento_userinfo_addresses} />
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <label>{language[lang].dados_pais}</label>
                                                                            <input type="text" name="country" className="form-control" placeholder="" value={user_info.current.addresses[0].country} onChange={handle_change_info_documento_userinfo_addresses} />
                                                                        </div>
                                                                    </div>

                                                                    <div className="row mb-3">
                                                                        <div className="col-12">
                                                                            <input type="checkbox" id="agree" onChange={checkboxHandler} defaultChecked={checkboxHandler_reload(agree)} />
                                                                            <label className="pl-2" htmlFor="agree">{language[lang].agree_terms_conditions}</label> <a href={Hosts.WEB_SITE_URI + "terms-conditions"} target="_blank" rel="noreferrer">{language[lang].terms_conditions_link}</a>

                                                                            <label className="pl-2" htmlFor="agree">{language[lang].agree_return_policy}</label> <a href={Hosts.WEB_SITE_URI + "devolucao-e-reembolso"} target="_blank" rel="noreferrer">{language[lang].return_policy_link}</a>

                                                                            <label className="pl-2 small" htmlFor="agree">{language[lang].terms_conditions_required}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='col-12 text-right mt-3'>
                                                                    <button className="btn btn-secondary px-4 mr-3" onClick={() => set_step('0')}><i className="bi bi-arrow-left mr-2"></i> {language[lang].prev}</button>
                                                                    <button className="btn btn-primary px-4" onClick={() => validate_step('2')}>{language[lang].next} <i className="bi bi-arrow-right ml-2"></i> </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className={step === '2' ? "tab-pane show active" : 'tab-pane'} role="tabpanel">
                                            <div className="row mb-5">

                                                <div className="col-12">
                                                    <h1 className="vertical-title-right">Encomendar</h1>
                                                </div>

                                                <div className="col-12">


                                                    <div className="card card-flush py-4 border-0">
                                                        <div className="card-body pt-0">

                                                            <div>

                                                                <div className="my-3">
                                                                    <h3 className="titulo-primario">{language[lang].dados_tipo_pagamento}</h3>
                                                                </div>

                                                                <div className="row mb-3 d-flex justify-content-center">

                                                                    <div className='col-6 col-sm-4'>
                                                                        <button type='button' className='btn btn-primary' onClick={() => set_payment('desk', '-2')}><i className="bi bi-currency-euro"></i></button>
                                                                    </div>

                                                                </div>

                                                                <div className='col-12 text-right mt-3'>
                                                                    <button className="btn btn-secondary px-4" onClick={() => set_step('1')}><i className="bi bi-arrow-left mr-2"></i> {language[lang].prev}</button>
                                                                    <button className="btn btn-primary px-4 d-none" onClick={handleSubmit}>{language[lang].confirm_purchase_confirm} <i className="bi bi-arrow-right ml-2"></i> </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <Footer />
            </>

};
export default CartDesk;

