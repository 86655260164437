import React, { useState, useEffect, useRef } from 'react';

import { Button } from "reactstrap";
import Footer from "../Footer/Footer";


import Navbar from "../Navbar/Navbar";
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import './Forms.css';

import * as Hosts from '../../../Hosts';

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import {
    set_language,
    reqGET,
    reqPOST,
    repo_banner_link,
    passwordHash,
    gerarPassword,
    content_language,
    pattern_email,
    pattern_vat

} from '../../../Utils';


import { language } from '../WEBContent/Language';

import Swal from 'sweetalert2'

const RegisterClientForm = () => {

    const [lang, set_lang] = useState('pt');
    const [, set_lang_id] = useState('1');

    // const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!#$%&/()=?*+';
    const [pwd, set_pwd] = useState('');

    //const [files_doc, set_files_doc] = useState([]);
    //const [files_doc_name, set_files_doc_name] = useState([]);


    // function generateString(length) {
    //     let result = ' ';
    //     const charactersLength = characters.length;
    //     for ( let i = 0; i < length; i++ ) {
    //         result += characters.charAt(Math.floor(Math.random() * charactersLength));
    //     }
    
    //     return result;
    // }

    useEffect(() => {

        set_pwd(gerarPassword());
        

        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }
    }, []);

    const [agree, setAgree] = useState(false);

    const checkboxHandler = () => {
        // if agree === true, it will be set to false
        // if agree === false, it will be set to true
        setAgree(!agree);
        // Don't miss the exclamation mark
    }

    const checkboxHandler_reload = (agree) => {
        //console.log('AGREE', agree)
        if (agree === true)
            return true
        else
            return false
        // if agree === false, it will be set to true
        //setAgree(!agree);
        // Don't miss the exclamation mark
    }

    const data_interface = {
        id: '',
        info: {
            companycode: Hosts.companycode,
            username: '',
            password: '',
            name: '',
            surname: '',
            birthday: '',
            email: '',
            vat: '',
            rgpd: false,
            ativo: true,
            entity_type: '',
            observation: '',
            identificacao: {
                tipo_identificacao:	'',
                numero_identificacao:	'',
                validade_identificacao:	''
            },
            use_preferences: {
                language: 'PT'
            }
        },
        addresses: [{
            //village: '',
            //city: '',
            //type: [],
            //region: '',
            country: '',
            //apartado: '',
            location: '',
            place_birth: '',
            village: '',
            municipality: '',
            //contats: [],
            //principal: '',
            //observation: '',
            postal_code: '',
            address: '',
            //address_detail: '',
        }],
        contacts: [{
            email: '',
    
            principal: 'true',
            //extensions: [],
            observation: '',
    
            //building_number: '',
            /*person_responsables: [{
                id: '',
                name: '',
                email: '',
                extensions: '',
                observation: '',
                mobile_phone: ''
            }],*/
        }],
        profile: { name: "Cliente" },
        notifications: []
    }

    const [, set_dados] = useState(null);
    const dados = useRef(data_interface);

    const [refresh, setRefresh] = useState(0);

    const check_entity_field = async (name, value) => {
		reqGET(`${Hosts.SIMCore_API}/entities/list/${Hosts.companycode}/*`)
			.then(res => {

				let ja_existe = false
				res.data.forEach((k) => {
					//console.log(res.data)
					if (k[name] === value) {
						ja_existe = true
					}
				})

				if (ja_existe === false) {

					return

				} else {
					set_dados(dados.current.info[name] = '');
					setRefresh(refresh + 1)
                    //console.log('aki aki');
                    Swal.fire({
                        title: (name === 'email' ? language[lang].contact_email : language[lang].username_email),
                        text: language[lang].username_error_message,
                        icon: 'error',
                        //showCancelButton: true,
                        confirmButtonColor: '#08396a',
                        //confirmButtonText: 'Tentar novamente',
                      })
                    //alert('Já existe um registo com este username.');
					/*confirmAlert({
						title: 'Aviso',
						message: 'Já existe um registo com este nickname.',
						buttons: [
							{
								label: 'Voltar',
								onClick: () => { 
									//set_dados(dados.current.info.username = '');
									//setRefresh(refresh + 1)
									return
								}
							},

						]
					})*/

					//console.log(ja_existe);
				}
			}
			)
	}

    const handle_change_info = (e) => {
        const { name, value } = e.target;

        //console.log('NAME: ', name)
        //console.log('VALUE: ', value)

        set_dados(dados.current.info[name] = value)

        if ((name === 'email') && value !== '') {
			
			check_entity_field('username', value)
            set_dados(dados.current.info['username'] = value)
            set_dados(dados.current.contacts[0]['email'] = value)
			
		}

        //console.log('Dados Current info:' + JSON.stringify(dados))
    }

    const handle_change_info_2 = (e) => {
		const { name, value } = e.target;

		set_dados(dados.current.info[name] = value)

		//console.log('Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«')
		//console.log('Dados Current info 2:' + JSON.stringify(dados))
	}

    

    const handleSubmit = async e => {
        e.preventDefault();


        Swal.fire('Loading...')

        //alert('SUBMIT');
        //return;

		dados.current.info.password = passwordHash(pwd);

        let mailOptions = {
            from: Hosts.contactFrom,
            // vários emails separados por virgulas
            to: dados.current.info.email,
            //to: 'suporte@assec.pt',
        
            //cc-> opcional
            //cc: Hosts.contactFrom,
            subject: Hosts.webSiteTitle + ' - ' + language[lang].trainee_regist,
            //text: 'Teste de EMAIL ', -> opcional
            html: 
              '<html><head></head><body style="background-color:#F7F7F7;">' +
                '<div style="width:100% !important;margin-top:0;margin-bottom:0;margin-right:0;margin-left:0;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;color:#425968;background-color:#F7F7F7;background-image:none;background-repeat:repeat;background-position:top left;background-attachment:scroll;font-family:Arial,Helvetica,sans-serif;font-size:14px;line-height:16px;">' +
                  '<table border="0" align="center" cellpadding="0" cellspacing="0" style="margin-top:0;margin-bottom:0;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;border-collapse:collapse;">' +
                    '<tr style="border-collapse: collapse;">' +
                      '<td style="border-collapse: collapse;">' +
                        '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; height: 90px; background-color:#ffffff;border:1px solid transparent;border-collapse:collapse;">' +
                          '<tr style="border-collapse: collapse;">' +
                            '<td align="left" class="logo" style="padding:0; border-collapse:collapse;">' +
                              '<a alt="' + Hosts.webSiteTitle + '" title="' + Hosts.webSiteTitle + '" href="' + Hosts.EMAIL_WEB_SITE_URL + '" target="_blank">' +
                                '<img style="width: 600px; height: 90px;" width="600" height="90" src="' + repo_banner_link("banner-email.jpg") + '"/>' +
                              '</a>'+
                            '</td>' +
                          '</tr>' +
                        '</table>' +
                        '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; height: 90px; padding: 15px 30px; background-color:#ffffff;border:1px solid transparent;border-collapse:collapse;">' +
                          '<tr style="border-collapse: collapse;">' +
                            '<td align="left" class style="padding:0 0 10px; border-collapse:collapse;">' +
                              '<div style="width:540px; margin: 0 auto; padding: 15px 30px;">'+
                                '<h4 style="text-transform: uppercase;font-family:Arial,Helvetica,sans-serif;">'+ language[lang].client_regist +'</h4>'+
                                '<span style="font-family:Arial,Helvetica,sans-serif;font-size:14px;line-height:18px;"><b>'+ language[lang].name +': </b><label>' + dados.current.info.name + '</label></span>' +
                                '<br/>'+
                                '<span style="font-family:Arial,Helvetica,sans-serif;font-size:14px;line-height:18px;"><b>'+ language[lang].email +': </b><label>' + dados.current.info.email + '</label></span>'+
                                '<br/>'+
                                '<span style="font-family:Arial,Helvetica,sans-serif;font-size:14px;line-height:18px;"><b>'+ language[lang].nif +': </b><label>' + dados.current.info.nif + '</label></span>'+
                                '<br/>'+
                                '<span style="font-family:Arial,Helvetica,sans-serif;font-size:14px;line-height:18px;"><b>'+ language[lang].birthday +': </b><label>' + dados.current.info.birthday + '</label></span>'+
                                '<br/>'+
                                '<h4 style="font-family:Arial,Helvetica,sans-serif;font-size:14px;line-height:18px;">'+ language[lang].access_data +'</h4>'+
                                '<span style="font-family:Arial,Helvetica,sans-serif;font-size:14px;line-height:18px;"><b>'+ language[lang].username +': </b><label>' + dados.current.info.username + '</label></span>'+
                                '<br/>'+
                                '<span style="font-family:Arial,Helvetica,sans-serif;font-size:14px;line-height:18px;"><b>'+ language[lang].password +': </b></label>'+ pwd +'</label></span>'+
                                '<br/>'+
                                '<br/>'+
                                '<a alt="' + Hosts.webSiteTitle + '" title="' + Hosts.webSiteTitle + '" href="' + Hosts.EMAIL_WEB_BACKOFFICE_URL + '" target="_blank">'+ language[lang].access_app +'</a>'+
                                '<br/>'+
                              '</div>'+
                            '</td>' +
                          '</tr>' +
                        '</table>' +
                        '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; height: 90px; background-color:' + Hosts.FOOTER_EMAIL_COLOR +'; padding: 10px; border:1px solid transparent;border-collapse:collapse;">' +
                          '<tr style="border-collapse: collapse;">' +
                            '<td align="left" style="padding:10px 0px; border-collapse:collapse;">' +
                              '<div style="margin: 0 auto; width:600px;">'+
                                '<div style="text-align: center; font-size: small; color: #FFFFFF;">'+
                                  '<span style="text-transform: uppercase;font-family:Arial,Helvetica,sans-serif;font-size:12px;">' + Hosts.webSiteTitle + '</span>'+
                                  '<br/>'+
                                  '<span style="font-family:Arial,Helvetica,sans-serif;font-size:12px;">' + Hosts.webSiteAdress + '</span>'+
                                  '<br/>'+
                                  '<a alt="' + Hosts.webSiteTitle + '" title="' + Hosts.webSiteTitle + '" href="' + Hosts.EMAIL_WEB_BACKOFFICE_URL + '" target="_blank" style="font-family:Arial,Helvetica,sans-serif;text-decoration:none;"> '+
                                    '<span style="color: #FFFFFF;font-family:Arial,Helvetica,sans-serif;font-size:12px;" >'+ Hosts.EMAIL_WEB_BACKOFFICE_URL +'</span>' +
                                  '</a>'+
                                '</div>'+
                              '</div>' +
                            '</td>' +
                          '</tr>' +
                        '</table>' +
                      '</td>' +
                    '</tr>' +
                  '</table>' +
                '</div>' +
              '<body><html>',
            //attachments -> opcional 
            /*attachments: [     
                {   // encoded string em Base 64
                  filename: 'text1.txt',
                  content: 'aGVsbG8gd29ybGQh',
                  encoding: 'base64'
                },
              ]*/
        
          };

        reqPOST(`${Hosts.SIMCore_API}/entities`, dados.current)
            .then(() => {   
                
                //alert('Registo efetuado com sucesso!\n\nConsulte os seus dados de acesso no email enviado.')
                set_dados(dados.current = JSON.parse(JSON.stringify(data_interface)))
                document.getElementById('agree').checked = false 
                //props.history.push(endpoints.list) 

                Swal.fire({
                    title: language[lang].form_trainee_register,
                    text: language[lang].regist_form_sucess_message,
                    icon: 'success',
                    //showCancelButton: true,
                    confirmButtonColor: '#08396a',
                    //confirmButtonText: 'Tentar novamente',
                  })

                reqPOST(`${Hosts.SIMCore_API_MAIL}`, {data: mailOptions})
                    .then(() => { 
                        console.log('Notificações enviadas com sucesso')
                    })
                    .catch((error) => console.log(error)/*alert('Problema ao enviar notificações!')*/)

            })
            .catch((error) => console.log(error) /*alert('Problema ao atualizar Registo!')*/)

    }

    return (
        <div>
            <Navbar />
            <Breadcrumb />
            <section className="col-12 col-sm-8 offset-sm-2 mb-5">

                <div className="container-fluid bg-color-gray-light mt-100 d-none">
                    <div className="container py-4 mb-4">
                        <h3 className="titulo-primario mb-0">{language[lang].app_form_title}</h3>
                    </div>
                </div>

                <div className="container mt-5">
                    <form onSubmit={handleSubmit} id="trainee_register_form">

                        <div className="row">

                            <div className="col-12 mb-3">
                                {language[lang].read_terms_conditions}
                            </div>

                            <div className="col-12 my-2">
                                <h4 className='text-pp'>{language[lang].personal_data}</h4>
                            </div>

                            <div className="col-12 mb-3">
                                <label className="control-label strong">{language[lang].name}*</label>
                                <input type="text" name="name"
                                    value={dados.current.info.name}
                                    className="form-control"
                                    placeholder={language[lang].name_placeholder}
                                    onChange={handle_change_info}
                                    required
                                />
                            </div>
                            
                            <div className="col-6 col-sm-4 mb-3">
                                <label className="control-label strong">{language[lang].email}*</label>
                                <input 
                                    type="email" 
                                    pattern={pattern_email}
                                    name="email"
                                    value={dados.current.info.email}
                                    className="form-control"
                                    placeholder={language[lang].email_placeholder}
                                    //onChange={handle_change_info}
                                    onBlur={handle_change_info}
                                    onChange={handle_change_info_2}
                                    required
                                />
                            </div>
                            <div className="col-6 col-sm-4 mb-3 d-none">
                                <label className="control-label strong">{language[lang].phone_number}*</label>
                                <PhoneInput
                                    defaultCountry="PT"
                                    className="form-control"
                                    placeholder={language[lang].phone_number_placeholder}
                                    value={dados.current.info.phone !== '' ? dados.current.info.phone : null}
                                    onChange={e => set_dados(dados.current.info.phone = e)} 
                                    /*required*/
                                />
                            </div>
                            <div className="col-6 col-sm-4 mb-3">
                                <label className="control-label strong">{language[lang].birthday}</label>
                                <input type="date" id="date" name="birthday"
                                    value={dados.current.info.birthday}
                                    className="form-control"
                                    placeholder={language[lang].birthday_placeholder}
                                    onChange={handle_change_info}
                                    //required
                                />
                            </div>
                            <div className="col-6 col-sm-4 mb-3">
                                <label className="control-label strong">NIF</label>
                                <input
                                    type="text"
                                    name="vat"
                                    minLength="9"
                                    inputMode='numeric'
                                    value={dados.current.info.vat}
                                    pattern={pattern_vat}
                                    className="form-control"
                                    placeholder={language[lang].nif_placeholder}
                                    onChange={handle_change_info}
                                    //required
                                />
                            </div>

                            <div className="col-12 mb-3">
                                <input type="checkbox" id="agree" onChange={checkboxHandler} defaultChecked={checkboxHandler_reload(agree)} />
                                <label className="pl-2" htmlFor="agree">{language[lang].agree_terms_conditions}</label> <a href={Hosts.WEB_SITE_URI + "terms-conditions"} target="_blank" rel="noreferrer">{language[lang].terms_conditions_link}</a>
                                <label className="pl-2 small" htmlFor="agree">{language[lang].terms_conditions_required}</label>
                            </div>

                            <div className="col-12 text-right">
                                <Button disabled={!agree} className="mt-5 mx-auto" color="primary">{language[lang].send}</Button>
                            </div>

                        </div>

                    </form>
                </div>

            </section>
           <Footer />
        </div>
    );


}

export default RegisterClientForm;