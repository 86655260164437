import React from "react";

import { Helmet } from 'react-helmet';

import * as Hosts from '../../../Hosts';

const ASSEC_Helmet = ({ title, description, keywords }) => {

    return (

        <Helmet>
            <title>{title !== '' ? title + ' - ' + Hosts.SITES.SIMWEBSITE.DEFAULT_SEO_TITLE : Hosts.SITES.SIMWEBSITE.DEFAULT_SEO_TITLE}</title>
            <meta name="description" content={description !== '' ? description : Hosts.SITES.SIMWEBSITE.DEFAULT_SEO_DESCRIPTION} />
            <meta name="keywords" content={keywords !== '' ? keywords : Hosts.SITES.SIMWEBSITE.DEFAULT_SEO_KEYWORDS} />
        </Helmet>

    )
}

export default ASSEC_Helmet;