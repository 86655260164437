const language = {
    'pt': 
        {
            accept_terms:       'Aceito os termos e condições.',
            advice:             'Os conteúdos utilizados na elaboração desta plataforma são meramente ilustrativos, tendo sido retirados (na sua maioria) do website: <a href="https://open.umn.edu/opentextbooks/" target="_blank">https://open.umn.edu/opentextbooks/</a><br> Estes conteúdos são licenciados por autores e editoras para serem livremente utilizados e adaptados.',
            agree_terms_conditions: 'Concordo com os ', 
            agree_return_policy:    ' e a ',
            comprar:                    'Comprar',
            cancel:             'Cancelar',
            confirm_purchase_cancel:    'Cancelar finalização',
            confirm_purchase_confirm:   'Finalizar',
            confirm_purchase_error:     'Erro a processar a encomenda.',
            confirm_purchase_success:   'Encomenda processada com sucesso.',
            confirm_purchase_text:      'Pretende finalizar a encomenda?',
            confirm_purchase_title:     'Confirmar encomenda',
            dados_cidade:               'Cidade',
            dados_cliente:              'Dados do cliente',
            dados_codigo_postal:        'Código-postal',
            dados_email:                'E-mail',
            dados_faturacao:            'Dados de faturação',
            dados_tipo_pagamento:       'Tipo de pagamento',
            dados_localidade:           'Localidade',
            dados_mobile_phone:         'Telemóvel',
            dados_morada:               'Morada',
            dados_nome:                 'Nome',
            dados_pais:                 'País',
            dados_vat:                  'NIF',
            equipamentos:               'Equipamentos', 
            my_order:                   'A minha encomenda',
            next:                       'Continuar',
            pista_aluguer:              'Aluguer da Pista',
            precos:                     'Preços',
            prev:                       'Voltar',
            product:                    'Produto',
            quantidade:                 'Quantidade',
            qtd:                        'Qtd',
            subtotal:                   'Subtotal',
            total:                      'Total',
            terms_conditions:   'Termos e condições',          
            terms_conditions_link:   'termos e condições.',
            return_policy_link:      'política de devolução e reembolso',
            terms_conditions_required: 'Os campos assinalados com (*) são de preenchimento obrigatório. O endereço de email permite um contacto mais rápido em caso de necessidade de esclarecimentos adicionais. A informação que submeter não será usada ou distribuída por outra razão que não o processamento do seu pedido.',
            unit_price:                 'Preço Unitário',
            vat:                        'IVA',
            confirm_login_error:        'O registo é obrigatório.',
            confirm_form_error:         'Os campos assinalados com (*) são de preenchimento obrigatório. O endereço de email permite um contacto mais rápido em caso de necessidade de esclarecimentos adicionais. A informação que submeter não será usada ou distribuída por outra razão que não o processamento do seu pedido.',
            confirm_agree_error:        'Tem de ler e aceitar os termos e condições para finalizar a compra.',
            contact_form:               'Nota de Encomenda',
            name:                       'Nome',
            dados_pagamento:            'Dados de pagamento',
            entity:                     'Entidade',
            reference:                  'Referência',
            estado:                     'Estado',
            aguardar_pagamento:         'A aguardar pagamento',
            num_encomenda:              'N.º da encomenda',
            data_limite:                'Data limite de pagamento',
            data_limite_txt:            'Caso não efectue o pagamento dentro da data limite, a sua encomenda será anulada.<br />Depois do pagamento, receberá a fatura dentro de 72 horas.',
            encomenda_finalizada:       'Encomenda registada com sucesso, consulte o seu e-mail para finalizar a encomenda com sucesso!<br><br>Irá ser redirecionado para a página inicial.',

            encomendar:                 'Encomendar',
            encomenda:                  'Encomenda',
            dados_pessoais:             'Dados pessoais',
            pagamento:                  'Pagamento',
        },
    'en':
        {
            accept_terms:       'Aceito os termos e condições.',
            advice:             'Os conteúdos utilizados na elaboração desta plataforma são meramente ilustrativos, tendo sido retirados (na sua maioria) do website: <a href="https://open.umn.edu/opentextbooks/" target="_blank">https://open.umn.edu/opentextbooks/</a><br> Estes conteúdos são licenciados por autores e editoras para serem livremente utilizados e adaptados.',
            agree_terms_conditions: 'I agree to ', 
            agree_return_policy:    ' and ',
            comprar:                    'Comprar',
            cancel:             'Cancelar',
            confirm_purchase_cancel:    'Cancelar finalização',
            confirm_purchase_confirm:   'Finalizar',
            confirm_purchase_error:     'Erro a processar a encomenda.',
            confirm_purchase_success:   'Encomenda processada com sucesso.',
            confirm_purchase_text:      'Pretende finalizar a encomenda?',
            confirm_purchase_title:     'Confirmar encomenda',
            dados_cidade:               'Cidade',
            dados_cliente:              'Dados do cliente',
            dados_codigo_postal:        'Zip code',
            dados_email:                'E-mail',
            dados_faturacao:            'Billing information',
            dados_tipo_pagamento:       'Payment type',
            dados_localidade:           'Location',
            dados_mobile_phone:         'Mobile phone',
            dados_morada:               'Address',
            dados_nome:                 'Name',
            dados_pais:                 'Country',
            dados_vat:                  'NIF',
            equipamentos:               'Equipamentos', 
            my_order:                   'My order',
            next:                       'Next',
            pista_aluguer:              'Slope rental',
            precos:                     'Preços',
            prev:                       'Back',
            product:                    'Product',
            quantidade:                 'Quantidade',
            qtd:                        'Qtd',
            subtotal:                   'Subtotal',
            total:                      'Total', 
            terms_conditions:   'Terms and conditions',
            terms_conditions_link:   'terms and conditions.',
            return_policy_link:      'return and refund policy',
            terms_conditions_required: 'The fields marked with (*) are required. The email address allows for faster contact in the event of further clarification. The information you submit will not be used or distributed for any other reason other than the processing of your request.',
            unit_price:                 'Unit Price',
            vat:                        'VAT',
            confirm_login_error:        'Registration is mandatory.',
            confirm_form_error:         'Os campos assinalados com (*) são de preenchimento obrigatório. O endereço de email permite um contacto mais rápido em caso de necessidade de esclarecimentos adicionais. A informação que submeter não será usada ou distribuída por outra razão que não o processamento do seu pedido.',
            confirm_agree_error:        'Tem de ler e aceitar os termos e condições para finalizar a compra.',
            contact_form:               'Nota de Encomenda',
            name:                       'Nome',
            dados_pagamento:            'Dados de pagamento',
            entity:                     'Entidade',
            reference:                  'Referência',
            estado:                     'Estado',
            aguardar_pagamento:         'A aguardar pagamento',
            num_encomenda:              'N.º da encomenda',
            data_limite:                'Data limite de pagamento',
            data_limite_txt:            'Caso não efectue o pagamento dentro da data limite, a sua encomenda será anulada.<br />Depois do pagamento, receberá a fatura dentro de 72 horas.',
            encomenda_finalizada:       'Encomenda registada com sucesso, consulte o seu e-mail para finalizar a encomenda com sucesso!<br><br>Irá ser redirecionado para a página inicial.',

            encomendar:                 'Order',
            encomenda:                  'Order',
            dados_pessoais:             'Personal data',
            pagamento:                  'Payment',
        },
    'es':
        {
            accept_terms:       'Aceito os termos e condições.',
            advice:             'Os conteúdos utilizados na elaboração desta plataforma são meramente ilustrativos, tendo sido retirados (na sua maioria) do website: <a href="https://open.umn.edu/opentextbooks/" target="_blank">https://open.umn.edu/opentextbooks/</a><br> Estes conteúdos são licenciados por autores e editoras para serem livremente utilizados e adaptados.',
            agree_terms_conditions:   'Estoy de acuerdo con ',
            agree_return_policy:    ' y ',
            comprar:                    'Comprar',
            cancel:             'Cancelar',
            confirm_purchase_cancel:    'Cancelar finalização',
            confirm_purchase_confirm:   'Finalizar',
            confirm_purchase_error:     'Erro a processar a encomenda.',
            confirm_purchase_success:   'Encomenda processada com sucesso.',
            confirm_purchase_text:      'Pretende finalizar a encomenda?',
            confirm_purchase_title:     'Confirmar encomenda',
            dados_cidade:               'Cidade',
            dados_cliente:              'Dados do cliente',
            dados_codigo_postal:        'Código postal',
            dados_email:                'E-mail',
            dados_faturacao:            'Datos de facturación',
            dados_tipo_pagamento:       'Tipo de pago',
            dados_localidade:           'Localidad',
            dados_mobile_phone:         'Celular',
            dados_morada:               'Direccíon',
            dados_nome:                 'Nombre',
            dados_pais:                 'País',
            dados_vat:                  'NIF',
            equipamentos:               'Equipamentos', 
            my_order:                   'Mi pedido',
            next:                       'Seguir',
            pista_aluguer:              'Alquiler de pista',
            precos:                     'Preços',
            prev:                       'Volver',
            product:                    'Producto',
            quantidade:                 'Quantidade',
            qtd:                        'Ctd',
            subtotal:                   'Subtotal',
            total:                      'Total',
            terms_conditions:   'Términos y condiciones',
            terms_conditions_link:   'términos y condiciones.',
            return_policy_link:      'política de devolución y reembolso',
            terms_conditions_required: 'Los campos marcados con (*) son obligatorios. La dirección de correo electrónico permite un contacto más rápido en caso de más aclaraciones. La información que envíe no será utilizada ni distribuida por ningún otro motivo que no sea el procesamiento de su solicitud.',
            unit_price:                 'Precio unitário',
            vat:                        'VAT',
            confirm_login_error:        'El registro es obligatorio.',
            confirm_form_error:         'Os campos assinalados com (*) são de preenchimento obrigatório. O endereço de email permite um contacto mais rápido em caso de necessidade de esclarecimentos adicionais. A informação que submeter não será usada ou distribuída por outra razão que não o processamento do seu pedido.',
            confirm_agree_error:        'Tem de ler e aceitar os termos e condições para finalizar a compra.',
            contact_form:               'Nota de Encomenda',
            name:                       'Nome',
            dados_pagamento:            'Dados de pagamento',
            entity:                     'Entidade',
            reference:                  'Referência',
            estado:                     'Estado',
            aguardar_pagamento:         'A aguardar pagamento',
            num_encomenda:              'N.º da encomenda',
            data_limite:                'Data limite de pagamento',
            data_limite_txt:            'Caso não efectue o pagamento dentro da data limite, a sua encomenda será anulada.<br />Depois do pagamento, receberá a fatura dentro de 72 horas.',
            encomenda_finalizada:       'Encomenda registada com sucesso, consulte o seu e-mail para finalizar a encomenda com sucesso!<br><br>Irá ser redirecionado para a página inicial.',

            encomendar:                 'Pedir',
            encomenda:                  'Pedido',
            dados_pessoais:             'Datos personales',
            pagamento:                  'Pago',
        },
    'fr':
        {
            accept_terms:       'Aceito os termos e condições.',
            advice:             'Os conteúdos utilizados na elaboração desta plataforma são meramente ilustrativos, tendo sido retirados (na sua maioria) do website: <a href="https://open.umn.edu/opentextbooks/" target="_blank">https://open.umn.edu/opentextbooks/</a><br> Estes conteúdos são licenciados por autores e editoras para serem livremente utilizados e adaptados.',
            agree_terms_conditions:   'Je suis d\'accord avec ',
            agree_return_policy:    ' at la ',
            comprar:                    'Comprar',
            cancel:             'Cancelar',
            confirm_purchase_cancel:    'Cancelar finalização',
            confirm_purchase_confirm:   'Finalizar',
            confirm_purchase_error:     'Erro a processar a encomenda.',
            confirm_purchase_success:   'Encomenda processada com sucesso.',
            confirm_purchase_text:      'Pretende finalizar a encomenda?',
            confirm_purchase_title:     'Confirmar encomenda',
            dados_cidade:               'Cidade',
            dados_cliente:              'Dados do cliente',
            dados_codigo_postal:        'Code postal',
            dados_email:                'E-mail',
            dados_faturacao:            'Données de facturation',
            dados_tipo_pagamento:       'Type de paiement',
            dados_localidade:           'Localité',
            dados_mobile_phone:         'Téléphone',
            dados_morada:               'Adresse',
            dados_nome:                 'Nom',
            dados_pais:                 'Pays',
            dados_vat:                  'NIF',
            equipamentos:               'Equipamentos', 
            my_order:                   'Mon Colis',
            next:                       'Continuer',
            pista_aluguer:              'Location de piste',
            precos:                     'Preços',
            prev:                       'Returner',
            product:                    'Produit',
            quantidade:                 'Quantidade',
            qtd:                        'Qtd',
            subtotal:                   'Subtotal',
            total:                      'Total', 
            terms_conditions:   'Conditions générales',
            terms_conditions_link:   'conditions générales.',
            return_policy_link:      'politique de retour et de remboursement',
            terms_conditions_required: 'Les champs marqués d\'un (*) sont obligatoires. L\'adresse email permet un contact plus rapide en cas de précisions complémentaires. Les informations que vous soumettez ne seront ni utilisées ni distribuées pour toute autre raison que le traitement de votre demande.',
            unit_price:                 'Prix Unitaire',
            vat:                        'VAT',
            confirm_login_error:        'L\'inscription est obligatoire.',
            confirm_form_error:         'Os campos assinalados com (*) são de preenchimento obrigatório. O endereço de email permite um contacto mais rápido em caso de necessidade de esclarecimentos adicionais. A informação que submeter não será usada ou distribuída por outra razão que não o processamento do seu pedido.',
            confirm_agree_error:        'Tem de ler e aceitar os termos e condições para finalizar a compra.',
            contact_form:               'Nota de Encomenda',
            name:                       'Nome',
            dados_pagamento:            'Dados de pagamento',
            entity:                     'Entidade',
            reference:                  'Referência',
            estado:                     'Estado',
            aguardar_pagamento:         'A aguardar pagamento',
            num_encomenda:              'N.º da encomenda',
            data_limite:                'Data limite de pagamento',
            data_limite_txt:            'Caso não efectue o pagamento dentro da data limite, a sua encomenda será anulada.<br />Depois do pagamento, receberá a fatura dentro de 72 horas.',
            encomenda_finalizada:       'Encomenda registada com sucesso, consulte o seu e-mail para finalizar a encomenda com sucesso!<br><br>Irá ser redirecionado para a página inicial.',

            encomendar:                 'Commander',
            encomenda:                  'Colis',
            dados_pessoais:             'Données personnelles.',
            pagamento:                  'Paiement',
        },

}

module.exports = {
    language
};