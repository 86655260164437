import React, { useState, useEffect } from 'react';

/* import { Link } from 'react-router-dom'; */

import Slider from "./Slider/Slider";
import Footer from "./Footer/Footer";

import parse from 'html-react-parser';

import Navbar from "./Navbar/Navbar";

import "./Pagina.css";

import * as Hosts from '../../Hosts';
import {
  set_language,
  reqGET,
  repo_img_link,
  repo_site_assets_link,
  content_language

} from '../../Utils';

import AssecImage from "./ASSEC_Image/Image"

// get our fontawesome imports
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { language } from './WEBContent/Language';

import { Navigation, Pagination, Autoplay, Scrollbar, A11y, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';

import "swiper/swiper-bundle.min.css";

import Modal from "./Forms/OpinionModal"
import Loader from '../../loader';
import Swal from 'sweetalert2';


const Pagina = props => {
  /* variável do tipo array */

  // const Slider = React.lazy(() => import("./Slider/Slider"));

  const [isloading, setLoading] = useState(true);

  const [lang, set_lang] = useState('pt');
  const [lang_id, set_lang_id] = useState('1');
  const [lang_date, set_lang_date] = useState('pt-PT')

  const [hiddenContent, /*sethiddenContent*/] = useState(false);

  //const [dataProject, setDataProject] = useState([]);
  //const [dataInfo, setDataInfo] = useState([]);
  //const [dataInfoResort, setDataInfoResort] = useState([]);

  //const [dataTemperature, setDataTemperature] = useState([]);
  //const [dataPistas, setDataPistas] = useState([]);
  //const [dataMeios, setDataMeios] = useState([]);
  //const [dataLatestUpdateDate, setDataLatestUpdateDate] = useState([]);
  //const [dataLatestUpdateHour, setDataLatestUpdateHour] = useState([]);
  //const [dataDestaque, setDataDestaque] = useState([]);
  //const [dataBanner2, setDataBanner2] = useState([]);
  const [dataParcerias, setDataParcerias] = useState([]);
  //const [dataPlatform, setDataPlatform] = useState([]);
  const [dataTestimony, setDataTestimony] = useState([]);
  //const [, setBanner] = useState([]);

  const [dataMote, setdataMote] = useState([]);

  const [show, setShow] = useState(false);

  /*let getInfoResort = async () => {
    //setLoading(true)
    reqGET(`${Hosts.SIMCore_API}/estancia/config/${Hosts.companycode}/1`)
      .then(res => {
        //setDataInfoResort(res.data)
        setDataTemperature(res.data[0].info.meteo)
        setDataPistas(res.data[0].info.pistas)
        setDataMeios(res.data[0].info.meios)
        setDataLatestUpdateDate(res.data[0].info.date.split('T')[0])
        setDataLatestUpdateHour(res.data[0].info.date.split('T')[1].split('.')[0].slice(0, -3))
        //console.log(res.data[0])
        setLoading(false)
        //calculo_margem()
      })
      .catch(erro => console.log('Erro', erro))
  }*/

  /*let getInfoDestaque = async () => {
    //setLoading(true)
    //reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/93`)
    reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/97`)
      .then(res => {
        setDataDestaque(res.data)
        //console.log(res.data)
        setLoading(false)
        //calculo_margem()
      })
      .catch(erro => console.log('Erro', erro))
  }*/

  /*let getInfoBanner2 = async () => {
    //setLoading(true)
    //reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/95`)
    reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/49`)
      .then(res => {
        setDataBanner2(res.data)
        //console.log(res.data)
        setLoading(false)
        //calculo_margem()
      })
      .catch(erro => console.log('Erro', erro))
  }*/

  let getInfoParcerias = async () => {
    //setLoading(true)
    reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/94`)
      .then(res => {
        setDataParcerias(res.data)
        //console.log(res.data)
        setLoading(false)
        //calculo_margem()
      }).catch(erro => console.log('Erro1', erro))
  }

  /*let getInfoPlatforms = async () => {
    //setLoading(true)

    reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/52`)
      .then(res => {
        setDataPlatform(res.data)
        //console.log('PLATAFORMAS', res.data)

      })
      .catch(erro => alert('Erro'))
  }*/

  let getTestimony = async () => {
    //setLoading(true)

    reqGET(`${Hosts.SIMCore_API}/web/content/type/${Hosts.companycode}/testimony`)
      .then(res => {
        setDataTestimony(res.data.filter(v => v.info.status === 'accepted'))
        setLoading(false)
        //console.log('Testemunhos: ', res.data)

      }).catch(erro =>
        Swal.fire({
          title: '',
          text: language[lang].label_erro_tempo_depois_de_horario_escolhido,
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#0095e8',
          confirmButtonText: language[lang].label_confirmar,
        })
          .then((result) => {
          })



      )
  }

  let getMote = async () => {
    //setLoading(true)
    reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/149`)
      .then(res => {
        setdataMote(res.data)
        //console.log(res.data)
        setLoading(false)
        //calculo_margem()
      })
      .catch(erro => console.log('Erro3', erro))
  }

  useEffect(() => {
    //getInfoResort()
    getMote()
    //getInfoBanner2()
    getInfoParcerias()
    //getInfoPlatforms()
    getTestimony()

    if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
      set_lang('pt')
      set_language('pt')
      set_lang_id('1')
      set_lang_date('pt-PT')



    }
    else {
      set_lang(localStorage.getItem('lang'))
      content_language.forEach(el => {
        if (el.code === localStorage.getItem('lang')) {
          set_lang_id(el.id)

          if(el.code === 'pt') {
            set_lang_date('pt-PT')
          }
          else if(el.code === 'en') {
            set_lang_date('en-EN')
          }
          else if(el.code === 'es') {
            set_lang_date('es-ES')
          }
          else if(el.code === 'fr') {
            set_lang_date('fr-FR')
          }

        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  window.addEventListener('popstate', function (e) {
    console.error('eeeeeeee', e)
    var state = e.state;
    if (state !== null) {
      //load content with ajax
    }
  });
  //window.addEventListener("focus", async() => await on_init(setLoading));
  return (
    isloading ?

      <Loader />

      :

      <div className='welcome'>
        <div className='d-none h1-heading'>
          <h1>{Hosts.webSiteTitle}</h1>
        </div>
       <Navbar pag_welcome={true} />

        <Slider />
        <section id="mote" className="py-5">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center my-3 my-md-5">
                <h3 className="titulo-primario" data-bg-text="Golden Spa">
                  <span>Golden Rock</span> Alambique Water Spa
                </h3>
              </div>
              {
                dataMote.map((v, key) => {
                  //console.log('------------> V', v)
                  //let link_mote = (v.info.slug !== undefined && v.info.slug !== '' ? Hosts.WEB_SITE_URI + '' + v.info.slug : Hosts.WEB_SITE_URI + 'pag/' + v.id);
                  let link_mote = Hosts.WEB_SITE_URI + 'apresentacao-geral';
                  //image = v.content[0].media.image[0].link
                  return (
                    <div key={key} className="col-12">
                      {/*<div className="mb-5">
                            <h3 className="titulo-primario">
                              {lang === 'pt' || v.content[0].language === undefined ? v.content[0].title : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].title === undefined || v.content[0].language[lang_id].title === '' ? v.content[0].title : v.content[0].language[lang_id].title)}
                            </h3>
                            <p className="subtitulo-secundario">
                              {lang === 'pt' || v.content[0].language === undefined ? v.content[0].subtitle : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].subtitle === undefined || v.content[0].language[lang_id].subtitle === '' ? v.content[0].subtitle : v.content[0].language[lang_id].subtitle)}
                            </p>
                          </div>*/}
                      <div className='div-texto column-count-2'>
                        {parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].text : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].text === undefined || v.content[0].language[lang_id].text === '' ? v.content[0].text : v.content[0].language[lang_id].text))}
                      </div>
                      <div className="text-center mt-3">
                        <a href={link_mote} className="btn btn-primary" /*title={language[lang].learn_more}*/>
                          {language[lang].learn_more}
                        </a>
                      </div>
                    </div>
                  )
                })
              }
          
            </div>
          </div>
        </section>

        <section id="banner_visita_virtual" className="bg-dark mt-5">
          <div className="container-fluid py-4">
            <div className="row">
              <div className="container">
                <div className="row text-center align-items-center">
                  <div className="col-12 col-sm-5 offset-sm-1 py-2">
                    <h3 className="titulo-primario font-weight-bold text-white">
                      {parse(language[lang].visita_virtual)}
                    </h3>
                    <p className="text-white">
                      {parse(language[lang].virtual_text)}
                    </p>
                    <a href={Hosts.WEB_SITE_URI + 'visita-virtual'} className="btn btn-secondary mt-3" >
                      {language[lang].virtual_btn}
                    </a>
                  </div>
                  <div className="col-12 col-sm-4 py-2">
                    <a href={Hosts.WEB_SITE_URI + 'visita-virtual'}>
                      <AssecImage
                        linkImage={repo_site_assets_link("visita_virtual.webp")}
                        width="800"
                        height="600"
                        fit='crop'
                        type='img'
                        sClass='img-fluid'
                        alt={parse(language[lang].visita_virtual)}
                        title={parse(language[lang].visita_virtual)}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="video" className="py-5">
          <div className="container">
            <div className="row">

              <div className="col-12 video-component mt-5">
                <div className="embed-responsive embed-responsive-16by9">
                  <div className="embed-responsive-item">
                    <video poster={repo_site_assets_link("video.jpg")} playsInline /*autoPlay*/ muted loop controls>
                      {/*- Video needs to be muted, since Chrome 66+ will not autoplay video with sound.
                          WCAG general accessibility recommendation is that media such as background video play through only once. Loop turned on for the purposes of illustration; if removed, the end of the video will fade in the same way created by pressing the "Pause" button*/}
                      <source src={repo_site_assets_link("video.mp4")} type="video/mp4" />
                      Sorry, your browser doesn't support videos.
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="banner_loja" className="parallax" style={{ backgroundImage: `url(` + repo_img_link("banner-atendimento.jpg") + `)` }}>
          <div className="container-fluid py-100 overlay-img">
            <div className="row">
              <div className="container">
                <div className="row text-center">
                  <div className="col-12 my-5">
                    <h3 className="titulo-primario font-weight-bold text-white" /*data-bg-text={language[lang].testimonials}*/>
                      {parse(language[lang].store_banner_text)}
                    </h3>
                    <a href={Hosts.WEB_SITE_URI + 'store'} className="btn btn-secondary mt-3" /*title={language[lang].learn_more}*/>
                      {language[lang].learn_more}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="parcerias">
          <div className="container-fluid py-5">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center my-5">
                  <h3 className="titulo-primario" data-bg-text={language[lang].partnerships}>
                    {language[lang].partnerships}
                  </h3>
                  {/*<h1 className="vertical-title-right">
                      {language[lang].partnerships}
                  </h1>*/}
                </div>
                <div className="col-12 d-none">
                  <div className="swiper swiper-container swiper-parcerias">
                    <Swiper
                      modules={[Navigation, Autoplay, Pagination, Scrollbar, A11y]}
                      spaceBetween={15}
                      slidesPerView={2}
                      slidesPerGroup={2}
                      //navigation={dataParcerias.length > 1 ? true : false}
                      /*navigation={{
                          nextEl: '.swiper-parcerias-button-next',
                          prevEl: '.swiper-parcerias-button-prev',
                      }}*/
                      loop={true}
                      speed={7000}
                      autoplay={{
                        delay: 5000,
                        disableOnInteraction: false
                      }}
                      //effect="fade"
                      //pagination={dataParcerias.length > 1 ? true : false}
                      pagination={{
                        el: '.swiper-pagination-parcerias',
                        clickable: true
                      }}
                      breakpoints={{
                        // when window width is >= 0px
                        0: {
                          slidesPerView: 2,
                          slidesPerGroup: 2
                        },
                        // when window width is >= 768px
                        768: {
                          slidesPerView: 3,
                          slidesPerGroup: 3
                        },
                        992: {
                          slidesPerView: 5,
                          slidesPerGroup: 5
                        },
                      }}
                    //scrollbar={{ draggable: true }}
                    //onSwiper={(swiper) => console.log(swiper)}
                    //onSlideChange={() => console.log('slide change')}
                    >

                      {
                        dataParcerias.map((v, key) => {

                          //console.log(v)
                          return (

                            v.content[0].media.image.map((v2, key2) => {

                              let img_title = (v2.link.includes(v2.name) || v2.name.includes('webp') || v2.name.includes('jpg') || v2.name.includes('png')) ? '' : v2.name

                              //check if link has http (if not, add it to link)
                              if (v2 !== undefined && v2 !== null && v2.url !== undefined && v2.url !== null && v2.url !== '' && v2.url.substr(0, 4) !== 'http') {
                                v2.url = 'http://' + v2.url;
                                //console.log(v2.url)

                              }
                              //console.log(v2)
                              return (
                                <SwiperSlide key={key2}>
                                  <div className="card">
                                    <div className="product-image position-relative">

                                      {
                                        v2 !== undefined && v2 !== null && v2.url !== undefined && v2.url !== null && v2.url !== '' ?
                                          <a title={v2.name} href={v2.url} target="_blank" rel="noreferrer">
                                            <img alt={v2.name} title={v2.link} className="img-fluid" src={v2.link} />
                                            {/*<p>{v2.name}</p>*/}
                                          </a>
                                          :

                                          <img alt={v2.name} title={img_title} className="img-fluid" src={v2.link} />
                                      }
                                    </div>
                                  </div>
                                </SwiperSlide>
                              )
                            })

                          )
                        })
                      }

                    </Swiper>
                  </div>
                  <div className="swiper-pagination-parcerias my-3"></div>
                </div>
                <div className="col-12">
                  <div className="row row-cols-2 row-cols-sm-3 row-cols-md-5 justify-content-center">
                    {
                      dataParcerias.map((v, key) => {

                        //console.log(v)
                        return (

                          v.content[0].media.image.map((v2, key2) => {

                            //check if link has http (if not, add it to link)
                            if (v2 !== undefined && v2 !== null && v2.url !== undefined && v2.url !== null && v2.url !== '' && v2.url.substr(0, 4) !== 'http') {
                              v2.url = 'http://' + v2.url;
                              //console.log(v2.url)

                            }

                            //console.log(v2)
                            return (
                              <div className="col mb-4" key={key2}>
                                <div className="card">
                                  <div className="partner-image position-relative">
                                    {
                                      v2 !== undefined && v2 !== null && v2.url !== undefined && v2.url !== null && v2.url !== '' /*&& v2.url.substr(0, 4) !== 'http'*/ ?
                                        // v2 !== null && v2.url !== undefined && v2.url !== '' ?
                                        <a alt={v2.name} href={v2.url} target="_blank" rel="noreferrer">
                                          <AssecImage
                                            linkImage={v2.link !== undefined ? v2.link : repo_img_link('nophoto.png')}
                                            width="300"
                                            height="300"
                                            fit='fit'
                                            type='img'
                                            sClass='img-fluid'
                                            alt={v2.name}
                                            title={v2.url}
                                          />
                                        </a>
                                        :
                                        <AssecImage
                                          linkImage={v2.link !== undefined ? v2.link : repo_img_link('nophoto.png')}
                                          width="300"
                                          height="300"
                                          fit='fit'
                                          type='img'
                                          sClass='img-fluid'
                                          alt={v2.name}
                                          title={(v2.link.includes(v2.name) || v2.name.includes('webp') || v2.name.includes('jpg') || v2.name.includes('png')) ? '' : v2.name}
                                        />
                                    }

                                  </div>
                                </div>
                              </div>
                            )
                          })

                        )
                      })
                    }
                  </div>
                </div>
                {/*<div className="col-12 d-none">
                    <div className="mt-5 text-right">
                      <a className="txt-btn" href="#parcerias" title={language[lang].see_it_all}>
                        {language[lang].see_it_all}
                        <FontAwesomeIcon icon={faLongArrowAltRight} />
                      </a>
                    </div>
                  </div>*/}
              </div>
            </div>
          </div>
        </section>

        <section id="opinion" className="parallax" style={{ backgroundImage: `url(` + repo_img_link("banner-testemunhos.jpg") + `)` }}>
          <div className="container-fluid py-5 overlay-img">
            <div className="row">
              <div className="container">
                <div className="row">
                  <div className="col-12 text-center mt-5">
                    <h3 className="titulo-primario text-white" data-bg-text={language[lang].testimonials}>
                      {language[lang].testimonials}
                    </h3>
                    {/*<h1 className="vertical-title-left">
                      {language[lang].testimonials}
                    </h1>*/}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="swiper swiper-container swiper-testemunhos">
                      <Swiper
                        modules={[Autoplay, Navigation, EffectFade, Pagination, Scrollbar, A11y]}

                        speed={8000}
                        loop={false}
                        autoplay={{
                          delay: 5000,
                          disableOnInteraction: false,
                        }}

                        spaceBetween={0}
                        //centeredSlides={true}
                        //centerInsufficientSlides={true}
                        slidesPerView={1}
                        //navigation
                        pagination={{
                          el: '.swiper-pagination-testemunhos',
                          clickable: true
                        }}
                        breakpoints={{
                          // when window width is >= 0px
                          /*0: {
                            slidesPerView: 1,
                          },*/
                          // when window width is >= 768px
                          768: {
                            slidesPerView: 2,
                          },
                          992: {
                            slidesPerView: 2,
                          },
                        }}
                      //scrollbar={{ draggable: true }}
                      >
                        {
                          dataTestimony.map((v, key) => {
                            return (
                              <SwiperSlide key={key} className='p-4'>
                                {/*<img src={repo_site_assets_link("aspas.svg")} alt={language[lang].testimonials} title={language[lang].testimonials} className="img-fluid mb-4" />*/}
                                <div className="testimonial-text">
                                  {parse(v.content[0].message)}
                                </div>
                                <div className='testimonial-title text-white'>
                                  <b>{parse(v.content[0].name)}</b>
                                  {
                                    v.content[0].address !== '' ?
                                      <b>
                                        &nbsp;&#45;&nbsp;
                                        {parse(v.content[0].address)}
                                      </b>
                                      : null
                                  }
                                </div>
                                <div className="small text-white">
                                  {/*v.info.date.creation.split('T')[0]*/}
                                  {new Date(v.info.date.creation).toLocaleDateString((lang_date), { day: "numeric", month: "long", year: "numeric" })}
                                </div>
                              </SwiperSlide>
                            )
                          })
                        }

                      </Swiper>
                    </div>
                    <div className="swiper-pagination-testemunhos px-4 px-lg-0 my-3"></div>
                    <div className="col-12 my-4 my-md-2 text-center">
                      <button type="button" /*onClick={() => setShow(true)}*/ data-toggle="modal" data-target={"#modal_form"} className="btn btn-secondary">{language[lang].testimonials_button_message}</button>
                      <Modal onClose={() => setShow(false)} show={show} />
                    </div>
                  </div>
                  {/*<div className="col-12 col-md-6 p-0 d-none d-sm-block">
                    <div style={{ backgroundImage: `url(` + repo_site_assets_link("testemunhos_image.jpg") + `)` }} alt={language[lang].testimonials} title={language[lang].testimonials} className="img-fluid background-image rounded-20" ></div>
                    <img src={repo_site_assets_link("testemunhos_image.jpg")} alt={language[lang].testimonials} title={language[lang].testimonials} className="img-fluid rounded-20" />
                  </div>*/}
                </div>
              </div>
            </div>
          </div>
        </section>

        {
          hiddenContent ?

            <div>
              <section id="info_resort_fixed" className='d-none'>

                {/*<a href={Hosts.WEB_SITE_URI + 'mapa_pistas/'} className="text-decoration-none" title={language[lang].resort_serra_da_estrela}>
                  <div className="round-card btn_04 text-center">
                    <div className='d-inline-flex'>
                      <div className='d-flex align-items-center justify-content-center mr-2'>
                        <img src={repo_site_assets_link("escola.svg")} alt={language[lang].resort_serra_da_estrela} title={language[lang].resort_serra_da_estrela} />
                      </div>
                      <div className='info-content p-2'>
                        <div className='titulo-secundario'>{language[lang].current_state}&#58;&nbsp;</div>
                        <div className={dataPistas.filter(v => v.state === true).length > 0 ? 'font-weight-bold text-uppercase text-success' : 'font-weight-bold text-uppercase text-danger'}>
                          {dataPistas.filter(v => v.state === true).length > 0 ? language[lang].open : language[lang].closed}
                        </div>
                      </div>
                    </div>
                  </div>
                </a>

                <a href="https://www.meteoestrela.pt/previsoes/neve/" title={language[lang].meteorology} target="_blank" rel="noreferrer" className="text-decoration-none">
                  <div className="round-card btn_01 text-center">
                    <div className='d-inline-flex'>
                      <div className='d-flex align-items-center justify-content-center mr-2'>
                        <img src={repo_site_assets_link("meteorologia.svg")} alt={language[lang].temperature} title={language[lang].temperature} />
                      </div>
                      <div className='info-content p-2'>
                        <h6 className="titulo-secundario">{language[lang].temperature}</h6>
                        <div className="numbers">
                          <span>{dataTemperature.min}ºC</span>
                          &nbsp;&#47;&nbsp;
                          <span>{dataTemperature.max}ºC</span>
                        </div>
                        <div style={{ color: 'var(--body)'}}>
                          <span className="mr-3">min</span>
                          <span className="ml-3">max</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>

                <a href={Hosts.WEB_SITE_URI + 'mapa_pistas/'} className="text-decoration-none" title={language[lang].tracks}>
                  <div className="round-card btn_02 text-center">
                    <div className='d-inline-flex'>
                      <div className='d-flex align-items-center justify-content-center mr-2'>
                        <img src={repo_site_assets_link("pistas.svg")} alt={language[lang].tracks} title={language[lang].tracks} />
                      </div>
                      <div className='info-content p-2'>
                        <h6 className="titulo-secundario">{language[lang].tracks}</h6>
                        <div className="numbers">
                          <span>{dataPistas.filter(v => v.state === true).length}</span>
                          &nbsp;&#47;&nbsp;
                          <span style={{ color: 'var(--body)'}}>{dataPistas.length}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
                
                <a href={Hosts.WEB_SITE_URI + 'mapa_pistas/'} className="text-decoration-none" title={language[lang].mechanical_means}>
                  <div className="round-card btn_03 text-center mb-0">
                    <div className='d-inline-flex'>
                      <div className='d-flex align-items-center justify-content-center mr-2'>
                        <img src={repo_site_assets_link("meios-mecanicos.svg")} alt={language[lang].mechanical_means} title={language[lang].mechanical_means} />
                      </div>
                      <div className='info-content p-2'>
                        <h6 className="titulo-secundario">{language[lang].mechanical_means}</h6>
                        <div className="numbers">
                          <span>{dataMeios.filter(v => v.state === true).length}</span>
                          &nbsp;&#47;&nbsp;
                          <span style={{ color: 'var(--body)'}}>{dataMeios.length}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>*/}
              </section>

              <section id="info_resort" className='d-none'>
                {/*<div className="container-fluid py-5">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 mt-5 text-center">
                        <h3 className="titulo-primario">
                          {language[lang].resort_serra_da_estrela}
                        </h3>
                        <div className='current_state my-3'>
                          <span className='titulo-secundario'>
                            {language[lang].current_state}&#58;&nbsp;
                          </span>
                          <span className={dataPistas.filter(v => v.state === true).length > 0 ? 'font-weight-bold text-uppercase text-success' : 'font-weight-bold text-uppercase text-danger'}>
                            {dataPistas.filter(v => v.state === true).length > 0 ? language[lang].open : language[lang].closed}
                          </span>
                        </div>
                        <p>{language[lang].latest_update}&#58;&nbsp;{dataLatestUpdateDate}&nbsp;{language[lang].at}&nbsp;{dataLatestUpdateHour}</p>
                      </div>

                      <div className="col-12 mt-4">
                        <div className="row justify-content-evenly no-gutters">
                          <div className="round-card text-center">
                            <img src={repo_site_assets_link("meteorologia.svg")} alt={language[lang].temperature} title={language[lang].temperature} className="img-fluid" />
                            <h6 className="titulo-secundario">{language[lang].temperature}</h6>
                            <div className="numbers">
                              <span>{dataTemperature.min}ºC</span>
                              &nbsp;&#47;&nbsp;
                              <span>{dataTemperature.max}ºC</span>
                            </div>
                            <div>
                              <span className="mr-3">min</span>
                              <span className="ml-3">max</span>
                            </div>
                          </div>
                          <a href={Hosts.WEB_SITE_URI + 'mapa_pistas/'} className="text-decoration-none" title={language[lang].tracks}>
                            <div className="round-card text-center">
                              <img src={repo_site_assets_link("pistas.svg")} alt={language[lang].tracks} title={language[lang].tracks} className="img-fluid" />
                              <h6 className="titulo-secundario">{language[lang].tracks}</h6>
                              <div className="numbers">
                                <span>{dataPistas.filter(v => v.state === true).length}</span>
                                &nbsp;&#47;&nbsp;
                                <span style={{ color: 'var(--body)'}}>{dataPistas.length}</span>
                              </div>
                            </div>
                          </a>
                          
                          <a href={Hosts.WEB_SITE_URI + 'mapa_pistas/'} className="text-decoration-none" title={language[lang].mechanical_means}>
                            <div className="round-card text-center">
                              <img src={repo_site_assets_link("meios-mecanicos.svg")} alt={language[lang].mechanical_means} title={language[lang].mechanical_means} className="img-fluid" />
                              <h6 className="titulo-secundario">{language[lang].mechanical_means}</h6>
                              <div className="numbers">
                                <span>{dataMeios.filter(v => v.state === true).length}</span>
                                &nbsp;&#47;&nbsp;
                                <span style={{ color: 'var(--body)'}}>{dataMeios.length}</span>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>*/}
              </section>

              <section id="app" className="parallax d-none" /*style={{ backgroundImage: `url(` + repo_img_link("banner-plataformas.png") + `)` }}*/>
                <div className="container-fluid py-5">
                  <div className="container mb-4">
                    <div className="row">
                      <div className="col-12 text-center">
                        {/*dataPlatform.map((v, key) => {
                          //console.log('------------> V', v)
                          return (
                            <div key={key}>
                              <div className="mb-5">
                                <h3 className="titulo-primario">
                                  {parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].title : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].title === undefined || v.content[0].language[lang_id].title === '' ? v.content[0].title : v.content[0].language[lang_id].title))}
                                </h3>
                                <p className="subtitulo-secundario">
                                  {parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].subtitle : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].subtitle === undefined || v.content[0].language[lang_id].subtitle === '' ? v.content[0].subtitle : v.content[0].language[lang_id].subtitle))}
                                </p>
                              </div>
                              <div className="column-count-2 text-left">
                                {parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].text : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].text === undefined || v.content[0].language[lang_id].text === '' ? v.content[0].text : v.content[0].language[lang_id].text))}
                              </div>
                              <div className="row">
                                {v.content.slice(1).map((v2, key2) => {
                                  let color = key2 === 1 ? '' : (key2 === 0 ? '-red' : '-orange');
                                  return (
                                    //console.log('PROJECT', v.content[0].text)
                                    <div className="col-12 col-lg-4 mt-4 mt-sm-0" key={key2} >
                                      <img className="my-3" src={v2.media.image[0].link} alt={v2.title} height="80px" />
                                      <p className="titulo-primario mb-3">{v2.title}</p>
                                      {parse(v2.text)}
                                      <div className="mt-4">
                                        <a href="." className={"color" + color}>
                                          {language[lang].go_platform}
                                          <FontAwesomeIcon className="ml-2" icon={faLongArrowAltRight} />
                                        </a>
                                      </div>
                                    </div>
                                  )
                                })
                                }
                              </div>
                            </div>
                          )
                        })*/
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section id="programas" className='d-none'>
                <div className="container-fluid py-5">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 my-5">
                        <h2 className="titulo-primario">
                          {language[lang].programs}
                        </h2>
                        <h1 className="vertical-title-left">
                          {language[lang].programs}
                        </h1>
                      </div>
                      <div className="col-12">
                        <div className="swiper swiper-container swiper-programas">
                          <Swiper
                            modules={[Navigation, Autoplay, Pagination, Scrollbar, A11y, EffectFade]}
                            spaceBetween={15}
                            //slidesPerView={3}
                            //navigation={banner.length > 1 ? true : false}
                            /*navigation={{
                                nextEl: '.swiper-programas-button-next',
                                prevEl: '.swiper-programas-button-prev',
                            }}*/
                            loop={false}
                            speed={7000}
                            autoplay={{
                              delay: 5000,
                              disableOnInteraction: false
                            }}
                            //effect="fade"
                            //pagination={banner.length > 1 ? true : false}
                            pagination={{
                              el: '.swiper-pagination-programas',
                              clickable: true
                            }}
                            breakpoints={{
                              // when window width is >= 0px
                              0: {
                                slidesPerView: 1,
                              },
                              // when window width is >= 768px
                              768: {
                                slidesPerView: 2,
                              },
                              992: {
                                slidesPerView: 3,
                              },
                              1200: {
                                slidesPerView: 3,
                              },
                            }}
                          /*scrollbar={{ draggable: true }}*/
                          //onSwiper={(swiper) => console.log(swiper)}
                          //onSlideChange={() => console.log('slide change')}
                          >
                            <SwiperSlide>
                              <div className="card border-0">
                                <div className="product-image position-relative">
                                  <a title="Programa1" href="#programas">
                                    {/*<img src={repo_site_assets_link("programa1.jpg")} alt="Programa1" title="Programa1" className="img-fluid" />*/}
                                    <div style={{ backgroundImage: `url(` + repo_site_assets_link("programa1.jpg") + `)` }} alt="Programa1" title="Programa1" className="img-fluid background-image"></div>
                                  </a>

                                </div>
                                <div className="product-content p-3 bg-color-gray-light">
                                  <div className="info mb-4">
                                    <span className="product-name">Forfait 2 dias</span>
                                    &nbsp;&#47;&nbsp;
                                    <span className="product-group">Adulto</span>
                                    <div className="price float-right">45,00€</div>
                                  </div>
                                  <button className="btn btn-primary float-right">{language[lang].learn_more}</button>
                                </div>
                              </div>

                            </SwiperSlide>
                            <SwiperSlide>
                              <div className="card border-0">
                                <div className="product-image position-relative">
                                  <a alt="Programa2" title="Programa2" href="#programas">
                                    {/*<img src={repo_site_assets_link("programa2.jpg")} alt="Programa2" title="Programa2" className="img-fluid" />*/}
                                    <div style={{ backgroundImage: `url(` + repo_site_assets_link("programa2.jpg") + `)` }} alt="Programa2" title="Programa2" className="img-fluid background-image"></div>

                                  </a>

                                </div>
                                <div className="product-content p-3 bg-color-gray-light">
                                  <div className="info mb-4">
                                    <span className="product-name">Forfait diário</span>
                                    &nbsp;&#47;&nbsp;
                                    <span className="product-group">Família</span>
                                    <div className="price float-right">78,00€</div>
                                  </div>
                                  <button className="btn btn-primary float-right">{language[lang].learn_more}</button>
                                </div>
                              </div>

                            </SwiperSlide>
                            <SwiperSlide>
                              <div className="card border-0">
                                <div className="product-image position-relative">
                                  <a alt="Programa3" title="Programa3" href="#programas">
                                    {/*<img src={repo_site_assets_link("programa3.jpg")} alt="Programa3" title="Programa3" className="img-fluid" />*/}
                                    <div style={{ backgroundImage: `url(` + repo_site_assets_link("programa3.jpg") + `)` }} alt="Programa3" title="Programa3" className="img-fluid background-image"></div>
                                  </a>

                                </div>
                                <div className="product-content p-3 bg-color-gray-light">
                                  <div className="info mb-4">
                                    <span className="product-name">Forfait 3 dias</span>
                                    &nbsp;&#47;&nbsp;
                                    <span className="product-group">Criança</span>
                                    <div className="price float-right">40,00€</div>
                                  </div>
                                  <button className="btn btn-primary float-right">{language[lang].learn_more}</button>
                                </div>
                              </div>

                            </SwiperSlide>
                            <SwiperSlide>
                              <div className="card border-0">
                                <div className="product-image position-relative">
                                  <a alt="Programa4" title="Programa4" href="#programas">
                                    <div style={{ backgroundImage: `url(` + repo_site_assets_link("programa4.jpg") + `)` }} alt="Programa4" title="Programa4" className="img-fluid background-image" ></div>
                                    {/*<img src={repo_site_assets_link("programa4.jpg")} alt="Programa4" title="Programa4" className="img-fluid" />*/}
                                  </a>

                                </div>
                                <div className="product-content p-3 bg-color-gray-light">
                                  <div className="info mb-4">
                                    <span className="product-name">Forfait Teste</span>
                                    &nbsp;&#47;&nbsp;
                                    <span className="product-group">All people</span>
                                    <div className="price float-right">50,00€</div>
                                  </div>
                                  <button className="btn btn-primary float-right">{language[lang].learn_more}</button>
                                </div>
                              </div>

                            </SwiperSlide>


                            {
                              /*programas.map((v, key) => {
                                return (
                                  <SwiperSlide key={key}>
                                    <div className='card'>
                                      <a alt="" href="/#">
                                        <img alt='' className="img-fluid" src={v.content[0].media.image[0].link} />
                                        {parse(v.info.date.published.split('T')[0])}
                                        <p><b>{parse(v.content[0].title)}</b></p>
                                        <p>{parse(v.content[0].abstract)}</p>
                                      </a>
                                    </div>
                                  </SwiperSlide>
                                )
                              })*/
                            }

                          </Swiper>
                        </div>
                        <div className="swiper-pagination-programas my-3"></div>
                      </div>
                      <div className="col-12">
                        <div className="my-2 text-right">
                          <a className="txt-btn" href="#programas" title={language[lang].see_it_all}>
                            {language[lang].see_it_all}
                            <FontAwesomeIcon icon={faLongArrowAltRight} />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section id="destaque_11" className="parallax d-none" /*style={{ backgroundImage: `url(` + repo_img_link("banner_pag_welcome_1.jpg") + `)` }}*/>
                <div className="container-fluid mp-mask">
                  <div className="row">
                    {/*<img alt='Snow' className="img-fluid" src={repo_img_link("banner_snow.svg")} style={{ marginTop: `-1px`}} />*/}
                    <div className="container py-100">
                      <div className="row">
                        <div className="col-12">
                          {
                            /*dataDestaque.map((v, key) => {
                            //console.log('------------> V', v)
                              let link_banner = (v.info.slug !== undefined && v.info.slug !== '' ? Hosts.WEB_SITE_URI + '' + v.info.slug : Hosts.WEB_SITE_URI + 'pag/' + v.id);
                              //image = v.content[0].media.image[0].link
                              return (
                                <div key={key}>
                                    <div className="mb-5">
                                      <h3 className="titulo-primario">
                                        {lang === 'pt' || v.content[0].language === undefined ? v.content[0].title : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].title === undefined || v.content[0].language[lang_id].title === '' ? v.content[0].title : v.content[0].language[lang_id].title)}
                                      </h3>
                                      <p className="subtitulo-secundario">
                                        {lang === 'pt' || v.content[0].language === undefined ? v.content[0].subtitle : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].subtitle === undefined || v.content[0].language[lang_id].subtitle === '' ? v.content[0].subtitle : v.content[0].language[lang_id].subtitle)}
                                      </p>
                                    </div>
                                    <div className='d-none'>
                                      {parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].text : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].text === undefined || v.content[0].language[lang_id].text === '' ? v.content[0].text : v.content[0].language[lang_id].text))}
                                    </div>
                                    <a href={link_banner} className="btn btn-primary" title={language[lang].learn_more}>
                                      {language[lang].learn_more}
                                    </a>
                                </div>
                              )
                            })*/
                          }
                        </div>
                      </div>
                    </div>
                    {/*<img alt='Snow' className="img-fluid" src={repo_img_link("banner_snow.svg")} style={{transform: `rotate(180deg)`, marginBottom: `-1px`}} />*/}
                  </div>
                </div>
              </section>

              <section id="destaque_1" className="parallax" style={{ backgroundImage: `url(` + repo_img_link("banner_pag_welcome_2.jpg") + `)` }}>
                <div className="container-fluid mp-mask">
                  <div className="row">
                    {/*<img alt='Snow' className="img-fluid" src={repo_img_link("banner_snow.svg")} style={{ marginTop: `-1px`}} />*/}
                    <div className="container py-100">
                      <div className="row">
                        <div className="col-12">
                          {
                            /*dataDestaque.map((v, key) => {
                            //console.log('------------> V', v)
                              let link_banner = (v.info.slug !== undefined && v.info.slug !== '' ? Hosts.WEB_SITE_URI + '' + v.info.slug : Hosts.WEB_SITE_URI + 'pag/' + v.id);
                              //image = v.content[0].media.image[0].link
                              return (
                                <div key={key}>
                                    <div className="mb-5">
                                      <h3 className="titulo-primario">
                                        {lang === 'pt' || v.content[0].language === undefined ? v.content[0].title : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].title === undefined || v.content[0].language[lang_id].title === '' ? v.content[0].title : v.content[0].language[lang_id].title)}
                                      </h3>
                                      <p className="subtitulo-secundario">
                                        {lang === 'pt' || v.content[0].language === undefined ? v.content[0].subtitle : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].subtitle === undefined || v.content[0].language[lang_id].subtitle === '' ? v.content[0].subtitle : v.content[0].language[lang_id].subtitle)}
                                      </p>
                                    </div>
                                    <div className='d-none'>
                                      {parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].text : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].text === undefined || v.content[0].language[lang_id].text === '' ? v.content[0].text : v.content[0].language[lang_id].text))}
                                    </div>
                                    <a href={link_banner} className="btn btn-primary" title={language[lang].learn_more}>
                                      {language[lang].learn_more}
                                    </a>
                                </div>
                              )
                            })*/
                          }
                        </div>
                      </div>
                    </div>
                    {/*<img alt='Snow' className="img-fluid" src={repo_img_link("banner_snow.svg")} style={{transform: `rotate(180deg)`, marginBottom: `-1px`}} />*/}
                  </div>
                </div>
              </section>

              <section id="destaque_22" className="parallax d-none" /*style={{ backgroundImage: `url(` + repo_img_link("banner_pag_welcome_2.jpg") + `)` }}*/>
                <div className="container-fluid mp-mask mb-0">
                  <div className="row">
                    {/*<img alt='Snow' className="img-fluid" src={repo_img_link("banner_snow.svg")} style={{ marginTop: `-1px`}} />*/}
                    <div className="container py-100">
                      <div className="row">
                        <div className="col-12 text-right">
                          {
                            /*dataBanner2.map((v, key) => {
                              //console.log('------------> V', v)
                              let link_banner = (v.info.slug !== undefined && v.info.slug !== '' ? Hosts.WEB_SITE_URI + '' + v.info.slug : Hosts.WEB_SITE_URI + 'pag/' + v.id);
                              //image = v.content[0].media.image[0].link
                              return (
                                <div key={key}>
                                    <div className="mb-5">
                                      <h3 className="titulo-primario">
                                        {lang === 'pt' || v.content[0].language === undefined ? v.content[0].title : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].title === undefined || v.content[0].language[lang_id].title === '' ? v.content[0].title : v.content[0].language[lang_id].title)}
                                      </h3>
                                      <p className="subtitulo-secundario">
                                        {lang === 'pt' || v.content[0].language === undefined ? v.content[0].subtitle : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].subtitle === undefined || v.content[0].language[lang_id].subtitle === '' ? v.content[0].subtitle : v.content[0].language[lang_id].subtitle)}
                                      </p>
                                    </div>
                                    <div className='d-none'>
                                      {parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].text : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].text === undefined || v.content[0].language[lang_id].text === '' ? v.content[0].text : v.content[0].language[lang_id].text))}
                                    </div>
                                    <a href={link_banner} className="btn btn-primary mb-5" title={language[lang].learn_more}>
                                      {language[lang].learn_more}
                                    </a>
                                </div>
                              )
                            })*/
                          }
                        </div>
                      </div>
                    </div>
                    {/*<img alt='Snow' className="img-fluid d-none" src={repo_img_link("banner_snow.svg")} style={{transform: `rotate(180deg)`, marginBottom: `-1px`}}/>*/}
                  </div>
                </div>
              </section>

              <section id="destaque_2" className="parallax" style={{ backgroundImage: `url(` + repo_img_link("banner_pag_welcome_1.jpg") + `)` }}>
                <div className="container-fluid mp-mask mb-0">
                  <div className="row">
                    {/*<img alt='Snow' className="img-fluid" src={repo_img_link("banner_snow.svg")} style={{ marginTop: `-1px`}} />*/}
                    <div className="container py-100">
                      <div className="row">
                        <div className="col-12">
                          {
                            /*dataBanner2.map((v, key) => {
                              //console.log('------------> V', v)
                              let link_banner = (v.info.slug !== undefined && v.info.slug !== '' ? Hosts.WEB_SITE_URI + '' + v.info.slug : Hosts.WEB_SITE_URI + 'pag/' + v.id);
                              //image = v.content[0].media.image[0].link
                              return (
                                <div key={key}>
                                    <div className="mb-5">
                                      <h3 className="titulo-primario">
                                        {lang === 'pt' || v.content[0].language === undefined ? v.content[0].title : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].title === undefined || v.content[0].language[lang_id].title === '' ? v.content[0].title : v.content[0].language[lang_id].title)}
                                      </h3>
                                      <p className="subtitulo-secundario">
                                        {lang === 'pt' || v.content[0].language === undefined ? v.content[0].subtitle : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].subtitle === undefined || v.content[0].language[lang_id].subtitle === '' ? v.content[0].subtitle : v.content[0].language[lang_id].subtitle)}
                                      </p>
                                    </div>
                                    <div className='d-none'>
                                      {parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].text : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].text === undefined || v.content[0].language[lang_id].text === '' ? v.content[0].text : v.content[0].language[lang_id].text))}
                                    </div>
                                    <a href={link_banner} className="btn btn-primary mb-5" title={language[lang].learn_more}>
                                      {language[lang].learn_more}
                                    </a>
                                </div>
                              )
                            })*/
                          }
                        </div>
                      </div>
                    </div>
                    {/*<img alt='Snow' className="img-fluid d-none" src={repo_img_link("banner_snow.svg")} style={{transform: `rotate(180deg)`, marginBottom: `-1px`}}/>*/}
                  </div>
                </div>
              </section>

            </div>

            :

            null
        }

        <Footer pag_welcome={true} />

      </div>
  )
}

export default Pagina;