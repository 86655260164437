const language = {
    'pt': 
        { 
            buy:                        'Comprar', 
            equipamentos:               'Equipamentos', 
            finalize_order:             'Finalizar',  
            forfaits:                   'Forfaits', 
            comprar:                    'Comprar',
            voltar:                     'Voltar',
            precos:                     'Preços',
            quantidade:                 'Quantidade',
            no_schedule:                'Sem horários disponíveis',
            schedule:                   'Agendar',
            schedule_code:              'Código de compra',
            schedule_choose:            'Escolher horário',
            choose_aula_type:           'Escolher modalidade da aula',

            purchase_name:              'Nome do aluno/responsável',
            purchase_email:             'Email do aluno/responsável',
            purchase_tlm:               'Tlm do aluno/responsável',
            purchase_level:             'Nível da aula',
            purchase_level_iniciacao:   'Iniciação',
            purchase_level_intermedio:  'Intermédio',
            purchase_level_avancado:    'Avançado',

            mail_scheduele_title:       'Marcação de Aula',
            mail_scheduele_cancel_title:'Cancelar marcação de Aula',
            mail_scheduele_name:        'Nome do Aluno/Responsável',
            mail_scheduele_email:       'E-mail',
            mail_scheduele_tlm:         'Contacto',
            mail_scheduele_type:        'Tipo de aula',
            mail_scheduele_level:       'Nível da aula',
            mail_scheduele_nalunos:     'Número de alunos',
            mail_scheduele_day:         'Data',
            mail_scheduele_hour:        'Início da aula',
            
            confirm_purchase_cancel:    'Cancelar finalização',
            confirm_purchase_confirm:   'Finalizar',
            confirm_purchase_error:     'Erro a processar o agendamento.',
            confirm_purchase_error_codigo: 'Erro a processar o agendamento, falta o código de compra, ou o código já foi usado.',
            confirm_purchase_success:   'Agendamento processado com sucesso.',
            confirm_purchase_text:      'Pretende finalizar o agendamento?',
            confirm_purchase_title:     'Confirmar agendamento',
            cancel_scheduele_cancel:    'Cancelar agendamento',
            cancel_scheduele_confirm:   'Finalizar',
            cancel_scheduele_error:     'Erro a cancelar o agendamento.',
            cancel_scheduele_error_codigo: 'Erro a cancelar o agendamento, falta o código de compra, ou o código incorrecto.',
            cancel_scheduele_success:   'Agendamento cancelado com sucesso.',
            cancel_scheduele_text:      'Pretende cancelar o agendamento?',
            cancel_scheduele_title:      'Cancelar agendamento',
            cancel_scheduele_back:      'Voltar',
        },
    'en':
        {
            buy:                        'Comprar', 
            equipamentos:               'Equipamentos', 
            finalize_order:             'Finalizar',  
            forfaits:                   'Forfaits', 
            comprar:                    'Comprar',
            voltar:                     'Voltar',
            precos:                     'Preços',
            quantidade:                 'Quantidade',
            no_schedule:                'Sem horários disponíveis',
            schedule:                   'Agendar',
            schedule_code:              'Código de compra',
            schedule_choose:            'Escolher horário',
            choose_aula_type:           'Escolher modalidade da aula',

            purchase_name:              'Nome do aluno/responsável',
            purchase_email:             'Email do aluno/responsável',
            purchase_tlm:               'Tlm do aluno/responsável',
            purchase_level:             'Nível da aula',
            purchase_level_iniciacao:   'Iniciação',
            purchase_level_intermedio:  'Intermédio',
            purchase_level_avancado:    'Avançado',

            mail_scheduele_title:       'Marcação de Aula',
            mail_scheduele_cancel_title:'Cancelar marcação de Aula',
            mail_scheduele_name:        'Nome do Aluno/Responsável',
            mail_scheduele_email:       'E-mail',
            mail_scheduele_tlm:         'Contacto',
            mail_scheduele_type:        'Tipo de aula',
            mail_scheduele_level:       'Nível da aula',
            mail_scheduele_nalunos:     'Número de alunos',
            mail_scheduele_day:         'Data',
            mail_scheduele_hour:        'Início da aula',

            confirm_purchase_cancel:    'Cancelar finalização',
            confirm_purchase_confirm:   'Finalizar',
            confirm_purchase_error:     'Erro a processar o agendamento.',
            confirm_purchase_error_codigo: 'Erro a processar o agendamento, falta o código de compra, ou o código já foi usado.',
            confirm_purchase_success:   'Agendamento processado com sucesso.',
            confirm_purchase_text:      'Pretende finalizar o agendamento?',
            confirm_purchase_title:     'Confirmar agendamento',
            cancel_scheduele_cancel:    'Cancelar agendamento',
            cancel_scheduele_confirm:   'Finalizar',
            cancel_scheduele_error:     'Erro a cancelar o agendamento.',
            cancel_scheduele_error_codigo: 'Erro a cancelar o agendamento, falta o código de compra, ou o código incorrecto.',
            cancel_scheduele_success:   'Agendamento cancelado com sucesso.',
            cancel_scheduele_text:      'Pretende cancelar o agendamento?',
            cancel_scheduele_title:      'Cancelar agendamento',
            cancel_scheduele_back:      'Voltar',

        },
    'es':
        {
            buy:                        'Comprar', 
            equipamentos:               'Equipamentos', 
            finalize_order:             'Finalizar',  
            forfaits:                   'Forfaits', 
            comprar:                    'Comprar',
            voltar:                     'Voltar',
            precos:                     'Preços',
            quantidade:                 'Quantidade',
            no_schedule:                'Sem horários disponíveis',
            schedule:                   'Agendar',
            schedule_code:              'Código de compra',
            schedule_choose:            'Escolher horário',
            choose_aula_type:           'Escolher modalidade da aula',

            purchase_name:              'Nome do aluno/responsável',
            purchase_email:             'Email do aluno/responsável',
            purchase_tlm:               'Tlm do aluno/responsável',
            purchase_level:             'Nível da aula',
            purchase_level_iniciacao:   'Iniciação',
            purchase_level_intermedio:  'Intermédio',
            purchase_level_avancado:    'Avançado',

            mail_scheduele_title:       'Marcação de Aula',
            mail_scheduele_cancel_title:'Cancelar marcação de Aula',
            mail_scheduele_name:        'Nome do Aluno/Responsável',
            mail_scheduele_email:       'E-mail',
            mail_scheduele_tlm:         'Contacto',
            mail_scheduele_type:        'Tipo de aula',
            mail_scheduele_level:       'Nível da aula',
            mail_scheduele_nalunos:     'Nímero de alunos',
            mail_scheduele_day:         'Data',
            mail_scheduele_hour:        'Início da aula',

            confirm_purchase_cancel:    'Cancelar finalização',
            confirm_purchase_confirm:   'Finalizar',
            confirm_purchase_error:     'Erro a processar o agendamento.',
            confirm_purchase_error_codigo: 'Erro a processar o agendamento, falta o código de compra, ou o código já foi usado.',
            confirm_purchase_success:   'Agendamento processado com sucesso.',
            confirm_purchase_text:      'Pretende finalizar o agendamento?',
            confirm_purchase_title:     'Confirmar agendamento',
            cancel_scheduele_cancel:    'Cancelar agendamento',
            cancel_scheduele_confirm:   'Finalizar',
            cancel_scheduele_error:     'Erro a cancelar o agendamento.',
            cancel_scheduele_error_codigo: 'Erro a cancelar o agendamento, falta o código de compra, ou o código incorrecto.',
            cancel_scheduele_success:   'Agendamento cancelado com sucesso.',
            cancel_scheduele_text:      'Pretende cancelar o agendamento?',
            cancel_scheduele_title:      'Cancelar agendamento',
            cancel_scheduele_back:      'Voltar',
        }
}

module.exports = {
    language
};