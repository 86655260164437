
import React, { useState, useEffect } from 'react';
import "../Pagina.css";
import "../content-styles.css"
import { set_language, content_language, repo_img_link } from '../../../Utils';
import './GenericStore.css';
import Loader from '../../../loader';
import Navbar from "../Navbar/Navbar";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Footer from "../Footer/Footer";
import { get_format_correct, lista_de_produtos } from './utils';
import { useParams } from "react-router-dom";
import { language } from './Language';

import AssecImage from "../ASSEC_Image/Image"
import { WEB_SITE_URI } from '../../../Hosts';

const ProdByCategory = () => {

    const [isloading, setLoading] = useState(true);
    const [lang, set_lang] = useState('pt');
    const [, set_lang_id] = useState('1');

    const [lista_produtos, set_lista_produtos] = useState([])
    let company_code = '0000000'
    const { category } = useParams();

    const set_info_about_lang = () => {
        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt') // é uma funcao
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }
    }

    const get_produtos = async () => {
        try {
            let list_produtos = await lista_de_produtos([{ codgrupo: category }], company_code)
            set_lista_produtos(list_produtos)
        } catch (error) {
            console.log('get_produtos erro: ', error)
        }
    }


    useEffect(() => {
        (async () => {
            const func_aux = async () => {
                try {
                    set_info_about_lang()
                    await get_produtos()
                    setLoading(false)

                } catch (error) {
                    console.log('func_aux error', error)
                    setLoading(false)

                }

            }
            await func_aux()
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (isloading ?

        <Loader />

        :

        <>
            <Navbar />

            <Breadcrumb />

            <div id="services" className="container-fluid my-5">
                <div className="row">
                    <div className='col-12'>
                        {
                            lista_produtos.length !== 0 ?
                                <div className='grid_container'>
                                    {
                                        lista_produtos.map((obj, key) => {
                                            let element = get_format_correct(obj, lang)
                                            return (
                                                <a key={key} href={WEB_SITE_URI + 'reservation/service/' + obj.id} className="grid_item bg-white pt-1 border-0 color:red"
                                                /* onClick={() => {
                                                    history.push('/reservation/service/' + obj.id);
                                                }} */>
                                                    {/*  <Link className="btn btn-primary float-right" to={{ pathname: '/reservation/service/' + obj.id  }} replace>
                                                            language[lang].comprar <i className="bi bi-arrow-right ml-2 p-2"></i>
                                                        </Link>  */}
                                                    {/*  <a href={WEB_SITE_URI + 'reservation/service/' + obj.id} className="btn btn-secondary mt-3" >
                                                        {language[lang].comprar}
                                                    </a> */}

                                                    <div>
                                                        <AssecImage
                                                            linkImage={element.img.length !== 0 && element.img[0].link !== '' ? element.img[0].link : repo_img_link('nophoto.webp')}
                                                            width="400"
                                                            height="300"
                                                            fit='crop'
                                                            type='img'
                                                            sClass="img-fluid"
                                                            alt=''
                                                        />
                                                    </div>
                                                    <div className='d-flex justify-content-between px-2 mt-2'>
                                                        <div>
                                                            <i className="label_black bi bi-clock mr-1"></i>
                                                            <label className='label_black'>
                                                                {element.duracao_min} {language[lang].label_min}
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label className='label_from mr-1'>
                                                                {language[lang].label_desde}
                                                            </label>
                                                            <label className='label_price font-weight-bold'>
                                                                {parseFloat(element.preco).toFixed(2)}€
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <label className='label_nome_produto font-weight-bold px-2'>
                                                            {element.nome}
                                                            <br />
                                                            <small>{element.observacao}</small>
                                                        </label>
                                                    </div>
                                                </a>
                                            )
                                        })
                                    }
                                </div>
                                :
                                <div className='container'>
                                    <div className='row py-5'>
                                        <div className='col-12 py-5'>
                                            <div className="d-flex justify-content-center p-5 overlay-img">
                                                <h3 className='text-white fs-6 py-5'>
                                                    {language[lang].label_nao_existe_info}
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>

            <Footer />
        </>

    );


}

export default ProdByCategory;
