import React, { useState, useEffect } from 'react';
//import { useLocation } from 'react-router-dom';

import ReactHtmlParser/*, { processNodes, convertNodeToElement, htmlparser2 }*/ from 'react-html-parser';
import parse from 'html-react-parser';
//import { Helmet } from 'react-helmet';

import * as Hosts from '../../../Hosts';
import { reqGET, content_language, set_language, repo_img_link } from '../../../Utils';

import Loader from '../../../loader';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
// import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { language } from '../WEBContent/Language';

import AssecImage from "../ASSEC_Image/Image"

export default function PressCards(props) {
    //const { pathname } = useLocation();

    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState('pt');
    const [lang_id, set_lang_id] = useState('1');

    //const [link, set_link] = useState('')

    // const data_interface = {
    //     id: '',
    //     info: {},
    //     content: {}
    // }
    // const [content, set_content] = useState(data_interface);
    const [list_content, set_list_content] = useState([]);

    useEffect(() => {
        /* console.log('aaaa') */
        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                set_lang_id(el.id)
                }
            })
        }

        try {
            let getInfoList = async () => {
                await reqGET(`${Hosts.SIMCore_API}/web/content/type/${Hosts.companycode}/press`)
                    .then(res => { 
                        if (res.data.length > 0) {

                            /*if(res.data[0].info.source !== undefined && res.data[0].info.source['text'] !== undefined && res.data[0].info.source['text'] !== '') {
                                const { hostname } = new URL(res.data[0].info.source.text)
                                set_link(hostname)
                            }*/

                            var hoje = new Date();
                            let aux = []
                            res.data.forEach((v) => {
                              if(v.info.date.published !== undefined && v.info.date.published !== '') {
                                  var date_p = new Date(v.info.date.published)
                    
                                  if(hoje >= date_p) {
                                    if(v.info.date.avaiable !== '') {
                                        var date_a = new Date(v.info.date.avaiable);

                                        if(hoje <= date_a) {
                                            aux.push(v)
                                        }
                                    }
                                    else {
                                        aux.push(v)
                                    }
                                  }
                              }
                            })
                            set_list_content(aux)
                            //set_list_content(res.data)
                            //console.log('NOTICIAS', res.data)
                  
                            setLoading(false)
                          }
                    })
                    .catch(erro => console.log('ERROR', erro))
            }
            getInfoList()
 
            return

        } catch {
            console.log('nada')

			return
		}
        
    }, []);

    return (isloading ?

		<Loader />
		
		:
        <>
            <Navbar />
            <Breadcrumb />
            <main className="press-cards my-5">
            {
                list_content.length > 0 ?
                    <section id="press-cards" className="content-body">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 mb-5 text-center">
                                    <h3 className="titulo-primario">{language[lang].press}</h3>
                                </div>
                                    
                                {
                                    list_content.map((content) => {
                                        return (
                                            content.content.map((el, k) => {  
                                                return (
                                                    <div key={k} className="col-12 col-lg-6 mb-5">
                                                        <div className="row">
                                                            <div className="col-12 col-sm-5 col-md-4 col-lg-5 h-100 image">
                                                                <div className="div-image"> 
                                                                    {

                                                                        lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['image'] !== undefined && el.language[lang_id]['media']['image'].length > 0 ?
                                                                            <AssecImage 
                                                                                linkImage={el['language'][lang_id]['media']['image'][0]['link']}
                                                                                width="450"
                                                                                height="300"
                                                                                fit='crop'
                                                                                type='img'
                                                                                sClass="img-fluid" 
                                                                                alt={el['language'][lang_id]['media']['image'][0]['name']}
                                                                            />
                                                                        :
                                                                            el['media'] !== undefined && el['media']['image'] !== undefined && el['media']['image'].length > 0 ?
                                                                                <AssecImage 
                                                                                    linkImage={el['media']['image'][0]['link']}
                                                                                    width="450"
                                                                                    height="300"
                                                                                    fit='crop'
                                                                                    type='img'
                                                                                    sClass="img-fluid" 
                                                                                    alt={el['media']['image'][0]['name']}
                                                                                />
                                                                            :

                                                                                <AssecImage 
                                                                                    linkImage={repo_img_link('nophoto.webp')}
                                                                                    width="450"
                                                                                    height="300"
                                                                                    fit='crop'
                                                                                    type='img'
                                                                                    sClass="img-fluid" 
                                                                                    alt="no_photo"
                                                                                />
                                                                        
                                                                    }
                                                                    {/* <img src={repo_img_link('nophoto.png')} className="img-fluid" alt="" /> */}
                                                                </div>
                                                            </div>
                                                            <div className='col-12 col-sm-7 col-md-8 col-lg-7 content'>
                                                                {
                                                                    content.info.date !== undefined && content.info.date['published'] !== '' ?
                                                                        <div className="press-date">
                                                                            {parse(content.info.date.published.split('T')[0])}
                                                                        </div>
                                                                    :
                                                                        null
                                                                }
                                                                <div className="press-title">
                                                                    {(lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title))}
                                                                </div>
                                                                { 
                                                                    el.abstract !== '' ?
                                                                        <div className="press-abstract">
                                                                            {ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.abstract : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].abstract === undefined || el.language[lang_id].abstract === '' ? el.abstract : el.language[lang_id].abstract))}
                                                                        </div>
                                                                    :
                                                                        null
                                                                }
                                                                { 
                                                                    el.text !== '' && el.text !== '<p></p>\n' ?
                                                                        <div class="press-text">
                                                                            {ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.text : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].text === undefined || el.language[lang_id].text === '' || el.language[lang_id].text === '<p></p>\n' ? el.text : el.language[lang_id].text))}
                                                                        </div>
                                                                    :
                                                                        null
                                                                }
                                                                {
                                                                    lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['doc'] !== undefined && el.language[lang_id]['media']['doc'].length > 0 ?
    
                                                                        <a className="pr-3" href={el.language[lang_id]['media']['doc'][0].link} rel="noreferrer" target="_blank">
                                                                            {language[lang].download}<i className="bi bi-download pl-2"></i>
                                                                        </a>
                                                                    :
                                                                        el['media'] !== undefined && el['media']['doc'] !== undefined && el.media.doc.length > 0 ? (
                                                                                                
                                                                            <a className="pr-3" href={el['media']['doc'][0].link} rel="noreferrer" target="_blank">
                                                                                {language[lang].download}<i className="bi bi-download pl-2"></i>
                                                                            </a>

                                                                        ) : null 
                                                                }
                                                                {
                                                                    content.info.source !== undefined && content.info.source['text'] !== undefined ? 
                                                                        content.info.source['text'].includes("http") ? 
                                                                            <a href={content.info.source['text']} target="_blank" rel="noreferrer">
                                                                                {language[lang].external_link}<i className="bi bi-link-45deg pl-2"></i>
                                                                            </a> 
                                                                        : 
                                                                            <a href={'http://' + content.info.source['text']} target="_blank" rel="noreferrer">
                                                                                {language[lang].external_link}<i className="bi bi-link-45deg pl-2"></i>
                                                                            </a>
                                                                    : 
                                                                        null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div> 
                                                )
                                            })
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </section>
                : null
            }
            </main>
            <Footer />
        </>
    )
}