import { useState } from 'react';

import jwt_decode from "jwt-decode";


const getTokenInfo = () => {

  const tokenString = localStorage.getItem('token');
  //console.log(tokenString)
  if (tokenString !== null)
    return jwt_decode(JSON.parse(tokenString).token);
  else
    return null
};

export default function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem('token_site');
    const userToken = JSON.parse(tokenString);
    return userToken?.token
  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    localStorage.setItem('token_site', JSON.stringify(userToken));
    setToken(userToken.token);
  };

  return {
    setToken: saveToken,
    token,
    getTokenInfo
  }
}

export {
  getTokenInfo
}