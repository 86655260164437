import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import './Navbar.css';


import { language } from '../WEBContent/Language';
import CostumModalLogin from '../GenericStore/CostumModalLogin';

import {
    reqGET,
    set_language,
    //repo_site_assets_link, 
    repo_logo_link,
    //repo_site_documents_link, 
    content_language
} from '../../../Utils';

import { faLongArrowAltLeft, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as Hosts from "../../../Hosts";
import { translate_generic_store, translate_horas } from '../GenericStore/utils';
import useContextProvider from '../GenericStore/Providers/ContextProvider';
import { nome_empresa } from '../GenericStore/HOSTS';
import { network } from '../GenericStore/UseService';
import { usarToken } from '../GenericStore/UseToken';

import Swal from 'sweetalert2';


const Navbar = (props) => {

    const data_interface = {
        id: '',
        info: {},
        content: {
            childs: []
        }
    }

    const { updateprodnegative, removeprod, get_lista_carrinho, get_numeroProdutos, get_lista, get_ws_info, get_is_logged, get_id_user, set_lista_carrinho, update_timer, get_linguagem } = useContextProvider()

    const [navbar, set_navbar] = useState(data_interface);
    //const [navbar_active, set_navbar_active] = useState(false);

    const [user_profile, set_user_profile] = useState('');

    const [lang, set_lang] = useState(localStorage.getItem('lang'));

    const [enableFixedSideNavigationMenu, /*setEnableFixedSideNavigationMenu*/] = useState(true);

    //const [mainLogo, setMainLogo] = useState(true)

    //const [disableScroll, setDisableScroll] = useState(false)

    //const [login_name,] = useState(getTokenInfo() === null ? '' : getTokenInfo().name);

    //const page_location = useLocation().pathname.split('/')[2];

    const [/*refresh*/, setRefresh] = useState(0);

    const page_location = useLocation().pathname.split('/').pop();

    const location = useLocation().pathname;

    //const navBarRef = useRef();

    //const [navBarHeight, setNavBarHeight] = useState(null);

    const [open_modal_login, set_open_modal_login] = useState(false)

    function logout() {

        console.log('logout()')

        usarToken().logout()

    }

    /*const changeLogo = () => {

        let speed = '';

        if(mainLogo) {
            speed = 800
        }else{
            speed = 100
        }

        setTimeout(function () {

            setMainLogo(!mainLogo)

        }, speed)

        let documentWidth = document.documentElement.clientWidth;
        let windowWidth = window.innerWidth;
        let scrollBarWidth = windowWidth - documentWidth;

        setTimeout(function () {

            if(!disableScroll) {
                document.body.style.marginRight = scrollBarWidth+"px"
                document.body.style.overflow = "hidden"
            }else{
                document.body.style = ''
                //document.body.style.marginRight = "0px"
                //document.body.style.overflow = "auto";
            }

            setDisableScroll(!disableScroll)

        }, 500)

    }*/

    /*const logoPath = (e) => {

        if(!e) {
            return repo_logo_link("logo_white.svg");
        }else{
            return repo_logo_link("logo.svg");
        }

    }*/

    const change_lang = (l) => {
        set_language(l)
        set_lang(l)
        window.location.reload()
    }

    useEffect(() => {
        set_lang(localStorage.getItem('lang'))

        console.log('get_numeroProdutos --->', get_numeroProdutos())
        console.log('get_is_logged --->', get_is_logged())

        console.log('get_lista --->', get_lista())

        set_navbar({ id: '', info: '', content: { childs: [] } })

        let getInfo = async () => {

            let temp_token_info = await usarToken().getToken()
            let token_info = JSON.parse(temp_token_info)

            if(token_info !== undefined && token_info !== null)
                set_user_profile(token_info.user_info.profile)

            let res = await reqGET(`${Hosts.SIMCore_API}/web/structure/${Hosts.companycode}/1`)
            for await (const v of res.data) {
                if (v.info.principal === true) {

                    if (lang !== 'pt') {
                        let x = 0

                        for await (const cl of content_language) {
                            if (cl.code === lang) {
                                for await (const el of v.content.childs) {
                                    if (el.id_content !== '') {
                                        if (el.children !== undefined) {
                                            x = x - el.children.length

                                            for await (const el_c of el.children) {
                                                let res_3 = await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${el_c.id_content}`)
                                                    // .then(res_3 => {
                                                        if (res_3.data.length > 0) {
                                                            if (res_3.data[0].info.language.length > 0) {
                                                                if (res_3.data[0].info.language[cl.id] !== undefined && res_3.data[0].info.language[cl.id] !== null) {
                                                                    if (res_3.data[0].info.language[cl.id]['name'] !== '') {
                                                                        el_c.title = res_3.data[0].info.language[cl.id]['name'];

                                                                        x++
                                                                        if (v.content.childs.length === x) {
                                                                            setRefresh(refresh => refresh + 1)
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            else {
                                                                x++
                                                                if (v.content.childs.length === x) {
                                                                    setRefresh(refresh => refresh + 1)
                                                                }
                                                            }
                                                        }
                                                        else {
                                                            x++
                                                            if (v.content.childs.length === x) {
                                                                setRefresh(refresh => refresh + 1)
                                                            }
                                                        }
                                                    // })
                                            }
                                        }

                                        let res_2 = await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${el.id_content}`)
                                            // .then(res_2 => {
                                                if (res_2.data.length > 0) {
                                                    if (res_2.data[0].info.language.length > 0) {
                                                        if (res_2.data[0].info.language[cl.id] !== undefined && res_2.data[0].info.language[cl.id] !== null) {
                                                            if (res_2.data[0].info.language[cl.id]['name'] !== '') {
                                                                el.title = res_2.data[0].info.language[cl.id]['name'];

                                                                x++
                                                                if (v.content.childs.length === x) {
                                                                    setRefresh(refresh => refresh + 1)
                                                                }
                                                            }
                                                        }
                                                    }
                                                    else {
                                                        x++
                                                        if (v.content.childs.length === x) {
                                                            setRefresh(refresh => refresh + 1)
                                                        }
                                                    }
                                                }
                                                else {
                                                    x++
                                                    if (v.content.childs.length === x) {
                                                        setRefresh(refresh => refresh + 1)
                                                    }
                                                }
                                            // })


                                    }
                                    else {
                                        if (el['language'] !== undefined && el['language'] !== null) {
                                            if (el.language[cl.id] !== undefined && el.language[cl.id]['title'] !== undefined && el.language[cl.id]['title'] !== '') {
                                                el.title = el.language[cl.id]['title']

                                                x++
                                                if (v.content.childs.length === x) {
                                                    setRefresh(refresh => refresh + 1)
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                    }

                    // console.log('v1', v)
                    set_navbar(v);
                }
            }
        }

        // console.log('navbar', navbar)
        getInfo()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lang])

    const handler_countdown = (obj) => { // trata de mostrar o tempo a passar
        try {

            let obj_aux = get_lista_carrinho().find((ele) => parseInt(ele.id_stock) === parseInt(obj.info.produto.id_stock))

            if (obj_aux !== undefined) {

                if ((obj_aux.tempo).toString() === '00:00') { // tempo chegou ao fim 

                    removeprod(obj)

                    return ''

                } else {
                    return obj_aux.tempo
                }

            }

        } catch (error) {
            console.log('handler_countdown error', error)
            return ''
        }
    }

    const handler_remover_produto = (obj) => {

        try {

            let ws = get_ws_info()

            if (parseInt(obj.quantidade) === 1) {

                let prod_nao_cativar = {
                    id_prod: obj.produto.id_prod,
                    quantidade: 1,
                    prod_timer: 0,
                    id_user: get_id_user(),
                    timestamp: obj.produto.id_event,
                    id_stock: obj.produto.id_stock
                }

                let prod_lista = [prod_nao_cativar]

                // distinguir se estou a apagar um produto ou a lista toda dos produtos
                if (prod_lista.length === 1) { // caso o utilizador pressione o botao de apagar todos os produtos do carrinho, mas essa lista apenar ter um produto

                    ws.send(JSON.stringify({ cativar: false, data: prod_lista }))

                } else { // caso te ter clicado em apagar tudo da lista de carrinho sendo que esta tem mais do que um produto

                    ws.send(JSON.stringify({ id_user: get_id_user(), cativar: false, data: prod_lista }))

                }

                ws.onmessage = async (event) => {
                    //    console.log('data_parse11', data_parse)

                    let data_parse = JSON.parse(event.data)
                    let type = data_parse.type

                    // type existe pois é uma forma de identificar se o server esta a enviar 
                    // informacao para atualizar carrinho e lista de stockes ou se esta a atualizar o countdown de um produto 
                    switch (type) {
                        case 'update_list':
                            try {
                                if (parseInt(get_id_user()) === parseInt(data_parse.info.id_client)) {
                                    if (get_lista_carrinho().length > 0 && data_parse.info.carrinho.length === 0) {

                                        window.location.reload()

                                    }
                                    let temp = []//await func_ws().repor_prods_from_carrinho(data_parse.info.carrinho)

                                    set_lista_carrinho(data_parse.info.carrinho, false, temp)
                                }
                                //set_lista_events(data_parse.lista_produtos)
                            } catch (erro) {
                                console.log('erro cativar_prod211111', erro)
                            }
                            break;


                        case 'temporal_segundo':
                            //  console.error('temporal_segundo11', data_parse.data.tempo)

                            try {

                                if (parseInt(get_id_user()) === parseInt(data_parse.data.id_user)) {

                                    if ((data_parse.data.tempo).toString() === '00:00') { // verificar se o countdown do produto chegou a zero
                                        let aux_prod_que_foi_eliminado = await network().get_produtos_by_id(data_parse.data.id_prod)
                                        //alert('O produto ' + aux_prod_que_foi_eliminado.nome + ' foi removido do seu carrinho') // enviar alert para qualquer ecra
                                        Swal.fire({
                                            title: '',
                                            text: language[lang].label_introducao_frase_de_remocao_de_prod + aux_prod_que_foi_eliminado.nome + language[lang].label_final_frase_de_remocao_de_prod,

                                            icon: 'warning',
                                            showCancelButton: false,
                                            confirmButtonColor: '#0095e8',
                                            confirmButtonText: language[lang].label_confirmar,
                                        })
                                            .then((result) => {
                                                window.location.reload()

                                            })
                                    } else {
                                        update_timer(data_parse.data)
                                    }

                                }
                            } catch (erro) {
                                console.log('erro cativar_prod12224', erro)
                            }
                            break;


                        case 'erro':  // mensagem de erro caso o obj nao ter sido bem atualizado na bd
                            try {

                                if (parseInt(data_parse.id_user) === parseInt(get_id_user())) {

                                    /*   alert(translate_generic_store(data_parse.data, lang.toLowerCase())) */
                                    Swal.fire({
                                        title: '',
                                        text: translate_generic_store(data_parse.data, lang.toLowerCase()),
                                        icon: 'error',
                                        showCancelButton: false,
                                        confirmButtonColor: '#0095e8',
                                        confirmButtonText: language[lang].label_confirmar,
                                    })
                                        .then((result) => {
                                            window.location.reload()
                                        })
                                }
                            } catch (error) {
                                console.log('switch erro handler_remover_produto error', error)
                            }
                            break;


                        case 'sucesso':  // mensagem de sucesso caso o obj ter sido bem atualizado na bd
                            console.log('sucesso14')

                            try {

                                if (parseInt(data_parse.id_user) === parseInt(get_id_user())) {

                                    //alert(translate_generic_store(data_parse.data, lang.toLowerCase()))
                                    Swal.fire({
                                        title: '',
                                        text: translate_generic_store(data_parse.data, lang.toLowerCase()),
                                        icon: 'success',
                                        showCancelButton: false,
                                        confirmButtonColor: '#0095e8',
                                        confirmButtonText: language[lang].label_confirmar,
                                    })
                                        .then((result) => {
                                            window.location.reload()
                                        })
                                    removeprod(obj)

                                }
                            } catch (error) {
                                console.log('switch sucesso handler_remover_produto error', error)
                            }
                            break;


                        default:
                            break;

                    }
                };
            } else {// qtd mais do que 1

                let prod_nao_cativar = {
                    id_prod: obj.produto.id_prod,
                    quantidade: 1,
                    prod_timer: 0,
                    id_user: get_id_user(),
                    timestamp: obj.produto.id_event,
                    id_stock: obj.produto.id_stock
                }

                let id_user = get_id_user()
                let prod_lista = [prod_nao_cativar]

                try {

                    // distinguir se estou a apagar um produto ou a lista toda dos produtos
                    if (prod_lista.length === 1) { // caso o utilizador pressione o botao de apagar todos os produtos do carrinho, mas essa lista apenar ter um produto
                        ws.send(JSON.stringify({ cativar: false, data: prod_lista }))
                    } else { // caso te ter clicado em apagar tudo da lista de carrinho sendo que esta tem mais do que um produto
                        ws.send(JSON.stringify({ id_user: id_user, cativar: false, data: prod_lista }))
                    }

                    ws.onmessage = async (event) => {
                        //console.log('data_parse12', data_parse)

                        let data_parse = JSON.parse(event.data)
                        let type = data_parse.type

                        // type existe pois é uma forma de identificar se o server esta a enviar 
                        // informacao para atualizar carrinho e lista de stockes ou se esta a atualizar o countdown de um produto 
                        switch (type) {
                            case 'update_list':
                                try {
                                    if (parseInt(id_user) === parseInt(data_parse.info.id_client)) {
                                        if (get_lista_carrinho().length > 0 && data_parse.info.carrinho.length === 0) {

                                            window.location.reload()

                                        }
                                        let temp = []//await func_ws().repor_prods_from_carrinho(data_parse.info.carrinho)

                                        set_lista_carrinho(data_parse.info.carrinho, false, temp)
                                    }
                                    // set_lista_events(data_parse.lista_produtos)
                                } catch (erro) {
                                    console.log('erro cativar_prod2', erro)
                                }

                                break;
                            case 'temporal_segundo':
                                // console.error('temporal_segundo12', data_parse.data.tempo)

                                try {

                                    if (parseInt(get_id_user()) === parseInt(data_parse.data.id_user)) {

                                        if ((data_parse.data.tempo).toString() === '00:00') { // verificar se o countdown do produto chegou a zero
                                            let aux_prod_que_foi_eliminado = await network().get_produtos_by_id(data_parse.data.id_prod)
                                            //alert('O produto ' + aux_prod_que_foi_eliminado.nome + ' foi removido do seu carrinho') // enviar alert para qualquer ecra
                                            Swal.fire({
                                                title: '',
                                                text: language[lang].label_introducao_frase_de_remocao_de_prod + aux_prod_que_foi_eliminado.nome + language[lang].label_final_frase_de_remocao_de_prod,
                                                icon: 'warning',
                                                showCancelButton: false,
                                                confirmButtonColor: '#0095e8',
                                                confirmButtonText: language[lang].label_confirmar,
                                            })
                                                .then((result) => {
                                                    window.location.reload()

                                                })
                                        } else {
                                            update_timer(data_parse.data)
                                        }

                                    }
                                } catch (erro) {
                                    console.log('erro cativar_prod12224', erro)
                                }
                                //  func_ws().func_catch_timer(event, set_lista_carrinho, get_lista_carrinho(), id_user)

                                break;
                            case 'erro':  // mensagem de erro caso o obj nao ter sido bem atualizado na bd
                                try {

                                    if (parseInt(data_parse.id_user) === parseInt(get_id_user())) {

                                        //  alert(translate_generic_store(data_parse.data, lang.toLowerCase()))
                                        Swal.fire({
                                            title: '',
                                            text: translate_generic_store(data_parse.data, lang.toLowerCase()),
                                            icon: 'error',
                                            showCancelButton: false,
                                            confirmButtonColor: '#0095e8',
                                            confirmButtonText: language[lang].label_confirmar,
                                        })
                                            .then((result) => {
                                                window.location.reload()
                                            })
                                    }

                                } catch (error) {
                                    console.log('switch erro1 handler_remover_produto error', error)
                                }

                                break;
                            case 'sucesso':  // mensagem de sucesso caso o obj ter sido bem atualizado na bd
                                console.log('sucesso15')

                                try {

                                    if (parseInt(data_parse.id_user) === parseInt(get_id_user())) {
                                        Swal.fire({
                                            title: '',
                                            text: translate_generic_store(data_parse.data, lang.toLowerCase()),
                                            icon: 'success',
                                            showCancelButton: false,
                                            confirmButtonColor: '#0095e8',
                                            confirmButtonText: language[lang].label_confirmar,
                                        })
                                            .then((result) => {
                                                window.location.reload()
                                            })
                                        //alert(translate_generic_store(data_parse.data, lang.toLowerCase()))
                                        updateprodnegative(obj.produto.id_stock)

                                    }
                                } catch (error) {
                                    console.log('switch sucesso 1handler_remover_produto error', error)
                                }

                                break;
                            default:
                                break;
                        }
                    };
                } catch (error) {
                    console.log('nao_cativar_prod error', error)
                }

            }

        } catch (error) {
            console.log('handler_remover_produto eror', error)
        }
    }

    const alert_handler_remover_produto = (el) => {
        try {
            return (
                Swal.fire({
                    title: '',
                    text: language[lang].label_eliminar_produto,
                    icon: 'question',

                    showCancelButton: true,
                    confirmButtonColor: '#0095e8',
                    cancelButtonColor: '#d9214e',

                    confirmButtonText: language[lang].label_confirmar,
                    cancelButtonText: language[lang].label_cancelar
                })
                    .then((result) => {
                        console.log('Swal1', result)
                        if (result.isConfirmed === true) {
                            handler_remover_produto(el.info)
                        }
                    })
            )
        } catch (error) {
            console.log('alert_handler_remover_produto error', error)
        }
    }

    return (
        <>
            {
                /*<div className="fixed-top">*/
                //para colocar navbar fixed na página principal e sticky nas secundárias
                /*<div className={props.pag_welcome ? "fixed-top" : "sticky-top"}>*/
                <div className={"sticky-top"}>
                    <section id="navbar" /*ref={navBarRef}*/>
                        {
                            !enableFixedSideNavigationMenu ?
                                <nav className="navbar navbar-expand-lg bg-navbar py-0 px-0 nav-effect">
                                    <div className="col-12">
                                        <div className="row">

                                            <div className="col-12 col-lg-10 offset-lg-1 top-bar w-100 py-2 small">
                                                <div className="float-left d-none">
                                                    <button className={"pr-2 button_no_border " + (lang === 'pt' ? 'current_lang' : '')} onClick={() => change_lang('pt')}>PT</button>
                                                    &#124;
                                                    <button className={"pr-2 button_no_border " + (lang === 'en' ? 'current_lang' : '')} onClick={() => change_lang('en')}>EN</button>
                                                    &#124;
                                                    <button className={"p-2 button_no_border " + (lang === 'es' ? 'current_lang' : '')} onClick={() => change_lang('es')}>ES</button>
                                                    &#124;
                                                    <button className={"p-2 button_no_border " + (lang === 'fr' ? 'current_lang' : '')} onClick={() => change_lang('fr')}>FR</button>
                                                </div>
                                                <div className="float-right bg-white">
                                                    <button className={"p-2 button_no_border " + (lang === 'pt' ? 'current_lang' : '')} onClick={() => change_lang('pt')}>PT</button>
                                                    &#124;
                                                    <button className={"p-2 button_no_border " + (lang === 'en' ? 'current_lang' : '')} onClick={() => change_lang('en')}>EN</button>
                                                    &#124;
                                                    <button className={"p-2 button_no_border " + (lang === 'es' ? 'current_lang' : '')} onClick={() => change_lang('es')}>ES</button>
                                                    &#124;
                                                    <button className={"p-2 button_no_border " + (lang === 'fr' ? 'current_lang' : '')} onClick={() => change_lang('fr')}>FR</button>
                                                </div>
                                            </div>

                                            <div className="col-12 d-none">
                                                <div className="row text-center border-bottom">
                                                    <a className="navbar-brand pt-0 pb-0 mr-auto ml-auto " href={Hosts.WEB_SITE_URI} alt="">
                                                        <img id="big-logo" src={repo_logo_link("logo.svg")} alt={Hosts.webSiteTitle} title={Hosts.webSiteTitle} className="my-4 svg-logo img-fluid" />
                                                    </a>
                                                </div>
                                            </div>

                                            <div className="col-12 d-lg-none d-flex justify-content-between bg-color-white py-2">
                                                <a className="navbar-brand" href={Hosts.WEB_SITE_URI}>
                                                    <img id="small-logo" className="svg-logo-small img-fluid" src={repo_logo_link("logo-mobile.svg")} alt={Hosts.webSiteTitle} title={Hosts.webSiteTitle} />
                                                </a>
                                                <button className="navbar-toggler float-right" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                                    <span className="navbar-toggler-icon"></span>
                                                </button>
                                            </div>

                                            <div className="col-12 col-lg-10 offset-lg-1 collapse navbar-collapse" id="navbarNav">
                                                <a className="navbar-brand pl-3 d-none d-lg-block py-2" href={Hosts.WEB_SITE_URI} alt="">
                                                    <img id="big-logo" className="svg-logo img-fluid" src={repo_logo_link("logo.svg")} alt={Hosts.webSiteTitle} title={Hosts.webSiteTitle} />
                                                </a>
                                                <div className="top w-100 d-none bg-color-gray-light px-4 py-2">
                                                    {/* <div className="float-left">
                                                <ul className="list-inline m-0">
                                                    <li className="list-inline-item pr-3">
                                                        <a href="http://www.chcbeira.min-saude.pt/" target="_blank" rel="noreferrer">
                                                            <img className="img-fluid" src="./img/logo_chcb.svg" width="180px" alt="Logo CHCB" title="Logo CHCB"/>
                                                        </a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="https://www.sns.gov.pt/" target="_blank" rel="noreferrer">
                                                            <img className="img-fluid" src="./img/logo_sns.svg" width="180px" alt="Logo SNS" title="Logo SNS"/>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div> */}
                                                    {/* <div className="float-left "> */}
                                                    <div className="float-left d-none">
                                                        <button className="pr-2 button_no_border" onClick={() => change_lang('en')}>EN</button>
                                                        |
                                                        <button className="pl-2 button_no_border" onClick={() => change_lang('pt')}>PT</button>
                                                    </div>
                                                    <div className="text-right">
                                                        <a className={"pr-2 " + (page_location === 'form_author' ? 'current_top' : '')} href={Hosts.WEB_SITE_URI + 'form_author'}>{language[lang].regist}</a>
                                                        |
                                                        <a className="pl-2" href={Hosts.WEB_BACKOFFICE_URI} target="_blank" rel="noreferrer">{language[lang].login}</a>
                                                    </div>
                                                </div>
                                                <ul className="navbar-nav ml-auto my-2">
                                                    {/*console.log('RES DATA MENU', navbar)*/}

                                                    {
                                                        props.mostrar_botao_voltar ?

                                                            <li className="nav-item" /* key={key} */ >
                                                                <a href={Hosts.WEB_SITE_URI} className={"nav-link "}>
                                                                    <FontAwesomeIcon className="mr-2" icon={faLongArrowAltLeft} />
                                                                    {language[lang].back}
                                                                </a>
                                                            </li>

                                                            :

                                                            navbar.content.childs.map((v, key) => {

                                                                //console.log(v)

                                                                let link = ''
                                                                v.id_content !== '' ?
                                                                    link = (v.slug !== undefined && v.slug !== '' ? Hosts.WEB_SITE_URI + '' + v.slug : Hosts.WEB_SITE_URI + 'pag/' + v.id_content)
                                                                    :
                                                                    link = (v.slug !== undefined && v.slug !== '' ? Hosts.WEB_SITE_URI + '' + v.slug : (v.link.substr(0, 4) !== 'http' && v.link.substr(0, 3) !== 'www' ? Hosts.WEB_SITE_URI + v.link : (v.link.substr(0, 3) === 'www' ? 'http://' + v.link : v.link)))

                                                                //let link = (v.slug !== undefined && v.slug !== '' ? Hosts.WEB_SITE_URI + '' + v.slug : Hosts.WEB_SITE_URI + 'pag/' + v.id_content);
                                                                let current_top_check = (v.slug !== undefined && v.slug !== '' ? v.slug : (v.id_content !== '' ? v.id_content : v.link));

                                                                if (v.children !== undefined && v.children !== '' && v.children.length > 0) {
                                                                    return (
                                                                        <li className="nav-item dropdown" key={key}>
                                                                            <a className="nav-link px-4 dropdown-toggle" href="/#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false">
                                                                                {v.title}
                                                                                <FontAwesomeIcon icon={faAngleDown} fixedWidth />
                                                                            </a>
                                                                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                                                {
                                                                                    v.children.map((v2, key2) => {

                                                                                        //console.log(v2)

                                                                                        let link_content_dropdown_item = ''
                                                                                        v2.id_content !== '' ?
                                                                                            link_content_dropdown_item = (v2.slug !== undefined && v2.slug !== '' ? Hosts.WEB_SITE_URI + '' + v2.slug : Hosts.WEB_SITE_URI + 'pag/' + v2.id_content)
                                                                                            :
                                                                                            link_content_dropdown_item = (v2.slug !== undefined && v2.slug !== '' ? Hosts.WEB_SITE_URI + '' + v2.slug : (v2.link.substr(0, 4) !== 'http' && v2.link.substr(0, 3) !== 'www' ? Hosts.WEB_SITE_URI + v2.link : (v2.link.substr(0, 3) === 'www' ? 'http://' + v2.link : v2.link)))


                                                                                        //let link_content_dropdown_item = (v2.slug !== undefined && v2.slug !== '' ? Hosts.WEB_SITE_URI + '' + v2.slug : Hosts.WEB_SITE_URI + 'pag/' + v2.id_content);
                                                                                        let current_top_dropdown = (v2.slug !== undefined && v2.slug !== '' ? v2.slug : (v2.id_content !== '' ? v2.id_content : v2.link));

                                                                                        return (
                                                                                            v2.link.substr(0, 4) !== 'http' && v2.link.substr(0, 3) !== 'www' ?
                                                                                                <a key={key2} href={link_content_dropdown_item} className={"dropdown-item " + ((page_location !== '' && page_location === current_top_dropdown) || (page_location !== '' && location === '/' + current_top_dropdown) ? 'current_top' : '')}  >
                                                                                                    {v2.title}
                                                                                                </a>
                                                                                                :
                                                                                                <a key={key2} href={link_content_dropdown_item} className={"dropdown-item " + ((page_location !== '' && page_location === current_top_dropdown) || (page_location !== '' && location === '/' + current_top_dropdown) ? 'current_top' : '')} target="_blank" rel="noreferrer" >
                                                                                                    {v2.title}
                                                                                                </a>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                }
                                                                else {

                                                                    return (
                                                                        v.link.substr(0, 4) !== 'http' && v.link.substr(0, 3) !== 'www' ?
                                                                            <li className="nav-item" key={key} >
                                                                                <a href={link} className={"nav-link " + (page_location === current_top_check ? 'current_top' : '')} >
                                                                                    {v.title}
                                                                                </a>
                                                                            </li>
                                                                            :
                                                                            <li className="nav-item" key={key} >
                                                                                <a href={link} className={"nav-link " + (page_location === current_top_check ? 'current_top' : '')} target="_blank" rel="noreferrer" >
                                                                                    {v.title}
                                                                                </a>
                                                                            </li>

                                                                    )

                                                                }

                                                                /*return (
                                                                    <li className="nav-item" key={key}>
                                                                        <Link activeClassName="active" to={v.link} spy={true} smooth={true} offset={-150} duration={600}
                                                                            className={"nav-link " + (page_location === 'scientia_magazine' ? 'current_top' : '')}>
                                                                            {v.title}
                                                                        </Link>
                                                                    </li>
                                                                )*/
                                                            })
                                                    }

                                                    {/*<div className="d-none">
                                                <li className="nav-item">
                                                    <a className={"nav-link " + (page_location === 'scientia_magazine' ? 'current_top' : '')} href={Hosts.WEB_SITE_URI + 'scientia_magazine'}>{language[lang].scientia_magazine}</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className={"nav-link " + (page_location === 'rules' ? 'current_top' : '')} href={Hosts.WEB_SITE_URI + 'rules'}>{language[lang].submission_rules}</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className={"nav-link " + (page_location === 'submissions' ? 'current_top' : '')} href={Hosts.WEB_SITE_URI + 'submissions'}>{language[lang].articles_submission}</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className={"nav-link " + (page_location === 'search' ? 'current_top' : '')} href={Hosts.WEB_SITE_URI + 'search'}>{language[lang].search}</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className={"nav-link " + (page_location ===  'reviewers' ? 'current_top': '')} href={Hosts.WEB_SITE_URI + 'reviewers'}>Revisores</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className={"nav-link " + (page_location ===  'technical_team' ? 'current_top': '')} href={Hosts.WEB_SITE_URI + 'technical_team'}>{language[lang].technical_team}</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className={"nav-link " + (page_location === 'contacts' ? 'current_top' : '')} href={Hosts.WEB_SITE_URI + 'contacts'}>{language[lang].contacts}</a>
                                                </li>
                                                <li className="nav-item dropdown d-none">
                                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false">
                                                        Teste Dropdown
                                                        <FontAwesomeIcon className="mr-2" icon={faAngleDown} fixedWidth />
                                                    </a>
                                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                        <a className="dropdown-item" href="#">Action</a>
                                                        <a className="dropdown-item" href="#">Another action</a>
                                                        <div className="dropdown-divider"></div>
                                                        <a className="dropdown-item" href="#">Something else here</a>
                                                    </div>
                                                </li>
                                            </div>*/}
                                                </ul>
                                                <div className="overlay"></div>
                                            </div>
                                        </div>
                                    </div>
                                </nav>
                                :
                                <nav className="navbar navbar-expand-none bg-navbar py-0 px-0 nav-effect">
                                    <div className="col-12">
                                        <div className="row">
                                            {/*<div className="d-none d-md-block col-12 top-bar">
                                        <div className="row">
                                            <div className="col-12 col-lg-10 offset-lg-1 px-3 py-0 small d-flex flex-direction-row justify-content-between align-items-center">
                                                <div className=" d-none">
                                                    <button className={"pr-2 button_no_border " + (lang === 'pt' ? 'current_lang' : '')} onClick={() => change_lang('pt')}>PT</button>
                                                    &#124;
                                                    <button className={"pr-2 button_no_border " + (lang === 'en' ? 'current_lang' : '')} onClick={() => change_lang('en')}>EN</button>
                                                    &#124;
                                                    <button className={"p-2 button_no_border " + (lang === 'es' ? 'current_lang' : '')} onClick={() => change_lang('es')}>ES</button>
                                                    &#124;
                                                    <button className={"p-2 button_no_border " + (lang === 'fr' ? 'current_lang' : '')} onClick={() => change_lang('fr')}>FR</button>
                                                </div>
                                                <div className="top-phone mr-3 p-2 d-none">
                                                    <i className="bi bi-telephone"></i>
                                                    <a href="tel:(+351)275094779" title="(+351) 275 094 779">(+351) 275 094 779</a> <small>({language[lang].chamada_rede_fixa})</small>
                                                </div>
                                                <div className="mr-2 mr-2 p-2 small">
                                                    <a href={repo_site_documents_link('CENTRO-04-38D7-FEDER-001180.pdf')} target="_blank" rel="noreferrer">
                                                        {language[lang].projeto_cofinanciado}
                                                    </a>
                                                </div>
                                                <div className="mr-2 mr-2 p-2 small d-none">
                                                    {
                                                    getTokenInfo() !== undefined && getTokenInfo() !== null && getTokenInfo().profile === 'Cliente' ? (
                                                        //'Carrinho | Perfil | Sair'
                                                        <>
                                                            <a href={Hosts.WEB_SITE_URI + 'store/tarifas'} className='mx-1'>
                                                                {language[lang].store}
                                                            </a>
                                                            |
                                                            <a href={Hosts.WEB_SITE_URI + 'store/cart'} className='mx-1'>
                                                                {language[lang].store_cart}
                                                            </a>
                                                            |
                                                            <a href={Hosts.WEB_BACKOFFICE_URI} className='mx-1'>
                                                                {language[lang].my_account}
                                                            </a>
                                                            |
                                                            <a href={Hosts.WEB_SITE_URI} className='mx-1' onClick={logout}>
                                                                {language[lang].logout}
                                                            </a>
                                                        </>
                                                    )
                                                    : 
                                                    <>
                                                        <a href={Hosts.WEB_SITE_URI + 'store/tarifas'} className='mx-1'>
                                                            {language[lang].store}
                                                        </a>
                                                        <a href={Hosts.WEB_BACKOFFICE_URI} className='mx-1' onClick={logout}>
                                                            {language[lang].login}
                                                        </a>
                                                    </>
                                                    }
                                                </div>
                                                <div className="ml-3">
                                                    {
                                                    getTokenInfo() !== undefined && getTokenInfo() !== null && getTokenInfo().profile === 'Cliente' ? (
                                                        //'Carrinho | Perfil | Sair'
                                                        <>
                                                            <a href={Hosts.WEB_SITE_URI + 'store/tarifas'} className='mx-1 d-none'>
                                                                {language[lang].store}
                                                            </a>
                                                            |
                                                            <a href={Hosts.WEB_SITE_URI + 'store/cart'} className='mx-1'>
                                                                {language[lang].store_cart}
                                                            </a>
                                                            |
                                                            <a href={Hosts.WEB_BACKOFFICE_URI} className='mx-1'>
                                                                {language[lang].my_account}
                                                            </a>
                                                            |
                                                            <a href={Hosts.WEB_SITE_URI} className='mx-1' onClick={logout}>
                                                                {language[lang].logout}
                                                            </a>
                                                        </>
                                                    )
                                                    : 
                                                    <>
                                                        <a href={Hosts.WEB_SITE_URI + 'store/tarifas'} className='mx-1 d-none'>
                                                            {language[lang].store}
                                                        </a>
                                                        <a href={Hosts.WEB_BACKOFFICE_URI} className='mx-1' onClick={logout}>
                                                            {language[lang].login}
                                                        </a>
                                                    </>
                                                    }

                                                    <button className={"p-2 button_no_border " + (lang === 'pt' ? 'current_lang' : '')} onClick={() => change_lang('pt')}>PT</button>
                                                    &#124;
                                                    <button className={"p-2 button_no_border " + (lang === 'en' ? 'current_lang' : '')} onClick={() => change_lang('en')}>EN</button>
                                                    &#124;
                                                    <button className={"p-2 button_no_border " + (lang === 'es' ? 'current_lang' : '')} onClick={() => change_lang('es')}>ES</button>
                                                    &#124;
                                                    <button className={"p-2 button_no_border " + (lang === 'fr' ? 'current_lang' : '')} onClick={() => change_lang('fr')}>FR</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>*/}
                                            {/*<div className="d-block d-md-none col-12 top-bar top-bar-mobile">
                                        <div className="row">
                                            <div className="col-12 col-lg-10 offset-lg-1 px-3 py-0 small">
                                                <div className="float-left">
                                                    <button className={"pr-2 button_no_border " + (lang === 'pt' ? 'current_lang' : '')} onClick={() => change_lang('pt')}>PT</button>
                                                    &#124;
                                                    <button className={"pr-2 button_no_border " + (lang === 'en' ? 'current_lang' : '')} onClick={() => change_lang('en')}>EN</button>
                                                    &#124;
                                                    <button className={"p-2 button_no_border " + (lang === 'es' ? 'current_lang' : '')} onClick={() => change_lang('es')}>ES</button>
                                                    &#124;
                                                    <button className={"p-2 button_no_border " + (lang === 'fr' ? 'current_lang' : '')} onClick={() => change_lang('fr')}>FR</button>
                                                </div>
                                                <div className="mr-2 mr-2 p-2 small float-right">
                                                    {
                                                    getTokenInfo() !== undefined && getTokenInfo() !== null && getTokenInfo().profile === 'Cliente' ? (
                                                        //'Carrinho | Perfil | Sair'
                                                        <>
                                                            <a href={Hosts.WEB_SITE_URI + 'store/tarifas'} className='mx-1'>
                                                                {language[lang].store}
                                                            </a>
                                                            |
                                                            <a href={Hosts.WEB_SITE_URI + 'store/cart'} className='mx-1'>
                                                                {language[lang].store_cart}
                                                            </a>
                                                            |
                                                            <a href={Hosts.WEB_BACKOFFICE_URI} className='mx-1'>
                                                                {language[lang].my_account}
                                                            </a>
                                                            |
                                                            <a href={Hosts.WEB_SITE_URI} className='mx-1' onClick={logout}>
                                                                {language[lang].logout}
                                                            </a>
                                                        </>
                                                    )
                                                    : 
                                                    <>
                                                        <a href={Hosts.WEB_SITE_URI + 'store/tarifas'} className='mx-1'>
                                                            {language[lang].store}
                                                        </a>
                                                        <a href={Hosts.WEB_BACKOFFICE_URI} className='mx-1' onClick={logout}>
                                                            {language[lang].login}
                                                        </a>
                                                    </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center align-items-center">
                                            <div className="d-none mr-3">
                                                <button className={"p-2 button_no_border " + (lang === 'pt' ? 'current_lang' : '')} onClick={() => change_lang('pt')}>PT</button>
                                                &#124;
                                                <button className={"p-2 button_no_border " + (lang === 'en' ? 'current_lang' : '')} onClick={() => change_lang('en')}>EN</button>
                                                &#124;
                                                <button className={"p-2 button_no_border " + (lang === 'es' ? 'current_lang' : '')} onClick={() => change_lang('es')}>ES</button>
                                                &#124;
                                                <button className={"p-2 button_no_border " + (lang === 'fr' ? 'current_lang' : '')} onClick={() => change_lang('fr')}>FR</button>
                                            </div>
                                            <div className="top-phone mr-3 p-2 d-none">
                                                <i className="bi bi-telephone"></i>
                                                <a href="tel:(+351)275094779" title="(+351) 275 094 779">(+351) 275 094 779</a> <small>({language[lang].chamada_rede_fixa})</small>
                                            </div>
                                            <div className="p-2 small">
                                                <a href={repo_site_documents_link('CENTRO-04-38D7-FEDER-001180.pdf')} target="_blank" rel="noreferrer">
                                                    {language[lang].projeto_cofinanciado}
                                                </a>
                                            </div>
                                        </div>
                                    </div>*/}

                                            <div className="col-12 col-xl-10 offset-xl-1 d-flex justify-content-between py-0">
                                                <a className="navbar-brand pl-2" href={Hosts.WEB_SITE_URI} alt={Hosts.webSiteTitle}>
                                                    <img id="big-logo" className="svg-logo img-fluid" /*src={logoPath(mainLogo)}*/ src={repo_logo_link("logo.svg")} alt={Hosts.webSiteTitle} title={Hosts.webSiteTitle} />
                                                </a>
                                                <ul className="navbar-extra ml-auto">
                                                    {/*<li className="nav-item">
                                                <a className={"nav-link px-3 px-xl-4 " + (page_location === 'store/tarifas' ? 'current_top' : '')} href={Hosts.WEB_SITE_URI + 'store/tarifas'}>
                                                    <img src={repo_site_assets_link("tarifas.svg")} alt={language[lang].store_tarifas} title={language[lang].store_tarifas} className="img-fluid mr-2" style={{width: '20px', height: '20px'}}/>
                                                    {language[lang].store_tarifas}
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link px-3 px-xl-4" href="https://www.meteoestrela.pt/previsoes/neve/" target="_blank" rel="noreferrer">
                                                    <img src={repo_site_assets_link("meteorologia.svg")} alt={language[lang].meteorology} title={language[lang].meteorology} className="img-fluid mr-2" style={{width: '20px', height: '20px'}}/>
                                                    {language[lang].meteorology}
                                                </a>
                                            </li>*/}

                                                    {
                                                    get_is_logged() ?
                                                        (
                                                            //'Carrinho | Perfil | Sair'
                                                            //language[lang].my_account
                                                            //language[lang].store_cart
                                                            //language[lang].login
                                                            <>
                                                                <li className="nav-item d-none">
                                                                    <a href={Hosts.WEB_SITE_URI + (user_profile === 'Colaborador' ? 'desk' : 'store')} className='nav-link4'>
                                                                        {language[lang].store}
                                                                    </a>
                                                                </li>

                                                                <li className="dropdown nav-item mr-2 mr-lg-3" style={{ zIndex: '21' }}>
                                                                    <a href={Hosts.WEB_BACKOFFICE_URI} className='d-none'>
                                                                        <i className="bi bi-person-fill bi-2x"></i>
                                                                    </a>
                                                                    <a className="dropdown-toggle" href="/#" /*id="accountDropdown"*/ title={language[lang].my_account} role="button" data-toggle="dropdown" aria-label={language[lang].my_account} aria-expanded="false">
                                                                        <i className="bi bi-person-fill bi-2x"></i>
                                                                    </a>
                                                                    <div className="dropdown-menu" /*aria-labelledby="accountDropdown"*/>
                                                                        <a href={Hosts.WEB_BACKOFFICE_URI + '/client_regist'} className='dropdown-item d-none' title={language[lang].my_account}>
                                                                            <i className="bi bi-person-circle mr-2"></i>
                                                                            {language[lang].my_account}
                                                                        </a>
                                                                        <a href={Hosts.WEB_SITE_URI + 'client/perfil'} className='dropdown-item' title={language[lang].my_account}>
                                                                            <i className="bi bi-person-circle mr-2"></i>
                                                                            {language[lang].my_account}
                                                                        </a>
                                                                        <a href={Hosts.WEB_SITE_URI + 'client/order'} className='dropdown-item' title={language[lang].orders}>
                                                                            <i className="bi bi-file-pdf mr-2"></i>
                                                                            {language[lang].orders}
                                                                        </a>
                                                                        <a href={Hosts.WEB_SITE_URI} className='dropdown-item' onClick={() => logout()} title={language[lang].logout}>
                                                                            <i className="bi bi-arrow-right-square mr-2"></i>
                                                                            {language[lang].logout}
                                                                        </a>
                                                                    </div>
                                                                </li>

                                                                {
                                                                get_numeroProdutos() > 0 ?
                                                                    <li className='dropdown mr-2 mr-lg-3 position-relative' style={{ zIndex: '21' }}>
                                                                        <a className="dropdown-toggle text-uppercase" href="/#"  /*id="languageDropdown"*/ role="button" data-toggle="dropdown" aria-label={lang} aria-expanded="false">
                                                                            <i className="bi bi-cart-fill bi-2x text-white"></i>
                                                                            {
                                                                            get_numeroProdutos() < 10 ?
                                                                                <span className="position-absolute badge translate-middle rounded-circle" style={{ fontVariantNumeric: 'tabular-nums', top: '8px', left: '9px' }}>{get_numeroProdutos()}</span>
                                                                            :
                                                                                <span className="position-absolute badge translate-middle rounded-circle" style={{ fontVariantNumeric: 'tabular-nums', top: '8px', left: '5px' }}>{get_numeroProdutos()}</span>
                                                                            }
                                                                        </a>
                                                                        <div className="dropdown-menu dropdown-menu-right div-cart">
                                                                            <div className='p-3' style={{ width: '355px' }}>
                                                                                <ul className='pl-0'>
                                                                                    {
                                                                                    get_lista_carrinho().length > 0 ?
                                                                                        get_lista_carrinho().sort((a, b) => {
                                                                                            let aux1 = a.info.produto.id
                                                                                            let aux2 = b.info.produto.id
                                                                                            if (aux1 < aux2) {
                                                                                                return -1;
                                                                                            }
                                                                                            if (aux1 > aux2) {
                                                                                                return 1;
                                                                                            }
                                                                                            return 0;
                                                                                        }).map((el, key) => {
                                                                                            let prod_timestamp = el.info.produto.id_event
                                                                                          //  let prod_principal = el.info.produto.composicao.find((el) => el.principal === true)

                                                                                            return (

                                                                                                <li key={key} className='list-group rounded-0 border-bottom mb-3'>
                                                                                                    <div className="card_products_list">
                                                                                                        <div className="text-right d-flex justify-content-end">
                                                                                                            <div className='text-nowrap text-truncate mr-2 pb-2'>
                                                                                                                <span className='font-weight-bold'>{translate_generic_store(el.info.produto.linha.subname, lang.toLowerCase())}</span>
                                                                                                                <br />
                                                                                                                <small className='mr-2 font-weight-bold'>
                                                                                                                    {translate_generic_store(el.info.produto.linha.observation, lang.toLowerCase())}
                                                                                                                </small>
                                                                                                                <small className='mr-2'>
                                                                                                                    {
                                                                                                                        el.info.produto.data
                                                                                                                    /* el.info.produto.details.goldenrock.dia.split('-')[2] +
                                                                                                                    '-' + el.info.produto.details.goldenrock.dia.split('-')[1] +
                                                                                                                    '-' + el.info.produto.details.goldenrock.dia.split('-')[0]   */
                                                                                                                    }
                                                                                                                </small>
                                                                                                                <small>
                                                                                                                    {translate_horas((get_linguagem() !== null ? get_linguagem() : 'PT'),  el.info.produto.linha.details[nome_empresa].validade_data_hora_ini_to_show)}
                                                                                                                </small>
                                                                                                            </div>
                                                                                                            <div className="text-nowrap">
                                                                                                                {el.quantidade === 0 ? '1' : el.quantidade}
                                                                                                                <i className="bi bi-x"></i>
                                                                                                                {isNaN(el.info.produto.linha.total_liquido) === true ? 0 : parseFloat(el.info.produto.linha.total_liquido).toFixed(2).replace('.', ',')} €
                                                                                                                <br />
                                                                                                                <small style={{ fontVariantNumeric: 'tabular-nums' }}>
                                                                                                                    {prod_timestamp === 0 || prod_timestamp === 'null' ? '' : handler_countdown(el)}
                                                                                                                </small>
                                                                                                            </div>
                                                                                                            <div className='text-right'>
                                                                                                                <button className='btn btn-secondary btn-sm ml-3 py-0 px-1' onClick={() => { alert_handler_remover_produto(el) }} >
                                                                                                                    <i className="bi bi-x"></i>
                                                                                                                </button>
                                                                                                            </div>
                                                                                                            <br />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </li>

                                                                                            )

                                                                                        })
                                                                                    :
                                                                                        null
                                                                                    }
                                                                                </ul>
                                                                                <div className='pt-3 d-none'>
                                                                                    <h5 className="text-right ">
                                                                                        TOTAL: <span>__,__ €</span>
                                                                                    </h5>
                                                                                </div>

                                                                                <div className='pt-3'>
                                                                                    <div >
                                                                                        <a href={Hosts.WEB_SITE_URI + (user_profile === 'Colaborador' ? 'desk/cart' : 'reservation/cart')} className="btn btn-secondary w-100"> {language[lang].go_to_cart}</a>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                :
                                                                    <li className="dropdown nav-item mr-2 mr-lg-3" style={{ zIndex: '21' }}>

                                                                        <a className="dropdown-toggle" href="/#" /*id="cartDropdown"*/ title={language[lang].store_cart} role="button" data-toggle="dropdown" aria-label={language[lang].store_cart} aria-expanded="false">
                                                                            <i className="bi bi bi-cart-x bi-2x"></i>
                                                                        </a>

                                                                        <div className="dropdown-menu dropdown-menu-right" /*aria-labelledby="cartDropdown"*/>
                                                                            <span className='dropdown-item'>
                                                                                {language[lang].store_cart_empty}
                                                                            </span>
                                                                        </div>
                                                                    </li>
                                                                }

                                                                <li className="nav-item d-none" style={{ zIndex: '21' }}>
                                                                    <a href={Hosts.WEB_SITE_URI} className='nav-link' onClick={logout} title={language[lang].logout}>
                                                                        <i className="bi bi-arrow-right-square bi-2x mr-2"></i>
                                                                        {language[lang].logout}
                                                                    </a>
                                                                </li>
                                                            </>
                                                        )
                                                        :
                                                        <>
                                                            <li className="nav-item d-none">
                                                                <a href={Hosts.WEB_SITE_URI + (user_profile === 'Colaborador' ? 'desk' : 'store')} className='nav-link'>
                                                                    {language[lang].store}
                                                                </a>
                                                            </li>
                                                            <li className="dropdown nav-item mr-2 mr-lg-3" style={{ zIndex: '21' }}>
                                                                {/*<a href={Hosts.WEB_BACKOFFICE_URI} onClick={logout} className='d-none'>
                                                                    <i className="bi bi-person-fill bi-2x"></i>
                                                                </a>*/}
                                                                <a className="dropdown-toggle" href="/#" /*id="accountDropdown"*/ title={language[lang].login} role="button" data-toggle="dropdown" aria-label={language[lang].login} aria-expanded="false">
                                                                    <i className="bi bi-person-fill bi-2x"></i>
                                                                </a>
                                                                <div className="dropdown-menu" /*aria-labelledby="accountDropdown"*/>

                                                                    <button type="button" className='dropdown-item' onClick={() => set_open_modal_login(true)}>
                                                                        {language[lang].login}
                                                                        <i className="bi bi-arrow-right ml-1"></i>
                                                                    </button>
                                                                    {/*<a href={Hosts.WEB_BACKOFFICE_URI} className='dropdown-item d-none' title={language[lang].insert_title}>
                                                                        {language[lang].insert_title}
                                                                        <i className="bi bi-arrow-right ml-1"></i>
                                                                    </a>*/}
                                                                </div>
                                                            </li>
                                                        </>
                                                    }
                                                    <li className="dropdown dropdown-menu-right nav-item" style={{ zIndex: '21' }}>
                                                        <a className="dropdown-toggle round-background text-uppercase" href="/#" /*id="languageDropdown"*/ role="button" data-toggle="dropdown" aria-label={lang} aria-expanded="false">
                                                            {lang}
                                                        </a>
                                                        <div className="dropdown-menu" /*aria-labelledby="languageDropdown"*/>
                                                            <button className={"dropdown-item button_no_border " + (lang === 'pt' ? 'current_lang' : '')} onClick={() => change_lang('pt')}>PT</button>
                                                            <button className={"dropdown-item button_no_border " + (lang === 'en' ? 'current_lang' : '')} onClick={() => change_lang('en')}>EN</button>
                                                            <button className={"dropdown-item button_no_border " + (lang === 'es' ? 'current_lang' : '')} onClick={() => change_lang('es')}>ES</button>
                                                            {/*<button className={"dropdown-item button_no_border " + (lang === 'fr' ? 'current_lang' : '')} onClick={() => change_lang('fr')}>FR</button>*/}
                                                        </div>
                                                    </li>
                                                    <li className="nav-item d-none d-lg-block ml-3" style={{ zIndex: '21' }}>
                                                        <a className={"btn btn-secondary " + (page_location === 'tarifas' ? 'current_top' : '')} href={Hosts.WEB_SITE_URI + (user_profile === 'Colaborador' ? 'desk' : 'store')}
                                                        /*   onClick={() => {
                                                              history.push('/store')
                                                          }} */
                                                        >
                                                            {language[lang].store}
                                                        </a>
                                                    </li>
                                                </ul>
                                                <button className="navbar-toggler align-self-center" type="button" /*onClick={changeLogo}*/ data-toggle="collapse" data-target="#sideNavigationMenu" aria-controls="sideNavigationMenu" aria-expanded="false" aria-label="Toggle navigation">
                                                    {/*<span className="navbar-toggler-icon"></span>*/}
                                                    <div className="animated-icon3">
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                    </div>
                                                </button>
                                            </div>
                                            <div className="collapse navbar-collapse" id="sideNavigationMenu">

                                                <ul className="navbar-nav my-2">

                                                    {
                                                        props.mostrar_botao_voltar ?

                                                            <li className="nav-item" /* key={key} */ >
                                                                <a href={Hosts.WEB_SITE_URI} className={"nav-link"}>
                                                                    <FontAwesomeIcon className="mr-2" icon={faLongArrowAltLeft} />
                                                                    {language[lang].back}
                                                                </a>
                                                            </li>

                                                            :

                                                            navbar.content.childs.map((v, key) => {

                                                                // console.log(v)

                                                                let link = ''
                                                                v.id_content !== '' ?
                                                                    link = (v.slug !== undefined && v.slug !== '' ? Hosts.WEB_SITE_URI + '' + v.slug : Hosts.WEB_SITE_URI + 'pag/' + v.id_content)
                                                                :
                                                                    link = (v.slug !== undefined && v.slug !== '' ? Hosts.WEB_SITE_URI + '' + v.slug : (v.link.substr(0, 4) !== 'http' && v.link.substr(0, 3) !== 'www' ? Hosts.WEB_SITE_URI + v.link : (v.link.substr(0, 3) === 'www' ? 'http://' + v.link : v.link)))

                                                                let current_top_check = (v.slug !== undefined && v.slug !== '' ? v.slug : (v.id_content !== '' ? v.id_content : v.link));

                                                                if (location === '/' + v.link) {
                                                                    current_top_check = v.link
                                                                }

                                                                if (v.children !== undefined && v.children !== '' && v.children.length > 0) {

                                                                    return (
                                                                        <li className="nav-item dropdown" key={key}>
                                                                            <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false">
                                                                                {v.title}
                                                                                <FontAwesomeIcon icon={faAngleDown} fixedWidth />
                                                                            </a>
                                                                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                                                {
                                                                                    v.children.map((v2, key2) => {

                                                                                        //console.log(v2)

                                                                                        let link_content_dropdown_item = ''
                                                                                        v2.id_content !== '' ?
                                                                                            link_content_dropdown_item = (v2.slug !== undefined && v2.slug !== '' ? Hosts.WEB_SITE_URI + '' + v2.slug : Hosts.WEB_SITE_URI + 'pag/' + v2.id_content)
                                                                                            :
                                                                                            link_content_dropdown_item = (v2.slug !== undefined && v2.slug !== '' ? Hosts.WEB_SITE_URI + '' + v2.slug : (v2.link.substr(0, 4) !== 'http' && v2.link.substr(0, 3) !== 'www' ? Hosts.WEB_SITE_URI + v2.link : (v2.link.substr(0, 3) === 'www' ? 'http://' + v2.link : v2.link)))


                                                                                        //let link_content_dropdown_item = (v2.slug !== undefined && v2.slug !== '' ? Hosts.WEB_SITE_URI + '' + v2.slug : Hosts.WEB_SITE_URI + 'pag/' + v2.id_content);
                                                                                        let current_top_dropdown = (v2.slug !== undefined && v2.slug !== '' ? v2.slug : (v2.id_content !== '' ? v2.id_content : v2.link));

                                                                                        if (location === '/' + v2.link) {
                                                                                            current_top_dropdown = v2.link
                                                                                        }

                                                                                        return (
                                                                                            v2.link.substr(0, 4) !== 'http' && v2.link.substr(0, 3) !== 'www' ?
                                                                                                <a key={key2} href={link_content_dropdown_item} className={"dropdown-item " + ((page_location !== '' && page_location === current_top_dropdown) || (page_location !== '' && location === '/' + current_top_dropdown) ? 'current_top' : '')}  >
                                                                                                    {v2.title}
                                                                                                </a>
                                                                                                :
                                                                                                <a key={key2} href={link_content_dropdown_item} className={"dropdown-item " + ((page_location !== '' && page_location === current_top_dropdown) || (page_location !== '' && location === '/' + current_top_dropdown) ? 'current_top' : '')} target="_blank" rel="noreferrer" >
                                                                                                    {v2.title}
                                                                                                </a>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                }
                                                                else {


                                                                    // console.log('v', v)

                                                                    return (
                                                                        v.link.substr(0, 4) !== 'http' && v.link.substr(0, 3) !== 'www' ?
                                                                            <li className="nav-item" key={key} >
                                                                                <a href={link} className={"nav-link 1 " + ((page_location !== '' && page_location === current_top_check) || (page_location !== '' && location === '/' + current_top_check) ? 'current_top' : '')} >
                                                                                    {v.title}
                                                                                </a>
                                                                            </li>
                                                                            :
                                                                            <li className="nav-item" key={key} >
                                                                                <a href={link} className={"nav-link 3 " + ((page_location !== '' && page_location === current_top_check) || (page_location !== '' && location === '/' + current_top_check) ? 'current_top' : '')} target="_blank" rel="noreferrer" >
                                                                                    {v.title}
                                                                                </a>
                                                                            </li>

                                                                    )

                                                                }
                                                            })
                                                    }

                                                    <li className="nav-item d-block d-lg-none mt-2 text-center">
                                                        <a className={"btn btn-secondary"} href={Hosts.WEB_SITE_URI + (user_profile === 'Colaborador' ? 'desk' : 'store')}
                                                        /* onClick={() => {
                                                            history.push('/store');
                                                        }} */
                                                        >
                                                            {language[lang].store}
                                                        </a>
                                                    </li>

                                                    {/*<li className="nav-item d-block d-lg-none">
                                                <a className="nav-link" href="https://www.meteoestrela.pt/previsoes/neve/" target="_blank" rel="noreferrer">
                                                    <img src={repo_site_assets_link("meteorologia.svg")} alt={language[lang].meteorology} title={language[lang].meteorology} className="img-fluid mr-2 d-none" style={{width: '15px', height: '15px'}}/>
                                                    {language[lang].meteorology}
                                                </a>
                                            </li>
                                            <li className="nav-item d-block d-lg-none">
                                                <a className={"nav-link " + (page_location === 'livecam' ? 'current_top' : '')} href={Hosts.WEB_SITE_URI + 'livecam'}>
                                                    <img src={repo_site_assets_link("livecam.svg")} alt={language[lang].livecam} title={language[lang].livecam} className="img-fluid mr-2 d-none" style={{width: '15px', height: '15px'}}/>
                                                    {language[lang].livecam}
                                                </a>
                                            </li>
                                            <li className="nav-item dropdown d-none">
                                                <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false">
                                                    Teste Dropdown
                                                    <FontAwesomeIcon className="mr-2" icon={faAngleDown} fixedWidth />
                                                </a>
                                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                    <a className="dropdown-item" href="/#">Action</a>
                                                    <a className="dropdown-item" href="/#">Another action</a>
                                                    <div className="dropdown-divider"></div>
                                                    <a className="dropdown-item" href="/#">Something else here</a>
                                                </div>
                                            </li>*/}
                                                </ul>
                                                <ul className="navbar-nav social-media mt-5 text-center">
                                                    <li className="nav-item">
                                                        {
                                                            Hosts.fbLink !== '' ?
                                                                <a className="nav-link d-inline-block p-0 mr-3" href={Hosts.fbLink} title="Facebook" alt="Facebook" target="_blank" rel="noreferrer">
                                                                    <i className="bi bi-facebook"></i>
                                                                    {/*<img src={repo_site_assets_link("facebook.svg")} title="Facebook" alt="Facebook" className="img-fluid"/>*/}
                                                                </a>
                                                            : null
                                                        }
                                                        {
                                                            Hosts.twitterLink !== '' ?
                                                                <a className="nav-link d-inline-block p-0 mr-3" href={Hosts.twitterLink} title="Twitter X" alt="Twitter X" target="_blank" rel="noreferrer">
                                                                    <i className="bi bi-twitter-x"></i>
                                                                    {/*<img src={repo_site_assets_link("twitter.svg")} title="Twitter" alt="Twitter" className="img-fluid"/>*/}
                                                                </a>
                                                            : null
                                                        }
                                                        {
                                                            Hosts.instaLink !== '' ?
                                                                <a className="nav-link d-inline-block p-0" href={Hosts.instaLink} title="Instagram" alt="Instagram" target="_blank" rel="noreferrer">
                                                                    <i className="bi bi-instagram"></i>
                                                                    {/*<img src={repo_site_assets_link("instagram.svg")} title="Instagram" alt="Instagram" className="img-fluid"/>*/}
                                                                </a>
                                                            : null
                                                        }
                                                    </li>
                                                </ul>
                                                <div className="overlay d-none"></div>
                                            </div>


                                        </div>
                                    </div>
                                </nav>
                        }
                    </section >
                </div >
            }
            {
                open_modal_login === true ?
                    <CostumModalLogin lang={lang} set_open_modal_login={set_open_modal_login}></CostumModalLogin>
                    :
                    null
            }
        </>



    )
};
export default Navbar;