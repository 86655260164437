import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import Swal from 'sweetalert2'

import "../Pagina.css";
import "../content-styles.css"

import * as Hosts from '../../../Hosts';
import {
    set_language,
	reqGET,
	reqPOST,
	reqPUT,
    content_language,
    functionSendMail,
} from '../../../Utils';

import './Aula.css';

import Loader from '../../../loader';
import Navbar from "../Navbar/Navbar";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Footer from "../Footer/Footer";

import { language } from './Language';
// import { getTokenInfo } from '../../../UseToken';

const Aula = () => {

    const { order } = useParams();

    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState('pt');
    const [, set_lang_id] = useState('1');

    //const [date, setDate] = useState(new Date());
    const [date, setDate] = useState(new Date());

    const [encomenda, set_encomenda] = useState({});
    const [encomenda_codigo_crompra, set_encomenda_codigo_crompra] = useState('');
    const [linha_aula_encomenda, set_linha_aula_encomenda] = useState('');
    const [aula, set_aula] = useState({});
    const [encomenda_paga, set_encomenda_paga] = useState(0);
    const [aula_ja_marcada, set_aula_ja_marcada] = useState(0);
    const [encomenda_qr, set_encomenda_qr] = useState([]);

    const [evento, set_evento] = useState({});
    const [codigo_compra, set_codigo_compra] = useState('');
    const [eventos, set_eventos] = useState([]);
    const [schedueles, setSchedueles] = useState([]);
    const [day_events, set_day_events] = useState([]);

    const [refresh, setRefresh] = useState(0);

    const [aula_type_id, set_aula_type_id] = useState('')
    const [, set_aula_type_name] = useState('')
    const [aulas_types, ] = useState([
		{ value: "1", label: "Ski" },
		{ value: "2", label: "Snowboard" }
	])

    useEffect(() => { 

        const getInfo = async () => {

            await reqGET(`${Hosts.SIMCore_API}/store/order/payment/${Hosts.companycode}/${order}`)
                .then(res => {

                        // console.log(res)
                        if(res.data.length > 0) {

                            res.data[0].info.documento.linhas.forEach(async (el, el_k) => {

                                // console.log('el', el)

                                let idp = el.id_produto
                                // if(el.id_sub_produto !== '') {
                                //     idp = el.id_sub_produto
                                // }

                                // console.log('EL_QR', res.data[0].info.qr)
                                let x = 0
                                let qr_aux = []
                                res.data[0].info.qr.forEach(el_qr_aux => {
                                    if(x === 0) {
                                        if(el_qr_aux.show_qr === false) {
                                            x++
                                            // console.log('EL_QR', el_qr_aux)
                                            qr_aux.push(el_qr_aux)
                                        }
                                    }
                                })
                                set_encomenda_qr(qr_aux)

                                if(el['aula_marcada'] !== undefined && (el['aula_marcada'] === 1 || el['aula_marcada'] === '1')) {
                                    set_aula_ja_marcada(1)
                                }

                                await reqGET(`${Hosts.SIMCore_API}/estancia/aula/info/${Hosts.companycode}/${idp}`)
                                    .then(res_2 => {

                                        if(res_2.data.length > 0) {
                                            if(res_2.data[0].info.type === "aula") {

                                                // console.log('res_2', el_k)
                                                // console.log('res_2', res_2.data[0])
                                                set_encomenda_codigo_crompra(el.id_produto)
                                                set_linha_aula_encomenda(el_k)
                                                set_aula(res_2.data[0])

                                                return
                                            }

                                        }

                                    })
                                    .catch(() => 404)

                            })

                            if(res.data[0].info.pagamento_pendente === '1' || res.data[0].info.pagamento_pendente === 1) {
                                set_encomenda_paga(1)
                            }


                            

                            set_encomenda(res.data[0])
                        }

                })
                .catch(() => 404)

        } 

        const get_events = async () => {
            //await reqGET(`${Hosts.SIMCore_API}/estancia/aula/event/${Hosts.companycode}`)
            await reqGET(`${Hosts.SIMCore_API}/estancia/aula/event/available/${Hosts.companycode}/free`)
                .then(res => { 
                    // setData(res.data); 
                    // setLoading(false) 
                    // console.log(res.data)
    
                    let aux_schedueles = []
                    res.data.forEach((v) => { 
                        let x = aux_schedueles.filter((el) => (el.start === v.info.detail.start && el.calendarId === v.info.detail.calendarId))
                        if(x.length === 0) {
                            aux_schedueles.push({
                                id: v.id,
                                companycode: v.info.companycode,
                                calendarId: v.info.detail.calendarId,
                                title: v.info.name + (v.info.instrutor.name !== '' ? ' - ' + v.info.instrutor.name : ''),
                                category: v.info.detail.category,
                                dueDateClass: v.info.detail.dueDateClass,
                                start: v.info.detail.start,
                                end: v.info.detail.end,
                                isAllDay: v.info.detail.isAllDay,
                                location: v.info.detail.location.name,
                                state: v.info.detail.state,
                                raw: v.info.detail.raw.class,
                                isReadOnly: v.info.detail.isReadOnly
                            })
                        }
                    })
    
                    // console.log(aux_schedueles)
                    set_eventos(res.data)
                    setSchedueles(aux_schedueles)
                    setLoading(false)
    
                    // get_day_schedules(new Date(), aux_schedueles)
                })
                .catch(() => 404)
        }

        if(localStorage.getItem('lang') === undefined  || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if(el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }

        getInfo()
        get_events()

    }, [refresh, order]); 

    const get_day_status = (date, view) => {
        // console.log(date)
        // console.log(view)

        // console.log(schedueles)
        let x = schedueles.filter((el) => {
            const d1 = new Date(el.start);
            const d2 = new Date(date);
            if(view === "month" &&  (d1.getFullYear() === d2.getFullYear()) && (d1.getMonth() === d2.getMonth()) && (d1.getDate() === d2.getDate()))
                return el
            else if(view === "year" && (d1.getFullYear() === d2.getFullYear()) && (d1.getMonth() === d2.getMonth()))
                return el
            else if(view === "decade" &&(d1.getFullYear() === d2.getFullYear()))
                return el
            else   
                return null
        })
        if(x.length === 0)
            return true
        else
            return false
    }

    const get_day_schedules = (date, array, type_id) => {
        setDate(date)

        set_day_events([])
        let x = array.filter((el) => {
            const d1 = new Date(el.start)
            const d2 = new Date(date)
            
            if((d1.getFullYear() === d2.getFullYear()) && (d1.getMonth() === d2.getMonth()) && (d1.getDate() === d2.getDate())) {
                if(el.calendarId === type_id)
                    return el
                else
                    return null
            }
            else
                return null
        })
        set_day_events(x)

    }

    const set_type = (value, label) => {

        set_aula_type_id(value)
        set_aula_type_name(label)

        // const d2 = new Date(date)
        // let d1 = d2.getFullYear() + '-' + ((d2.getMonth() + 1) + '').padStart(2, '0') + '-' + (d2.getDate() + '').padStart(2, '0')
        get_day_schedules(date, schedueles, value)
    }

    const set_scheduele = (id) => {
        // console.log('ID', id)

        // console.log(eventos)

        let x = eventos.filter(el => el.id === id)
        if(x.length === 1) {
            let res = x.pop()

            //console.log(res)

            res.info.inscritos[0] = {
                name: '',
                age: '',
                identification_type: '',
                identification_number: '',
                codigo_compra: ''
            }

            set_evento(res)

            // Swal.fire({
            //     title: language[lang].confirm_purchase_title,
            //     text: language[lang].confirm_purchase_text,
            //     icon: 'question',
            //     showCancelButton: true,
            //     confirmButtonColor: '#0095e8',
            //     cancelButtonColor: '#d9214e',
            //     confirmButtonText: language[lang].confirm_purchase_confirm,
            //     cancelButtonText: language[lang].confirm_purchase_cancel
            // })
            // .then((result) => {
            //     if (result.isConfirmed) {
            //         reqPOST(`${Hosts.SIMCore_API}/estancia/aula/event`, res)
            //             .then(() => {     
            //                 Swal.fire(language[lang].confirm_purchase_success, '', 'info')
            //             })
            //             .catch(() => Swal.fire(language[lang].confirm_purchase_error, '', 'info'))
            //     }
            // })

        }
    }

    const save_scheduele = async () => {

            let x_qr = 0
            // encomenda.info.documento.linhas.forEach((el) => {
            encomenda.info.qr.forEach((el) => {

                if(x_qr === 0 && el.show_qr === false) {
                    console.log('2 - x_qr', x_qr)
                    x_qr++

                    reqGET(`${Hosts.SIMCore_API}/estancia/aula/info/${Hosts.companycode}/${el['id']}`)
                        .then(async () => { 

                            evento.info.detail['state'] = 'busy';
                            evento.info.detail['calendarId'] = '0';

                            reqGET(`${Hosts.SIMCore_API}/entities/${Hosts.companycode}/${encomenda.info.documento.user_info.id_user}`)
                                .then(async (res_3) => { 

                                    if(res_3.data.length > 0) {

                                        // console.log('res_3', res_3.data[0])

                                        evento.info.inscritos[0]['id'] = encomenda.info.documento.user_info.id_user;
                                        evento.info.inscritos[0]['age'] = res_3.data[0].info.birthday;
                                        evento.info.inscritos[0]['name'] = encomenda.info.documento.user_info.name;
                                        evento.info.inscritos[0]['id_encomenda'] = encomenda.id;
                                        evento.info.inscritos[0]['id_timestamp'] = encomenda.info.id_usado_para_gerar_pagamento;
                                        evento.info.inscritos[0]['codigo_compra'] = encomenda_codigo_crompra;
                                        evento.info.inscritos[0]['identification_type'] = '';
                                        evento.info.inscritos[0]['identification_number'] = '';
                                        evento.info.inscritos[0]['id_aula_dentro_encomenda'] = linha_aula_encomenda;

                                        evento.info.inscritos[0]['aux_qrcode'] = encomenda_qr

                                        evento.info.inscritos[0]['classe_level'] = '';

                                        // await reqPUT(`${Hosts.SIMCore_API}/estancia/aula/event`, evento)
                                        await reqPUT(`${Hosts.SIMCore_API}/estancia/aula/event/client`, evento)
                                            .then(() => {

                                                // sendMail_scheduele()

                                                let y = eventos.filter(el_qr => el_qr.id !== evento.id && el_qr.info.instrutor.id === evento.info.instrutor.id && ( (el_qr.info.detail.start === evento.info.detail.start) ))
                                                let y2 = eventos.filter(el_qr => el_qr.id !== evento.id && el_qr.info.instrutor.id === evento.info.instrutor.id && ( (evento.info.detail.end > el_qr.info.detail.start && evento.info.detail.end < el_qr.info.detail.end) ))
                                                let y3 = eventos.filter(el_qr => el_qr.id !== evento.id && el_qr.info.instrutor.id === evento.info.instrutor.id && ( (evento.info.detail.start > el_qr.info.detail.start && evento.info.detail.start < el_qr.info.detail.end) ))
                                                if(y.length > 0) {
                                                    y.forEach((el_cancelar) => {
                                                        reqGET(`${Hosts.SIMCore_API}/estancia/aula/event/${Hosts.companycode}/${el_cancelar['id']}`)
                                                            .then(res_aula_2 => { 

                                                                let evento_c = res_aula_2.data[0]
                                                                evento_c.info.detail['state'] = 'cancel';
                                                                evento_c.info.detail['calendarId'] = '3';

                                                                reqPUT(`${Hosts.SIMCore_API}/estancia/aula/event`, evento_c)
                                                                    .then(() => {

                                                                    })

                                                            })
                                                    })
                                                }
                                                if(y2.length > 0) {
                                                    y2.forEach((el_cancelar) => {
                                                        reqGET(`${Hosts.SIMCore_API}/estancia/aula/event/${Hosts.companycode}/${el_cancelar['id']}`)
                                                            .then(res_aula_2 => { 

                                                                let evento_c = res_aula_2.data[0]
                                                                evento_c.info.detail['state'] = 'cancel';
                                                                evento_c.info.detail['calendarId'] = '3';

                                                                reqPUT(`${Hosts.SIMCore_API}/estancia/aula/event`, evento_c)
                                                                    .then(() => {

                                                                    })

                                                            })
                                                    })
                                                }
                                                if(y3.length > 0) {
                                                    y3.forEach((el_cancelar) => {
                                                        reqGET(`${Hosts.SIMCore_API}/estancia/aula/event/${Hosts.companycode}/${el_cancelar['id']}`)
                                                            .then(res_aula_2 => { 

                                                                let evento_c = res_aula_2.data[0]
                                                                evento_c.info.detail['state'] = 'cancel';
                                                                evento_c.info.detail['calendarId'] = '3';

                                                                reqPUT(`${Hosts.SIMCore_API}/estancia/aula/event`, evento_c)
                                                                    .then(() => {

                                                                    })

                                                            })
                                                    })
                                                }

                                                setRefresh(refresh + 1) 
                                            })
                                            .catch(() => Swal.fire('', language[lang].confirm_purchase_error, 'info'))

                                        await reqGET(`${Hosts.SIMCore_API}/store/order/payment/${Hosts.companycode}/${encomenda.info.id_usado_para_gerar_pagamento}`)
                                            .then((res_order) => {


                                                //console.log('res_order', res_order)

                                                if(res_order.data.length > 0) {

                                                    let x = 0
                                                    console.log(res_order.data.length)
                                                    res_order.data[0].info.documento.linhas.forEach((el_linhas) => {

                                                        console.log('AAAAKKKKKIIII', x)
                                                        if(x === 0) {
                                                            console.log('AAAAKKKKKIIII222222222222')

                                                            console.log("el_linhas['quantidade_aux_em_caso_de_aula']", el_linhas['quantidade_aux_em_caso_de_aula'])

                                                            if(el_linhas['quantidade_aux_em_caso_de_aula'] !== undefined && el_linhas['quantidade_aux_em_caso_de_aula'] !== null && parseInt(el_linhas['quantidade_aux_em_caso_de_aula']) > 0) {
                                                                console.log("el_linhas['quantidade_aux_em_caso_de_aula']", el_linhas['quantidade_aux_em_caso_de_aula'])

                                                                el_linhas['quantidade_aux_em_caso_de_aula'] = parseInt(el_linhas['quantidade_aux_em_caso_de_aula']) - 1
                                                                x++

                                                                console.log("el_linhas['quantidade_aux_em_caso_de_aula']", el_linhas['quantidade_aux_em_caso_de_aula'])
                                                                if(parseInt(el_linhas['quantidade_aux_em_caso_de_aula']) === 0) {
                                                                    el_linhas['aula_marcada'] = 1
                                                                }

                                                                reqPUT(`${Hosts.SIMCore_API}/store/order`, res_order.data[0])
                                                                    .then(() => {
                                                                        console.log('ORDER UPDATED', res_order.data[0])
                
                                                                        Swal.fire('', language[lang].confirm_purchase_success, 'info')

                                                                        setRefresh(refresh + 1)
                                                                    })
                                                                    .catch(() => console.log('error'))

                                                            }
                                                            // else {
                                                            //     el_linhas['quantidade_aux_em_caso_de_aula'] = 0
                                                            //     el_linhas['aula_marcada'] = 1
                                                            // }
                                                            
                                                        }
                                                    })
                                                    
                                                }

                                            })
                                            .catch(() => console.log('error'))

                                    }

                                })
                                .catch(() => Swal.fire('', language[lang].confirm_purchase_error, 'info'))

                        })
                }
            })

    }

    /*
    const save_scheduele_old = async () => {
        if(codigo_compra !== '') {
            
            // VERIFICAR SE CODIGO EXISTE
            //reqGET(`${Hosts.SIMCore_API}/store/order/code/${Hosts.companycode}/${codigo_compra}`)
            reqPOST(`${Hosts.SIMCore_API}/store/order/code/${Hosts.companycode}`, {code: codigo_compra})
                .then(res => { 
                    // console.log('save_scheduele', res)

                    if(res.data.length === 1) {

                        reqPOST(`${Hosts.SIMCore_API}/estancia/aula/event/code/${Hosts.companycode}`, {code: codigo_compra})
                            .then(res_2 => { 

                                if(res_2.data.length === 0) {

                                    res.data[0].info.documento.linhas.forEach((el) => {
                            
                                        if(el['codigo_aula'] !== undefined && el['codigo_aula'] === codigo_compra) {

                                            // VERIFICAR O PRODUTO E O NUMERO DE ALUNOS
                                            reqGET(`${Hosts.SIMCore_API}/estancia/aula/info/${Hosts.companycode}/${el['id_produto']}`)
                                                .then(async res_aula => { 

                                                    // console.log('linha', el)
                                                    // console.log('aula', res_aula)

                                                    //evento.info.inscritos[0]['codigo_compra'] = codigo_compra;
                                                    //evento.info.inscritos[0]['id_purchase_order'] = res.data[0].id;
                                                    evento.info.detail['state'] = 'busy';

                                                    let nome_responsavel = ''
                                                    let email_responsavel = ''
                                                    let tlm_responsavel = ''
                                                    let nivel_aula = ''
                                                    const { value: formValues } = await Swal.fire({
                                                        title: language[lang].confirm_purchase_title,
                                                        html:
                                                            '<label>' + language[lang].purchase_name + '</label>' +
                                                            '<input id="swal-input1" type="text" class="form-control mb-4">' +
                                                            '<label>' + language[lang].purchase_email + '</label>' +
                                                            '<input id="swal-input2" type="text" class="form-control mb-4">' +
                                                            '<label>' + language[lang].purchase_tlm + '</label>' +
                                                            '<input id="swal-input3" type="text" class="form-control mb-4">' +
                                                            '<label>' + language[lang].purchase_level + '</label>' +
                                                            '<select id="swal-input4" class="form-control form-select" aria-label="Default select example">' +
                                                                '<option value=' + language[lang].purchase_level_iniciacao + ' select>' + language[lang].purchase_level_iniciacao + '</option>' +
                                                                '<option value=' + language[lang].purchase_level_intermedio + '>' + language[lang].purchase_level_intermedio + '</option>' +
                                                                '<option value=' + language[lang].purchase_level_avancado + '>' + language[lang].purchase_level_avancado + '</option>' +
                                                            '</select>',
                                                        focusConfirm: false,
                                                        showCancelButton: true,
                                                        confirmButtonColor: '#0095e8',
                                                        cancelButtonColor: '#d9214e',
                                                        preConfirm: () => {
                                                            return [
                                                                document.getElementById('swal-input1').value,
                                                                document.getElementById('swal-input2').value,
                                                                document.getElementById('swal-input3').value,
                                                                document.getElementById('swal-input4').value
                                                            ]
                                                        }
                                                    })

                                                    if(formValues) {
                                                        nome_responsavel = formValues[0]
                                                        email_responsavel = formValues[1]
                                                        tlm_responsavel = formValues[2]
                                                        nivel_aula = formValues[3]
                                                    }

                                                    // console.log('nome_responsavel', nome_responsavel)
                                                    // console.log('nivel_aula', nivel_aula)

                                                    // Swal.fire({
                                                    //     title: language[lang].confirm_purchase_title,
                                                    //     text: language[lang].confirm_purchase_text,
                                                    //     icon: 'question',
                                                    //     showCancelButton: true,
                                                    //     confirmButtonColor: '#0095e8',
                                                    //     cancelButtonColor: '#d9214e',
                                                    //     confirmButtonText: language[lang].confirm_purchase_confirm,
                                                    //     cancelButtonText: language[lang].confirm_purchase_cancel
                                                    // })
                                                    // .then((result) => {
                                                    //     if (result.isConfirmed) {
                                                        if (nome_responsavel !== '' && email_responsavel !== '' && tlm_responsavel !== '' && nivel_aula !== '') {

                                                            if(res_aula.data[0].info.value.alunos > 1) {
                                                                evento.info.inscritos = []
                                                                Array.from(Array(parseInt(res_aula.data[0].info.value.alunos)), (_, k) => {
        
                                                                    evento.info.inscritos.push({
                                                                        "age": "", 
                                                                        "name": nome_responsavel, 
                                                                        "email": email_responsavel, 
                                                                        "tlm": tlm_responsavel, 
                                                                        "classe_level": nivel_aula,
                                                                        "codigo_compra": codigo_compra, 
                                                                        "identification_type": "", 
                                                                        "identification_number": "", 
                                                                        "id_purchase_order": res.data[0].id
                                                                    })

                                                                    return true
                                                                })
                                                            }
                                                            else {
        
                                                                evento.info.inscritos[0]['codigo_compra'] = codigo_compra;
                                                                evento.info.inscritos[0]['id_purchase_order'] = res.data[0].id;
                                                                evento.info.inscritos[0]['name'] = nome_responsavel;
                                                                evento.info.inscritos[0]['classe_level'] = nome_responsavel;
        
                                                            }

                                                            reqPUT(`${Hosts.SIMCore_API}/estancia/aula/event`, evento)
                                                                .then(() => {

                                                                    sendMail_scheduele()

                                                                    let y = eventos.filter(el => el.id !== evento.id && el.info.detail.start === evento.info.detail.start && el.info.instrutor.id === evento.info.instrutor.id)
                                                                    if(y.length > 0) {
                                                                        y.forEach((el_cancelar) => {
                                                                            reqGET(`${Hosts.SIMCore_API}/estancia/aula/event/${Hosts.companycode}/${el_cancelar['id']}`)
                                                                                .then(res_aula_2 => { 

                                                                                    let evento_c = res_aula_2.data[0]
                                                                                    evento_c.info.detail['state'] = 'cancel';

                                                                                    reqPUT(`${Hosts.SIMCore_API}/estancia/aula/event`, evento_c)
                                                                                        .then(() => {

                                                                                        })

                                                                                })
                                                                        })
                                                                    }

                                                                    setRefresh(refresh + 1) 
                                                                    Swal.fire(language[lang].confirm_purchase_success, '', 'info')

                                                                })
                                                                .catch(() => Swal.fire(language[lang].confirm_purchase_error, '', 'info'))
                                                        }
                                                    // })

                                                })

                                        }
                                        else {
                                            Swal.fire(language[lang].confirm_purchase_error_codigo, '', 'info')
                                        }

                                    })
                                }
                                else {
                                    Swal.fire(language[lang].confirm_purchase_error_codigo, '', 'info')
                                }

                            })
                    }

                })
                .catch(() => 404)
        }
        else {
            Swal.fire(language[lang].confirm_purchase_error_codigo, '', 'info')
        }

    }
    */

    const cancel_scheduele = async () => {

        let codigo_compra = ''
        let justificacao = ''
        let nome_responsavel = ''
        let email_responsavel = ''
        let tlm_responsavel = ''
        // let { value: codigo_compra } = await Swal.fire({
        //     title: 'Cancelar agendamento',
        //     input: 'text',
        //     inputLabel: 'Código de compra',
        //     inputPlaceholder: 'Insira o seu código de compra.'
        // })

        const { value: formValues } = await Swal.fire({
            title: 'Cancelar agendamento',
            html:
                '<label>Código de compra</label>' +
                '<input id="swal-input1" type="text" class="form-control mb-4">' +
                '<label>' + language[lang].purchase_name + '</label>' +
                '<input id="swal-input2" type="text" class="form-control mb-4">' +
                '<label>' + language[lang].purchase_email + '</label>' +
                '<input id="swal-input3" type="text" class="form-control mb-4">' +
                '<label>' + language[lang].purchase_tlm + '</label>' +
                '<input id="swal-input4" type="text" class="form-control mb-4">' +
                '<label>Indique a razão</label>' +
                '<textarea id="swal-input5" type="text" class="form-control mb-2">',
            focusConfirm: false,
            preConfirm: () => {
                return [
                    document.getElementById('swal-input1').value,
                    document.getElementById('swal-input2').value,
                    document.getElementById('swal-input3').value,
                    document.getElementById('swal-input4').value,
                    document.getElementById('swal-input5').value
                ]
            }
        })
          
        if (formValues) {
            codigo_compra = formValues[0]
            nome_responsavel = formValues[1]
            email_responsavel = formValues[2]
            tlm_responsavel = formValues[3]
            justificacao = formValues[4]
        }

        if(codigo_compra !== '' && nome_responsavel !== '' && email_responsavel !== '' && tlm_responsavel !== '' && justificacao !== '') {

            reqPOST(`${Hosts.SIMCore_API}/store/order/code/${Hosts.companycode}`, {code: codigo_compra})
                .then(res => { 

                    if(res.data.length === 1) {

                        if(res.data.length === 1) {
                            reqPOST(`${Hosts.SIMCore_API}/estancia/aula/event/code/${Hosts.companycode}`, {code: codigo_compra})
                                .then(res_2 => { 
    
                                    if(res_2.data.length === 1) {
    
                                        res_2.data[0].info.inscritos = [];
                                        res_2.data[0].info.inscritos[0] = {"age": "", "name": "", "codigo_compra": "", "identification_type": "", "identification_number": ""};
                                        //res_2.data[0].info.inscritos[0]['codigo_compra'] = '';
                                        //res_2.data[0].info.inscritos[0]['id_purchase_order'] = '';
                                        res_2.data[0].info.detail['state'] = 'free';
    
                                        Swal.fire({
                                            title: language[lang].cancel_scheduele_title,
                                            text: language[lang].cancel_scheduele_text,
                                            icon: 'question',
                                            showCancelButton: true,
                                            confirmButtonColor: '#0095e8',
                                            cancelButtonColor: '#d9214e',
                                            confirmButtonText: language[lang].cancel_scheduele_confirm,
                                            cancelButtonText: language[lang].cancel_scheduele_back
                                        })
                                        .then((result) => {
                                            if (result.isConfirmed) {
                                                reqPUT(`${Hosts.SIMCore_API}/estancia/aula/event`, res_2.data[0])
                                                    .then(() => {

                                                        let y = eventos.filter(el => el.id !== res_2.data[0].id && el.info.detail.start === res_2.data[0].info.detail.start && el.info.instrutor.id === res_2.data[0].info.instrutor.id)
                                                        if(y.length > 0) {
                                                            y.forEach((el_cancelar) => {
                                                                reqGET(`${Hosts.SIMCore_API}/estancia/aula/event/${Hosts.companycode}/${el_cancelar['id']}`)
                                                                    .then(res_aula_2 => { 

                                                                        let evento_c = res_aula_2.data[0]
                                                                        evento_c.info.detail['state'] = 'free';

                                                                        reqPUT(`${Hosts.SIMCore_API}/estancia/aula/event`, evento_c)
                                                                            .then(() => {

                                                                            })

                                                                    })
                                                            })
                                                        }

                                                        sendMail_cancel(nome_responsavel, email_responsavel, tlm_responsavel)
                                                        setRefresh(refresh + 1) 
                                                        Swal.fire(language[lang].cancel_scheduele_success, '', 'info')
                                                    })
                                                    .catch(() => Swal.fire(language[lang].cancel_scheduele_error, '', 'info'))
                                            }
                                        })
                                    }
                                    else {
                                        Swal.fire(language[lang].cancel_scheduele_error, '', 'info')
                                    }

                                })
                        }
                        else {
                            Swal.fire(language[lang].cancel_scheduele_error, '', 'info')
                        }

                    }
                    else {
                        Swal.fire(language[lang].cancel_scheduele_error, '', 'info')
                    }

                })

        }
        else {
            Swal.fire(language[lang].cancel_scheduele_error, '', 'info')
        }

    }

    /*
    const sendMail_scheduele = () => {

        let date_hour_start = evento.info.detail.start.split('T')
        let today = new Date();
        let today_aux = String(today.getDate()).padStart(2, '0') + '-' + String(today.getMonth() + 1).padStart(2, '0') + '-' + today.getFullYear();

        let mail_content =  '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; height: 90px; padding: 15px 30px; background-color:' + Hosts.EMAIL_BACKGROUND_COLOR +'; border:1px solid transparent; border-collapse:collapse;">' +
                                '<tr style="border-collapse: collapse;">' +
                                    '<td align="left" style="padding: 30px 40px 0px; font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.EMAIL_TEXT_SIZE +'; line-height:' + Hosts.EMAIL_TEXT_LINE_HEIGHT  +'; color:' + Hosts.EMAIL_TEXT_COLOR +'">' +
                                        '<div>' +
                                            '<span><b>' + language[lang].mail_scheduele_title + '</b></span>' +
                                        '</div>' +
                                    '</td>' + 
                                    '<td align="right" style="padding: 30px 40px 0px; font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.EMAIL_TEXT_SIZE +'; line-height:' + Hosts.EMAIL_TEXT_LINE_HEIGHT  +'; color:' + Hosts.EMAIL_TEXT_COLOR +'">' +
                                        '<div>'+
                                            '<span>'+ today_aux + '</span>'+
                                        '</div>'+
                                    '</td>' +
                                '</tr>' +

                                '<tr>' +
                                    '<td colspan="2" align="right" style="padding: 0px 40px; height: 15px;"></td>' +
                                '</tr>' +
                    
                                '<tr style="border-collapse: collapse;">' +
                                    '<td align="left" style="padding:40px; border-collapse:collapse; font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.EMAIL_TEXT_SIZE +'; line-height:' + Hosts.EMAIL_TEXT_LINE_HEIGHT  +'; color:' + Hosts.EMAIL_TEXT_COLOR +'">' +
                                        '<div>'+
                                            '<span><b>' + language[lang].mail_scheduele_name + ': </b><label>' + evento.info.inscritos[0]['name'] + '</label></span>' +
                                            //'<br/>'+
                                            //'<span><b>' + language[lang].mail_scheduele_email + ': </b><label>' + evento.info.inscritos[0]['email'] + '</label></span>'+
                                            //'<br/>'+
                                            //'<span><b>' + language[lang].mail_scheduele_tlm + ': </b><label>' + evento.info.inscritos[0]['tlm'] + '</label></span>'+
                                            '<br/>'+
                                            '<span><b>' + language[lang].mail_scheduele_type + ': </b><label>' + evento.info.category + '</label></span>' +
                                            //'<br/>'+
                                            //'<span><b>' + language[lang].mail_scheduele_level + ': </b><label>' + evento.info.inscritos[0]['classe_level'] + '</label></span>' +
                                            '<br/>'+
                                            '<span><b>' + language[lang].mail_scheduele_nalunos + ': </b><label>' + evento.info.inscritos.length + '</label></span>'+
                                            '<br/>'+
                                            '<span><b>' + language[lang].mail_scheduele_day + ': </b><label>' + date_hour_start[0] + '</label></span>'+
                                            '<br/>'+
                                            '<span><b>' + language[lang].mail_scheduele_hour + ': </b><label>' + date_hour_start[1] + '</label></span>'+
                                            '<br/>'+
                                            '<br/>'+
                                            '<span>Mais informação</span>'+
                                            '<br/>'+
                                        '</div>'+
                                    '</td>' +
                                '</tr>' +
                            '</table>'

        functionSendMail('web.dev@assec.pt', [evento.info.inscritos[0]['email']], ['web.dev@assec.pt'], 'Marcação de aula', mail_content, [])
            .then((res) => {
                if(res === true) {
                    // Swal.fire({
                    //     title: language[lang].opinion_form,
                    //     text: language[lang].sent_successfully,
                    //     icon: 'success',
                    //     confirmButtonColor: '#08396a',
                    // })
                }
                else {
                    // Swal.fire({
                    //     title: language[lang].opinion_form,
                    //     text: language[lang].sent_successfully,
                    //     icon: 'success',
                    //     confirmButtonColor: '#08396a',
                    // })
                }
            })
    }
    */

    const sendMail_cancel = (name, email, tlm) => {

        let today = new Date();
        let today_aux = String(today.getDate()).padStart(2, '0') + '-' + String(today.getMonth() + 1).padStart(2, '0') + '-' + today.getFullYear();

        let mail_content =  '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; height: 90px; padding: 15px 30px; background-color:' + Hosts.EMAIL_BACKGROUND_COLOR +'; border:1px solid transparent; border-collapse:collapse;">' +
                                '<tr style="border-collapse: collapse;">' +
                                    '<td align="left" style="padding: 30px 40px 0px; font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.EMAIL_TEXT_SIZE +'; line-height:' + Hosts.EMAIL_TEXT_LINE_HEIGHT  +'; color:' + Hosts.EMAIL_TEXT_COLOR +'">' +
                                        '<div>' +
                                            '<span><b>' + language[lang].mail_scheduele_cancel_title + '</b></span>' +
                                        '</div>' +
                                    '</td>' + 
                                    '<td align="right" style="padding: 30px 40px 0px; font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.EMAIL_TEXT_SIZE +'; line-height:' + Hosts.EMAIL_TEXT_LINE_HEIGHT  +'; color:' + Hosts.EMAIL_TEXT_COLOR +'">' +
                                        '<div>'+
                                            '<span>'+ today_aux + '</span>'+
                                        '</div>'+
                                    '</td>' +
                                '</tr>' +

                                '<tr>' +
                                    '<td colspan="2" align="right" style="padding: 0px 40px; height: 15px;"></td>' +
                                '</tr>' +
                                '<tr style="border-collapse: collapse;">' +
                                    '<td align="left" style="padding:40px; border-collapse:collapse; font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.EMAIL_TEXT_SIZE +'; line-height:' + Hosts.EMAIL_TEXT_LINE_HEIGHT  +'; color:' + Hosts.EMAIL_TEXT_COLOR +'">' +
                                        '<div>'+
                                            '<br/>'+
                                            '<span>Mais informação</span>'+
                                            '<br/>'+
                                        '</div>'+
                                    '</td>' +
                                '</tr>' +
                            '</table>'

        functionSendMail('web.dev@assec.pt', [email], [], 'Cancelar marcação de aula', mail_content, [])
            .then((res) => {
                if(res === true) {
                    // document.getElementById('agree').checked = false
                    // Swal.fire({
                    //     title: language[lang].opinion_form,
                    //     text: language[lang].sent_successfully,
                    //     icon: 'success',
                    //     confirmButtonColor: '#08396a',
                    // })
                }
                else {
                    // Swal.fire({
                    //     title: language[lang].opinion_form,
                    //     text: language[lang].sent_successfully,
                    //     icon: 'success',
                    //     confirmButtonColor: '#08396a',
                    // })
                }
            })

    }

    return (isloading ?

		<Loader />
		
		:
        <>
            <Navbar /> 

            <Breadcrumb /> 

            <div id="aula" className="container-fluid my-5">
                <div className="row">
                    <div className="container mb-3">
                        <h3 className="titulo-primario">{language[lang].schedule}</h3>
                        <span>Encomenda N.º {encomenda.id} {aula_ja_marcada === 1 ? '(Aula já marcada)' : ''} {encomenda_paga === 0 ? '(A aguardar pagamento)' : ''} {aula['id'] === undefined ? '(Sem aula para agendar)' : ''}</span>
                    </div>

                    <div className="container">
                        <div className="row">

                            <div className="col-12 col-md-10 mb-5">
                                <div className='div-schedueles'>
                                    <div className='row'>
                                        <div className="col-12 col-md-6">
                                            <div className='calendar-container'>
                                                <Calendar
                                                    onChange={(e) => get_day_schedules(e, schedueles, aula_type_id)} 
                                                    value={date} 
                                                    minDate={new Date()}
                                                    //locale={lang === 'en' ? 'en-US' : (lang === 'es' ? 'es-ES' : 'pt-PT')}
                                                    tileDisabled={({ date, view }) => get_day_status(date, view)}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 schedueles-choose">
                                            <div className=''>

                                                <div className='text-center pt-3'>
                                                    <h3 className="subtitulo-secundario">{language[lang].schedule_choose}</h3>
                                                    <span className='bold'>{date.toLocaleDateString()}</span>
                                                </div>

                                                <div className='pt-3 pb-2'>
                                                    <div className='row'>
                                                        {aulas_types.map((el, key) => {
                                                            return (
                                                                <div key={key} className='col-6 text-center mb-2'>
                                                                    <button className={"btn btn-scheduele text-center px-3" + (aula_type_id === el.value ? ' active' : '')} onClick={() => set_type(el.value, el.label)}>
                                                                        {el.label}
                                                                    </button>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>

                                                <div className='pt-3 pb-5'>
                                                    <div className='row'>
                                                        {day_events.map((el, key) => {
                                                            return (
                                                                <div key={key} className='col-6 text-center mb-2'>
                                                                    <button className={"btn btn-scheduele text-center px-3" + (evento['id'] !== undefined && evento['id'] === el.id ? ' active' : '')} onClick={() => set_scheduele(el.id)}>
                                                                        {el.start.split("T").pop()}h - {el.end.split("T").pop()}h
                                                                    </button>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>

                                                {
                                                aula_type_id !== '' ?
                                                    day_events.length > 0 ?
                                                        evento['id'] !== undefined ?
                                                            <div className='row text-center mb-2'>
                                                                <div className="col-12 mb-3 d-none">
                                                                    <label>{language[lang].schedule_code}</label>
                                                                    <input type="text" name="codigo_compra" style={{width: '250px', margin: 'auto'}} className="form-control" placeholder={language[lang].schedule_code} value={codigo_compra} onChange={(e) => set_codigo_compra(e.target.value)} />
                                                                </div>
                                                                {
                                                                aula['id'] === undefined ?
                                                                    null
                                                                :
                                                                    encomenda_paga === 1 && aula_ja_marcada === 0 ?
                                                                        <div className="col-12 mb-3">
                                                                            <button className="btn btn-primary text-center px-5" onClick={() => save_scheduele()}>{language[lang].finalize_order}</button>
                                                                        </div>
                                                                    :
                                                                        null
                                                                }
                                                            </div>
                                                        :
                                                            null
                                                    :
                                                        <div className='text-center mb-2'>
                                                            <p>{language[lang].no_schedule}</p>
                                                        </div>
                                                :
                                                    <div className='text-center mb-2'>
                                                        <p>{language[lang].choose_aula_type}</p>
                                                    </div>
                                                    
                                                }

                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className='div-buttons'>
                                    <div className='row'>
                                        <div className='col-6 mt-3'>
                                            <Link className="btn" to={{ pathname: '/store/tarifas' }} replace>
                                                <i className="bi bi-arrow-left mr-2"></i> {language[lang].voltar}
                                            </Link>
                                        </div>
                                        {
                                        true === false ?
                                            <div className='col-6 text-right mt-3'>
                                                <button type="button" className="btn btn-primary" onClick={() => cancel_scheduele()}>
                                                    <i className="bi bi-x-lg mr-2"></i> {language[lang].cancel_scheduele_title}
                                                </button>
                                            </div>
                                        : 
                                            null
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div> 
                </div> 
            </div>    
        
            <Footer />
        </>
      
    );

};
export default Aula;

