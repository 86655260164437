
import React, { useState, useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import "../Pagina.css";
import "../content-styles.css"
import { set_language, content_language, repo_img_link } from '../../../Utils';
import './GenericStore.css';
import Loader from '../../../loader';
import Navbar from "../Navbar/Navbar";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Footer from "../Footer/Footer";

import { get_format_correct, lista_de_produtos } from './utils';
import { translate_generic_store, translate_horas } from '../GenericStore/utils';
import useContextProvider from '../GenericStore/Providers/ContextProvider';
import { nome_empresa } from '../GenericStore/HOSTS';
import { network } from '../GenericStore/UseService';
import { usarToken } from '../GenericStore/UseToken';

import { language } from './Language';
import { lista_de_categorias } from './utils';

import AssecImage from "../ASSEC_Image/Image"
import { WEB_SITE_URI } from '../../../Hosts';

import * as Hosts from "../../../Hosts";

import Swal from 'sweetalert2';

const ProdByCategory = (props) => {
    const { updateprodnegative, removeprod, get_lista_carrinho, get_numeroProdutos, get_lista, get_ws_info, get_id_user, set_lista_carrinho, update_timer, get_linguagem } = useContextProvider()

    const [isloading, setLoading] = useState(true);
    const [, setLoadingDiv] = useState(true);
    const [lang, set_lang] = useState('pt');
    const [, set_lang_id] = useState('1');

    const [user_profile, set_user_profile] = useState('');

    const [lista_categorias, set_lista_categorias] = useState([])
    const [selected_categoria, set_selected_categoria] = useState('SPA')
    // const [flag_carrinho_aberto, set_flag_carrinho_aberto] = useState(false)

    const [isRedirect, setRedirect] = useState(false);

    const [lista_produtos, set_lista_produtos] = useState([])
    let company_code = '0000000'
    // const { category } = useParams();

    const set_info_about_lang = () => {
        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt') // é uma funcao
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }
    }

    const set_new_category = (new_cat) => {
        set_selected_categoria(new_cat)

        setLoadingDiv(true)

        get_produtos(new_cat)
    }

    const get_produtos = async (selected_cat) => {
        try {
            let list_produtos = await lista_de_produtos([{ codgrupo: selected_cat }], company_code)
            set_lista_produtos(list_produtos)

            setLoading(false)
            setLoadingDiv(false)
        } catch (error) {
            console.log('get_produtos erro: ', error)
        }
    }

    const get_produtos_schedule = async (id_prod) => {
        console.log('get_produtos_schedule', id_prod)





    }

    useEffect(() => {
        (async () => {

            let temp_token_info = await usarToken().getToken()
            let token_info = JSON.parse(temp_token_info)

            if (token_info !== undefined && token_info !== null) {
                if (token_info.user_info.profile !== 'Colaborador') {
                    setRedirect(true)
                }

                set_user_profile(token_info.user_info.profile)
            }

            const func_aux = async () => {
                try {

                    set_info_about_lang()

                    let list_categories = await lista_de_categorias()
                    set_lista_categorias(list_categories)

                    if (props !== undefined && props !== null && props.location !== undefined && props.location !== null && props.location.item !== undefined && props.location.item !== null && props.location.item.codgrupo !== undefined && props.location.item.codgrupo !== null && props.location.item.codgrupo !== '') {
                        console.log('props.location.item.codgrupo', props.location.item.codgrupo)
                        set_selected_categoria(props.location.item.codgrupo)


                        await get_produtos(props.location.item.codgrupo)
                    }
                    else {

                        await get_produtos(selected_categoria)
                    }


                    setLoading(false)

                } catch (error) {
                    console.log('func_aux error', error)
                    setLoading(false)

                }

            }
            await func_aux()
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handler_countdown = (obj) => { // trata de mostrar o tempo a passar
        try {

            let obj_aux = get_lista_carrinho().find((ele) => parseInt(ele.id_stock) === parseInt(obj.produto.details[nome_empresa].id_stock))

            if (obj_aux !== undefined) {

                if ((obj_aux.tempo).toString() === '00:00') { // tempo chegou ao fim 

                    removeprod(obj)

                    return ''

                } else {
                    return obj_aux.tempo
                }

            }

        } catch (error) {
            console.log('handler_countdown error', error)
            return ''
        }
    }

    const handler_remover_produto = (obj) => {

        try {

            let ws = get_ws_info()

            if (parseInt(obj.quantidade) === 1) {

                let prod_nao_cativar = {
                    id_prod: obj.produto.id_prod,
                    quantidade: 1,
                    prod_timer: 0,
                    id_user: get_id_user(),
                    timestamp: obj.produto.id_event,
                    id_stock: obj.produto.id_stock
                }

                let prod_lista = [prod_nao_cativar]

                // distinguir se estou a apagar um produto ou a lista toda dos produtos
                if (prod_lista.length === 1) { // caso o utilizador pressione o botao de apagar todos os produtos do carrinho, mas essa lista apenar ter um produto

                    ws.send(JSON.stringify({ cativar: false, data: prod_lista }))

                } else { // caso te ter clicado em apagar tudo da lista de carrinho sendo que esta tem mais do que um produto

                    ws.send(JSON.stringify({ id_user: get_id_user(), cativar: false, data: prod_lista }))

                }

                ws.onmessage = async (event) => {
                    //    console.log('data_parse11', data_parse)

                    let data_parse = JSON.parse(event.data)
                    let type = data_parse.type

                    // type existe pois é uma forma de identificar se o server esta a enviar 
                    // informacao para atualizar carrinho e lista de stockes ou se esta a atualizar o countdown de um produto 
                    switch (type) {
                        case 'update_list':
                            console.log('update_list4', data_parse)

                            try {
                                if (parseInt(get_id_user()) === parseInt(data_parse.info.id_client)) {
                                    if (get_lista_carrinho().length > 0 && data_parse.info.carrinho.length === 0) {

                                        window.location.reload()

                                    }
                                    let temp = []//await func_ws().repor_prods_from_carrinho(data_parse.info.carrinho)

                                    set_lista_carrinho(data_parse.info.carrinho, false, temp)
                                }
                                //set_lista_events(data_parse.lista_produtos)
                            } catch (erro) {
                                console.log('erro cativar_prod211111', erro)
                            }
                            break;


                        case 'temporal_segundo':
                            //  console.error('temporal_segundo11', data_parse.data.tempo)

                            try {

                                if (parseInt(get_id_user()) === parseInt(data_parse.data.id_user)) {

                                    if ((data_parse.data.tempo).toString() === '00:00') { // verificar se o countdown do produto chegou a zero
                                        let aux_prod_que_foi_eliminado = await network().get_produtos_by_id(data_parse.data.id_prod)
                                        //alert('O produto ' + aux_prod_que_foi_eliminado.nome + ' foi removido do seu carrinho') // enviar alert para qualquer ecra
                                        Swal.fire({
                                            title: '',
                                            text: language[lang].label_introducao_frase_de_remocao_de_prod + aux_prod_que_foi_eliminado.nome + language[lang].label_final_frase_de_remocao_de_prod,

                                            icon: 'warning',
                                            showCancelButton: false,
                                            confirmButtonColor: '#0095e8',
                                            confirmButtonText: language[lang].label_confirmar,
                                        })
                                            .then((result) => {
                                                window.location.reload()

                                            })
                                    } else {
                                        update_timer(data_parse.data)
                                    }

                                }
                            } catch (erro) {
                                console.log('erro cativar_prod12224', erro)
                            }
                            break;


                        case 'erro':  // mensagem de erro caso o obj nao ter sido bem atualizado na bd
                            try {

                                if (parseInt(data_parse.id_user) === parseInt(get_id_user())) {

                                    /*   alert(translate_generic_store(data_parse.data, get_linguagem().toLowerCase())) */
                                    Swal.fire({
                                        title: '',
                                        text: translate_generic_store(data_parse.data, get_linguagem().toLowerCase()),
                                        icon: 'error',
                                        showCancelButton: false,
                                        confirmButtonColor: '#0095e8',
                                        confirmButtonText: language[lang].label_confirmar,
                                    })
                                        .then((result) => {
                                            window.location.reload()
                                        })
                                }
                            } catch (error) {
                                console.log('switch erro handler_remover_produto error', error)
                            }
                            break;


                        case 'sucesso':  // mensagem de sucesso caso o obj ter sido bem atualizado na bd
                            console.log('sucesso14')

                            try {

                                if (parseInt(data_parse.id_user) === parseInt(get_id_user())) {

                                    //alert(translate_generic_store(data_parse.data, get_linguagem().toLowerCase()))
                                    Swal.fire({
                                        title: '',
                                        text: translate_generic_store(data_parse.data, get_linguagem().toLowerCase()),
                                        icon: 'success',
                                        showCancelButton: false,
                                        confirmButtonColor: '#0095e8',
                                        confirmButtonText: language[lang].label_confirmar,
                                    })
                                        .then((result) => {
                                            window.location.reload()
                                        })
                                    removeprod(obj)

                                }
                            } catch (error) {
                                console.log('switch sucesso handler_remover_produto error', error)
                            }
                            break;


                        default:
                            break;

                    }
                };
            } else {// qtd mais do que 1

                let prod_nao_cativar = {
                    id_prod: obj.produto.id_prod,
                    quantidade: 1,
                    prod_timer: 0,
                    id_user: get_id_user(),
                    timestamp: obj.produto.id_event,
                    id_stock: obj.produto.id_stock
                }

                let id_user = get_id_user()
                let prod_lista = [prod_nao_cativar]

                try {

                    // distinguir se estou a apagar um produto ou a lista toda dos produtos
                    if (prod_lista.length === 1) { // caso o utilizador pressione o botao de apagar todos os produtos do carrinho, mas essa lista apenar ter um produto
                        ws.send(JSON.stringify({ cativar: false, data: prod_lista }))
                    } else { // caso te ter clicado em apagar tudo da lista de carrinho sendo que esta tem mais do que um produto
                        ws.send(JSON.stringify({ id_user: id_user, cativar: false, data: prod_lista }))
                    }

                    ws.onmessage = async (event) => {
                        //console.log('data_parse12', data_parse)

                        let data_parse = JSON.parse(event.data)
                        let type = data_parse.type

                        // type existe pois é uma forma de identificar se o server esta a enviar 
                        // informacao para atualizar carrinho e lista de stockes ou se esta a atualizar o countdown de um produto 
                        switch (type) {
                            case 'update_list':
                                try {
                                    if (parseInt(id_user) === parseInt(data_parse.info.id_client)) {
                                        if (get_lista_carrinho().length > 0 && data_parse.info.carrinho.length === 0) {

                                            window.location.reload()

                                        }
                                        let temp = []//await func_ws().repor_prods_from_carrinho(data_parse.info.carrinho)

                                        set_lista_carrinho(data_parse.info.carrinho, false, temp)
                                    }
                                    // set_lista_events(data_parse.lista_produtos)
                                } catch (erro) {
                                    console.log('erro cativar_prod2', erro)
                                }

                                break;
                            case 'temporal_segundo':
                                // console.error('temporal_segundo12', data_parse.data.tempo)

                                try {

                                    if (parseInt(get_id_user()) === parseInt(data_parse.data.id_user)) {

                                        if ((data_parse.data.tempo).toString() === '00:00') { // verificar se o countdown do produto chegou a zero
                                            let aux_prod_que_foi_eliminado = await network().get_produtos_by_id(data_parse.data.id_prod)
                                            //alert('O produto ' + aux_prod_que_foi_eliminado.nome + ' foi removido do seu carrinho') // enviar alert para qualquer ecra
                                            Swal.fire({
                                                title: '',
                                                text: language[lang].label_introducao_frase_de_remocao_de_prod + aux_prod_que_foi_eliminado.nome + language[lang].label_final_frase_de_remocao_de_prod,
                                                icon: 'warning',
                                                showCancelButton: false,
                                                confirmButtonColor: '#0095e8',
                                                confirmButtonText: language[lang].label_confirmar,
                                            })
                                                .then((result) => {
                                                    window.location.reload()

                                                })
                                        } else {
                                            update_timer(data_parse.data)
                                        }

                                    }
                                } catch (erro) {
                                    console.log('erro cativar_prod12224', erro)
                                }
                                //  func_ws().func_catch_timer(event, set_lista_carrinho, get_lista_carrinho(), id_user)

                                break;
                            case 'erro':  // mensagem de erro caso o obj nao ter sido bem atualizado na bd
                                try {

                                    if (parseInt(data_parse.id_user) === parseInt(get_id_user())) {

                                        //  alert(translate_generic_store(data_parse.data, get_linguagem().toLowerCase()))
                                        Swal.fire({
                                            title: '',
                                            text: translate_generic_store(data_parse.data, get_linguagem().toLowerCase()),
                                            icon: 'error',
                                            showCancelButton: false,
                                            confirmButtonColor: '#0095e8',
                                            confirmButtonText: language[lang].label_confirmar,
                                        })
                                            .then((result) => {
                                                window.location.reload()
                                            })
                                    }

                                } catch (error) {
                                    console.log('switch erro1 handler_remover_produto error', error)
                                }

                                break;
                            case 'sucesso':  // mensagem de sucesso caso o obj ter sido bem atualizado na bd
                                console.log('sucesso15')

                                try {

                                    if (parseInt(data_parse.id_user) === parseInt(get_id_user())) {
                                        Swal.fire({
                                            title: '',
                                            text: translate_generic_store(data_parse.data, get_linguagem().toLowerCase()),
                                            icon: 'success',
                                            showCancelButton: false,
                                            confirmButtonColor: '#0095e8',
                                            confirmButtonText: language[lang].label_confirmar,
                                        })
                                            .then((result) => {
                                                window.location.reload()
                                            })
                                        //alert(translate_generic_store(data_parse.data, get_linguagem().toLowerCase()))
                                        updateprodnegative(obj.produto.id_stock)

                                    }
                                } catch (error) {
                                    console.log('switch sucesso 1handler_remover_produto error', error)
                                }

                                break;
                            default:
                                break;
                        }
                    };
                } catch (error) {
                    console.log('nao_cativar_prod error', error)
                }

            }

        } catch (error) {
            console.log('handler_remover_produto eror', error)
        }
    }

    const alert_handler_remover_produto = (el) => {
        try {
            return (
                Swal.fire({
                    title: '',
                    text: language[lang].label_eliminar_produto,
                    icon: 'question',

                    showCancelButton: true,
                    confirmButtonColor: '#0095e8',
                    cancelButtonColor: '#d9214e',

                    confirmButtonText: language[lang].label_confirmar,
                    cancelButtonText: language[lang].label_cancelar
                })
                    .then((result) => {
                        console.log('Swal1', result)
                        if (result.isConfirmed === true) {
                            handler_remover_produto(el.info)
                        }
                    })
            )
        } catch (error) {
            console.log('alert_handler_remover_produto error', error)
        }
    }

    return (
        isloading ?
            <Loader />
            :
            isRedirect ?
                <Redirect to={'/'} replace />
                :
                <>
                    <Navbar />

                    <Breadcrumb />

                    <div id="services" className="container-fluid">
                        <div className="d-flex">

                            <div className='div1_parcialmente_aberto my-5'>
                                <div className=''>
                                    <div className=''>
                                        {
                                            lista_categorias.length !== 0 ?
                                                <div className='d-flex justify-content-center mb-4'>
                                                    {
                                                        lista_categorias.map((el, key) => {
                                                            return (
                                                                <button key={key} type='button' onClick={() => set_new_category(el.codgrupo)} className={'btn btn-primary mr-2' + (el.codgrupo.toLowerCase() === selected_categoria.toLocaleLowerCase() ? ' active' : '')}>
                                                                    {translate_generic_store(el.translate, lang)}
                                                                </button>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                :
                                                <div style={{
                                                    backgroundColor: '#e2dbc9',
                                                    display: 'flex',
                                                    flex: 1,
                                                    flexDirection: 'row',
                                                    alignContent: 'center',
                                                    justifyContent: 'center',
                                                    padding: '5%'
                                                }}>
                                                    <label>
                                                        {language[lang].label_nao_existe_info}
                                                    </label>
                                                </div>
                                        }
                                    </div>

                                    <div className={''} >
                                        {
                                            lista_produtos.length !== 0 ?
                                                <div className={'grid_container open'}>
                                                    {
                                                        lista_produtos.map((obj, key) => {
                                                            let element = get_format_correct(obj, lang)
                                                            return (
                                                                <a key={key} href={WEB_SITE_URI + 'desk/service/' + obj.id} className="grid_item bg-white pt-1 border-0 color:red"
                                                                    onClick={() => {
                                                                        // history.push('/reservation/service/' + obj.id);
                                                                        get_produtos_schedule(obj.id)
                                                                    }}>
                                                                    <div>
                                                                        <AssecImage
                                                                            linkImage={element.img.length !== 0 && element.img[0].link !== '' ? element.img[0].link : repo_img_link('nophoto.webp')}
                                                                            width="400"
                                                                            height="300"
                                                                            fit='crop'
                                                                            type='img'
                                                                            sClass="img-fluid"
                                                                            alt=''
                                                                        />
                                                                    </div>
                                                                    <div className='d-flex justify-content-between px-2 mt-2'>
                                                                        <div>
                                                                            <i className="bi bi-clock mr-1"></i>
                                                                            <label>
                                                                                {element.duracao_min} {language[lang].label_min}
                                                                            </label>
                                                                        </div>
                                                                        <div>
                                                                            <label className='label_from mr-1'>
                                                                                {language[lang].label_desde}
                                                                            </label>
                                                                            <label className='label_price font-weight-bold'>
                                                                                {element.preco}€
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <label className='label_nome_produto font-weight-bold px-2'>
                                                                            {element.nome}
                                                                            <br />
                                                                            <small>{element.observacao}</small>
                                                                        </label>
                                                                    </div>
                                                                </a>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                :
                                                <div className='container'>
                                                    <div className='row py-5'>
                                                        <div className='col-12 py-5'>
                                                            <div className="d-flex justify-content-center p-5 overlay-img">
                                                                <h3 className='text-white fs-6 py-5'>
                                                                    {language[lang].label_nao_existe_info}
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className={'div-cart' + (get_lista_carrinho().length > 0 ? '' : '')} >
                                <div className='flex flex-column p-3'>

                                    <h2>O seu carrinho2</h2>
                                    {
                                        get_lista_carrinho().length > 0 ?
                                            get_lista_carrinho().sort((a, b) => {
                                                let aux1 = a.info.produto.id
                                                let aux2 = b.info.produto.id
                                                if (aux1 < aux2) {
                                                    return -1;
                                                }
                                                if (aux1 > aux2) {
                                                    return 1;
                                                }
                                                return 0;
                                            }).map((el, key) => {
                                                let prod_timestamp = el.info.produto.id_event
                                                //  let prod_principal = el.info.produto.composicao.find((el) => el.principal === true)

                                                return (

                                                    <li key={key} className='list-group rounded-0 border-bottom mb-3'>
                                                        <div className="card_products_list">
                                                            <div className="text-right d-flex justify-content-end">
                                                                <div className='text-nowrap text-truncate mr-3 pb-2'>
                                                                    <span className='font-weight-bold'>{translate_generic_store(el.info.produto.linha.subname, get_linguagem().toLowerCase())}</span>
                                                                    <br />
                                                                    <small className='mr-3 font-weight-bold'>
                                                                        {translate_generic_store(el.info.produto.linha.observation, get_linguagem().toLowerCase())}
                                                                    </small>
                                                                    <small className='mr-3'>
                                                                        {
                                                                            el.info.produto.data
                                                                            /* el.info.produto.details.goldenrock.dia.split('-')[2] +
                                                                            '-' + el.info.produto.details.goldenrock.dia.split('-')[1] +
                                                                            '-' + el.info.produto.details.goldenrock.dia.split('-')[0]   */
                                                                        }
                                                                    </small>
                                                                    <small>
                                                                        {translate_horas(get_linguagem(), el.info.produto.linha.details[nome_empresa].validade_data_hora_ini_to_show)}
                                                                    </small>
                                                                </div>
                                                                <div className="text-nowrap">
                                                                    {el.quantidade === 0 ? '1' : el.quantidade}
                                                                    <i className="bi bi-x"></i>
                                                                    {isNaN(el.info.produto.linha.total_liquido) === true ? 0 : parseFloat(el.info.produto.linha.total_liquido).toFixed(2).replace('.', ',')} €
                                                                    <br />
                                                                    <small style={{ fontVariantNumeric: 'tabular-nums' }}>
                                                                        {prod_timestamp === 0 || prod_timestamp === 'null' ? '' : handler_countdown(el)}
                                                                    </small>
                                                                </div>
                                                                <div className='text-right'>
                                                                    <button className='btn btn-secondary btn-sm ml-3 py-0 px-1' onClick={() => { alert_handler_remover_produto(el) }} >
                                                                        <i className="bi bi-x"></i>
                                                                    </button>
                                                                </div>
                                                                <br />
                                                            </div>
                                                        </div>
                                                    </li>

                                                )

                                            })
                                            :
                                            null
                                    }
                                </div>
                                {
                                    get_lista_carrinho().length > 0 ?
                                        <div className='flex flex-column p-3'>
                                            <div >
                                                <a href={Hosts.WEB_SITE_URI + (user_profile === 'Colaborador' ? 'desk/cart' : 'reservation/cart')} className="btn btn-secondary w-100">Ir para o carrinho</a>
                                            </div>
                                        </div>
                                        :
                                        null
                                }
                            </div>

                        </div>
                    </div>

                    <Footer />
                </>
    );


}

export default ProdByCategory;
