import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import "../Pagina.css";
import "../content-styles.css"

import * as Hosts from '../../../Hosts';
import {
    set_language,
    reqGET,
    content_language,
    my_local_storage,

} from '../../../Utils';

import './Reserva.css';

import Loader from '../../../loader';
import Navbar from "../Navbar/Navbar";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Footer from "../Footer/Footer";

import { language } from './Language';

import AssecImage from "../ASSEC_Image/Image"

const Reserva = () => {



    const { pathname } = useLocation();

    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState('pt');
    const [, set_lang_id] = useState('1');

    const [services, set_services] = useState([]);

    const [, set_cart] = useState({});

    let getServices = async () => {

        reqGET(`${Hosts.SIMCore_API}/platform/products/grupo/WEL`)
            .then(res => {
                set_services(res.data)

                setLoading(false)
                // console.log(res.data)
            })
            .catch(erro => console.log(erro))

    }

    useEffect(() => {

        if (my_local_storage.getItem('cart') !== undefined && my_local_storage.getItem('cart') !== null) {
            //console.log('cart', my_local_storage.getItem('cart'))

            let start = new Date(JSON.parse(my_local_storage.getItem('cart')).date).getTime()
            let end = new Date().getTime()

            var msDiff = start - end;
            //var diffdays = Math.floor(msDiff / (1000 * 60 * 60 * 24));
            var diffhours = Math.floor(msDiff / (1000 * 60 * 60));
            if (diffhours > -2) {
                set_cart(JSON.parse(my_local_storage.getItem('cart')))
            }
            else {
                set_cart({})
                my_local_storage.removeItem('cart')
            }
        }

        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }

        getServices()

    }, [pathname]);

    /*   const get_item_cart_value = (id) => {
  
          let result = 0
          cart['services'].forEach(el => {
              if(el.id === id) {
                  result = el.quantity
              }
          })
          return result
  
      } */

    /*  const change_quantity = (id, e) => {
 
         //if(cart['date'] === undefined) {
             cart['date'] = new Date()
         //}
 
         if(cart['services'] === undefined)
             cart['services'] = [] 
 
         const aux = cart['services'].filter(el => el.id === id)
         if(aux.length > 0) {
             cart['services'].forEach(el => {
                 if(el.id === id) {
                     el.quantity = e
                 }
             })
         }
         else {
             cart['services'].push({id: id, quantity: e})
         }
 
         console.log('Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«')
         console.log('cart:' + JSON.stringify(cart))
         
         upgrade_cart('cart', JSON.stringify(cart))
         set_cart(cart)
         
     } */

    /*  const add_cart_finalize_order = () => {
         // console.log('cart', cart)
         // upgrade_cart('cart', JSON.stringify(cart))
 
         // setRefresh(refresh + 1)
 
         // console.log('sessionSotage', sessionStorage.getItem('cart'))
 
         window.location.href = Hosts.WEB_SITE_URI + 'store/cart'
     } */

    return (isloading ?

        <Loader />

        :
        <>
            <Navbar />
            <Breadcrumb />

            <div id="services" className="container-fluid my-5">
                <div className="row">
                    <div className='col-12'>

                        <div className="container my-5 d-none">
                            <h3 className="titulo-primario">{language[lang].comprar}</h3>
                        </div>

                        <div className="container">
                            <div className="row">
                                {
                                    services.map((el, key) => {
                                        return (
                                            <div key={key} className={'col-4 mb-3'}>
                                                <div className={'card rounded-0'}>
                                                    <div className='card-header p-0'>
                                                        {
                                                            el.composicao[0].multimedia.link_images.length > 0 ?
                                                                <AssecImage
                                                                    linkImage={el.composicao[0].multimedia.link_images[0].link}
                                                                    width="450"
                                                                    height="300"
                                                                    fit='crop'
                                                                    type='img'
                                                                    sClass="img-fluid"
                                                                    alt={''}
                                                                />
                                                                :
                                                                <img alt='' className='img-fluid py-3 px-5' src="https://cdn.assec.app/repo/alambique/goldenrock/static/assets/logo/logo.svg" />
                                                        }
                                                    </div>
                                                    <div className='card-body'>
                                                        <h5 className='card-title'>{el.nome}</h5>
                                                        <hp className='card-text'></hp>
                                                    </div>
                                                    <div className='card-footer'>
                                                        <button className='text-right'
                                                        /*  onClick={() => {
                                                             history.push('/reservation/service/' + el.id);
                                                         }} */
                                                        >

                                                            {/*  <Link className="btn btn-secondary" to={{ pathname: '/reservation/service/' + el.id }} replace>
                                                                Reservar
                                                            </Link>  */}
                                                            <a href={Hosts.WEB_SITE_URI + '/reservation/service/' + el.id} className="btn btn-secondary mt-3" >
                                                                Reservar
                                                            </a>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>

    );

};
export default Reserva;

