import React, { useState, useEffect/*, useRef */} from 'react';
import {Button } from "reactstrap";
import Navbar from '../Navbar/Navbar';
import Footer from "../Footer/Footer";

import { useParams } from "react-router-dom";

import './Forms.css';
// import parse from 'html-react-parser';

import Swal from 'sweetalert2'

import * as Hosts from '../../../Hosts';
import {
    set_language,
    //reqGET,
	reqPOST,
    /*repo_banner_link,*/
    content_language,
    passwordHash

} from '../../../Utils';


import { language } from '../WEBContent/Language';


const RecoveryForm = () => {

    const { hash } = useParams();

    const [lang, set_lang] = useState('pt');
    const [/*lang_id*/, set_lang_id] = useState('1');
    const [/*data*/, /*setData*/] = useState([]); 
    
    const [password_new, set_password_new] = useState(''); 
    const [password_conf, set_password_conf] = useState(''); 

    /*let getInfo = async () => {

        reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/7`)
            .then(res => { 
                setData(res.data)
                //console.log(res.data)

            })
            .catch(erro => alert('Erro'))  

    }*/
 
    useEffect(() => { 
        //getInfo()
        
        console.log('######################################')
        console.log('######################################')
        console.log('######################################')
        console.log('######################################')
        console.log('######################################')
        console.log('######################################')


        if(localStorage.getItem('lang') === undefined  || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if(el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }
    
    }, []); 

    const handleSubmit = async e => {
        e.preventDefault();

        if(password_new !== password_conf) {
            Swal.fire(language[lang].confirmation_password_error_message, '', 'error')
        }
        else {

            reqPOST(Hosts.SIMCore_API + '/entities/password/recovery', {companycode: Hosts.companycode, recovery_hash: hash, new_password: passwordHash(password_new)})
                .then(res => {
                    console.log(res)
  
                    if(res.status === 200) {
                        set_password_new('')
                        set_password_conf('')
                        
                        Swal.fire(language[lang].change_password_sucess_message, '', 'info')
                    }
                    else {
                        Swal.fire(language[lang].change_password_expiration_message, '', 'error')
                    }
                    
            
                })
                .catch(() => 404)
        }
    }
        
    return(
        <div>
            <Navbar />
            <section id="recovery" className="py-5">
                <div className="container">
                    <div className='row'>
                        <div className='col-12 mb-5'>
                            <h3 className="titulo-primario">
                                {language[lang].contact_recovery}
                            </h3>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit}>

                        <div className="row">
                            <div className="col-12 col-sm-6">
                                <label>{language[lang].password_new}*</label>
                                <input type="password" name="password_new" value={password_new} className="form-control" placeholder="" onChange={(e) => set_password_new(e.target.value)} required />
                            </div>                            
                            <div className="col-12 col-sm-6">
                                <label>{language[lang].password_conf}*</label>
                                <input type="password" name="password_conf" value={password_conf} className="form-control" placeholder="" onChange={(e) => set_password_conf(e.target.value)} required />
                            </div>
                            
                        </div>

                        <div className="text-right">
                            <Button className="mt-5 mx-auto" color="primary">{language[lang].send}</Button>     
                        </div>                              

                    </form>
                </div>
            </section>
            <Footer />
        </div>
    );


};

export default RecoveryForm;