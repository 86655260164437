export const language = {
    'pt':
    {
        label_erro_tempo_depois_de_horario_escolhido: 'Não foi possivel atualizar por favor tente de novo.',

        label_min: 'min',
        label_desde: 'desde / ',
        register_here: 'Registe-se aqui',
        recover_password: 'Recuperar palavra-passe',
        label_disponibilidade: 'Disponibilidade',
        label_marcar: 'Marcar',
        label_inicio: 'Inicio: ',
        label_fim: 'Fim: ',
        label_manha: 'Manhã',
        label_tarde: 'Tarde',
        label_introducao_frase_de_remocao_de_prod: 'O produto ',
        label_final_frase_de_remocao_de_prod: ' foi removido do seu carrinho.',
        label_aviso_servidor_foi_a_baixo: 'A ligação à internet foi interrompida, o seu carrinho de compras foi apagado, para voltar a ligar-se por favor reinicie a aplicação.',
        label_eliminar_produto: 'Tem a certeza que pretende eliminar o produto?',
        label_confirmar: 'Confirmar',
        label_cancelar: 'Cancelar',
        label_nao_existe_info: 'Não existe informação.',
        comprar: 'Comprar',
        store_tarifas_info: '* Vendas exclusivas Online. Não se efetuam vendas na Estância de Ski.',
        store_tarifas: 'Tarifas',
        username: 'Utilizador',
        //password: 'Password',
        send: 'Enviar',
        introduza_email: 'Introduza o email',

        name: 'Nome',
        name_placeholder: 'Introduza o nome',
        new_password: 'Nova password',
        new_password_placeholder: 'Introduza a nova password',
        new_password_2: 'Confirmar password',
        new_password_2_placeholder: 'Confirme a nova password',
        mobile_phone: 'Número de telemóvel',
        mobile_phone_placeholder: 'Introduza o número de telemóvel',
        surname: 'Apelido',
        surname_insert: 'Introduza o apelido',
        access_data: 'Dados de acesso',
        username_email: 'Email',
        email_placeholder: 'Introduza o email',
        username_error_message: 'Já existe um registo com este username.',
        username_insert: 'Introduza o utilizador',
        agree_terms_conditions: 'Concordo com os ',
        terms_conditions: 'Termos e condições',
        terms_conditions_link: 'termos e condições.',
        terms_conditions_required: 'Os campos assinalados com (*) são de preenchimento obrigatório. O endereço de email permite um contacto mais rápido em caso de necessidade de esclarecimentos adicionais. A informação que submeter não será usada ou distribuída por outra razão que não o processamento do seu pedido.',

        agree_rgpd: 'Gostaria de receber informações de interesse sobre o Golden Rock - Alambique Water Spa.',

        contact_email: 'Contact Email',

        contact_recovery: 'Pedido de recuperação',
        password: 'Palavra-passe',
        password_recover: 'Foi registado um pedido de recuperação da palavra-passe da sua conta.',
        password_recover_2: 'Clique no seguinte link para confirmar o seu pedido e escolher outra palavra-passe:',
        password_recover_3: 'Se não efetuou nenhum pedido, ignore este mail e a sua palavra-passe não será alterada.',

        submitted_application: 'Pedido enviado',
        email: 'E-mail',
        email_contact: 'E-mail preferencial de contacto',
        email_error_message: 'Campo inválido. Inclua um "@" no endereço de e-mail.',
        email_recover_pass: 'Verifique o seu e-mail.',
        email_recover_pass_2: 'O e-mail não se encontra registado.',
        email_register: 'E-mail',
        email_settings: 'Definições de email',
        incorrect_request: 'Pedido incorreto',
        confirmation_password_error_message: 'Password de confirmação diferente da password inserida',
        regist_form_sucess_message: 'Registro realizado com sucesso. Verifique os seus dados de acesso no email enviado.',

        insert_title: 'Novo Registo',
        insert_text: 'Tem a certeza que pretende continuar?',

        failed_login: 'Falha ao iniciar sessão',
        incorrect_login_data: 'Dados de acesso incorretos',
        label_impossivel_add_outro_prod_timer1: 'Não é possível adicionar produto. ',
        label_impossivel_add_outro_prod_timer2: 'Atingiu o limite máximo de ',
        label_impossivel_add_outro_prod_timer3: ' produtos temporizados por encomenda.',





    },
    'en':
    {
        label_erro_tempo_depois_de_horario_escolhido: 'Unable to update, please try again.',

        label_min: 'min',
        label_desde: 'since/',
        register_here: 'Register here',
        recover_password: 'Recover password',
        label_disponibilidade: 'Availability',
        label_marcar: 'To mark',
        label_inicio: 'Start: ',
        label_fim: 'End: ',
        label_manha: 'Morning',
        label_tarde: 'Afternoon',
        label_introducao_frase_de_remocao_de_prod: 'The product ',
        label_final_frase_de_remocao_de_prod: ' has been removed from your cart.',
        label_aviso_servidor_foi_a_baixo: 'The internet connection has been interrupted, your shopping cart has been deleted, to reconnect please restart the application.',
        label_eliminar_produto: 'Are you sure you want to delete the product?',
        label_confirmar: 'Confirm',
        label_cancelar: 'Cancel',
        label_nao_existe_info: 'There is no information.',
        comprar: 'To buy',
        store_tarifas_info: '* Exclusive Online Sales. No sales are made at the Ski Resort.',
        store_tarifas: 'Tariffs',
        username: 'Username',
        //  password: 'Password',
        send: 'Send',
        introduza_email: 'Introduza o email',

        name: 'Nome',
        name_placeholder: 'Introduza o nome',
        new_password: 'Nova password',
        new_password_placeholder: 'Introduza a nova password',
        new_password_2: 'Confirmar password',
        new_password_2_placeholder: 'Confirme a nova password',
        mobile_phone: 'Número de telemóvel',
        mobile_phone_placeholder: 'Introduza o número de telemóvel',
        surname: 'Apelido',
        surname_insert: 'Introduza o apelido',
        access_data: 'Dados de acesso',
        username_email: 'Email',
        email_placeholder: 'Introduza o email',
        username_error_message: 'Já existe um registo com este username.',
        username_insert: 'Introduza o utilizador',
        agree_terms_conditions: 'Concordo com os ',
        terms_conditions: 'Termos e condições',
        terms_conditions_link: 'termos e condições.',
        terms_conditions_required: 'Os campos assinalados com (*) são de preenchimento obrigatório. O endereço de email permite um contacto mais rápido em caso de necessidade de esclarecimentos adicionais. A informação que submeter não será usada ou distribuída por outra razão que não o processamento do seu pedido.',

        agree_rgpd: 'Gostaria de receber informações de interesse sobre o Golden Rock - Alambique Water Spa.',

        contact_email: 'Contact Email',

        contact_recovery: 'Pedido de recuperação',
        password: 'Palavra-passe',
        password_recover: 'Foi registado um pedido de recuperação da palavra-passe da sua conta.',
        password_recover_2: 'Clique no seguinte link para confirmar o seu pedido e escolher outra palavra-passe:',
        password_recover_3: 'Se não efetuou nenhum pedido, ignore este mail e a sua palavra-passe não será alterada.',

        submitted_application: 'Pedido enviado', email: 'E-mail',
        email_contact: 'Contact e-mail',
        email_error_message: 'Invalid field. Include an "@" in the email address.',
        email_recover_pass: 'Check your email.',
        email_recover_pass_2: 'E-mail is not registered.',
        email_register: 'Register',
        email_settings: 'E-mail settings',
        incorrect_request: 'Pedido incorreto',
        confirmation_password_error_message: 'Password de confirmação diferente da password inserida',
        regist_form_sucess_message: 'Registration successful. Check your access data in the email sent.',

        insert_title: 'Novo Registo',
        insert_text: 'Tem a certeza que pretende continuar?',

        failed_login: 'Falha ao iniciar sessão',
        incorrect_login_data: 'Dados de acesso incorretos',
        label_impossivel_add_outro_prod_timer1: 'Unable to add product. ',
        label_impossivel_add_outro_prod_timer2: 'Reached the maximum limit of ',
        label_impossivel_add_outro_prod_timer3: ' timed products of order.',
    },
    'es':
    {
        label_erro_tempo_depois_de_horario_escolhido: 'No se puede actualizar, inténtalo de nuevo.',

        label_min: 'min',
        label_desde: 'desde/',
        register_here: 'Registrar aquí',
        recover_password: 'Recuperar contraseña',
        label_disponibilidade: 'Disponibilidad',
        label_marcar: 'Marcar',
        label_inicio: 'Comenzar: ',
        label_fim: 'Fin: ',
        label_manha: 'Mañana',
        label_tarde: 'Tarde',
        label_introducao_frase_de_remocao_de_prod: 'El producto ',
        label_final_frase_de_remocao_de_prod: ' ha sido eliminado de su carrito.',
        label_aviso_servidor_foi_a_baixo: 'La conexión a Internet se ha interrumpido, su carrito de compras ha sido eliminado, para volver a conectarse reinicie la aplicación.',
        label_eliminar_produto: '¿Estás seguro de que quieres deshacerte del producto?',
        label_confirmar: 'Confirmar',
        label_cancelar: 'Cancelar',
        label_nao_existe_info: 'No hay información.',
        comprar: 'Comprar',
        store_tarifas_info: '* Ventas exclusivas en línea. No se realizan ventas en la Estación de Esquí.',
        store_tarifas: 'Tarifas',
        username: 'Username',
        //password: 'Password',
        send: 'Send',
        introduza_email: 'Introduza o email',

        name: 'Nome',
        name_placeholder: 'Introduza o nome',
        new_password: 'Nova password',
        new_password_placeholder: 'Introduza a nova password',
        new_password_2: 'Confirmar password',
        new_password_2_placeholder: 'Confirme a nova password',
        mobile_phone: 'Número de telemóvel',
        mobile_phone_placeholder: 'Introduza o número de telemóvel',
        surname: 'Apelido',
        surname_insert: 'Introduza o apelido',
        access_data: 'Dados de acesso',
        username_email: 'Email',
        email_placeholder: 'Introduza o email',
        username_error_message: 'Já existe um registo com este username.',
        username_insert: 'Introduza o utilizador',
        agree_terms_conditions: 'Concordo com os ',
        terms_conditions: 'Termos e condições',
        terms_conditions_link: 'termos e condições.',
        terms_conditions_required: 'Os campos assinalados com (*) são de preenchimento obrigatório. O endereço de email permite um contacto mais rápido em caso de necessidade de esclarecimentos adicionais. A informação que submeter não será usada ou distribuída por outra razão que não o processamento do seu pedido.',

        agree_rgpd: 'Gostaria de receber informações de interesse sobre o Golden Rock - Alambique Water Spa.',

        contact_email: 'Contact Email',

        contact_recovery: 'Pedido de recuperação',
        password: 'Palavra-passe',
        password_recover: 'Foi registado um pedido de recuperação da palavra-passe da sua conta.',
        password_recover_2: 'Clique no seguinte link para confirmar o seu pedido e escolher outra palavra-passe:',
        password_recover_3: 'Se não efetuou nenhum pedido, ignore este mail e a sua palavra-passe não será alterada.',

        submitted_application: 'Pedido enviado', email: 'E-mail',
        email_contact: 'Contact e-mail',
        email_error_message: 'Invalid field. Include an "@" in the email address.',
        email_recover_pass: 'Check your email.',
        email_recover_pass_2: 'E-mail is not registered.',
        email_register: 'Register',
        email_settings: 'E-mail settings',
        incorrect_request: 'Pedido incorreto',
        confirmation_password_error_message: 'Password de confirmação diferente da password inserida',
        regist_form_sucess_message: 'Registration successful. Check your access data in the email sent.',

        insert_title: 'Novo Registo',
        insert_text: 'Tem a certeza que pretende continuar?',

        failed_login: 'Falha ao iniciar sessão',
        incorrect_login_data: 'Dados de acesso incorretos',
        label_impossivel_add_outro_prod_timer1: 'No se puede agregar producto. ',
        label_impossivel_add_outro_prod_timer2: 'Alcanzó el límite máximo de ',
        label_impossivel_add_outro_prod_timer3: ' productos temporizados bajo demanda.',

    },
    'fr': {
        label_erro_tempo_depois_de_horario_escolhido: 'Impossible de mettre à jour, veuillez réessayer.',

        label_min: 'min',
        label_desde: 'depuis/',
        register_here: 'Inscrivez-vous ici',
        recover_password: 'Récupérer mot de passe',
        label_disponibilidade: 'Disponibilité',
        label_marcar: 'Marquer',
        label_inicio: 'Commencer: ',
        label_fim: 'Fin: ',
        label_manha: 'Matin',
        label_tarde: 'Après-midi',
        label_introducao_frase_de_remocao_de_prod: 'Le produit ',
        label_final_frase_de_remocao_de_prod: ' a été supprimé de votre panier.',
        label_aviso_servidor_foi_a_baixo: "La connexion internet a été interrompue, votre panier a été supprimé, pour vous reconnecter merci de redémarrer l'application.",
        label_eliminar_produto: 'Êtes-vous sûr de vouloir supprimer le produit ?',
        label_confirmar: 'Confirmer',
        label_cancelar: 'Annuler',
        label_nao_existe_info: "Il n'y a aucune information.",
        comprar: 'Acheter',
        store_tarifas_info: '* Ventes en ligne exclusives. Aucune vente n\'est effectuée à la Station de Ski.',
        store_tarifas: 'Tarifs',
        username: 'Username',
        //password: 'Password',
        send: 'Send',
        introduza_email: 'Introduza o email',

        name: 'Nome',
        name_placeholder: 'Introduza o nome',
        new_password: 'Nova password',
        new_password_placeholder: 'Introduza a nova password',
        new_password_2: 'Confirmar password',
        new_password_2_placeholder: 'Confirme a nova password',
        mobile_phone: 'Número de telemóvel',
        mobile_phone_placeholder: 'Introduza o número de telemóvel',
        surname: 'Apelido',
        surname_insert: 'Introduza o apelido',
        access_data: 'Dados de acesso',
        username_email: 'Email',
        email_placeholder: 'Introduza o email',
        username_error_message: 'Já existe um registo com este username.',
        username_insert: 'Introduza o utilizador',
        agree_terms_conditions: 'Concordo com os ',
        terms_conditions: 'Termos e condições',
        terms_conditions_link: 'termos e condições.',
        terms_conditions_required: 'Os campos assinalados com (*) são de preenchimento obrigatório. O endereço de email permite um contacto mais rápido em caso de necessidade de esclarecimentos adicionais. A informação que submeter não será usada ou distribuída por outra razão que não o processamento do seu pedido.',

        agree_rgpd: 'Gostaria de receber informações de interesse sobre o Golden Rock - Alambique Water Spa.',

        contact_email: 'Contact Email',

        contact_recovery: 'Pedido de recuperação',
        password: 'Palavra-passe',
        password_recover: 'Foi registado um pedido de recuperação da palavra-passe da sua conta.',
        password_recover_2: 'Clique no seguinte link para confirmar o seu pedido e escolher outra palavra-passe:',
        password_recover_3: 'Se não efetuou nenhum pedido, ignore este mail e a sua palavra-passe não será alterada.',

        submitted_application: 'Pedido enviado', email: 'E-mail',
        email_contact: 'Contact e-mail',
        email_error_message: 'Invalid field. Include an "@" in the email address.',
        email_recover_pass: 'Check your email.',
        email_recover_pass_2: 'E-mail is not registered.',
        email_register: 'Register',
        email_settings: 'E-mail settings',
        incorrect_request: 'Pedido incorreto',
        confirmation_password_error_message: 'Password de confirmação diferente da password inserida',
        regist_form_sucess_message: 'Registration successful. Check your access data in the email sent.',

        insert_title: 'Novo Registo',
        insert_text: 'Tem a certeza que pretende continuar?',

        failed_login: 'Falha ao iniciar sessão',
        incorrect_login_data: 'Dados de acesso incorretos',
        label_impossivel_add_outro_prod_timer1: "Impossible d'ajouter un produit. ",
        label_impossivel_add_outro_prod_timer2: 'A atteint la limite maximale de ',
        label_impossivel_add_outro_prod_timer3: ' produits chronométrés sur commande.',


    }
}

