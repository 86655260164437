export let cores = {
    cor1: '#83A1B2',
    cor2: '#EEF4FD',//escuro
    cor3: 'white',
    cor4: '#cccbca',
    cor5: 'grey',
    cor6: 'transparent',
    cor7: 'black',
    cor8: 'red',
    cor9: 'green',
    cor10: 'rgba(188, 209, 238, 0.2)',
    cor11: 'rgba(0,0,0,0.0)',
    cor12: 'rgba(113, 143, 163, 1)',
    cor13: '#8c6f29',
    cor14: '#e2dbc9',
    cor15: '#f9f9f9',
    cor16: '#aaaaaa',
    cor17: '#252c30',
    cor18: '#5c6164',
    cor19: '#F96332',


}


export const imagens = {

    icon_relogio: require('../img/clock.png'),




}


//export const valores_costums = { cores, imagens, nome_ecras, dados_da_empresa, EMPRESA_TIMING }