
async function Prod_verify(lista) {

    let lista_to_return = []
    let flag_foi_validado = false

    lista.forEach((v) => {
        if (v.activo === true && v.visible === true && !isNaN(v.total_liquido)) {

            v.composicao.forEach((el) => {
                if (el.principal === true || el.principal === 'true') {
                    el.descricao.nome !== undefined &&
                        el.descricao.nome !== '' &&
                        !isNaN(parseFloat(el.valores.iva.code)) &&
                        !isNaN(parseFloat(el.valores.iva.taxa)) &&
                        !isNaN(parseFloat(el.valores.preco_liquido)) &&
                        !isNaN(parseFloat(el.valores.preco_unitario))

                        ? flag_foi_validado = true : flag_foi_validado = false

                } else if (el.principal === false || el.principal === 'false') {
                    el.descricao.nome !== undefined &&
                        el.descricao.nome !== '' &&
                        !isNaN(parseFloat(el.valores.iva.code)) &&
                        !isNaN(parseFloat(el.valores.iva.taxa)) &&
                        !isNaN(parseFloat(el.valores.preco_liquido)) &&
                        !isNaN(parseFloat(el.valores.preco_unitario))

                        ? flag_foi_validado = true : flag_foi_validado = false
                }
            })
        } else {
            flag_foi_validado = false
        }

        if (flag_foi_validado) {
            lista_to_return.push(v)
        }

    })
    return lista_to_return
}
export { Prod_verify }
