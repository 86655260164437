import React, { useState, useEffect } from 'react';

import { set_language, content_language, repo_img_link} from '../../../Utils';

import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

import Loader from '../../../loader';

export default function Normal(props) {

    const [, set_lang] = useState('pt');
    const [, set_lang_id] = useState('1');

    const [isloading, setLoading] = useState(true);

    useEffect(() => {
        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }

        setLoading(false) 
        
    }, []);

    return (isloading ?

		<Loader />
		
		:
        <>
            <Navbar />
            <main className="notfound">
                <div>
                    {/* <Helmet>
                        <title>{content.info.seo !== undefined && content.info.seo['title'] !== undefined ? content.info.seo['title'] : Hosts.SITES.SIMWEBSITE.DEFAULT_SEO_TITLE}</title>
                        <meta name="description" content={content.info.seo !== undefined && content.info.seo['description'] !== undefined ? content.info.seo['description'] : Hosts.SITES.SIMWEBSITE.DEFAULT_SEO_DESCRIPTION} />
                        <meta name="keywords" content={content.info.seo !== undefined && content.info.seo['keywords'] !== undefined ? content.info.seo['keywords'] : Hosts.SITES.SIMWEBSITE.DEFAULT_SEO_KEYWORDS} />
                    </Helmet> */}
                </div>
                <div className="col-12 text-center mb-5">
                    <section className="container" style={{ backgroundImage: `url(` + repo_img_link("not-found.jpg") + `)`, height: `46vh`, backgroundSize: `cover`, backgroundPosition: `center`}}>
                    </section>
                    {/* {language[lang].not_found} */}
                </div>
                
            </main>
            <Footer />
        </>
    )
}