import { useEffect, useState, useRef } from "react";
import Modal from "react-modal";
import './GenericStore.css';
import './CostumModalLogin.css';
import { language } from "./Language";
import  useContextProvider  from "./Providers/ContextProvider";

import * as Hosts from '../../../Hosts';
import {reqPOST, reqGET, passwordHash, repo_banner_link, repo_logo_link} from '../../../Utils';



import Swal from 'sweetalert2'

const CostumModalLogin = ({ set_open_modal_login, lang }) => {
    Modal.setAppElement("#root");

    const data_interface = {
        id: '',
        info: {
            companycode: Hosts.companycode,
            username: '',
            password: '',
            name: '',
            surname: '',
            location: '',
            email: '',
            from: '',
            vat: '999999990',
            marketing:false,
            terms_checked: true,
            rgpd: true,
            ativo: true,
            birthday: '',
            entity_type: '',
            observation: '',
            identification: {
              tipo_identificacao: '',
              numero_identificacao: '',
              validade_identificacao: ''
            },
            notifications: [],
            use_preferences: { language: 'PT' }
        },
        addresses: [{
            //village: '',
            //city: '',
            //type: [],
            //region: '',
            country: '',
            //apartado: '',
            //localidade: '',
            //contats: [],
            //principal: '',
            //observation: '',
            //postal_code: '',
            //street_name: '',
            //address_detail: '',
        }],
        contacts: [{
            contactemail: '',
            email: '',
    
            principal: 'true',
            //extensions: [],
            observation: '',
    
            //building_number: '',
            /*person_responsables: [{
                id: '',
                name: '',
                email: '',
                extensions: '',
                observation: '',
                mobile_phone: ''
            }],*/
        }],
        profile: { name: "Cliente" },
        notifications: []
    }

    const [ , set_dados ] = useState( null ) ;
    const dados = useRef( data_interface ) ;

    const [user_email, set_user_email] = useState('')
    const [user_password, set_user_password] = useState('')
    const [is_loading, set_is_loading] = useState(false)

    const [password_recovery, setPasswordRecovery] = useState('');
    const [link_final, /*set_link_final*/] = useState(Hosts.EMAIL_WEB_SITE_URL);

    const [refresh, setRefresh] = useState(0);

    const [agree, setAgree] = useState(false);
    const [agree2, setAgree2] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(true);

    const { handle_login, func_loader } = useContextProvider()

    const handle_visible_password = () => {
        var x = document.getElementById("id_password");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
    }

    const handle_on_enter_key = (key, id_origem) => {
        if (parseInt(key) === 13) {
            if (id_origem === 'id_input_email') {
                document.getElementById('id_password').focus()
            } 
            else if (id_origem === 'id_password') {
                document.getElementById('id_button_login').focus()
            }
        }
    }

    const handle_change_info = async (e) => {
        const { name, value } = e.target;

        if ((name === 'username' || name === 'email') && value !== '') {
            dados.current.info['username'] = value
            dados.current.info['email'] = value
            dados.current.contacts[0]['email'] = value
                    
            //check_entity_field_api(name, value)
            await checkEmailValidation(value, name)
        }
        else {
            set_dados(dados.current.info[name] = value)
        }

        await checkFieldValidation()

        setRefresh(refresh + 1)
    }

    const handle_change_info_2 = async (e) => {
		const { name, value } = e.target;

        set_dados(dados.current.info[name] = value)
    }

    const handle_change_contacts = async (e) => {
        const { name, value } = e.target;

        set_dados(dados.current.info[name] = value)
        
        await checkFieldValidation()

        setRefresh(refresh + 1)
    }

    const checkEmailValidation = async (data, name) => {

        const pattern_email_validation = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$/;
    
        if(pattern_email_validation.test(data) === false){
            dados.current.info['username'] = ''
            dados.current.info['email'] = ''
            set_dados(dados.current,);
            setRefresh(refresh + 1)
    
            await Swal.fire({
              title: language[lang].email,
              text: language[lang].email_error_message,
              icon: 'error',
              //showCancelButton: true,
              confirmButtonColor: '#08396a',
              //confirmButtonText: 'Tentar novamente',
            })
    
            /*confirmAlert({
              title: language[lang].email,
              message: language[lang].email_error_message,
              buttons: [
                {
                  label: language[lang].back,
                  onClick: () => { return }
                }
    
              ]
            });*/
        }
        else {
          await check_entity_field_api(name, data)
        }
    }

    const handle_change_password = (e) => {
		const { value } = e.target;

		if (dados.current.info.password !== '' && value === dados.current.info.password) {
      
			console.log('A password introduzida e a confirmação são iguais')
      
		}
		else {
            // confirmAlert({
            //     title: language[lang].password,
            //     message: language[lang].confirmation_password_error_message,
            //     buttons: [
            //         {
            //             label: language[lang].back,
            //             onClick: () => { return }
            //         }
            //     ]
            // });
            Swal.fire(language[lang].password, language[lang].confirmation_password_error_message, 'error')
		}

        checkFieldValidation()
	}

    const checkFieldValidation = async () => {
  
      //console.log('checkFieldValidation agree', agree)
  
          let input01 = (dados.current.info.name !== '')
          // let input02 = (dados.current.info.surname !== '')
      // let input03 = (dados.current.info.location !== '')
      let input04 = (dados.current.info.email !== '')
      let input05 = (dados.current.info.password !== '')
      let input06 = (dados.current.info.username !== '')
      let input07 = (dados.current.info.terms_checked === true)
      //let input08 = (dados.current.info.rgpd_checked === true)
  
      if(!input04 && input06) {
        set_dados(dados.current.info.email = dados.current.info.username)
        input04 = input06
  
        await checkEmailValidation(dados.current.info.username, dados.current.info.name)
      }
      else if(input04 && !input06) {
        set_dados(dados.current.info.username = dados.current.info.email)
        input06 = input04
  
        await checkEmailValidation(dados.current.info.username, dados.current.info.name)
      }
  
          if(input01 && input04 && input05 && input06 & input07) {
              setBtnDisabled(false);
          } else{
              setBtnDisabled(true);
          }
  
    }

    const check_entity_field_api = async (name, value) => {
		reqGET(`${Hosts.SIMCore_API}/app/entities/username/${Hosts.companycode}/${value}`)
			.then(res => {

                //console.log(res);

                if (res.data[0].response === false) {

                    return

                } else {
                    dados.current.info['email'] = ''
                    dados.current.info['username'] = ''

                    set_dados(dados.current, )

                    setRefresh(refresh + 1)

                    Swal.fire({
                        title: (name === 'email' ? language[lang].contact_email : language[lang].username_email),
                        text: language[lang].username_error_message,
                        icon: 'error',
                        //showCancelButton: true,
                        confirmButtonColor: '#08396a',
                        //confirmButtonText: 'Tentar novamente',
                    })

				}
			}
			)
	}

    const checkboxHandler = () => {
      // if agree === true, it will be set to false
      // if agree === false, it will be set to true

      setAgree(!agree);
      dados.current.info['terms_checked'] = !agree
      setRefresh(refresh + 1)
  
      checkFieldValidation()

      //console.log(!agree)
      //setRefresh(refresh + 1)
      // Don't miss the exclamation mark
  
      //console.log('Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«')
      //console.log('Dados Current:' + JSON.stringify(dados))
    }
  
    const checkboxHandler_reload = (agree) => {
      //console.log('AGREE', agree)

      if (agree === true){
          return true
      }
      else {
          return false
      }
      // if agree === false, it will be set to true
      //setAgree(!agree);
      // Don't miss the exclamation mark
    }

    const checkboxHandler_2 = () => {
      // if agree === true, it will be set to false
      // if agree === false, it will be set to true
      setAgree2(!agree2);
      //dados.current.info['rgpd'] = !agree2
      dados.current.info['marketing'] = !agree2
      setRefresh(refresh + 1)
  
      checkFieldValidation()
  
      //console.log(!agree)
      //setRefresh(refresh + 1)
      // Don't miss the exclamation mark
  
      //console.log('Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«')
      //console.log('Dados Current:' + JSON.stringify(dados))
    }

    const checkboxHandler_2_reload = (agree2) => {
      //console.log('AGREE', agree)
      if (agree2 === true){
          return true
      }
      else {
          return false
      }
      // if agree === false, it will be set to true
      //setAgree(!agree);
      // Don't miss the exclamation mark
    }

    useEffect(() => {
        console.log('entrouuuu')
    }, [])

    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);

    function toggleModal() {
		setIsOpen(!isOpen);
	}
    function toggleModal2() {
        setIsOpen2(!isOpen2);
    }

    const sendRecovery = async () => {
        await reqGET(Hosts.SIMCore_API + '/entities/password/recovery/' + Hosts.companycode + '/' + password_recovery)
          .then(res => {
            //console.log(res)
    
            if(res.status === 200) {
              let link_aux = link_final + '/regist/client/recovery/' + res.data
    


                const mailHtmlTop = '<html><head><link rel="preconnect" href="https://fonts.googleapis.com"><link rel="preconnect" href="https://fonts.gstatic.com" crossorigin><link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,700;1,400;1,700&display=swap" rel="stylesheet"> </head><body style="background-color:' + Hosts.EMAIL_BODY_BACKGROUND_COLOR +'; font-family:poppins, helvetica, arial, sans-serif; ">' +
                    '<div style="width:100% !important; margin-top:0; margin-bottom:0; margin-right:0; margin-left:0; padding-top:0; padding-bottom:0; padding-right:0; padding-left:0; color:' + Hosts.EMAIL_TEXT_COLOR +';">' +
                        '<table border="0" align="center" cellpadding="0" cellspacing="0" style="margin-top:0; margin-bottom:0; padding-top:0; padding-bottom:0; padding-right:0; padding-left:0; border-collapse:collapse;">' +
                            '<tr style="border-collapse: collapse;">' +
                                '<td style="border-collapse: collapse; font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.EMAIL_TEXT_SIZE +'; line-height:' + Hosts.EMAIL_TEXT_LINE_HEIGHT  +';">' +
                                    '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; background-color:' + Hosts.EMAIL_BACKGROUND_COLOR +'; border:1px solid transparent; border-collapse:collapse;">' +
                                        '<tr style="border-collapse: collapse;">' +
                                            '<td align="left" class="logo" style="padding:0; border-collapse:collapse;">' +
                                                '<a alt="' + Hosts.webSiteTitle + '" title="' + Hosts.webSiteTitle + '" href="' + Hosts.EMAIL_WEB_SITE_URL + '" target="_blank">' +
                                                    '<img style="width: 600px; " src="' + repo_banner_link("banner-email.jpg") + '"/>' +
                                                '</a>'+
                                            '</td>' +
                                        '</tr>' +
                                    '</table>'

                const mailHtmlBottom =  '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; background-color:' + Hosts.FOOTER_EMAIL_COLOR +'; padding: 10px; border: 1px solid transparent; border-collapse:collapse;">' +
                                            '<tr style="border-collapse: collapse;">' +
                                                '<td align="left" style="padding-bottom: 10px; padding-top: 10px; border-collapse:collapse; font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.FOOTER_EMAIL_TEXT_SIZE +'; line-height:' + Hosts.FOOTER_EMAIL_TEX_LINE_HEIGHT  +';">' +
                                                    '<div>'+
                                                        '<div style="text-align: center; font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.FOOTER_EMAIL_TEXT_SIZE +'; line-height:' + Hosts.FOOTER_EMAIL_TEX_LINE_HEIGHT  +'; color:' + Hosts.FOOTER_EMAIL_TEXT_COLOR +';">'+
                                                            '<span style="text-transform: uppercase;">' + Hosts.webSiteTitle + '</span>'+
                                                            '<br/>'+
                                                            '<span>' + Hosts.webSiteAdress + '</span>'+
                                                            '<br/>'+
                                                            '<a alt="' + Hosts.webSiteTitle + '" title="' + Hosts.webSiteTitle + '" href="' + Hosts.EMAIL_WEB_SITE_URL + '" target="_blank" style="font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.FOOTER_EMAIL_TEXT_SIZE +'; line-height:' + Hosts.FOOTER_EMAIL_TEX_LINE_HEIGHT  +';"> '+
                                                                '<span style="color:' + Hosts.FOOTER_EMAIL_TEXT_COLOR +';font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.FOOTER_EMAIL_TEXT_SIZE +'; line-height:' + Hosts.FOOTER_EMAIL_TEX_LINE_HEIGHT  +'; text-decoration: underline" >'+ Hosts.EMAIL_WEB_SITE_URL +'</span>' +
                                                            '</a>'+
                                                        '</div>'+
                                                    '</div>' +
                                                '</td>' +
                                            '</tr>' +
                                        '</table>' +
                                    '</td>' +
                                '</tr>' +
                            '</table>' +
                        '</div>' +
                    '<body>' +
                '<html>'

                let mail_content =  '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; height: 90px; padding: 15px 30px; background-color:' + Hosts.EMAIL_BACKGROUND_COLOR +';border:1px solid transparent;border-collapse:collapse;">' +
                            '<tr style="border-collapse: collapse;">' +
                            '<td align="left" class style="padding:40px; font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.EMAIL_TEXT_SIZE +'; line-height:' + Hosts.EMAIL_TEXT_LINE_HEIGHT  +'; color:' + Hosts.EMAIL_TEXT_COLOR +'">' +
                            '<div>'+
                                '<h4 style="text-transform: uppercase;">'+ language[lang].contact_recovery +':</h4>'+
                                '<span><label>' + language[lang].password_recover + '</label></span>'+
                                '<br/>'+
                                '<br/>'+
                                '<span><label>' + language[lang].password_recover_2 + ' <a href="'+ link_aux +'" alt="' + Hosts.webSiteTitle + '" title="' + Hosts.webSiteTitle + '">' + language[lang].recover_password + '</a></label></span>'+
                                '<br/>'+
                                '<br/>'+
                                '<span><label>' + language[lang].password_recover_3 + '</label></span>'+
                                '<br />' +
                                '<br />' +
                            '</div>'+
                        '</td>' +
                    '</tr>' +
                '</table>'

                let mailOptions = {
                    from: Hosts.contactFrom,
                    // vários emails separados por virgulas
                    //to: web.dev@assec.pt',
                    to: password_recovery,
                    //cc-> opcional
                    //cc: Hosts.contactFrom,
                    subject: Hosts.webSiteTitle + ' - ' + language[lang].contact_recovery,
                    //text: 'Teste de EMAIL ', -> opcional
                    html: ''
                    //     '<html><head><link rel="preconnect" href="https://fonts.googleapis.com"><link rel="preconnect" href="https://fonts.gstatic.com" crossorigin><link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,700;1,400;1,700&display=swap" rel="stylesheet"> </head><body style="background-color:' + Hosts.EMAIL_BODY_BACKGROUND_COLOR +'; font-family:poppins, helvetica, arial, sans-serif; ">' +
                    //         '<div style="width:100% !important;margin-top:0;margin-bottom:0;margin-right:0;margin-left:0;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;color:#425968;background-color:#F7F7F7;background-image:none;background-repeat:repeat;background-position:top left;background-attachment:scroll;font-family:Arial,Helvetica,sans-serif;font-size:14px;line-height:16px;">' +
                    //             '<table border="0" align="center" cellpadding="0" cellspacing="0" style="margin-top:0;margin-bottom:0;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;border-collapse:collapse;">' +
                    //                 '<tr style="border-collapse: collapse;">' +
                    //                     '<td style="border-collapse: collapse;">' +
                    //                         '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; height: 90px; background-color:#ffffff;border:1px solid transparent;border-collapse:collapse;">' +
                    //                             '<tr style="border-collapse: collapse;">' +
                    //                                 '<td align="left" class="logo" style="padding:0; border-collapse:collapse;">' +
                    //                                     '<a alt="' + Hosts.webSiteTitle + '" title="' + Hosts.webSiteTitle + '" href="' + Hosts.EMAIL_WEB_SITE_URL + '" target="_blank">' +
                    //                                         '<img style="width: 600px; height: 90px;" width="600" height="90" src="' + repo_banner_link("banner-email.jpg") + '"/>' +
                    //                                     '</a>'+
                    //                                 '</td>' +
                    //                             '</tr>' +
                    //                         '</table>' +
                    //                         '<table width="540" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 540px; height: 90px; padding: 15px 30px; background-color:#ffffff;border:1px solid transparent;border-collapse:collapse;">' +
                    //                             '<tr style="border-collapse: collapse;">' +
                    //                                 '<td align="left" class style="padding:0 0 10px; border-collapse:collapse;">' +
                    //                                     '<div style="width:540px; margin: 0 auto; padding: 15px 30px;">'+
                    //                                         '<h4 style="text-transform: uppercase;">'+ language[lang].contact_recovery +':</h4>'+
                    //                                         '<span><label>' + language[lang].password_recover + '</label></span>'+
                    //                                         '<br/>'+
                    //                                         '<br/>'+
                    //                                         '<span><label>' + language[lang].password_recover_2 + ' <a href="'+ link_aux +'" alt="' + Hosts.webSiteTitle + '" title="' + Hosts.webSiteTitle + '">' + language[lang].recover_password + '</a></label></span>'+
                    //                                         '<br/>'+
                    //                                         '<br/>'+
                    //                                         '<span><label>' + language[lang].password_recover_3 + '</label></span>'+
                    //                                         '<br />' +
                    //                                         '<br />' +
                    //                                     '</div>'+
                    //                                 '</td>' +
                    //                             '</tr>' +
                    //                         '</table>' +
                    //                         '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; height: 90px; background-color:' + Hosts.FOOTER_EMAIL_COLOR +'; padding: 10px; border:1px solid transparent;border-collapse:collapse;">' +
                    //                             '<tr style="border-collapse: collapse;">' +
                    //                                 '<td align="left" style="padding:10px 0px; border-collapse:collapse;">' +
                    //                                     '<div style="margin: 0 auto; width:600px;">'+
                    //                                         '<div style="text-align: center; font-size: small; color: ' + Hosts.FOOTER_EMAIL_TEXT_COLOR + ';">'+
                    //                                             '<span style="text-transform: uppercase;">' + Hosts.webSiteTitle + '</span>'+
                    //                                             '<br/>'+
                    //                                             '<span>' + Hosts.webSiteAdress + '</span>'+
                    //                                             '<br/>'+
                    //                                             '<a alt="' + Hosts.webSiteTitle + '" title="' + Hosts.webSiteTitle + '" href="' + Hosts.EMAIL_WEB_SITE_URL + '" target="_blank" style="text-decoration:none;"> '+
                    //                                                 '<span style="color: ' + Hosts.FOOTER_EMAIL_TEXT_COLOR + ';" >'+ Hosts.EMAIL_WEB_SITE_URL +'</span>' +
                    //                                             '</a>'+
                    //                                         '</div>'+
                    //                                     '</div>' +
                    //                                 '</td>' +
                    //                             '</tr>' +
                    //                         '</table>' +
                    //                     '</td>' +
                    //                 '</tr>' +
                    //             '</table>' +
                    //         '</div>' +
                    //     '<body>' +
                    // '<html>',
                }; 
    
                mailOptions.html = mailHtmlTop + mail_content + mailHtmlBottom
    
                reqPOST(`${Hosts.SIMCore_API_MAIL}`, {data: mailOptions})
                    .then(() => { 
        
                        // toggleModal()
        


                        setIsOpen(false);
                        setIsOpen2(false);

                        setRefresh(refresh + 1)

                        // confirmAlert({
                        //     title: language[lang].submitted_application,
                        //     message: language[lang].email_recover_pass,
                        //     buttons: [
                        //     {
                        //         label: language[lang].back,
                        //         onClick: () => { return }
                        //     }
                
                        //     ]
                        // });

                        Swal.fire(language[lang].submitted_application, language[lang].email_recover_pass, 'info')
        
                    })
                    .catch((error) => console.log(error)/*alert('Problema ao enviar notificações!')*/)
            }
            else {
                // confirmAlert({
                //     title: language[lang].incorrect_request,
                //     message: language[lang].email_recover_pass_2,
                //     buttons: [
                //     {
                //         label: language[lang].back,
                //         onClick: () => { return }
                //     }
        
                //     ]
                // });

                Swal.fire(language[lang].incorrect_request, language[lang].email_recover_pass_2, 'error')
            }
          })
          .catch(() => 404)
    }

    const saveUser = async () => {

        Swal.fire({
            title: language[lang].insert_title,
            text: language[lang].insert_text,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: 'var(--primary)',
            cancelButtonColor: '#d9214e',
            confirmButtonText: language[lang].label_confirmar,
            cancelButtonText: language[lang].label_cancelar
        })
        .then(async (result) => {
            if(result.isConfirmed) {

                let password = dados.current.info.password;
                dados.current.info.password = passwordHash(password);
                dados.current.info['from'] = 'web';
  
                await reqPOST(`${Hosts.SIMCore_API}/app/entities`, dados.current)
                    .then(() => { 

                        set_dados(dados.current = JSON.parse(JSON.stringify(data_interface)))
                        document.getElementById('terms').checked = false 
                        // toggleModal2()


                        setIsOpen(false);
                        setIsOpen2(false);

                        setRefresh(refresh + 1)

                        Swal.fire(language[lang].user_regist, language[lang].regist_form_sucess_message, 'info')

                    })
                    .catch((error) => console.log(error))

            }
        })

        /* 
        confirmAlert({
            title: language[lang].insert_title,
            message: language[lang].insert_text,
            buttons: [
                {
                    label: language[lang].yes,
                    onClick: async () => {
        
                        let password = dados.current.info.password;
                        dados.current.info.password = passwordHash(password);
                        dados.current.info['from'] = 'web';
        
                        await reqPOST(`${Hosts.SIMCore_API}/app/entities`, dados.current)
                            .then(() => {   
                                
                                //alert('Registo efetuado com sucesso!\n\nConsulte os seus dados de acesso no email enviado.')
                                set_dados(dados.current = JSON.parse(JSON.stringify(data_interface)))
                                document.getElementById('terms').checked = false 
                                toggleModal2()
                                //props.history.push(endpoints.list) 
        
                                // Swal.fire({
                                //     title: language[lang].insert_title,
                                //     text: language[lang].insert_success,
                                //     icon: 'success',
                                    // showCancelButton: true,
                                    // confirmButtonColor: '#08396a',
                                    // confirmButtonText: 'Tentar novamente',
                                })
        
                                // confirmAlert({
                                //   title: language[lang].user_regist,
                                //   message: language[lang].regist_form_sucess_message,
                                //   buttons: [
                                //     {
                                //       label: language[lang].back,
                                //       onClick: () => { return }
                                //     }
                        
                                //   ]
                                // });
                                Swal.fire(language[lang].user_regist, language[lang].regist_form_sucess_message, 'info')

                            })
                            .catch((error) => console.log(error))
                    }
    
                },
                {
                    label: language[lang].no,
                    onClick: () => { return }
                }
            ]
        })
       */

    }

    return (
        <>
            <Modal
                isOpen={true}
                // onRequestClose={}
                contentLabel={language[lang].register_here}
                className="mymodal div-right"
                overlayClassName="myoverlay"
                closeTimeoutMS={500}
            >
                {
                is_loading === true ?
                <div className="div-modal">
                    <div className="modal-body modal-modal pt-0">
                        <div className="col-12">
                            { func_loader() }
                        </div>
                    </div>
                </div>
                :
                    <>
                        <div className="text-right">
                            <button className="btn p-1" alt={language[lang].close} title={language[lang].close} onClick={() => { set_open_modal_login(false); console.log('carregou') }}>
                                <i className="bi bi-x-lg"></i>
                            </button>
                        </div>

                        <div className="div-modal">
                            <div className="modal-body modal-modal pt-0">
                                <div className="text-center mb-3">
                                    <img alt='' src={repo_logo_link("logo.svg")} style={{ width: '180px' }} />
                                </div>
                                <div>
                                    <label>
                                        <p className="mb-1">{language[lang].username}</p>
                                        <input id="id_input_email" type="text" name='username' value={user_email} className="form-input w-100 form-control-lg rounded-0 border-0 bg-color-gray-light" onKeyDown={(e) => { handle_on_enter_key(e.keyCode, e.target.id) }} onChange={(e) => set_user_email(e.target.value)} />
                                    </label>
                                </div>
                                <div className="form-group position-relative">
                                    <label>
                                        <p className="mb-1">{language[lang].password}</p>
                                        <input id="id_password" type="password" name='username' value={user_password} className="form-input w-100 form-control-lg rounded-0 border-0 bg-color-gray-light" onKeyDown={(e) => { handle_on_enter_key(e.keyCode, e.target.id) }} onChange={(e) => set_user_password(e.target.value)} />
                                        <i className="bi bi-eye-slash-fill" style={{position: 'absolute', fontSize: '30px', right: '15px', bottom: '8px', width: '30px'}} onClick={() => { handle_visible_password() }}></i>
                                    </label>
                                </div>

                                <div className="text-center">
                                    <button id="id_button_login" className="btn btn-secondary px-5" onClick={() => handle_login(user_email, user_password, set_is_loading, set_open_modal_login)}>
                                        Login
                                    </button>
                                </div>
                                <div className="d-flex flex-column justify-content-center mt-4">
                                    <button className="btn btn-link btn-sm pb-0" type="button" onClick={() => toggleModal2()} >{language[lang].register_here}!</button>
                                    <button className="btn btn-link btn-sm pt-0" variant="primary" type="button" onClick={() => toggleModal()} >{language[lang].recover_password}!</button>
                                </div>
                            </div>
                        </div>
                    </>
                }   
            </Modal>

            <Modal
                isOpen={isOpen}
                onRequestClose={toggleModal}
                contentLabel={language[lang].recover_password}
                className="mymodal div-right"
                overlayClassName="myoverlay"
                closeTimeoutMS={500}
            >
                <div className="text-right">
                    <button className="btn p-1" alt={language[lang].close} title={language[lang].close} onClick={() => { toggleModal() }}>
                        <i className="bi bi-x-lg"></i>
                    </button>
                </div>
                <div className="div-modal">
                    <div className="modal-body modal-modal">
                        <label>
                            <p className="mb-1">{language[lang].recover_password}</p>
                            <input type="email" className="form-input w-100 form-control-lg rounded-0 border-0 bg-color-gray-light" id='email-modal-recuperacao' placeholder={language[lang].introduza_email} name='email' onChange={(e) => setPasswordRecovery(e.target.value)} />
                        </label>
                    </div>

                    <div className="text-center">
                        <button type="button" className="btn btn-secondary px-5" onClick={() => sendRecovery()}>{language[lang].send}</button>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={isOpen2}
                onRequestClose={toggleModal2}
                contentLabel={language[lang].register_here}
                className="mymodal div-right"
                overlayClassName="myoverlay"
                closeTimeoutMS={500}
            >

                <div className="text-right">
                    <button className="btn p-1" alt={language[lang].close} title={language[lang].close} onClick={() => { toggleModal2() }}>
                        <i className="bi bi-x-lg"></i>
                    </button>
                </div>
                <div className="div-modal">
                    <div className="modal-body modal-modal">
                        <h4 className='dash-title'>{language[lang].register_here}!</h4>
                        <div className="form-group">
                            <div className="row">

                                <div className='form-group col-12 col-md-6 d-none'>
                                    <label>{language[lang].contact_email}*</label>
                                    <input type="email" className="form-input w-100 form-control-lg rounded-0 border-0 bg-color-gray-light" placeholder={language[lang].contact_email_placeholder} name='email' value={dados.current.info.email} onBlur={handle_change_info} onChange={handle_change_info_2} required />
                                </div>

                                <div className='form-group col-12 col-md-6'>
                                    <label>{language[lang].name}*</label>
                                    <input type="text" className="form-input w-100 form-control-lg rounded-0 border-0 bg-color-gray-light" placeholder={language[lang].name_placeholder} name='name' value={dados.current.info.name} onChange={handle_change_info} required />
                                </div>

                                <div className='form-group col-12 col-md-6'>
                                    <label>{language[lang].mobile_phone}</label>
                                    <input type="text" className="form-input w-100 form-control-lg rounded-0 border-0 bg-color-gray-light" placeholder={language[lang].mobile_phone_placeholder} name='mobile_phone' value={dados.current.contacts !== undefined && dados.current.contacts.length > 0 ? dados.current.contacts[0].mobile_phone : ''} onChange={handle_change_contacts} />
                                </div>

                                <div className='form-group col-12 col-md-6 d-none'>
                                    <label>{language[lang].surname}*</label>
                                    <input type="text" className="form-input w-100 form-control-lg rounded-0 border-0 bg-color-gray-light" placeholder={language[lang].surname_insert} name='surname' value={dados.current.info.surname} onChange={handle_change_info} />
                                </div>

                                <div className='form-group col-12 col-md-6 d-none'>
                                    <label>{language[lang].location}</label>
                                    <input type="text" name="location" className="form-input w-100 form-control-lg rounded-0 border-0 bg-color-gray-light" placeholder={language[lang].location_placeholder} value={dados.current.info.location} onChange={handle_change_info} />
                                </div>

                                <div className='form-group col-12 mt-3'>
                                    <h4 className='dash-title'>{language[lang].access_data}</h4>
                                </div>

                                {/* <div className='form-group col-12 col-md-6'>
                                <label>{language[lang].user}</label>
                                <input type="text" name="username" className="form-input w-100 form-control-lg rounded-0 border-0 bg-color-gray-light" placeholder={language[lang].username_insert}/>
                                </div> */}
                    
                                <div className='form-group col-12 col-md-6'>
                                    <label>{language[lang].username_email}*</label>
                                    <input type="email" className="form-input w-100 form-control-lg rounded-0 border-0 bg-color-gray-light" placeholder={language[lang].email_placeholder} name='username' value={dados.current.info.username} onBlur={handle_change_info} onChange={handle_change_info_2} required/>
                                </div>

                                <div className="col-12"></div>
                            
                                <div className='form-group col-12 col-md-6'>
                                    <label>{language[lang].new_password}*</label>
                                    <input type="password" name="password" value={dados.current.info.password} className="form-input w-100 form-control-lg rounded-0 border-0 bg-color-gray-light" placeholder={language[lang].new_password_placeholder} onChange={handle_change_info} />
                                </div>

                                <div className='form-group col-12 col-md-6'>
                                    <label>{language[lang].new_password_2}*</label>
                                    <input type="password" name="password_conf" /*value={password_nova_conf}*/ className="form-input w-100 form-control-lg rounded-0 border-0 bg-color-gray-light" placeholder={language[lang].new_password_2_placeholder} onBlur={handle_change_password} /*onChange={handle_change_password_conf}*//>
                                </div>

                                <div className="col-12 mt-3">
                                    <input 
                                        type="checkbox"
                                        name="rgpd_check"
                                        id="rgpd_r"
                                        onChange={() => checkboxHandler_2(agree2)}
                                        defaultChecked={checkboxHandler_2_reload(agree2)}
                                    className="me-2"
                                    />
                                    <label htmlFor="rgpd_r">{language[lang].agree_rgpd}</label>
                                </div>

                                <div className="col-12 mt-3">
                                    <input 
                                        type="checkbox"
                                        name="terms_check"
                                        id="terms_r"
                                        onChange={() => checkboxHandler(agree)}
                                        defaultChecked={checkboxHandler_reload(agree)}
                                        className="me-2"
                                        required
                                    />
                                    <label htmlFor="terms_r">{language[lang].agree_terms_conditions}
                                        <a href={Hosts.EMAIL_WEB_SITE_URL + "/terms-conditions"} target="_blank" rel="noreferrer">{language[lang].terms_conditions_link}</a>
                                    </label>
                                    <label className="ps-2 small">{language[lang].terms_conditions_required}</label>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="text-center">
                            <button type="button" onClick={() => saveUser()} className="btn btn-primary px-5" disabled={btnDisabled} /*disabled={!agree}*/ >{language[lang].send}</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )


};
export default CostumModalLogin;
