import React, { useEffect, useState } from 'react';

import "../Pagina.css";
import "./VisitaVirtual.css";
import "../content-styles.css"

import Loader from '../../../loader'
import Navbar from "../Navbar/Navbar";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Footer from "../Footer/Footer";

const VisitaVirtual = () => {

    const [isloading, set_isloading] = useState(true);

    useEffect(() => {

        set_isloading(false)

    }, []);


    return isloading ?

        <Loader />

    :
        <>
            <Navbar />
            <Breadcrumb />
            <div id="vitual">
                <div className='showcase-wrapper'>
                    <iframe width="100%" src="https://my.matterport.com/show/?play=1&amp;lang=en-US&amp;m=ayduxei84va" frameborder="0" allowfullscreen="" allow="vr" title="Explore GOLDEN ROCK - ALAMBIQUE WATER SPA in 3D" referrerpolicy="unsafe-url" className="showcase-visita-virtual"></iframe>
                </div>
            </div>
            <Footer />
        </>

};
export default VisitaVirtual;

