
//import { SHA256 } from 'crypto-js';
import moment from 'moment';
import { network } from './UseService';
import { Prod_verify } from './verify_valid_prod';
import { nome_empresa } from './HOSTS';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'

let obj_encomenda = {
    id: '',
    info: {
        qr: [],
        origin: 'web',
        estado_pagamento: '0', // 0-amarelo(pagamento pendente) , 1- verde (pagamento realizado), -1 vermelho (encomenda cancelada), 2 azul (produto ja utilizado)
        pagamento_pendente: '0',
        companycode: '',
        tipo_documento: 'nota_de_encomenda',
        metodo_de_pagamento: '', // -1-pagamento por mbwab, 0-pagamento por cartao de credito, 1-pagamento por referencia
        numero: '',
        serie: '',
        data: '',                                                           // 'AAAA-MM-DD',
        timestamp: '',                                                      // 'with all info',
        documento: {
            rmb: {
                rmb_ref: '',
                rmb_ent: '',
                rmb_pag: '',
            },
            user_info: {
                id_user: '',
                username: '',                                               // para fazer match
                name: '',
                vat: '999999990',                                           // Caso não exista é o 999999990 que é o consumidor final genérico 
                addresses: [{
                    village: '',
                    city: '',
                    type: [],
                    region: '',
                    country: '',
                    apartado: '',
                    localidade: '',
                    contats: [],
                    principal: 'true',
                    observation: '',
                    postal_code: '',
                    street_name: '',
                    address_detail: '',
                }],                                                         // user_data_inter
                contacts: [{
                    email: '',
                    phone: '',
                    principal: 'true',
                    extensions: [],
                    observation: '',
                    mobile_phone: '',
                    building_number: '',
                    person_responsables: [{
                        name: '',
                        email: '',
                        extensions: '',
                        observation: '',
                        mobile_phone: ''
                    }],
                }],                                                         // user_data_inter
                obs: '',
            },
            linhas: [],
            total_documento: 0,
            total_iva_documento: 0,
            taxa_desconto_global: 0,
            total_iliquido_documento: 0,
            total_desconto_linha_documento: 0,
            valor_desconto_global_a_aplicar: 0,
            total_iliquido_documento_com_deconto_linha_e_global: 0
        }
    }

}
export const lista_de_categorias = async () => {
    let lista = []
    try {
        lista = await network().get_categories()
        /* [
            { category: 'weellness', name: { 'pt': 'Bem-estar', 'en': 'Weellness' }, img: valores_costums.imagens.icon_menu_loja_opt1 },
            { category: 'spa', name: { 'pt': 'Spa', 'en': 'Spa' }, img: valores_costums.imagens.icon_menu_loja_opt2 },
            { category: 'piscinas', name: { 'pt': 'Piscinas', 'en': 'Pools' }, img: valores_costums.imagens.icon_menu_loja_opt3 },
            { category: 'ginasio', name: { 'pt': 'Ginásio', 'en': 'Gymnasium' }, img: valores_costums.imagens.icon_menu_loja_opt4 },
            { category: 'estetica', name: { 'pt': 'Estética', 'en': 'Aesthetics' }, img: valores_costums.imagens.icon_menu_loja_opt5 },
        ] */
    } catch (error) {
        lista = []
        console.log('lista_de_categoria, erros: ', error)
    }
    return lista

}

export const lista_de_produtos = async (lista_keys_para_procurar) => {
    let lista_produtos
    let lista_to_return = []
    // lista_keys_para_procurar contem a lista de categorias dos produtos  return
    try {
        if (lista_keys_para_procurar.length !== 0) {
            lista_produtos = await network().get_produtos_by_codgrupo(lista_keys_para_procurar[0].codgrupo)
        }
        lista_to_return = await Prod_verify(lista_produtos.data) // verifica se os valores que sao usados existem

    } catch (error) {
        console.log('lista_produtos eroo', error)
    }
    return lista_to_return
}
export const ordem_de_compra = async (lista_de_produtos_do_carrinho, id_user, id_usado_para_gerar_pagamento, data) => {
    obj_encomenda.info.qr = []
    let lista_linhas = []
    //let count_aux = 0
    /* await Promise.all( */lista_de_produtos_do_carrinho.forEach( (element) => {
        let obj = element.info.produto.linha

        let qtd = element.quantidade
       /*  let obj_default_linhas_da_encomenda = {

            pretence_ao_produto: '',
            id_produto: '',
            observation: '',
            observation_trad: [],
            id_produto_na_lista_linhas: '',
            designacao: '',
            subname: '', // se for aula a informaçao do numero de pessoas vai aqui
            subname_trad: [],
            unidade: '',
            quantidade: '',
            preco_unitario: '',
            taxa_iva: '',
            taxa_desconto_linha: '0.00',
            total_iliquido: '0.00',
            total_iliquido_apresentado: '0.00',
            total_desconto: '0.00',
            total_iliquido_com_desconto: '0.00',
            total_iva: '0.00',
            total_com_iva: '0.00',
            total_liquido: '0.00',
            total_com_iva_apresentado: '0.00',
            total_iva_apresentado: '0.00',
            calendario: [],
            details: {},

        }
        let obj_calendario = {
            validade_data_hora_fim: '',
            validade_data_hora_ini: '',
            dia: '',
            id_event: '',
            id_stock: '',

        }
        let obj_details = {
            goldenrock: {
                is_spa: false,
                is_gymn: false,
                is_pack: false,
                is_poll: false,
                hashcode: "",
                is_random: "",
                signature: "",
                is_estetic: false,
                id_entidade: "",
                is_wellness: true,
                id_encomenda: "",
                numero_child: 0,
                numero_staff: "0",
                validade_dias: "0",
                numero_adultos: 0,
                numero_pessoas: "0",
                valiade_epocas: 0,
                tem_quipamentos: false,
                data_hora_compra: "",
                validade_duracao: "",
                validade_data_hora_fim: "",
                validade_data_hora_ini: "",
                id_usado_para_gerar_pagamento: ""
            }
        } */
        obj.details[nome_empresa].data_hora_compra = data
        obj.details[nome_empresa].id_entidade = id_user
        obj.details[nome_empresa].id_usado_para_gerar_pagamento = id_usado_para_gerar_pagamento
        obj.details[nome_empresa].is_random = element.info.produto.cod_grupo
        let preco_unitario = obj.preco_unitario
        obj.quantidade = qtd
        obj.preco_unitario = preco_unitario
        obj.total_iliquido = parseFloat(preco_unitario) * parseFloat(qtd)
        obj.total_iliquido_apresentado = (parseFloat(preco_unitario) * parseFloat(qtd)).toFixed(2)
        obj.total_desconto = (parseFloat(preco_unitario) * parseFloat(qtd)) * parseFloat(0.00) * 0.01
        obj.total_iliquido_com_desconto = parseFloat(preco_unitario * qtd) - parseFloat(parseFloat(preco_unitario * qtd) * parseFloat(0.00) * 0.01)
        obj.total_iva = calcule_iva(preco_unitario, parseFloat(obj.taxa_iva), qtd)
        obj.total_com_iva = (preco_unitario * (1 + (parseFloat(obj.taxa_iva) / 100)) * qtd)
        obj.total_liquido = (parseFloat(preco_unitario) * parseFloat(qtd)) * (1 + (parseInt(obj.taxa_iva) * 0.01))
        obj.total_iva_apresentado = calcule_iva(preco_unitario, parseFloat(obj.taxa_iva), qtd).toFixed(2)
        obj.taxa_desconto_linha = '0.00'

      //  count_aux++

        lista_linhas.push(obj)
        /* let obj_tabela_qr = {
            id: '',
            info: {
                classes: [],
                entries: []  
            }
        } */
        // await Promise.all(element.info.produto.composicao.map(async (obj) => {

        //     obj_details.goldenrock.data_hora_compra = data
        //     // obj_details.goldenrock.id_encomenda = '000'
        //     obj_details.goldenrock.id_entidade = id_user
        //     obj_details.goldenrock.id_usado_para_gerar_pagamento = id_usado_para_gerar_pagamento
        //     obj_details.goldenrock.is_random = obj.grupo.code
        //     obj_details.goldenrock.numero_adultos = element.info.produto.details.goldenrock.numero_adultos
        //     obj_details.goldenrock.numero_child = element.info.produto.details.goldenrock.numero_child
        //     obj_details.goldenrock.numero_pessoas = element.info.produto.details.goldenrock.numero_pessoas
        //     //obj_details.goldenrock.numero_staff = element.produto.details.goldenrock.numero_staff
        //     obj_details[nome_empresa].validade_dias = element.info.produto.details[nome_empresa].validade_dias
        //     obj_details[nome_empresa].validade_duracao = element.info.produto.details[nome_empresa].validade_duracao

        //     obj_details.goldenrock.validade_data_hora_fim = element.info.produto.details.goldenrock.dia + 'T' + element.info.produto.details.goldenrock.validade_data_hora_fim
        //     obj_details.goldenrock.validade_data_hora_ini = element.info.produto.details.goldenrock.dia + 'T' + element.info.produto.details.goldenrock.validade_data_hora_ini
        //     /* let obj_codes = {}

        //     let numero_de_qr = parseInt(obj_details.goldenrock.numero_pessoas) * parseInt(element.quantidade)
        //     console.log('numero_de_qr', numero_de_qr)
        //     console.log('ob2222j', JSON.stringify(obj, null, '\t'))

        //     console.log('obj.valores.iva.taxa', obj.valores.iva)
        //     console.log('numero_de_qr', numero_de_qr) */


        //     let preco_unitario = obj.valores.length === 0 ? 0 : obj.valores.preco_unitario

        //     obj_default_linhas_da_encomenda.pretence_ao_produto = element.info.produto.id
        //     obj_default_linhas_da_encomenda.designacao = element.info.produto.nome
        //     obj_default_linhas_da_encomenda.pretence_ao_produto = element.info.produto.id
        //     obj_default_linhas_da_encomenda.id_produto = element.info.produto.id
        //     obj_default_linhas_da_encomenda.observation = obj.descricao.observacao//obj.descricao.observacao.length === 0 ? '' : translate(obj.descricao.observacao, 'pt')
        //     obj_default_linhas_da_encomenda.observation_trad = obj.descricao.observacao
        //     obj_default_linhas_da_encomenda.id_produto_na_lista_linhas = count_aux
        //     obj_default_linhas_da_encomenda.subname = obj.descricao.nome//translate(obj.descricao.nome, 'pt')
        //     obj_default_linhas_da_encomenda.subname_trad = obj.descricao.nome
        //     obj_default_linhas_da_encomenda.unidade = obj.unidade.code
        //     obj_default_linhas_da_encomenda.quantidade = qtd
        //     obj_default_linhas_da_encomenda.preco_unitario = preco_unitario
        //     obj_default_linhas_da_encomenda.taxa_iva = obj.valores.length === 0 ? 0 : obj.valores.iva.taxa
        //     obj_default_linhas_da_encomenda.total_iliquido = parseFloat(preco_unitario) * parseFloat(qtd)
        //     obj_default_linhas_da_encomenda.total_iliquido_apresentado = (parseFloat(preco_unitario) * parseFloat(qtd)).toFixed(2)
        //     obj_default_linhas_da_encomenda.total_desconto = (parseFloat(preco_unitario) * parseFloat(qtd)) * parseFloat(0.00) * 0.01
        //     obj_default_linhas_da_encomenda.total_iliquido_com_desconto = parseFloat(preco_unitario * qtd) - parseFloat(parseFloat(preco_unitario * qtd) * parseFloat(0.00) * 0.01)
        //     obj_default_linhas_da_encomenda.total_iva = calcule_iva(preco_unitario, parseFloat(obj.valores.length === 0 ? 0 : obj.valores.iva.code), qtd)
        //     obj_default_linhas_da_encomenda.total_com_iva = (preco_unitario * (1 + (parseFloat(obj.valores.length === 0 ? 0 : obj.valores.iva.code) / 100)) * qtd)
        //     obj_default_linhas_da_encomenda.total_liquido = (parseFloat(preco_unitario) * parseFloat(qtd)) * (1 + (parseInt(obj.valores.iva.code) * 0.01))
        //     obj_default_linhas_da_encomenda.total_iva_apresentado = calcule_iva(preco_unitario, parseFloat(obj.valores.length === 0 ? 0 : obj.valores.iva.code), qtd).toFixed(2)
        //     obj_default_linhas_da_encomenda.taxa_desconto_linha = '0.00'


        //     if (obj.principal === true) {
        //         obj_calendario.dia = element.info.produto.details.goldenrock.dia
        //         obj_calendario.id_event = element.info.produto.details.goldenrock.id_event
        //         obj_calendario.id_stock = element.info.produto.details.goldenrock.id_stock
        //         obj_calendario.validade_data_hora_fim = element.info.produto.details.goldenrock.validade_data_hora_fim
        //         obj_calendario.validade_data_hora_ini = element.info.produto.details.goldenrock.validade_data_hora_ini

        //         obj_default_linhas_da_encomenda.calendario.push(obj_calendario)

        //     }
        //     obj_default_linhas_da_encomenda.details = obj_details

        //     count_aux++

        //     lista_linhas.push(obj_default_linhas_da_encomenda)

        //     obj_default_linhas_da_encomenda = {}
        //     obj_calendario = {}
        //     obj_details = {}

        // }))


    })
 


    let total_iliquido_documento_aux = lista_linhas.reduce(
        (acumulador_value, current_value) => {
            return parseFloat(parseFloat(acumulador_value) + parseFloat(current_value.total_iliquido_com_desconto))
        }, 0
    )
    let total_desconto_linha_documento_aux = lista_linhas.reduce(
        (acumulador_value, current_value) => {
            return parseFloat(parseFloat(acumulador_value) + parseFloat(current_value.total_desconto))
        }, 0
    )
    let taxa_desconto_global_aux = lista_linhas.reduce(
        (acumulador_value, current_value) => {
            return parseFloat(parseFloat(acumulador_value) + parseFloat(current_value.taxa_desconto_linha))
        }, 0
    )
    let valor_desconto_global_a_aplicar_aux = 0.00
    let total_iliquido_documento_com_deconto_linha_e_global_aux = parseFloat(total_iliquido_documento_aux) - (parseFloat(total_desconto_linha_documento_aux) + parseFloat(valor_desconto_global_a_aplicar_aux))
    let total_iva_documento_aux = lista_linhas.reduce(
        (acumulador_value, current_value) => {
            return parseFloat(parseFloat(acumulador_value) + parseFloat(current_value.total_iva))
        }, 0
    )
    let total_documento_aux = parseFloat(total_iliquido_documento_com_deconto_linha_e_global_aux) + parseFloat(total_iva_documento_aux)

    obj_encomenda.info.documento.linhas = lista_linhas
    obj_encomenda.info.documento.total_iliquido_documento = total_iliquido_documento_aux
    obj_encomenda.info.documento.total_desconto_linha_documento = total_desconto_linha_documento_aux
    obj_encomenda.info.documento.taxa_desconto_global = taxa_desconto_global_aux
    obj_encomenda.info.documento.valor_desconto_global_a_aplicar = valor_desconto_global_a_aplicar_aux
    obj_encomenda.info.documento.total_iliquido_documento_com_deconto_linha_e_global = total_iliquido_documento_com_deconto_linha_e_global_aux
    obj_encomenda.info.documento.total_iva_documento = total_iva_documento_aux
    obj_encomenda.info.documento.total_documento = total_documento_aux
    return obj_encomenda

}
const calcule_iva = (preco, taxe, qtd) => {

    let valor_sem_iva = 0
    let iva_real = 0
    valor_sem_iva = preco * qtd
    iva_real = valor_sem_iva * (taxe / 100)
    let valor_com_iva = valor_sem_iva * (1 + (taxe / 100))
    iva_real = valor_com_iva - (valor_com_iva / (1 + (taxe / 100)))
    return iva_real


}

/* const verify_true_or_false = (variavel) => {
    return variavel === true ? 1 : 0

} */
export const translate_generic_store = (key_list, lang) => {

    let res = ''
    if (key_list.length === 0) { // caso a lista nao tenha qualquer tipo de informacao 
        return ''
    } else {
        try {
            res = key_list.filter(e => e[lang])[0][lang]
            // console.log('Translate:', key_list, ':', lang, '=', res)
            return res.length === 0 ? key_list.filter(e => e['pt'])[0]['pt'] : res
        } catch (error) {
            //console.log('translate erro ', error)
            // anteriormente devolvia o que esta em comentario, porem foi trocado pois ha objetos que nao tem lang pt 
            try {
                return res.length === 0 ? key_list.filter(e => e['pt'])[0]['pt'] : res
            } catch (error) {
                return ''// res.length === 0 ? key_list.filter(e => e['pt'])[0]['pt']  :  res

            }

        }
    }


}

export const aviso_lista_vazia_layout = (linguagem) => {
    return (
        <div className="d-flex justify-content-center overlay-store-img mb-5">
            <h5 className='text-white px-1 py-5'>
                {linguagem === 'en' ? 'No information' : 'Sem informação'}
            </h5>
        </div>
    )
}

export const get_format_correct = (prod, lang) => {
    //TUDO O QUE É STRINGS TEM LINGUAS
    let prod_principal = prod.composicao.find((el) => el.principal === true)
    let estrutura_obj = {
        id: prod.id,
        nome: translate_generic_store(prod_principal.descricao.nome, lang),
        observacao: translate_generic_store(prod_principal.descricao.observacao, lang),
        preco: prod.total_liquido,
        duracao_min: prod.details[nome_empresa].validade_duracao,
        texto_de_apresentacao: translate_generic_store(prod_principal.descricao.descricao, lang),
        img: prod_principal.multimedia.link_images
    }
    return estrutura_obj
}
export const get_data_func = () => {
    let data = moment().tz("Europe/Lisbon").format('YYYY-MM-DDTHH:mm:ss.SSSZ')
    return data.substring(0, data.length - 6) + 'Z'
}

export const verifica_dia_atual_menor_que_dia = (data_atual, data_compara) => {
    let array_data_atual = data_atual.split('T');
    let dia_data_atual = array_data_atual[0]

    let dia_data_atual_array = dia_data_atual.split('-')
    let dia_data_compara_array = data_compara.split('-')

    let ano_atual = parseInt(dia_data_atual_array[0])
    let ano_compara = parseInt(dia_data_compara_array[0])
    let mes_atual = parseInt(dia_data_atual_array[1])
    let mes_compara = parseInt(dia_data_compara_array[1])
    let dia_atual = parseInt(dia_data_atual_array[2])
    let dia_compara = parseInt(dia_data_compara_array[2])
    if (ano_atual < ano_compara) {


        return true
    }
    else {
        if (mes_atual < mes_compara) {
            return true
        } else {
            if (dia_atual < dia_compara) {

                return true
            } else {
                return false
            }
        }
    }

}
export const verifica_hora_atual_maior_que_hora = (data_atual, hora_compara) => {
   /*  console.error('verifica_hora_atual_maior_que_hora1',data_atual)
    console.error('verifica_hora_atual_maior_que_hora2',hora_compara)
   */  let array_data_atual = data_atual.split('T');
    let hora_atual = array_data_atual[1].substring(0, array_data_atual[1].length - 5)
    let array_hora_atual = hora_atual.split(':')
    let array_hora_compara = hora_compara.split(':')

    let hh_atual = parseInt(array_hora_atual[0])
    let hh_compara = parseInt((array_hora_compara[0].toString()).length === 1 ? '0' + array_hora_compara[0] : array_hora_compara[0])
    let mm_atual = parseInt(array_hora_atual[1])
    let mm_compara = parseInt(array_hora_compara[1])
    let ss_atual = parseInt(array_hora_atual[2])
    let ss_compara = parseInt(array_hora_compara[2])


    if (parseInt(hh_atual) > parseInt(hh_compara)) {

        return true
    } else if (parseInt(hh_atual) === parseInt(hh_compara)) {
        if (parseInt(mm_atual) > parseInt(mm_compara)) {

            return true
        } else if (parseInt(mm_atual) === parseInt(mm_compara)) {
            if (parseInt(ss_atual) >= parseInt(ss_compara)) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }

    } else {
        return false
    }


}
export const translate_horas = (lang, horas) => {
    try {
        let string_tp_return = horas

        if (lang === 'en') {
            string_tp_return = string_tp_return + moment(horas, 'HH:mm').format('a')
        } else if (lang === 'es') {
            string_tp_return = string_tp_return + 'h'

        } else if (lang === 'fr') {
            string_tp_return = string_tp_return + 'h'
        } else {
            string_tp_return = string_tp_return + 'h'
        }

        //  return horas + (lang === 'PT' ? 'h' : moment(horas, 'HH:mm').format('a'))
        return string_tp_return
    } catch (error) {
        console.log('translate_horas error', error)
        return ''
    }

}
export const get_uniq_element_by_fields = (lista, fields_list) => {
    try {
        /* export function procura_a_string_na_lista(lista_keys_para_procurar, lista, string_a_procurar) {

            let aux_lista = lista_keys_para_procurar.flatMap((chave) => {
              return lista.filter((obj) => {
                if ((obj[chave]).toString().toLowerCase().includes(string_a_procurar.toString().toLowerCase())) {
                  return obj
                }
              })
            })
            //console.log('aux_lista', aux_lista)
            let aux_lista_sem_repeticoes = [... new Set(aux_lista)]
            return aux_lista_sem_repeticoes
          } */

        let temp = []
        lista.forEach((e) => {
            fields_list.forEach((field) => {

                if (temp.find((k) => k[field] === e[field]) === undefined) {


                    temp.push(e)
                }
            })

        })
        return temp
    } catch (error) {
        console.log('get_uniq_element_by_fields error', error)
        return []

    }
}
export const adiciona_min_to_data_generica = (data_horas_para_add, n_minutos) => {

    let aux_data_horas_para_add = data_horas_para_add.includes('Z') === false ? data_horas_para_add + ':00.000Z' : data_horas_para_add

    let data_horas_para_add_array = aux_data_horas_para_add.split('T')

    let horas_data_horas_para_add = data_horas_para_add_array[1].substring(0, data_horas_para_add_array[1].length - 5)
    let horas_data_horas_para_add_array = horas_data_horas_para_add.split(':')
    let horas_para_add = horas_data_horas_para_add_array[0]
    let min_para_add = horas_data_horas_para_add_array[1]
    let sec_para_add = horas_data_horas_para_add_array[2]
    let aux_min = parseInt(min_para_add) + n_minutos
    let divisao = aux_min / 60
    // tenho de fazer um while
    let horas_to_return = parseInt(horas_para_add)
    let min_to_return
    while (divisao > 1) {
        // assim sabemos quantas horas temos de adicionar
        // sempre que adicionamos uma hora tiramos 60 min ao aux_min
        horas_to_return = parseInt(horas_to_return) + 1

        min_to_return = parseInt(aux_min) - 60

        aux_min = parseInt(aux_min) - 60

        divisao = aux_min / 60




    }
    if (parseInt(divisao) === 1) {// significa que a soma dos minutos é igual a uma hora
        // entao adicionamos 1 hora mas os minutos ficam a zero

        horas_to_return = parseInt(horas_to_return) + 1

        min_to_return = parseInt(aux_min) - 60

    } else if (parseInt(divisao) < 1) {
        min_to_return = parseInt(aux_min)

    }
    let min_to_return_aux = min_to_return.toString().length === 1 ? '0' + min_to_return.toString() : min_to_return.toString()
    let data_to_return = horas_to_return + ':' + min_to_return_aux + ':' + sec_para_add


    return data_to_return


}
export const loader_layout = () => {

    return (<div style={{
        //  backgroundColor: cores.cor14,
        display: 'flex',
        flexDirection: 'row',
        flex: 1,

        alignItems: 'center',
        justifyContent: 'center'

    }}>

        <Loader
            type="TailSpin"
            //color="#8C6F29"
            color="var(--primary)"
            height={75}
            width={75}
        // timeout={2000} //3 secs
        />

    </div>)

}




//module.exports = { lista_de_categorias, lista_de_produtos, ordem_de_compra }
