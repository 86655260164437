import React, { useEffect, useState } from "react";

import { language } from '../WEBContent/Language';
import {
    set_language,
    content_language

} from '../../../Utils';
import './Preview.css';

const Preview = () => {
 
	const [lang, set_lang] = useState('pt');
    const [, set_lang_id] = useState('1');

    useEffect(() => { 
        if(localStorage.getItem('lang') === undefined  || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if(el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }
    
    }, []); 

  	return (
		<div 
			className="div-preview text-center p-2 border-0 shadow-sm" 
			title={language[lang].content_preview} 
			target="_blank"
		>
			<span>{language[lang].content_preview}</span>
		</div>
  	);
};

export default Preview;
