import React, { useState, useEffect } from 'react';
import { Link, useParams, Redirect } from 'react-router-dom';

import ReactHtmlParser/*, { processNodes, convertNodeToElement, htmlparser2 }*/ from 'react-html-parser';
import Calendar from 'react-calendar';
import '../GenericStore/calendar.css';
import "../Pagina.css";
import "../content-styles.css"
import { set_language, content_language, repo_img_link } from '../../../Utils';
import './Service.css';
import '../GenericStore/GenericStore.css';
import Loader from '../../../loader';
import Navbar from "../Navbar/Navbar";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Footer from "../Footer/Footer";
import { network } from '../GenericStore/UseService';
import { usarToken } from '../GenericStore/UseToken';
import { lista_de_categorias, adiciona_min_to_data_generica, aviso_lista_vazia_layout, get_data_func, get_uniq_element_by_fields, loader_layout, translate_generic_store, translate_horas, verifica_hora_atual_maior_que_hora } from '../GenericStore/utils';
import moment from 'moment';
import GenericSlider from '../GenericSlider/GenericSlider';
import { cores } from '../GenericStore/valores';
import { language } from '../GenericStore/Language';
import CostumModalLogin from '../GenericStore/CostumModalLogin';
import useContextProvider from '../GenericStore/Providers/ContextProvider';
import { PRODUCTION, nome_empresa } from '../GenericStore/HOSTS';
import Swal from 'sweetalert2';

import AssecImage from "../ASSEC_Image/Image"
import func_ws from '../GenericStore/comunication_websocket';

import * as Hosts from "../../../Hosts";




//let data = get_data_func()
//set_dia_selecionado_func(data)
//let dia_selecionado =set_dia_selecionado_func(data)

const Service = () => {





    const { get_var_numero_maximo_de_produtos_com_tempo, get_intervalo_tolerancia, refreshlista, get_var_timer_countdown, updateprodnegative, removeprod, get_lista_carrinho, get_numeroProdutos, get_lista, updateprodpositive, get_ws_info, get_is_logged, get_id_user, set_lista_carrinho, update_timer, addprod, get_linguagem } = useContextProvider()



    //////////////////////
    let flag_login_valid = get_is_logged()
    const duracao_min = get_var_timer_countdown()
    const numero_maximo_de_produtos_com_tempo = get_var_numero_maximo_de_produtos_com_tempo()

    const [dia_selecionado, set_dia_selecionado] = useState()
    const [dia_selecionado_string, set_dia_selecionado_string] = useState()

    ////////////////////////////

    const [isloading, set_isloading] = useState(true);
    const [state_loader, set_state_loader] = useState(true)

    const [refresh, set_refresh] = useState(0)

    const [lang, set_lang] = useState('pt');
    const [, set_lang_id] = useState('1');

    const [user_profile, set_user_profile] = useState('');

    const { product_id } = useParams();

    const [prod_info, set_prod_info] = useState({})
    const [horario_from_bd, set_horario_from_bd] = useState([]) // guarda os horarios todos que recebemos da network 
    const [lista_imagens, set_lista_imagens] = useState([])
    const [prod_principal_da_composicao, set_prod_principal_da_composicao] = useState({})
    const [horas_manha, set_horas_manha] = useState([]) // lista de horarios do dia selecionado, apenas eventos que decorrem de manha
    const [horas_tarde, set_horas_tarde] = useState([]) // lista de horarios do dia selecionado, apenas eventos que decorrem de manha
    //const [dia_selecionado,set_dia_selecionado] = useState({})
    const [open_modal_login, set_open_modal_login] = useState(false)
    const [flag_carrinho_aberto, set_flag_carrinho_aberto] = useState(false)

	const [isRedirect, setRedirect] = useState(false);

    const meses_pt = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
    const meses_en = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    const meses_es = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    const meses_fr = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'September', 'Octobre', 'Novembre', 'Décembre']
    const dias_da_semana_pt = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado']
    const dias_da_semana_en = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    const dias_da_semana_es = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']
    const dias_da_semana_fr = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']
    const dias_da_semana_short_pt = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
    const dias_da_semana_short_en = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    const dias_da_semana_short_es = ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb']
    const dias_da_semana_short_fr = ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam']

    const [, set_calendar_weekdays] = useState(lang === 'en' ? dias_da_semana_short_en : lang === 'pt' ? dias_da_semana_short_pt : lang === 'fr' ? dias_da_semana_short_fr : dias_da_semana_short_es)

    const [lista_categorias, set_lista_categorias] = useState([])
    const [selected_categoria, set_selected_categoria] = useState('SPA')
    const [, setLoadingDiv] = useState(true);


    const set_new_category = (new_cat) => {
        set_selected_categoria(new_cat)

        setLoadingDiv(true)

    }

    let set_dia_selecionado_func = (dia, set_refresh) => {

        console.log('set_dia_selecionado_func:', dia)
        try {
            let dia_selecionado_tmp = {}
            dia_selecionado_tmp.dateString = dia.split('T')[0]
            dia_selecionado_tmp.day = dia.split('T')[0].split('-')[2]
            dia_selecionado_tmp.month = dia.split('T')[0].split('-')[1]
            dia_selecionado_tmp.timestamp = ''
            dia_selecionado_tmp.year = dia.split('T')[0].split('-')[0]

            console.log('set_dia_selecionado_func RESULT:', dia_selecionado_tmp.dateString)

            set_refresh(dia_selecionado_tmp.dateString)

            return dia_selecionado_tmp.dateString
        } catch (error) {
            console.log('set_dia_selecionado_func error', error)

        }

    }


    const solve_language = () => {

        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')

            set_calendar_weekdays(dias_da_semana_short_pt)
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)

                    if (lang === 'en')
                        set_calendar_weekdays(dias_da_semana_short_en)
                    if (lang === 'es')
                        set_calendar_weekdays(dias_da_semana_short_es)
                    if (lang === 'fr')
                        set_calendar_weekdays(dias_da_semana_short_fr)
                }
            })
        }
    }

    const get_prod_por_id = async () => {
        //console.log('get_prod_por_idget_prod_por_idget_prod_por_id')
        try {
            let info_prod = await network().get_produtos_by_id(product_id)




            set_prod_info(info_prod)

            solve_imagens_slider(info_prod)


            set_selected_categoria(info_prod.codgrupo)


            return info_prod
        } catch (error) {
            console.log('get_prod_por_id error', error)
            return {}

        }
    }

    const solve_imagens_slider = (informacao_prod) => {
        let prod_principal = informacao_prod.composicao.find((el) => el.principal === true)
        set_lista_imagens(prod_principal.multimedia.link_images)
        set_prod_principal_da_composicao(prod_principal)
    }

    // const formato_correto_obj = async (obj_stock) => {
    //     // para ser mais facil de apresentar e por os preços trocarem com bastante facilidade 
    //     try {
    //         //   console.log('obj_stock', obj_stock)
    //         let aux_hora_inicio = (obj_stock.start_at).split(':')
    //         let aux_hora_fim = (obj_stock.end_at).split(':')

    //         let aux = await network().get_preco_by_id_da_lista_de_stock(parseInt(obj_stock.id))
    //         console.log('aux_precocoococococ', aux)
    //         let obj = {
    //             id_stock: obj_stock.id,
    //             id_prod: obj_stock.id_produto,
    //             preco: aux === undefined ? '' : aux.preco_liquido,
    //             horas_inicio: aux_hora_inicio[0] + ':' + aux_hora_inicio[1],
    //             horas_fim: aux_hora_fim[0] + ':' + aux_hora_fim[1],
    //             obj_completo: obj_stock,
    //             detalhes_preco: aux === undefined ? [] : aux
    //         }

    //         return obj

    //     } catch (error) {
    //         console.log('formato_correto_obj error', error)
    //         return {}
    //     }
    // }

    const filter_events_by_hour = (lista, dia) => {
        try {
            let list_to_return = []
            list_to_return = lista.filter(element => {

                let hora_evento = element.linha.calendario[0].validade_data_hora_ini.substring(0, 5)
                let horas_to_compare = adiciona_min_to_data_generica(dia, get_intervalo_tolerancia())
                let dia_to_compare = dia.split('T')[0] + 'T' + horas_to_compare + '.' + dia.split('T')[1].split('.')[1]

                let flag_fora_de_prazo = verifica_hora_atual_maior_que_hora(dia_to_compare, hora_evento)
                if (flag_fora_de_prazo === false) {
                    return true
                } else {
                    return false
                }
            });

            return list_to_return
        } catch (error) {
            console.log('filter_events_by_hour error', error)
            return []

        }
    }

    /* const delete_eventos_de_colisoes_e_cativados = async (lista) => {
        //  console.log('delete_eventos_de_colisoes_e_cativados', JSON.stringify(lista, null, '\t'))
        try {
            let list_colisoes_e_cativados = []

            let list_events_cativados = lista.filter((el) => (parseInt(el.obj_completo.qtd_cativada) !== 0 || el.obj_completo.visible === false))
            console.log('list_events_cativados', JSON.stringify(list_events_cativados, null, '\t'))

            list_colisoes_e_cativados.push(list_events_cativados)

            await Promise.all(list_events_cativados.map(async (obj) => {
                let lista_colisoes_aux = await network().get_events_incompativeis_to_cative(obj.obj_completo.id_event)
                console.log('lista_colisoes_aux', lista_colisoes_aux)
                lista_colisoes_aux.data.forEach((objeto) => {
                    let aux_obj = lista.find((e) => e.obj_completo.id_event === objeto)
                    if (aux_obj !== undefined) {
                        list_colisoes_e_cativados.push(aux_obj)
                    }
                })

            }))

            let list_to_return = []

            lista.forEach((element) => {
                // flat() => [[{}],[{}, {}]] transforma em [{},{},{}]
                if (list_colisoes_e_cativados.flat().find((el) => el === element) === undefined) {
                    if (parseInt(element.obj_completo.qtd_disponivel) !== 0 && element.obj_completo.visible === true) {
                        list_to_return.push(element)
                    }
                }
            })

            return list_to_return

        } catch (erro) {
            console.log('delete_eventos_de_colisoes_e_cativados eror', erro)
            return []
        }

    } */
    const sort_events_by_pm_am = (lista) => {

        try {
            let manha = []
            let tarde = []
            //const lista = await delete_eventos_de_colisoes_e_cativados(lista_aux)

            lista.forEach((el) => {
                // await Promise.all(lista.map(async el => {

                let horas = el.linha.calendario[0].validade_data_hora_ini
                let hora_inicio = (horas).split(':')[0]

                if (parseInt(hora_inicio) >= 12) {
                    // let aux_horario = await formato_correto_horario(el)
                    //horario da tarde
                    tarde.push(el)
                } else {
                    //let aux_horario =await formato_correto_horario(el)

                    // horario  manha
                    manha.push(el)
                }

            })
            /* 
                        let lista_manha_final = get_uniq_element_by_fields(manha, ['horas_inicio', 'obj_completo.id_event'])
            
                        let lista_tarde_final = get_uniq_element_by_fields(tarde, ['horas_inicio', 'obj_completo.id_event'])
             */

            set_horas_manha(manha)
            set_horas_tarde(tarde)

        } catch (error) {
            console.log('sort_events_by_pm_am error', error)
        }
    }

    // const get_prods_by_date = async (dia, informacao_prod, set_state_loader) => {
    //     try {
    //         let obj_stock = await network().get_stocks_by_event_date_and_id_do_produto(parseInt(informacao_prod.id), dia)

    //         //set_state_loader(false)
    //         return obj_stock

    //     } catch (error) {
    //         console.log('get_prods_by_date erro', error)
    //         return []
    //     }

    // }
    // const format_list_prods = async (list) => {
    //     try {
    //         let list_to_return = []
    //         let lista = get_uniq_element_by_fields(list, ['start_at'])

    //         await Promise.all(lista.map(async (element) => {

    //             let obj_formato_correto = await formato_correto_obj(element)

    //             if ((obj_formato_correto.preco).toString().length > 0) {

    //                 list_to_return.push(obj_formato_correto)
        //         } else {
        //             // await network().solve_event_error(element, 'Produto sem preco')
        //             alert('Produto sem preco')

        //         }
        //     }))
        //     return list_to_return

        // } catch (error) {
        //     console.log('format_list_prods error', error)
        //     return []
        // }


    // }
    const func_print = (text_to_indentify_print, text_to_print, type_of_printing = 'log', flag_all_json = false) => {
        try {

            if (PRODUCTION === false) {
                if (flag_all_json) {
                    console[type_of_printing](text_to_indentify_print, JSON.stringify(text_to_print, null, '\t'))
                } else {
                    console[type_of_printing](text_to_indentify_print + text_to_print)

                }

            } else {
                return
            }
        } catch (error) {
            console.log('func_print error', error)

            return {}

        }

    }
    const get_uniq_element_field = (lista, fields_list) => {
        try {
            let temp = []
            lista.forEach((e) => {
                console.log('e', e)

                fields_list.forEach((field) => {
                    let array_campos = field.split('.')
                    console.log('array_campos', array_campos)


                    if (temp.find((k) => resolve_obj(k, array_campos) === resolve_obj(e, array_campos)) === undefined) {
                        //     if (temp.find((k) => k[field] === e[field]) === undefined) {


                        temp.push(e)
                    }
                })

            })
            /*    let temp = []
               lista.map((e) => {
                   if (temp.find((k) => k[field] === e[field]) === undefined) {
                       temp.push(e)
                   }
               }) */
            return temp
        } catch (error) {
            func_print('get_uniq_element_field eror', error)

            return []

        }
    }
    const resolve_obj = (obj, array_campos) => {
        if (array_campos.length === 1) {
            return obj[array_campos[0]]
        } else {

            let temp = resolve_key_list(obj, array_campos)
            return temp

        }
    }
    let resolve_key_list = (obj, lista_de_campos) => {

        if (lista_de_campos.length === 0) {
            return obj
        } else {

            return resolve_key_list(obj[lista_de_campos[0]], lista_de_campos.slice(1))
        }
    }

    const set_info_by_date = async (informacao_prod, dia_selecionado) => {

        console.log('--------------------------->dia_selecionado:1', dia_selecionado)
        console.log('--------------------------->informacao_prod:1', informacao_prod)
        try {
            set_horario_from_bd([])
            set_horas_manha([])
            set_horas_tarde([])
            let data_today = get_data_func()
            let array_field_to_filter = [
                {
                    field: 'cod_grupo',
                    array_valores: [informacao_prod.codgrupo]
                },
                {
                    field: "stocks.visible",
                    array_valores: ['true']
                },
                {
                    field: 'id_prod',
                    array_valores: [informacao_prod.id]
                },
                {
                    field: 'ano',
                    array_valores: [dia_selecionado.split('-')[0]]
                },
                {
                    field: 'mes',
                    array_valores: [dia_selecionado.split('-')[1]]
                },
                {
                    field: 'dia',
                    array_valores: [dia_selecionado.split('-')[2]]
                }


            ]
            let new_horario = await network().get_prods_cache_filter(array_field_to_filter)




            if (informacao_prod.codgrupo === 'WEL') {

                /*  console.log('list_prods_from_network', list_prods_from_network)
                 console.log('aux_list_prods_from_network', aux_list_prods_from_network)
  */


                if (dia_selecionado === data_today.split('T')[0]) { // se o dia selecionado for hoje faco verificacoes
                    let list_filter = filter_events_by_hour(new_horario, data_today)
                    let lista = get_uniq_element_field(list_filter, ['linha.calendario.0.validade_data_hora_ini'])

                    sort_events_by_pm_am(lista)
                    set_state_loader(false)


                } else if (dia_selecionado > data_today.split('T')[0]) {
                    let lista = get_uniq_element_field(new_horario, ['linha.calendario.0.validade_data_hora_ini'])

                    sort_events_by_pm_am(lista)
                    set_state_loader(false)


                } else {

                    set_horas_manha([])
                    set_horas_tarde([])
                    set_state_loader(false)

                }



            } else /* if (informacao_prod.codgrupo === 'SPA') */ {

                // await handle_ver_disponibilidade_do_dia(data.split('T')[0], informacao_prod)
                if ((dia_selecionado >= data_today.split('T')[0])) {

                    // let list_format_correct = []

                    set_horario_from_bd(new_horario)

                    set_state_loader(false)

                    //await get_prods_by_date(dia_selecionado.current.dateString, informacao_prod, set_state_loader)
                } else {
                    set_state_loader(false)

                }

            }
            set_isloading(false)

        } catch (error) {
            set_isloading(false)
            set_state_loader(false)
            console.log('--------------------------->dia_selecionado:', dia_selecionado)

            console.log('set_info_by_date error', error)

        }

    }

    const get_format_dia_correct = (day) => {
        try {

            //let dia_tipo_date = new Date(day.dateString)
            let dia_tipo_date = new Date(day)
            let day_arrany = day.split('-')
            let month = day_arrany[1]
            let dia = day_arrany[2]
            let year = day_arrany[0]

            let dia_semana_string = ''
            //  let dia_semana_string_short = ''
            let mes_string = ''

            if (lang === 'en') {
                dia_semana_string = dias_da_semana_en[dia_tipo_date.getDay()]
                mes_string = meses_en[month - 1]
            } else if (lang === 'es') {
                dia_semana_string = dias_da_semana_es[dia_tipo_date.getDay()]
                mes_string = meses_es[month - 1]
            } else if (lang === 'fr') {
                dia_semana_string = dias_da_semana_fr[dia_tipo_date.getDay()]
                mes_string = meses_fr[month - 1]
            } else {
                dia_semana_string = dias_da_semana_pt[dia_tipo_date.getDay()]
                mes_string = meses_pt[month - 1]
            }


            let data_to_return = dia_semana_string + ', ' + dia + ' ' + mes_string + ' ' + year
            return data_to_return
        } catch (error) {
            console.log('get_format_dia_correct error', error)
            return ''

        }

    }
    const verify_if_still_available_put_prod_timer = () => {
        func_print('verify_if_still_available_put_prod_timer numero_maximo_de_produtos_com_tempo', numero_maximo_de_produtos_com_tempo)

        try {
            if (numero_maximo_de_produtos_com_tempo.toString() === '-1') {
                return true
            } else {
                if (get_lista_carrinho().length === 0) {
                    return true
                } else {


                    let prods_timer = get_lista_carrinho().filter((el) =>
                        el.timestamp.toString().length === 0 ? false : true


                    )
                    func_print('prods_timer.len', prods_timer.length, 'log', true)
                    if ((prods_timer.length).toString() >= numero_maximo_de_produtos_com_tempo.toString()) {
                        return false
                    } else {
                        return true
                    }
                }

            }


        } catch (error) {
            func_print('verify_if_still_available_put_prod_timer error', error)

        }
    }


    const handle_sessao_selecionada = async (horario) => {
        let date_hoje = get_data_func()
        set_isloading(true)
        try {
            let flag_is_possible_add_more_prods = verify_if_still_available_put_prod_timer()
            if (flag_is_possible_add_more_prods === true) {
                // para ser mais facil de mostrar informacoes deste produto, e tambem ser mais facil de criar a encomenda 

                if (dia_selecionado !== date_hoje.split('T')[0]) {

                    // let aux_prod_to_order = prod_info

                    // /* let obj_stock = await network().get_stock_by_id_event(parseInt(horario.obj_completo.id_event))
                    // console.log('obj_stock', obj_stock) */

                    // aux_prod_to_order.details[nome_empresa].dia = dia_selecionado
                    // aux_prod_to_order.details[nome_empresa].validade_data_hora_fim = horario.horas_fim
                    // aux_prod_to_order.details[nome_empresa].validade_data_hora_ini = horario.horas_inicio
                    // aux_prod_to_order.details[nome_empresa].id_event = horario.obj_completo.id_event
                    // aux_prod_to_order.details[nome_empresa].id_stock = horario.id_stock
                    // aux_prod_to_order.total_liquido = horario.detalhes_preco.preco_liquido

                    // let new_composicao = aux_prod_to_order.composicao.map((el) => {
                    //     if (el.principal === true) {
                    //         el.valores = horario.detalhes_preco
                    //     }
                    //     return el
                    // })

                    // aux_prod_to_order.composicao = new_composicao


                    send_sessao_to_ws(horario) // envia novos dados ao wss
                } else {
                    let horas_to_compare = adiciona_min_to_data_generica(date_hoje, get_intervalo_tolerancia())
                    let dia_to_compare = date_hoje.split('T')[0] + 'T' + horas_to_compare + '.' + date_hoje.split('T')[1].split('.')[1]
                    let flag_fora_de_prazo = verifica_hora_atual_maior_que_hora(dia_to_compare, horario.horas_inicio)

                    if (flag_fora_de_prazo === false) {

                        /* let aux_prod_to_order = prod_info
                        // let obj_stock = await network().get_stock_by_id_event(horario.obj_completo.id_event)
                        //console.log('horario', JSON.stringify(horario, null, '\t'))
    
                        aux_prod_to_order.details[nome_empresa].dia = dia_selecionado
                        aux_prod_to_order.details[nome_empresa].validade_data_hora_fim = horario.horas_fim
                        aux_prod_to_order.details[nome_empresa].validade_data_hora_ini = horario.horas_inicio
                        aux_prod_to_order.details[nome_empresa].id_event = horario.obj_completo.id_event
                        aux_prod_to_order.details[nome_empresa].id_stock = horario.obj_completo.id//obj_stock.id
                        aux_prod_to_order.total_liquido = horario.detalhes_preco.preco_liquido
    
                        let new_composicao = aux_prod_to_order.composicao.map((el) => {
                            if (el.principal === true) {
                                el.valores = horario.detalhes_preco
    
                            }
                            return el
    
    
                        }) 
                        aux_prod_to_order.composicao = new_composicao
    */
                        send_sessao_to_ws(horario) // envia novos dados ao wss


                    } else {
                        Swal.fire({
                            title: '',
                            text: language[lang].label_erro_tempo_depois_de_horario_escolhido,
                            icon: 'error',
                            showCancelButton: false,
                            confirmButtonColor: '#0095e8',
                            confirmButtonText: language[lang].label_confirmar,
                        })
                            .then((result) => {
                                window.location.reload()
                            })

                    }

                }
                //set_isloading(false)
            } else {
                Swal.fire({
                    title: '',
                    text: language[lang].label_impossivel_add_outro_prod_timer1 + language[lang].label_impossivel_add_outro_prod_timer2 + numero_maximo_de_produtos_com_tempo + language[lang].label_impossivel_add_outro_prod_timer3,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#0095e8',
                    confirmButtonText: language[lang].label_confirmar,
                })
                    .then((result) => {
                        set_isloading(false)

                    })
            }

        } catch (error) {
            console.log('handle_sessao_selecionada error', error)
            set_isloading(false)

        }
    }

    const send_sessao_to_ws = async (obj) => {
        // cativar produto
        console.log('send_sessao_to_ws')
        let ws = get_ws_info()

        let flag_connect = false
        flag_connect = func_ws().verify_if_wss_is_open(ws)
        let id_user = get_id_user()
        let aux_list_to_cative = await network().get_events_incompativeis_to_cative(obj.id_event)
        console.log('aux_list_to_cative', aux_list_to_cative)
        if (flag_connect === false) {
            console.log('passou2')
            func_ws().close_ws(ws)

            func_ws().conecta_e_cativa_prod_event(refreshlista, addprod, update_timer, lang, set_lista_carrinho, aux_list_to_cative, duracao_min, id_user, obj, get_lista_carrinho)
        } else {
            console.log('passou1')

            try {

                /*   let ws = get_ws_info()
                  let id_user = get_id_user()
      
                  // buscar prods que sao para meter nao visivel por incompatibilidade de horario com evento que esta a ser marcado agora 
                  console.log('obj', obj)
                  console.log('duracao_min', duracao_min)
                  let aux_list_to_cative = await network().get_events_incompativeis_to_cative(obj.details[nome_empresa].id_event)
       */
                let prod = {
                    id_prod: obj.id_prod,
                    id_event: obj.id_event,
                    quantidade: 1,
                    id_user: id_user,
                    prod_timer: duracao_min,
                    lista_incompativel: aux_list_to_cative.data,
                    id_stock: obj.id_stock,
                    info: {
                        produto: obj,
                        quantidade: 1,
                    }


                }

                // cativar produto é ativada quando o client adiciona produto no carrinho
                // nesta fase o prod ja tem o id client
                ws.send(JSON.stringify({ cativar: true, data: [prod] }))

                ws.onmessage = async (event) => {

                    let data_parse = JSON.parse(event.data)
                    let type = data_parse.type
                    console.log('data_parse7', data_parse)

                    // type existe pois é uma forma de identificar o que o server esta a enviar 
                    // informacao para atualizar carrinho e lista de stockes ou se esta a atualizar o countdown de um produto 
                    switch (type) {
                        case 'update_list': // atualiza carrinho e lista de produtos

                            try {

                                if (parseInt(id_user) === parseInt(data_parse.info.id)) {
                                    if (get_lista_carrinho().length > 0 && data_parse.info.carrinho.length === 0) {

                                        window.location.reload()

                                    }
                                    let temp = []//await func_ws().repor_prods_from_carrinho(data_parse.info.carrinho)

                                    set_lista_carrinho(data_parse.info.carrinho, false, temp)// funcao vinda do provider
                                }

                                // set_lista_events(data_parse.lista_produtos)// funcao vinda do provider

                            } catch (erro) {
                                console.log('erro cativar_prod1', erro)
                            }

                            break;

                        case 'temporal_segundo':  // caso do server enviar informacao para atualizar o countdown de um produto
                            //console.error('temporal_segundo7', data_parse.data.tempo)

                            try {

                                if (parseInt(id_user) === parseInt(data_parse.data.id_user)) {

                                    if ((data_parse.data.tempo).toString() === '00:00') { // verificar se o countdown do produto chegou a zero
                                        let aux_prod_que_foi_eliminado = await network().get_produtos_by_id(data_parse.data.id_prod)
                                        Swal.fire({
                                            title: '',
                                            text: language[lang].label_introducao_frase_de_remocao_de_prod + aux_prod_que_foi_eliminado.nome + language[lang].label_final_frase_de_remocao_de_prod,
                                            icon: 'warning',
                                            showCancelButton: false,
                                            confirmButtonColor: '#0095e8',
                                            confirmButtonText: language[lang].label_confirmar,
                                        })
                                            .then((result) => {
                                                window.location.reload()

                                            })
                                        //  alert('O produto ' + aux_prod_que_foi_eliminado.nome + ' foi removido do seu carrinho') // enviar alert para qualquer ecra

                                    } else {

                                        if ((data_parse.data.tempo).toString() === '59:59') {

                                            update_timer('')

                                        } else {

                                            update_timer(data_parse.data)

                                        }

                                    }
                                }

                            } catch (erro) {
                                console.log('erro cativar_prod12223', erro)
                            }

                            break;
                        case 'erro':  // mensagem de erro caso o obj nao ter sido bem atualizado na bd

                            try {


                                if (parseInt(data_parse.id_user) === parseInt(id_user)) {
                                    Swal.fire({
                                        title: '',
                                        text: translate_generic_store(data_parse.data, lang.toLowerCase()),
                                        icon: 'error',
                                        showCancelButton: false,
                                        confirmButtonColor: '#0095e8',
                                        confirmButtonText: language[lang].label_confirmar,
                                    })
                                        .then((result) => {
                                            window.location.reload()
                                        })
                                    // alert(translate_generic_store(data_parse.data, (get_linguagem() !== null ? get_linguagem().toLowerCase() : 'pt')))

                                }
                            } catch (erro) {
                                console.log('switch erro send_sessao_to_ws error:', erro)
                            }


                            break;
                        case 'sucesso': // mensagem de sucesso caso o obj ter sido bem atualizado na bd
                            console.log('sucesso810')

                            try {

                                if (parseInt(data_parse.id_user) === parseInt(id_user)) {
                                    addprod(obj)// funcao vinda do provider 
                                    Swal.fire({
                                        title: '',
                                        text: translate_generic_store(data_parse.data, lang.toLowerCase()),
                                        icon: 'success',
                                        showCancelButton: false,
                                        confirmButtonColor: '#0095e8',
                                        confirmButtonText: language[lang].label_confirmar,
                                    })
                                        .then((result) => {
                                            window.location.reload()

                                        })
                                    // alert(translate_generic_store(data_parse.data, (get_linguagem() !== null ? get_linguagem().toLowerCase() : 'pt')))

                                }
                            } catch (erro) {
                                console.log('switch sucesso send_sessao_to_ws error:', erro)
                            }


                            break;
                        default:
                            break;
                    }
                };

            } catch (error) {
                console.log('send_info_to_ws eror', error)
            }
        }

    }

    const handle_stock_selecionado = (horario_sessao) => {
        // caso de ja ter escolhido o horario 
        let flag_update_prod = false
        set_isloading(true)

        try {

            // no caso dos stocks procuramos de ele ja existe na lista pois se ele existir é o mesmo produto mas com quantidade 2 e etc
            let obj_encontrado = get_lista_carrinho().length === 0 ? undefined : get_lista_carrinho().find((el) => parseInt(el.info.produto.id_stock) === parseInt(prod_info.id_stock))

            //let aux_prod_to_order = prod_info

            if (obj_encontrado !== undefined) { // caso do objeto ja existis na lista 
                flag_update_prod = true


                /*   // let obj_principal = prod_info.composicao.find((el) => el.principal === true)
  
                  aux_prod_to_order.details[nome_empresa].dia = dia_selecionado
                  aux_prod_to_order.details[nome_empresa].validade_data_hora_fim = horario_sessao.horas_fim
                  aux_prod_to_order.details[nome_empresa].validade_data_hora_ini = horario_sessao.horas_inicio
                  aux_prod_to_order.details[nome_empresa].id_event = 0 // pois stocks nao sao eventos , logo nao tem id_event
                  aux_prod_to_order.details[nome_empresa].id_stock = horario_sessao.id_stock
                  aux_prod_to_order.total_liquido = horario_sessao.detalhes_preco.preco_liquido//obj_principal.valores.preco_liquido
  
                  let new_composicao = aux_prod_to_order.composicao.map((el) => {
  
                      if (el.principal === true) {
  
                          el.valores = horario_sessao.detalhes_preco // pois  o produto esta definido com um preço base porem consuante o dia e hora o preço pode subir
  
                      }
  
                      return el
  
                  })
  
                  aux_prod_to_order.composicao = new_composicao
   */
            } else { // produto ainda nao existe na lista entao tem de ser criado 
                flag_update_prod = true

                //let obj_principal = prod_info.composicao.find((el) => el.principal === true)

                /*  aux_prod_to_order.details[nome_empresa].dia = dia_selecionado
                 aux_prod_to_order.details[nome_empresa].validade_data_hora_fim = horario_sessao.horas_fim
                 aux_prod_to_order.details[nome_empresa].validade_data_hora_ini = horario_sessao.horas_inicio
                 aux_prod_to_order.details[nome_empresa].id_event = 0// pois stocks nao sao eventos , logo nao tem id_event
                 aux_prod_to_order.details[nome_empresa].id_stock = horario_sessao.id_stock
                 aux_prod_to_order.total_liquido = horario_sessao.detalhes_preco.preco_liquido
 
                 let new_composicao = aux_prod_to_order.composicao.map((el) => {
 
                     if (el.principal === true) {
 
                         el.valores = horario_sessao.detalhes_preco
 
                     }
 
                     return el
 
                 })
 
                 aux_prod_to_order.composicao = new_composicao */


            }

            send_stock_to_ws(flag_update_prod, horario_sessao) // envia novos dados ao wss
            // set_isloading(true)

        } catch (error) {
            console.log('handle_sessao_selecionada error', error)
            // set_isloading(true)
            set_isloading(false)


        }
    }

    const send_stock_to_ws = async (flag_update_prod, horario_sessao) => {
        // cativar produto
        let flag_connect = false
        let ws = get_ws_info()
        flag_connect = func_ws().verify_if_wss_is_open(ws)
        if (flag_connect === false) {
            func_ws().close_ws(ws)
            func_ws().conecta_e_cativa_prod_stock(refreshlista, horario_sessao, flag_update_prod, update_timer, addprod, updateprodpositive, lang, language, set_lista_carrinho, get_id_user, horario_sessao, get_lista_carrinho)

        } else {
            try {

                let ws = get_ws_info()
                let id_user = get_id_user()

                let prod = {
                    id_prod: horario_sessao.id_prod,
                    id_event: '',
                    quantidade: 1,// o produto é sempre add um de cada vez
                    id_user: id_user,
                    prod_timer: 0, //  zero pois este prod nao necessita de countdown
                    lista_incompativel: [], // os stocks nunca tem eventos sobrepostos
                    id_stock: horario_sessao.id_stock,
                    info: {
                        produto: horario_sessao,
                        quantidade: 1,
                    }
                }

                // nesta fase o prod ja tem o id client
                ws.send(JSON.stringify({ cativar: true, data: [prod] }))

                ws.onmessage = async (event) => {

                    let data_parse = JSON.parse(event.data)
                    let type = data_parse.type
                    console.log('data_parse8', data_parse)

                    // type existe pois é uma forma de identificar se o server esta a enviar 
                    // informacao para atualizar carrinho e lista de stockes ou se esta a atualizar o countdown de um produto 
                    switch (type) {
                        case 'update_list': // atualiza carrinho e lista de produtos

                            try {

                                if (parseInt(id_user) === parseInt(data_parse.info.id)) {
                                    if (get_lista_carrinho().length > 0 && data_parse.info.carrinho.length === 0) {

                                        window.location.reload()

                                    }
                                    let temp = []//await func_ws().repor_prods_from_carrinho(data_parse.info.carrinho)

                                    set_lista_carrinho(data_parse.info.carrinho, false, temp)
                                }

                                //  set_lista_events(data_parse.lista_produtos)

                            } catch (erro) {
                                console.log('erro cativar_prod1', erro)
                            }

                            break;
                        case 'temporal_segundo':  // caso do server enviar informacao para atualizar o countdown de um produto
                            //console.error('temporal_segundo8', data_parse.data.tempo)

                            try {

                                if (parseInt(id_user) === parseInt(data_parse.data.id_user)) {

                                    if ((data_parse.data.tempo).toString() === '00:00') { // verificar se o countdown do produto chegou a zero
                                        let aux_prod_que_foi_eliminado = await network().get_produtos_by_id(data_parse.data.id_prod)

                                        Swal.fire({
                                            title: '',
                                            text: language[lang].label_introducao_frase_de_remocao_de_prod + aux_prod_que_foi_eliminado.nome + language[lang].label_final_frase_de_remocao_de_prod,

                                            icon: 'warning',
                                            showCancelButton: false,
                                            confirmButtonColor: '#0095e8',
                                            confirmButtonText: language[lang].label_confirmar,
                                        })
                                            .then((result) => {
                                                /* console.log('result', result)
                                                ws.close() */
                                                window.location.reload()

                                            })

                                        //  alert('O produto ' + aux_prod_que_foi_eliminado.nome + ' foi removido do seu carrinho') // enviar alert para qualquer ecra

                                    } else {

                                        if ((data_parse.data.tempo).toString() === '59:59') {

                                            update_timer('')

                                        } else {

                                            update_timer(data_parse.data)

                                        }

                                    }
                                }

                            } catch (erro) {
                                console.log('erro cativar_prod1222e', erro)
                            }

                            break;
                        case 'erro':  // mensagem de erro caso o obj nao ter sido bem atualizado na bd
                            try {

                                if (parseInt(data_parse.id_user) === parseInt(id_user)) {
                                    Swal.fire({
                                        title: '',
                                        text: translate_generic_store(data_parse.data, lang.toLowerCase()),
                                        icon: 'error',
                                        showCancelButton: false,
                                        confirmButtonColor: '#0095e8',
                                        confirmButtonText: language[lang].label_confirmar,
                                    })
                                        .then((result) => {
                                            window.location.reload()
                                        })
                                    //alert(translate_generic_store(data_parse.data, (get_linguagem() !== null ? get_linguagem().toLowerCase() : 'pt')))

                                }
                            } catch (erro) {
                                console.log('switch erro send_stock_to_ws error:', erro)

                            }


                            break;
                        case 'sucesso':  // mensagem de sucesso caso o obj ter sido bem atualizado na bd
                            console.log('sucesso11')

                            try {

                                if (parseInt(data_parse.id_user) === parseInt(id_user)) {
                                    if (flag_update_prod) {

                                        updateprodpositive(horario_sessao.id_stock) // function vinda do provider, atualiza a quantidade para 2 ou mais 
                                    } else {

                                        addprod(horario_sessao) // funcao do provider, adiciona o produto na lista 
                                    }
                                    Swal.fire({
                                        title: '',
                                        text: translate_generic_store(data_parse.data, lang.toLowerCase()),
                                        icon: 'success',
                                        showCancelButton: false,
                                        confirmButtonColor: '#0095e8',
                                        confirmButtonText: language[lang].label_confirmar,
                                    })
                                        .then((result) => {
                                            window.location.reload()
                                        })
                                    // alert(translate_generic_store(data_parse.data, (get_linguagem() !== null ? get_linguagem().toLowerCase() : 'pt')))

                                }
                            } catch (erro) {
                                console.log('switch sucesso send_stock_to_ws error:', erro)

                            }

                            break;
                        default:
                            break;
                    }
                };
            } catch (error) {
                console.log('send_info_to_ws eror', error)
            }
        }

    }

    const handler_countdown = (obj) => { // trata de mostrar o tempo a passar
        try {

            let obj_aux = get_lista_carrinho().find((ele) => parseInt(ele.id_stock) === parseInt(obj.info.produto.details[nome_empresa].id_stock))

            if (obj_aux !== undefined) {

                if ((obj_aux.tempo).toString() === '00:00') { // tempo chegou ao fim 

                    removeprod(obj)

                    return ''

                } else {
                    return obj_aux.tempo
                }

            }

        } catch (error) {
            console.log('handler_countdown error', error)
            return ''
        }
    }

    const handler_remover_produto = (obj) => {

        try {

            let ws = get_ws_info()

            if (parseInt(obj.quantidade) === 1) {

                let prod_nao_cativar = {
                    id_prod: obj.produto.id_prod,
                    quantidade: 1,
                    prod_timer: 0,
                    id_user: get_id_user(),
                    timestamp: obj.produto.id_event,
                    id_stock: obj.produto.id_stock
                }

                let prod_lista = [prod_nao_cativar]

                // distinguir se estou a apagar um produto ou a lista toda dos produtos
                if (prod_lista.length === 1) { // caso o utilizador pressione o botao de apagar todos os produtos do carrinho, mas essa lista apenar ter um produto

                    ws.send(JSON.stringify({ cativar: false, data: prod_lista }))

                } else { // caso te ter clicado em apagar tudo da lista de carrinho sendo que esta tem mais do que um produto

                    ws.send(JSON.stringify({ id_user: get_id_user(), cativar: false, data: prod_lista }))

                }

                ws.onmessage = async (event) => {
                    //    console.log('data_parse11', data_parse)

                    let data_parse = JSON.parse(event.data)
                    let type = data_parse.type

                    // type existe pois é uma forma de identificar se o server esta a enviar 
                    // informacao para atualizar carrinho e lista de stockes ou se esta a atualizar o countdown de um produto 
                    switch (type) {
                        case 'update_list':
                            try {
                                if (parseInt(get_id_user()) === parseInt(data_parse.info.id_client)) {
                                    if (get_lista_carrinho().length > 0 && data_parse.info.carrinho.length === 0) {

                                        window.location.reload()

                                    }
                                    let temp = []//await func_ws().repor_prods_from_carrinho(data_parse.info.carrinho)

                                    set_lista_carrinho(data_parse.info.carrinho, false, temp)
                                }
                                //set_lista_events(data_parse.lista_produtos)
                            } catch (erro) {
                                console.log('erro cativar_prod211111', erro)
                            }
                            break;


                        case 'temporal_segundo':
                            //  console.error('temporal_segundo11', data_parse.data.tempo)

                            try {

                                if (parseInt(get_id_user()) === parseInt(data_parse.data.id_user)) {

                                    if ((data_parse.data.tempo).toString() === '00:00') { // verificar se o countdown do produto chegou a zero
                                        let aux_prod_que_foi_eliminado = await network().get_produtos_by_id(data_parse.data.id_prod)
                                        //alert('O produto ' + aux_prod_que_foi_eliminado.nome + ' foi removido do seu carrinho') // enviar alert para qualquer ecra
                                        Swal.fire({
                                            title: '',
                                            text: language[lang].label_introducao_frase_de_remocao_de_prod + aux_prod_que_foi_eliminado.nome + language[lang].label_final_frase_de_remocao_de_prod,

                                            icon: 'warning',
                                            showCancelButton: false,
                                            confirmButtonColor: '#0095e8',
                                            confirmButtonText: language[lang].label_confirmar,
                                        })
                                            .then((result) => {
                                                window.location.reload()

                                            })
                                    } else {
                                        update_timer(data_parse.data)
                                    }

                                }
                            } catch (erro) {
                                console.log('erro cativar_prod12224', erro)
                            }
                            break;


                        case 'erro':  // mensagem de erro caso o obj nao ter sido bem atualizado na bd
                            try {

                                if (parseInt(data_parse.id_user) === parseInt(get_id_user())) {

                                    /*   alert(translate_generic_store(data_parse.data, (get_linguagem() !== null ? get_linguagem().toLowerCase() : 'pt'))) */
                                    Swal.fire({
                                        title: '',
                                        text: translate_generic_store(data_parse.data, (get_linguagem() !== null ? get_linguagem().toLowerCase() : 'pt')),
                                        icon: 'error',
                                        showCancelButton: false,
                                        confirmButtonColor: '#0095e8',
                                        confirmButtonText: language[lang].label_confirmar,
                                    })
                                        .then((result) => {
                                            window.location.reload()
                                        })
                                }
                            } catch (error) {
                                console.log('switch erro handler_remover_produto error', error)
                            }
                            break;


                        case 'sucesso':  // mensagem de sucesso caso o obj ter sido bem atualizado na bd
                            console.log('sucesso14')

                            try {

                                if (parseInt(data_parse.id_user) === parseInt(get_id_user())) {

                                    //alert(translate_generic_store(data_parse.data, (get_linguagem() !== null ? get_linguagem().toLowerCase() : 'pt')))
                                    Swal.fire({
                                        title: '',
                                        text: translate_generic_store(data_parse.data, (get_linguagem() !== null ? get_linguagem().toLowerCase() : 'pt')),
                                        icon: 'success',
                                        showCancelButton: false,
                                        confirmButtonColor: '#0095e8',
                                        confirmButtonText: language[lang].label_confirmar,
                                    })
                                        .then((result) => {
                                            window.location.reload()
                                        })
                                    removeprod(obj)

                                }
                            } catch (error) {
                                console.log('switch sucesso handler_remover_produto error', error)
                            }
                            break;


                        default:
                            break;

                    }
                };
            } else {// qtd mais do que 1

                let prod_nao_cativar = {
                    id_prod: obj.produto.id_prod,
                    quantidade: 1,
                    prod_timer: 0,
                    id_user: get_id_user(),
                    timestamp: obj.produto.id_event,
                    id_stock: obj.produto.id_stock
                }

                let id_user = get_id_user()
                let prod_lista = [prod_nao_cativar]

                try {

                    // distinguir se estou a apagar um produto ou a lista toda dos produtos
                    if (prod_lista.length === 1) { // caso o utilizador pressione o botao de apagar todos os produtos do carrinho, mas essa lista apenar ter um produto
                        ws.send(JSON.stringify({ cativar: false, data: prod_lista }))
                    } else { // caso te ter clicado em apagar tudo da lista de carrinho sendo que esta tem mais do que um produto
                        ws.send(JSON.stringify({ id_user: id_user, cativar: false, data: prod_lista }))
                    }

                    ws.onmessage = async (event) => {
                        //console.log('data_parse12', data_parse)

                        let data_parse = JSON.parse(event.data)
                        let type = data_parse.type

                        // type existe pois é uma forma de identificar se o server esta a enviar 
                        // informacao para atualizar carrinho e lista de stockes ou se esta a atualizar o countdown de um produto 
                        switch (type) {
                            case 'update_list':
                                try {
                                    if (parseInt(id_user) === parseInt(data_parse.info.id_client)) {
                                        if (get_lista_carrinho().length > 0 && data_parse.info.carrinho.length === 0) {

                                            window.location.reload()

                                        }
                                        let temp = []//await func_ws().repor_prods_from_carrinho(data_parse.info.carrinho)

                                        set_lista_carrinho(data_parse.info.carrinho, false, temp)
                                    }
                                    // set_lista_events(data_parse.lista_produtos)
                                } catch (erro) {
                                    console.log('erro cativar_prod2', erro)
                                }

                                break;
                            case 'temporal_segundo':
                                // console.error('temporal_segundo12', data_parse.data.tempo)

                                try {

                                    if (parseInt(get_id_user()) === parseInt(data_parse.data.id_user)) {

                                        if ((data_parse.data.tempo).toString() === '00:00') { // verificar se o countdown do produto chegou a zero
                                            let aux_prod_que_foi_eliminado = await network().get_produtos_by_id(data_parse.data.id_prod)
                                            //alert('O produto ' + aux_prod_que_foi_eliminado.nome + ' foi removido do seu carrinho') // enviar alert para qualquer ecra
                                            Swal.fire({
                                                title: '',
                                                text: language[lang].label_introducao_frase_de_remocao_de_prod + aux_prod_que_foi_eliminado.nome + language[lang].label_final_frase_de_remocao_de_prod,
                                                icon: 'warning',
                                                showCancelButton: false,
                                                confirmButtonColor: '#0095e8',
                                                confirmButtonText: language[lang].label_confirmar,
                                            })
                                                .then((result) => {
                                                    window.location.reload()

                                                })
                                        } else {
                                            update_timer(data_parse.data)
                                        }

                                    }
                                } catch (erro) {
                                    console.log('erro cativar_prod12224', erro)
                                }
                                //  func_ws().func_catch_timer(event, set_lista_carrinho, get_lista_carrinho(), id_user)

                                break;
                            case 'erro':  // mensagem de erro caso o obj nao ter sido bem atualizado na bd
                                try {

                                    if (parseInt(data_parse.id_user) === parseInt(get_id_user())) {

                                        //  alert(translate_generic_store(data_parse.data, (get_linguagem() !== null ? get_linguagem().toLowerCase() : 'pt')))
                                        Swal.fire({
                                            title: '',
                                            text: translate_generic_store(data_parse.data, (get_linguagem() !== null ? get_linguagem().toLowerCase() : 'pt')),
                                            icon: 'error',
                                            showCancelButton: false,
                                            confirmButtonColor: '#0095e8',
                                            confirmButtonText: language[lang].label_confirmar,
                                        })
                                            .then((result) => {
                                                window.location.reload()
                                            })
                                    }

                                } catch (error) {
                                    console.log('switch erro1 handler_remover_produto error', error)
                                }

                                break;
                            case 'sucesso':  // mensagem de sucesso caso o obj ter sido bem atualizado na bd
                                console.log('sucesso15')

                                try {

                                    if (parseInt(data_parse.id_user) === parseInt(get_id_user())) {
                                        Swal.fire({
                                            title: '',
                                            text: translate_generic_store(data_parse.data, (get_linguagem() !== null ? get_linguagem().toLowerCase() : 'pt')),
                                            icon: 'success',
                                            showCancelButton: false,
                                            confirmButtonColor: '#0095e8',
                                            confirmButtonText: language[lang].label_confirmar,
                                        })
                                            .then((result) => {
                                                window.location.reload()
                                            })
                                        //alert(translate_generic_store(data_parse.data, (get_linguagem() !== null ? get_linguagem().toLowerCase() : 'pt')))
                                        updateprodnegative(obj.produto.id_stock)

                                    }
                                } catch (error) {
                                    console.log('switch sucesso 1handler_remover_produto error', error)
                                }

                                break;
                            default:
                                break;
                        }
                    };
                } catch (error) {
                    console.log('nao_cativar_prod error', error)
                }

            }

        } catch (error) {
            console.log('handler_remover_produto eror', error)
        }
    }

    const alert_handler_remover_produto = (el) => {
        try {
            return (
                Swal.fire({
                    title: '',
                    text: language[lang].label_eliminar_produto,
                    icon: 'question',

                    showCancelButton: true,
                    confirmButtonColor: '#0095e8',
                    cancelButtonColor: '#d9214e',

                    confirmButtonText: language[lang].label_confirmar,
                    cancelButtonText: language[lang].label_cancelar
                })
                    .then((result) => {
                        console.log('Swal1', result)
                        if (result.isConfirmed === true) {
                            handler_remover_produto(el.info)
                        }
                    })
            )
        } catch (error) {
            console.log('alert_handler_remover_produto error', error)
        }
    }

    const aux_func = async () => {
        try {
            let aux_dia = set_dia_selecionado_func(get_data_func(), set_dia_selecionado)
            set_state_loader(true)
            set_dia_selecionado_string(get_format_dia_correct(aux_dia))

            //let data = get_data_func()
            //set_dia_selecionado_func(data)
            let informacao_prod = await get_prod_por_id()

            await set_info_by_date(informacao_prod, aux_dia)
        } catch (error) {
            console.log('error aux_func1', error)
        }
    }

    useEffect(() => {
        (async () => {

            let temp_token_info = await usarToken().getToken()
            let token_info = JSON.parse(temp_token_info)

            if(token_info !== undefined && token_info !== null) {
                if(token_info.user_info.profile !== 'Colaborador') {
                    setRedirect(true)
                }

                set_user_profile(token_info.user_info.profile)
            }

            await solve_language()
            await aux_func()

            let list_categories = await lista_de_categorias()
            set_lista_categorias(list_categories)

        })()
        return () => { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        (async () => {

            if (refresh !== 0) {
                set_dia_selecionado_string(get_format_dia_correct(dia_selecionado))
                await set_info_by_date(prod_info, dia_selecionado)

            }
        })()
        return () => {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh])

    return (isloading ?
        <Loader />
    :
        isRedirect ?
            <Redirect to={'/'} replace />
        :
            <>
                <Navbar />
                <Breadcrumb />
                {
                    open_modal_login === true ?
                        <CostumModalLogin lang={lang} set_open_modal_login={set_open_modal_login}></CostumModalLogin>
                        :
                        null
                }
                <div id='id_div_pai' className="d-flex position-relative- overflow-hidden-">
                    <div className={get_lista_carrinho().length > 0 ? 'div1_parcialmente_aberto' : 'div1_totalmente_aberto'}>

                        <div className="container">
                            <div className='row'>

                                <div className='col-12 mt-5'>
                                    {
                                    lista_categorias.length !== 0 ?
                                        <div className='d-flex justify-content-center mb-4'>
                                            {
                                            lista_categorias.map((el, key) => {
                                                return (
                                                    <Link key={key} className={'btn btn-primary mr-2' + (el.codgrupo.toLowerCase() === selected_categoria.toLocaleLowerCase() ? ' active' : '')} to={{ pathname: '/desk', item: {codgrupo: el.codgrupo} }} replace>
                                                        {translate_generic_store(el.translate, lang)}
                                                    </Link>
                                                )
                                            })
                                            }
                                        </div>
                                    :
                                        null
                                    }
                                </div>

                                <div className='col-12 col-md-7 pt-3'>
                                    <div className='container_slider'>

                                        {
                                            lista_imagens.length > 0 ?
                                                <GenericSlider list_imagens={lista_imagens} />
                                                :

                                                <AssecImage
                                                    linkImage={repo_img_link('nophoto.webp')}
                                                    width="700"
                                                    height="350"
                                                    fit='crop'
                                                    type='img'
                                                    sClass="img-fluid "
                                                    alt={repo_img_link('nophoto.webp')}
                                                />
                                        }
                                    </div>
                                    <div className='d-flex justify-content-between px-2 mt-2'>
                                        <div>
                                            <i className="bi bi-clock mr-1"></i>
                                            <label>
                                                {prod_info.details[nome_empresa].validade_duracao} {language[lang].label_min}
                                            </label>
                                        </div>
                                        <div>
                                            <label className='label_from mr-1'>
                                                {language[lang].label_desde}
                                            </label>
                                            <label className='label_price font-weight-bold'>
                                                {parseFloat(prod_info.total_liquido).toFixed(2)}€
                                            </label>
                                        </div>
                                    </div>
                                    <div className='text-center'>
                                        <label className='label_nome_produto font-weight-bold px-2'>
                                            {translate_generic_store(prod_principal_da_composicao.descricao.nome, lang)}
                                            <br />
                                            <small>{translate_generic_store(prod_principal_da_composicao.descricao.observacao, lang)}</small>
                                        </label>
                                    </div>
                                    <div>
                                        {ReactHtmlParser(translate_generic_store(prod_principal_da_composicao.descricao.descricao, lang))}
                                    </div>
                                </div>
                                <div className='col-12 col-md-5 pt-3'>
                                    <div className='text-center pb-3'>
                                        <h3>
                                            {language[lang].label_disponibilidade}
                                        </h3>
                                    </div>
                                    <div className='text-center pb-3'>

                                        <Calendar
                                            onActiveStartDateChange={(e) => {
                                                set_horario_from_bd([])
                                                set_horas_manha([])
                                                set_horas_tarde([])
                                            }}
                                            onChange={(e) => {
                                                //console.log('e', e)
                                                // handle_select_diferent_date(e)
                                                set_state_loader(true)
                                                set_refresh(refresh + 1)
                                                set_dia_selecionado_func(moment(e).format('YYYY-MM-DDTHH:mm:ss'), set_dia_selecionado)
                                                //set_info_by_date(prod_info, dia_selecionado)
                                            }}
                                            // value={date}
                                            minDate={new Date()}
                                            formatShortWeekday={(locale, value) => lang === 'en' ? dias_da_semana_short_en[value.getDay()] : lang === 'es' ? dias_da_semana_short_es[value.getDay()] : lang === 'fr' ? dias_da_semana_short_fr[value.getDay()] : dias_da_semana_short_pt[value.getDay()]}
                                            locale={lang === 'en' ? 'en-US' : (lang === 'es' ? 'es-ES' : 'pt-PT')}
                                        // tileDisabled={({ date, view }) => get_day_status(date, view)}
                                        />
                                        <p className='pt-3'>
                                            <strong>{dia_selecionado_string}</strong>
                                        </p>
                                    </div>
                                    <div>

                                        {
                                            prod_info.codgrupo === 'WEL' ?

                                                <div className='d-flex justify-content-between mb-5'>
                                                    <div className='col-6'>
                                                        <h4>
                                                            {language[lang].label_manha}
                                                        </h4>
                                                        <div className='d-flex flex-column'>
                                                            {
                                                                state_loader === true ?
                                                                    loader_layout() :
                                                                    horas_manha.length === 0 ?
                                                                        aviso_lista_vazia_layout()
                                                                        :
                                                                        horas_manha.sort((a, b) => {
                                                                            let aux1 = a.linha.calendario[0].validade_data_hora_ini
                                                                            let aux2 = b.linha.calendario[0].validade_data_hora_ini
                                                                            if (aux1 < aux2) {
                                                                                return -1;
                                                                            }
                                                                            if (aux1 > aux2) {
                                                                                return 1;
                                                                            }
                                                                            return 0;
                                                                        }).map((el, key) => {
                                                                            return (
                                                                                <button key={key} className='btn btn-primary mb-2' onClick={() => { flag_login_valid === true ? handle_sessao_selecionada(el) : set_open_modal_login(true); console.log('clicaste4') }}>
                                                                                    <p className='mb-0 p-2'>
                                                                                        {el.linha.calendario[0].validade_data_hora_ini + (lang !== 'en' ? 'h' : moment(el.linha.calendario[0].validade_data_hora_ini, 'HH:mm').format('a'))}
                                                                                        <span className='btn_price ml-2'>
                                                                                            {parseFloat(el.linha.total_liquido).toFixed(2)}€
                                                                                        </span>
                                                                                    </p>
                                                                                </button>
                                                                            )
                                                                        })
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <h4>
                                                            {language[lang].label_tarde}
                                                        </h4>
                                                        <div className='d-flex flex-column'>
                                                            {
                                                                state_loader === true ?
                                                                    loader_layout() :
                                                                    horas_tarde.length === 0 ?
                                                                        aviso_lista_vazia_layout()
                                                                        :
                                                                        horas_tarde.sort((a, b) => {
                                                                            let aux1 = a.linha.calendario[0].validade_data_hora_ini
                                                                            let aux2 = b.linha.calendario[0].validade_data_hora_ini
                                                                            if (aux1 < aux2) {
                                                                                return -1;
                                                                            }
                                                                            if (aux1 > aux2) {
                                                                                return 1;
                                                                            }
                                                                            return 0;
                                                                        }).map((el, key) => {
                                                                            return (
                                                                                <button key={key} className='btn btn-primary mb-2' onClick={() => { flag_login_valid === true ? handle_sessao_selecionada(el) : set_open_modal_login(true); console.log('clicaste4') }}>
                                                                                    <p className='mb-0 p-2'>
                                                                                        {el.linha.calendario[0].validade_data_hora_ini + (lang !== 'en' ? 'h' : moment(el.linha.calendario[0].validade_data_hora_ini, 'HH:mm').format('a'))}
                                                                                        <span className='btn_price ml-2'>
                                                                                            {parseFloat(el.linha.total_liquido).toFixed(2)}€
                                                                                        </span>
                                                                                    </p>
                                                                                </button>
                                                                            )
                                                                        })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                state_loader === true ?
                                                    loader_layout()

                                                    :
                                                    horario_from_bd.length === 0 ?
                                                        aviso_lista_vazia_layout()
                                                        :
                                                        <div className='d-flex justify-content-center mb-5'>
                                                            {
                                                                horario_from_bd.sort((a, b) => {
                                                                    let aux1 = a.linha.calendario[0].validade_data_hora_ini
                                                                    let aux2 = b.linha.calendario[0].validade_data_hora_ini
                                                                    if (aux1 < aux2) {
                                                                        return -1;
                                                                    }
                                                                    if (aux1 > aux2) {
                                                                        return 1;
                                                                    }
                                                                    return 0;
                                                                }).map((el, key) => {
                                                                    return (
                                                                        <button key={key} className='btn btn-primary mb-2' onClick={() => { flag_login_valid === true ? handle_stock_selecionado(el) : set_open_modal_login(true); console.log('clicasteq1qqqqq') }}>
                                                                            <p className='mb-0 p-2'>
                                                                                <span className='text-uppercase mr-5'>{language[lang].label_marcar}</span>
                                                                                {el.linha.calendario[0].validade_data_hora_ini + (lang !== 'en' ? 'h' : moment(el.linha.calendario[0].validade_data_hora_ini, 'HH:mm').format('a'))}
                                                                                <span className='mx-2'>às</span>
                                                                                {el.linha.calendario[0].validade_data_hora_fim + (lang !== 'en' ? 'h' : moment(el.linha.calendario[0].validade_data_hora_fim, 'HH:mm').format('a'))}
                                                                                <span className='btn_price ml-2'>
                                                                                    {parseFloat(el.linha.total_liquido).toFixed(2)}€
                                                                                </span>
                                                                            </p>
                                                                        </button>
                                                                    )
                                                                })
                                                            }
                                                        </div>

                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'div-cart' + (get_lista_carrinho().length > 0 ? '' : ' closed')} >
                        <div className='flex flex-column p-3'>

                            <h3>O seu carrinho</h3>
                            {
                            get_lista_carrinho().length > 0 ?
                                get_lista_carrinho().sort((a, b) => {
                                    let aux1 = a.info.produto.id
                                    let aux2 = b.info.produto.id
                                    if (aux1 < aux2) {
                                        return -1;
                                    }
                                    if (aux1 > aux2) {
                                        return 1;
                                    }
                                    return 0;
                                }).map((el, key) => {
                                    let prod_timestamp = el.info.produto.id_event
                                    //  let prod_principal = el.info.produto.composicao.find((el) => el.principal === true)

                                    return (

                                        <li key={key} className='list-group rounded-0 border-bottom mb-3'>
                                            <div className="card_products_list">
                                                <div className="text-right d-flex justify-content-end">
                                                    <div className='text-nowrap text-truncate mr-3 pb-2'>
                                                        <span className='font-weight-bold'>{translate_generic_store(el.info.produto.linha.subname, (get_linguagem() !== null ? get_linguagem().toLowerCase() : 'pt'))}</span>
                                                        <br />
                                                        <small className='mr-3 font-weight-bold'>
                                                            {translate_generic_store(el.info.produto.linha.observation, (get_linguagem() !== null ? get_linguagem().toLowerCase() : 'pt'))}
                                                        </small>
                                                        <small className='mr-3'>
                                                            {
                                                                el.info.produto.data
                                                            /* el.info.produto.details.goldenrock.dia.split('-')[2] +
                                                            '-' + el.info.produto.details.goldenrock.dia.split('-')[1] +
                                                            '-' + el.info.produto.details.goldenrock.dia.split('-')[0]   */
                                                            }
                                                        </small>
                                                        <small>
                                                            {translate_horas((get_linguagem() !== null ? get_linguagem() : 'PT'),  el.info.produto.linha.details[nome_empresa].validade_data_hora_ini_to_show)}
                                                        </small>
                                                    </div>
                                                    <div className="text-nowrap">
                                                        {el.quantidade === 0 ? '1' : el.quantidade}
                                                        <i className="bi bi-x"></i>
                                                        {isNaN(el.info.produto.linha.total_liquido) === true ? 0 : parseFloat(el.info.produto.linha.total_liquido).toFixed(2).replace('.', ',')} €
                                                        <br />
                                                        <small style={{ fontVariantNumeric: 'tabular-nums' }}>
                                                            {prod_timestamp === 0 || prod_timestamp === 'null' ? '' : handler_countdown(el)}
                                                        </small>
                                                    </div>
                                                    <div className='text-right'>
                                                        <button className='btn btn-secondary btn-sm ml-3 py-0 px-1' onClick={() => { alert_handler_remover_produto(el) }} >
                                                            <i className="bi bi-x"></i>
                                                        </button>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </li>

                                    )

                                })
                            :
                                null
                            }
                        </div>
                        {
                        get_lista_carrinho().length > 0 ?
                            <div className='flex flex-column p-3'>
                                <div >
                                    <a href={Hosts.WEB_SITE_URI + (user_profile === 'Colaborador' ? 'desk/cart' : 'reservation/cart')} className="btn btn-secondary w-100">Ir para o carrinho</a>
                                </div>
                            </div>
                        :
                            null
                        }
                    </div>
                </div>
                <Footer />
            </>
    );

};
export default Service;

