

export function usarToken() { 
  // antigo nome useToken
  // nao é valido pois estava a ser detetado como um hook
  

  const getToken = async () => {
    try {
      const tokenString = localStorage.getItem('@tokengr');
      return tokenString === null ? null : tokenString
    } catch (error) {
      console.log("ficheiro: useToken, funcao: getToken, error:", error)
    }
  };

  const saveToken = async (userToken, userData) => {
    try {
      if (userToken) {
        return localStorage.setItem('@tokengr', JSON.stringify(userToken))
      }
    } catch (error) {
      console.log("ficheiro: useToken, funcao: saveToken, error:", error)
    }
  }


 
  const logout = async () => {
    try {
      localStorage.clear()
      //console.log('localStorage.getIte); logout', localStorage.getItem('@tokengr'))
      return
    } catch (error) {
      console.log("ficheiro: useToken, funcao: logout, error:", error)
    }
  }

  return {
    getToken,
    saveToken,
    logout,
  }



}

