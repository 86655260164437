import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

//import { Fancybox as NativeFancybox } from "@fancyapps/ui/dist/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox.css";


import ReactHtmlParser/*, { processNodes, convertNodeToElement, htmlparser2 }*/ from 'react-html-parser';
import { Helmet } from 'react-helmet';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

import * as Hosts from '../../../Hosts';
import { reqGET, content_language} from '../../../Utils';

import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import Loader from '../../../loader';
import Preview from '../Preview/Preview';
import Breadcrumb from '../Breadcrumb/Breadcrumb';

import AssecImage from "../ASSEC_Image/Image"

export default function Normal(props) {
    const { pathname } = useLocation();

    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState(localStorage.getItem('lang'));

    const [lang_id, set_lang_id] = useState('1');

    const data_interface = {
        id: '',
        info: {},
        content: {}
    }
    const [content, set_content] = useState(data_interface);
    const [/*gallery*/, set_gallery] = useState([]);
    //const [documents, set_documents] = useState([]);

    useEffect(() => {

        set_lang(localStorage.getItem('lang'))
        content_language.forEach(el => {
            if (el.code === localStorage.getItem('lang')) {
            set_lang_id(el.id)
            }
        })
        
        try {
			let id = props.info.state.id;
			let slug = props.info.state.slug;
			let preview_id = props.info.state.preview;

            if(id !== '') {
        
                let getPreviewInfoId = async () => {
                    await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${preview_id}`)
                        .then(res => { 
                            if(res.data.length > 0) {
                                res.data[0].info['preview'] = true

                                var hoje = new Date();
                                let status = 0
                                if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                                    var date_p = new Date(res.data[0].info.date.published);
        
                                    status = 1
                                    if(hoje >= date_p) 
                                        status = 2
                                }
       
                                if(res.data[0].info.preview === true) {
                                    //res.data[0].content = {text: 'preview'}
        
                                    set_content(res.data[0])
                                    setLoading(false)   
                                }
                                else if(status !== 2) {
                                    res.data[0].content = {text: 'rascunho ou agendado'}
        
                                    set_content(res.data[0])
                                    setLoading(false)    
                                }
                                else {
                                    set_content(res.data[0]) 
        
                                    if(res.data[0].content['gallery'] !== undefined && res.data[0].content['gallery'] !== '') {
                                        reqGET(`${Hosts.SIMCore_API}/gallery/${Hosts.companycode}/${res.data[0].content['gallery']}`)
                                            .then(res => {
                                                set_gallery(res.data[0].info.media);
                                            })
                                            .catch(error => console.log(error)/*TODO: GO TO FAIL FETCH*/)
                                    }
            
                                    setLoading(false) 
                                }
                            }
                        })
                        .catch(erro => console.log('ERROR', erro))
                }

                let getInfoId = async () => {
                    await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${id}`)
                        .then(res => { 
                            if(res.data.length > 0) {
        
                                var hoje = new Date();
                                let status = 0
                                if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                                    var date_p = new Date(res.data[0].info.date.published);
        
                                    status = 1
                                    if(hoje >= date_p) 
                                        status = 2
                                }
        
                                if(res.data[0].info.preview === true) {
                                    set_content(res.data[0])
                                    setLoading(false)   
                                }
                                else if(status !== 2) {
                                    res.data[0].content = {text: 'rascunho ou agendado'}
        
                                    set_content(res.data[0])
                                    setLoading(false)    
                                }
                                else {
                                    set_content(res.data[0]) 
        
                                    if(res.data[0].content['gallery'] !== undefined && res.data[0].content['gallery'] !== '') {
                                        reqGET(`${Hosts.SIMCore_API}/gallery/${Hosts.companycode}/${res.data[0].content['gallery']}`)
                                            .then(res => {
                                                set_gallery(res.data[0].info.media);
                                            })
                                            .catch(error => console.log(error)/*TODO: GO TO FAIL FETCH*/)
                                    }
            
                                    setLoading(false) 
                                }
                            }
                        })
                        .catch(erro => console.log('ERROR', erro))
                }
            
                let getInfoSlug = async () => {
                    reqGET(`${Hosts.SIMCore_API}/web/content/slug/${Hosts.companycode}/${slug}`)
                        .then(res => { 
                            if(res.data.length > 0) {
        
                                var hoje = new Date();
                                let status = 0
                                if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                                    var date_p = new Date(res.data[0].info.date.published);
        
                                    status = 1
                                    if(hoje >= date_p) 
                                        status = 2
                                }
        
                                if(status !== 2)  {
                                    res.data[0].content = [{text: 'rascunho ou agendado'}]
        
                                    set_content(res.data[0])
                                    setLoading(false)
                                }
                                else {
                                    if(res.data[0].content['gallery'] !== undefined && res.data[0].content['gallery'] !== '') {
                                        reqGET(`${Hosts.SIMCore_API}/gallery/${Hosts.companycode}/${res.data[0].content['gallery']}`)
                                            .then(res => {
                                                set_gallery(res.data[0].info.media);
                                            })
                                            .catch(error => console.log(error)/*TODO: GO TO FAIL FETCH*/)
                                    }
        
                                    set_content(res.data[0]) 
        
                                    setLoading(false) 
                                }
                            }
                        })
                        .catch(erro => console.log('ERROR', erro))
                }

                if(preview_id !== undefined && preview_id !== 'undefined' && preview_id !== '') {
                    getPreviewInfoId()
                }
                else if(id !== undefined && id !== 'undefined' && id !== '') {
                    getInfoId()
                }
                else if(slug !== undefined && slug !== 'undefined' && slug !== '')  {
                    getInfoSlug()
                }
            }
            else {
                console.log('=== ""')
            }

            return

        } catch {
            console.log('nada')

			return
		}
        
    }, [props.info.state.id, props.info.state.slug, props.info.state.preview]);

    const get_seo_data = (type) => {

        if(type === 'title') {
            if(content.info.seo !== undefined && content.info.seo['title'] !== undefined  && content.info.seo['title'] !== '') {
                return content.info.seo['title']
            } 
            else {
                return Hosts.SITES.SIMWEBSITE.DEFAULT_SEO_TITLE
            }
        }
        else if(type === 'description') {
            if(content.info.seo !== undefined && content.info.seo['description'] !== undefined  && content.info.seo['description'] !== '') {
                return content.info.seo['description']
            } 
            else if(content.content !== undefined && content.content.length > 0 && content.content[0]['abstract'] !== undefined && content.content[0]['abstract'] !== undefined  && content.content[0]['abstract'] !== '') {
                return content.content[0]['abstract']
            } 
            else {
                return Hosts.SITES.SIMWEBSITE.DEFAULT_SEO_DESCRIPTION
            }
        }
        if(type === 'keywords') {
            if(content.info.seo !== undefined && content.info.seo['keywords'] !== undefined  && content.info.seo['keywords'] !== '') {
                return content.info.seo['keywords']
            } 
            else {
                return Hosts.SITES.SIMWEBSITE.DEFAULT_SEO_KEYWORDS
            }
        }
        else if(type === 'image') {
            if(content.content !== undefined && content.content.length > 0 && content.content[0]['media'] !== undefined && content.content[0]['media']['image'] !== undefined && content.content[0]['media']['image'].length > 0 && content.content[0]['media']['image'][0]['link'] !== '') {
                return content.content[0]['media']['image'][0]['link']
            } 
            else {
                return Hosts.SITES.SIMWEBSITE.DEFAULT_SEO_IMAGE
            }
        }
        else if(type === 'link') {
            let title_seo = Hosts.WEB_SITE_URI + pathname
            return '.' + title_seo.replace("//", "/");
        }
    }

    return (isloading ?

		<Loader />

		:
        <>
            {
            content.info['preview'] === true ? 
                <Preview />
            : 
                null
            }
            <Navbar />
            <Breadcrumb />
            <main className="normal my-3 mb-md-5">
                <div>
                    <Helmet>
                        <title>{ get_seo_data('title') }</title>
                        <meta property="og:url" content={ get_seo_data('link') } />
                        <meta property="og:title" content={ get_seo_data('title') }></meta>
                        <meta name="twitter:title" content={ get_seo_data('title') }></meta>
                        <meta name="description" content={ get_seo_data('description') } />
                        <meta property="og:description" content={ get_seo_data('description') }></meta>
                        <meta name="twitter:description" content={ get_seo_data('description') }></meta>
                        <meta name="keywords" content={ get_seo_data('keywords') } />
                        <meta property="og:image" content={ get_seo_data('image') }></meta>
                        <meta name="twitter:image" content={ get_seo_data('image') }></meta>
                    </Helmet>
                </div>

                {
                content.content.map((el, k) => {  
                    return (
                        <section key={k} className="content-body">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        {
                                            lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['image'] !== undefined && el.language[lang_id]['media']['image'].length > 0 ?

                                                <div className="div-image"> 
                                                    <AssecImage 
                                                        linkImage={el['language'][lang_id]['media']['image'][0]['link']}
                                                        width="1280"
                                                        height="720"
                                                        fit='crop'
                                                        type='img'
                                                        sClass="img-fluid" 
                                                        alt={el['language'][lang_id]['media']['image'][0]['name']}
                                                    />
                                                    {/*<img src={el['language'][lang_id]['media']['image'][0]['link']} className="img-fluid" alt={el['language'][lang_id]['media']['image'][0]['name']} />*/}
                                                </div>

                                            :

                                                el['media'] !== undefined && el['media']['image'] !== undefined && el['media']['image'].length > 0 ? (
                                                    <div className="div-image mb-3 mb-md-5 mt-3">
                                                        <AssecImage 
                                                            linkImage={el['media']['image'][0]['link']}
                                                            width="1280"
                                                            height="720"
                                                            fit='crop'
                                                            type='img'
                                                            sClass="img-fluid" 
                                                            alt={el['media']['image'][0]['name']}
                                                        />
                                                        {/*<img src={el['media']['image'][0]['link']} className="img-fluid" alt={el['media']['image'][0]['name']} />*/}
                                                    </div>

                                                ) : null 

                                        }

                                        {
                                            <div className="div-content my-3">
                                                <h1 className={el.title !== '' ? "titulo-primario my-3" : "d-none"}>
                                                    {lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title)}
                                                </h1>
                                                { 
                                                    el.subtitle !== '' ?
                                                        <h2 className={el.subtitle !== '' ? "titulo-secundario mb-3" : "d-none"}>
                                                            {lang === 'pt' || el.language === undefined ? el.subtitle : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].subtitle === undefined || el.language[lang_id].subtitle === '' ? el.subtitle : el.language[lang_id].subtitle)}
                                                        </h2>
                                                    :
                                                        ''
                                                }
                                                { 
                                                    el.text !== '' ?
                                                        <div className="div-text">
                                                            {ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.text : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].text === undefined || el.language[lang_id].text === '' || el.language[lang_id].text === '<p></p>\n' ? el.text : el.language[lang_id].text))}
                                                        </div>
                                                    :
                                                        ''
                                                }
                                            </div>
                                        }

                                        {
                                            lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['image'] !== undefined && el.language[lang_id]['media']['image'].length > 1 && 0 ?
                                                <div className="div-content my-3">
                                                    <div className="row div-gallery">
                                                        {
                                                        el.language[lang_id]['media']['image'].slice(1).map((el_img, k_img) => {
                                                            return (
                                                                <div key={k_img} className="col-2">
                                                                    <figure>
                                                                        <AssecImage 
                                                                            linkImage={el_img.link}
                                                                            width="450"
                                                                            height="300"
                                                                            fit='crop'
                                                                            type='img'
                                                                            sClass="img-fluid" 
                                                                            alt={el_img.name}
                                                                        />
                                                                        {/*<img className="img-fluid" src={el_img.link} alt={el_img.name} title={el_img.name} />*/}
                                                                        <figcaption>{el_img.description}</figcaption>
                                                                    </figure>
                                                                </div>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                </div>
                                            :
                                                el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 1 && 0 ? (
                                                    <div className="div-content my-3">
                                                        <div className="row div-gallery">
                                                            {
                                                            el.media.image.slice(1).map((el_img, k_img) => {
                                                                return (
                                                                    <div key={k_img} className="col-2">
                                                                        <figure>
                                                                            <AssecImage 
                                                                                linkImage={el_img.link}
                                                                                width="450"
                                                                                height="300"
                                                                                fit='crop'
                                                                                type='img'
                                                                                sClass="img-fluid" 
                                                                                alt={el_img.name}
                                                                            />
                                                                            {/*<img className="img-fluid" src={el_img.link} alt={el_img.name} title={el_img.name} />*/}
                                                                            <figcaption>{el_img.description}</figcaption>
                                                                        </figure>
                                                                    </div>
                                                                )
                                                            })
                                                            }
                                                        </div>
                                                    </div>
                                                ) : null 
                                        }
                                        {
                                            lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['doc'] !== undefined && el.language[lang_id]['media']['doc'].length > 0 ?
                                                <div className="div-content my-3">
                                                    <div className="row div-documents">
                                                        {
                                                        el.language[lang_id]['media']['doc'].map((el_doc, k_doc) => {
                                                            return (
                                                                <div key={k_doc} className="col-12 mb-2">
                                                                    {/* <img className="img-fluid" src={el_doc.link} title="" /> */}
                                                                    
                                                                    <a href={el_doc.link} rel="noreferrer" target="_blank">
                                                                        <FontAwesomeIcon className="font-weight-small pr-2" icon={faFilePdf} fixedWidth />
                                                                        {el_doc.name}
                                                                    </a>
                                                                </div>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                </div>
                                            :
                                                el['media'] !== undefined && el['media']['doc'] !== undefined && el.media.doc.length > 0 ? (
                                                    <div className="div-content my-3">
                                                        <div className="row div-documents">
                                                            {
                                                            el.media.doc.map((el_doc, k_doc) => {
                                                                return (
                                                                    <div key={k_doc} className="col-12 mb-2">
                                                                        {/* <img className="img-fluid" src={el_doc.link} title="" /> */}
                                                                        
                                                                        <a href={el_doc.link} rel="noreferrer" target="_blank">
                                                                            <FontAwesomeIcon className="font-weight-small pr-2" icon={faFilePdf} fixedWidth />
                                                                            {el_doc.name}
                                                                        </a>
                                                                    </div>
                                                                )
                                                            })
                                                            }
                                                        </div>
                                                    </div>
                                                ) : null 
                                        }
                                    </div>
                                </div>
                            </div>
                        </section>
                    )
                })
                }
            </main>
            <Footer />
        </>
    )
}