import React, {useState, useEffect, useRef } from 'react';
import DataTable from 'react-data-table-component';
// import DataTableExtensions from "react-data-table-component-extensions";
// import "react-data-table-component-extensions/dist/index.css";

import "../Pagina.css";
import "../content-styles.css"

import Swal from 'sweetalert2'

import * as Hosts from '../../../Hosts';
import {
    //set_language,
	reqGET,
	// reqPOST,
	// reqPUT,
    content_language,
    // passwordHash,
    // upgrade_cart,
    // my_local_storage,
    // repo_site_assets_link,
    // functionSendMail,

} from '../../../Utils';

import { getTokenInfo } from '../../../UseToken';

import Loader from '../../../loader'

import Navbar from "../Navbar/Navbar";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Footer from "../Footer/Footer";

import { language } from './Language';
import useContextProvider from '../GenericStore/Providers/ContextProvider';

export default function Encomendas(props) {
    const { get_id_user } = useContextProvider()

    const nota_de_encomenda = useRef({});
    const user_info = useRef({});
    // const encomendas = useRef(null);
    const [encomendas, set_encomendas] = useState([]);

	const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState('pt');
    const [lang_id, set_lang_id] = useState('1');
    
    useEffect(() => {

        let getClientOrders = async (id_user) => {
            return await reqGET(`${Hosts.SIMCore_API}/store/order/client/${Hosts.companycode}/${id_user}`)
                .then(res => {

                    set_encomendas(res.data)

                    setLoading(false)

                })
                .catch(() => 404
            )
        }
        if (get_id_user() !== undefined && get_id_user() !== null && get_id_user() !== '') {
            getClientOrders(get_id_user())
        }

        if(localStorage.getItem('lang') === undefined  || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if(el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }

    }, [props]);

    let translate = (key_list, lang) => {
        let res = ''
        try {
            res = key_list.filter(e => e[lang])[0][lang]
            console.log('Translate:',key_list,':',lang,'=', res)
            return res
        } 
        catch (error) {
            return res
        }	
    }

    const abrirEncomenda = async (id_order) => {
        console.log('id_order', id_order)

        let order_aux = await reqGET(`${Hosts.SIMCore_API}/get/order/id/encomenda//${id_order}`)
        console.log('order_aux', order_aux)


        let contentHtml = ''
        contentHtml +=  '<div class="col-12">'
        contentHtml +=      '<div class="row">'
        contentHtml +=          '<div class="col-12 text-left mb-4">'
        contentHtml +=              '<strong>' + language[lang].field_n_encomenda + ':</strong> ' + order_aux.data[0].id + '<br>'
        // contentHtml +=              '<strong>' + language[lang].field_name + ':</strong> ' + order_aux.data[0].info.documento.user_info.name + '<br>'
        // contentHtml +=              '<strong>' + language[lang].field_email + ':</strong> ' + order_aux.data[0].info.documento.user_info.username + '<br>'
        // contentHtml +=              '<strong>' + language[lang].field_vat + ':</strong> ' + order_aux.data[0].info.documento.user_info.vat + '<br>'
        contentHtml +=              '<strong>' + language[lang].field_date + ':</strong> ' + (order_aux.data[0].info.timestamp.split('T')[0] + ' ' + order_aux.data[0].info.timestamp.split('T')[1].split(':')[0] + ':' + order_aux.data[0].info.timestamp.split('T')[1].split(':')[1]) + '<br>'
        contentHtml +=          '</div>'
        contentHtml +=          '<div class="col-12 mb-4">'
        contentHtml +=              '<table class="table table-striped">'
        contentHtml +=                  '<thead>'
        contentHtml +=                      '<tr>'
        contentHtml +=                          '<td align="left" style="width: 50%;"><strong>' + language[lang].field_name + '</strong></td>'
        contentHtml +=                          '<td align="center"><strong>' + language[lang].field_qtd + '</strong></td>'
        contentHtml +=                          '<td align="right"><strong>' + language[lang].vat + '</strong></td>'
        contentHtml +=                          '<td align="right"><strong>' + language[lang].field_total + '</strong></td>'
        contentHtml +=                      '</tr>'
        contentHtml +=                  '</thead>'
        contentHtml +=                  '<tbody>'

                        order_aux.data[0].info.documento.linhas.forEach((el) => {
                            contentHtml +=  '<tr class="align-middle">'
                            contentHtml +=      '<td align="left">'
                            contentHtml +=          '<strong>' + el.designacao + '</strong><br />' 
                            contentHtml +=          '<strong>' 
                            contentHtml +=              translate(el.observation, 'pt') + '&nbsp;&nbsp;&nbsp;'
                            contentHtml +=          '</strong>' 
                            contentHtml +=          el.details.goldenrock.validade_data_hora_ini.split('T')[0].split('-')[2] +
                                                    '-' + el.details.goldenrock.validade_data_hora_ini.split('T')[0].split('-')[1] +
                                                    '-' + el.details.goldenrock.validade_data_hora_ini.split('T')[0].split('-')[0] +
                                                    '&nbsp;&nbsp;&nbsp;' +
                                                    el.details.goldenrock.validade_data_hora_ini.split('T')[1] + 'h'
                            contentHtml +=      '</td>'

                            contentHtml +=      '<td align="center" style="font-family: Open Sans, sans-serif; font-variant-numeric: tabular-nums; vertical-align: middle;">' + el.quantidade + '</td>'
                            
                            contentHtml +=      '<td align="right" style="font-family: Open Sans, sans-serif; font-variant-numeric: tabular-nums; vertical-align: middle;">' + parseFloat(el.taxa_iva).toFixed(0) + '%</td>'

                            contentHtml +=      '<td align="right" style="font-family: Open Sans, sans-serif; font-variant-numeric: tabular-nums; vertical-align: middle;">' + parseFloat(el.total_liquido).toFixed(2) + '€</td>'
                            contentHtml += '</tr>'
                        })
        
        contentHtml +=                  '</tbody>'
        contentHtml +=                  '<tfoot>'
        contentHtml +=                      '<tr class="text-uppercase" style="line-height: 30px;">'
        contentHtml +=                          '<td>' + '</td>'
        contentHtml +=                          '<td>' + '</td>'
        contentHtml +=                          '<td align="right"><strong>' + language[lang].vat + '</strong></td>'
        contentHtml +=                          '<td align="right" style="font-family: Open Sans, sans-serif; font-variant-numeric: tabular-nums;">' + parseFloat(order_aux.data[0].info.documento.total_iva_documento).toFixed(2) + '€</td>'
        contentHtml +=                      '</tr>'
        contentHtml +=                      '<tr class="text-uppercase">'
        contentHtml +=                          '<td>' + '</td>'
        contentHtml +=                          '<td>' + '</td>'
        contentHtml +=                          '<td align="right"><strong>' + language[lang].field_total + '</strong></td>'
        contentHtml +=                          '<td align="right" style="font-family: Open Sans, sans-serif; font-variant-numeric: tabular-nums;">' + parseFloat(order_aux.data[0].info.documento.total_documento).toFixed(2) + '€</td>'
        contentHtml +=                      '</tr>'
        contentHtml +=                  '</tfoot>'
        contentHtml +=              '</table>'
        contentHtml +=          '</div>'

        contentHtml +=          '<div class="col-12">'
        contentHtml +=              '<div class="row">'

                            order_aux.data[0].info.qr.forEach((el) => {
                                console.log('el', el)
                                contentHtml +=  '<div class="col-4">'
                                    contentHtml +=  '<span>'
                                    contentHtml +=  '<strong><small>' + (translate(el.title, 'pt')) + '</small></strong><br />'
                                    contentHtml +=  '<strong><small>' + (translate(el.observation, 'pt')) + '&nbsp;</small></strong>'
                                    contentHtml +=  '</span>'
                                    contentHtml +=  '<img class="img-fluid" src="' + el.svg + '" />'
                                contentHtml +=  '</div>'
                            })

        contentHtml +=              '</div>'
        contentHtml +=          '</div>'
        contentHtml +=      '</div>'
        contentHtml +=  '</div>'


        Swal.fire({
            title: "<strong>" + language[lang].contact_form + "</strong>",
            width: '50vw',
            maxWidth: '500px',
            icon: "",
            html: contentHtml,
            showCloseButton: false,
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: language[lang].close
          });

    }

    const columns = [
        {
            name: language[lang].field_n_encomenda,
            selector: 'id',
            sortable: true,
            center: true,
            minWidth: '100px',
            maxWidth: '15%',
        },
        {
            name: language[lang].field_date,
            selector: 'info.data',
            sortable: true,
            center: true,
            minWidth: '100px',
            maxWidth: '15%',
        },
        {
            name: language[lang].field_name,
            selector: 'info.documento.user_info.name',
            sortable: true,
            left: true,
            minWidth: '200px',
            maxWidth: '45%',
        },
        {
            name: language[lang].field_total,
            selector: 'info.documento.total_documento',
            cell: row => 
                <span style={{fontFamily: 'Open Sans, sans-serif', fontVariantNumeric: "tabular-nums"}}>
                    {parseFloat(row.info.documento.total_documento).toFixed(2) + ' €'}
                </span>
            ,
            sortable: true,
            right: true,
            minWidth: '100px',
            maxWidth: '10%',
        },
        {
            name: language[lang].field_pagamento_pendente,
            selector: 'info.pagamento_pendente',
            cell: row => (
                parseInt(row.info.pagamento_pendente) === 1 ? 
                    <span className="badge rounded-0 bg-success p-2"><i className="bi bi-check-lg text-white lead"></i></span> 
                : 
                    parseInt(row.info.pagamento_pendente) === 2 ? 
                            <span className="badge rounded-0 bg-primary p-2"><i className="bi bi-check-lg text-white lead"></i></span> 
                        : 
                            parseInt(row.info.pagamento_pendente) === 0 ? 
                                <span className="badge rounded-0 bg-warning p-2"><i className="bi bi-exclamation-lg text-dark lead"></i></span> 
                            : 
                                parseInt(row.info.pagamento_pendente) === -2 ? 
                                    <span className="badge rounded-0 bg-secondary p-2"><i className="bi bi-arrow-return-left text-white lead"></i></span>
                                :
                                    <span className="badge rounded-0 bg-danger p-2"><i className="bi bi-x-lg text-white lead"></i></span>
            ),
            sortable: false,
            center: true,
            minWidth: '100px',
            maxWidth: '10%',
        },
        {
            name: '',
            right: true,
            export: false,
            print: false,
            minWidth: '50px',
            maxWidth: '5%',
            cell: row => 
                <span onClick={() => abrirEncomenda(row.id)}>
                    <i className="bi bi-eye lead"></i>
                </span>
        },
    ]

    const defaultComponentOptions = {
        rowsPerPageText: language[lang].table_rows_per_page,
        rangeSeparatorText: language[lang].table_separator,
        noRowsPerPage: false,
        selectAllRowsItem: false,
        selectAllRowsItemText: language[lang].table_all        
    }

    return (
        isloading ?
            <Loader />
    :
        <>
            <Navbar /> 

            <Breadcrumb /> 

            <div id="cart" className="container-fluid my-5">
                <div className="row">
                    <div className="container">

                        <div className='row'>
                            <div className='col-12'>

                                <div className={"table-body" + (encomendas === undefined || encomendas === null || encomendas.length === 0 ? ' py-5 my-5' : '')}>
                                    <div className={(encomendas === undefined || encomendas === null || encomendas.length === 0 ? ' py-5 my-5' : '')}>
                                        <DataTable
                                            columns={columns}
                                            data={encomendas}
                                            noDataComponent={language[lang].table_empty}
                                            noHeader
                                            pagination
                                            exportHeaders
                                            paginationComponentOptions={defaultComponentOptions}
                                            paginationPerPage={25}
                                            paginationRowsPerPageOptions={[25, 50, 100]}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            
            <Footer />
        </>

    );
}

