//import { createHash } from 'crypto';
//const crypto = require('crypto');
import * as crypto from 'crypto';
import * as Hosts from '../src/Hosts'
//export const Hosts = require('../src/Hosts');

import axios from 'axios';

export const requests = async (url, request_type, body_data) => {

    let localtoken = (JSON.parse(localStorage.getItem('token_site')))
    //console.log('TOKEN NO UTILS:', localtoken)
    const requestOptions = {
        method: request_type,

        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + (localtoken === null ? '' : Hosts.SITES.SIMWEBSITE.ACCESS_TOKEN)
        },
        body: JSON.stringify(body_data)

    }
    //console.log(localtoken)
    return await fetch(url, requestOptions).then(response => response.json())
    //.then(res => { //console.log(res); return res })
}

export const requests_file = async (url, request_type, body_data) => {

    let localtoken = (JSON.parse(localStorage.getItem('token_site')))
    //console.log('TOKEN NO UTILS:', localtoken)
    const requestOptions = {
        method: request_type,
        headers: {
            'Authorization': 'Bearer ' + (localtoken === null ? '' : Hosts.SITES.SIMWEBSITE.ACCESS_TOKEN)
        },
        body: body_data

    }
    //console.log(localtoken)
    return await fetch(url, requestOptions).then(response => response.json())
    //.then(res => { //console.log(res); return res })
}

export const simpleAxiosGet = async (url, path) => {

    return await axios.get(url + path)
        .then(response => response.data)
        .then(res => {
            // console.log('res', res)
            return res
        })
        .catch(erro => {
            console.log('ERROR1', erro)  
            return ''
        })

}

export const reqGET = async (url) => {
    return await requests(url, 'GET')
}

export const reqPOST = async (url, data) => {
    return await requests(url, 'POST', data)
}

export const reqPUT = async (url, data) => {
    return await requests(url, 'PUT', data)
}

export const reqDELETE = async (url, data) => {
    return await requests(url, 'DELETE', data)
}

export const reqFILE = async (url, data) => {
    return await requests_file(url, 'POST', data)
}

export const load_countries_pt = async () => {
    return await reqGET(`${Hosts.SIMCore_API}/countries/langpt/list`)
}

export const load_districts_pt = async () => {
    return await reqGET(`${Hosts.SIMCore_API}/countries/pt/districts`)
}

export const load_cities_of_district_pt = async district => {
    return await reqGET(`${Hosts.SIMCore_API}/countries/pt/citiesofdistrict/${district}`)
}

export const load_district_of_city_pt = async city => {
    return await reqGET(`${Hosts.SIMCore_API}/countries/pt/districtofcity/${city}`)
}

export const load_freguesias_pt = async city => {
    return await reqGET(`${Hosts.SIMCore_API}/countries/pt/villagesofcity/${city}`)
}

export const get_vat_info = async vat => {
    return await reqGET(`${Hosts.SIMCore_API}/vat/${vat}`)
}


export const select_option_util_fun = (arg, set_fun, field) => {
    set_fun(...[arg.map(k => ({ value: k[field], label: k[field] }))])
}

export const select_regist_util_fun = (arg, field) => {
    return arg[0][field]
}


export const obter_cidades_por_distrito = (distrito, set_fun) => load_cities_of_district_pt(distrito).then(res => select_option_util_fun(res.data, set_fun, 'langpt_cc'))

export const obter_distrito_por_cidade = (cidade) => load_district_of_city_pt(cidade).then(res => { return select_regist_util_fun(res.data, 'langpt_dt') })

export const obter_freguesias_por_cidade = (cidade, set_fun) => load_freguesias_pt(cidade).then(res => select_option_util_fun(res.data, set_fun, 'langpt_fr'))

export const obter_paises_em_pt = set_fun => load_countries_pt().then(res => select_option_util_fun(res.data, set_fun, 'langpt'))

export const obter_distritos_pt = set_fun => load_districts_pt().then(res => select_option_util_fun(res.data, set_fun, 'langpt_dt'))


export const passwordHash = password => crypto.createHash('md5').update(password).digest("hex")
export const gerarPassword = () => {
    return Math.random().toString(36).slice(-10);
}

export const pattern_vat =
    '^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$'

export const pattern_email = '^[a-z0-9._%+\\-]+@[a-z0-9.\\-]+.[a-z]{2,4}$';

export const set_language = (lang) => {
    localStorage.setItem('lang', lang);
}

export const content_language = [
    {
        id: '1',
        code: 'pt'
    },
    {
        id: '2',
        code: 'en'
    },
    {
        id: '3',
        code: 'es'
    },
    {
        id: '4',
        code: 'fr'
    }
];

// REPO UTILS
export const repo_base_link = (resource) => Hosts.REPO + "static/assets/" + resource + "/";

export const repo_site_link = (resource) => Hosts.REPO + "static/site/" + resource + "/";

export const repo_favicon_link = (resource) => repo_base_link("favicon") + resource;
export const repo_hero_link = (resource) => repo_base_link("hero") + resource;
export const repo_logo_link = (resource) => repo_base_link("logo") + resource;
export const repo_img_link = (resource) => repo_base_link("img") + resource;
export const repo_banner_link = (resource) => repo_base_link("banner") + resource;


export const repo_site_assets_link = (resource) => repo_site_link("assets") + resource;
export const repo_site_documents_link = (resource) => repo_site_link("documents") + resource;

export const my_local_storage = {
    getItem(key) {
        return localStorage.getItem(key)
    },
    setItem(key, value, expiry) {
        // if the expiry time is 0, it means there is no need to add the item
        if (expiry === 0) 
            return

        localStorage.setItem(key, value)
        let timer;

        if(expiry) {
            timer = setTimeout(() => {
                this.removeItem(key);
                timer && clearTimeout(timer)
            }, expiry)
        }

    },
    removeItem(key) {
        localStorage.removeItem(key)
    },
    clear() {
        localStorage.clear()
    }
}

export const upgrade_cart = (name, cart) => {
    //    60 000 =   60 segundos =  1 minuto
    // 3 600 000 = 3600 segundos = 60 minutos
    my_local_storage.setItem(name, cart, 3600000)
    //sessionStorage.setItem(name, cart)
}

export const functionSendMail = async (sendFrom, sendTo, sendToCC, subject, content, attachment) => {

    const mailHtmlTop = '<html><head><link rel="preconnect" href="https://fonts.googleapis.com"><link rel="preconnect" href="https://fonts.gstatic.com" crossorigin><link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,700;1,400;1,700&display=swap" rel="stylesheet"> </head><body style="background-color:' + Hosts.EMAIL_BODY_BACKGROUND_COLOR +'; font-family:poppins, helvetica, arial, sans-serif; ">' +
                            '<div style="width:100% !important; margin-top:0; margin-bottom:0; margin-right:0; margin-left:0; padding-top:0; padding-bottom:0; padding-right:0; padding-left:0; color:' + Hosts.EMAIL_TEXT_COLOR +';">' +
                                '<table border="0" align="center" cellpadding="0" cellspacing="0" style="margin-top:0; margin-bottom:0; padding-top:0; padding-bottom:0; padding-right:0; padding-left:0; border-collapse:collapse;">' +
                                    '<tr style="border-collapse: collapse;">' +
                                        '<td style="border-collapse: collapse; font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.EMAIL_TEXT_SIZE +'; line-height:' + Hosts.EMAIL_TEXT_LINE_HEIGHT  +';">' +
                                            '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; background-color:' + Hosts.EMAIL_BACKGROUND_COLOR +'; border:1px solid transparent; border-collapse:collapse;">' +
                                                '<tr style="border-collapse: collapse;">' +
                                                    '<td align="left" class="logo" style="padding:0; border-collapse:collapse;">' +
                                                        '<a alt="' + Hosts.webSiteTitle + '" title="' + Hosts.webSiteTitle + '" href="' + Hosts.EMAIL_WEB_SITE_URL + '" target="_blank">' +
                                                            '<img style="width: 600px; " src="' + repo_banner_link("banner-email.jpg") + '"/>' +
                                                        '</a>'+
                                                    '</td>' +
                                                '</tr>' +
                                            '</table>'

    const mailHtmlBottom =  '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; background-color:' + Hosts.FOOTER_EMAIL_COLOR +'; padding: 10px; border: 1px solid transparent; border-collapse:collapse;">' +
                                '<tr style="border-collapse: collapse;">' +
                                    '<td align="left" style="padding-bottom: 10px; padding-top: 10px; border-collapse:collapse; font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.FOOTER_EMAIL_TEXT_SIZE +'; line-height:' + Hosts.FOOTER_EMAIL_TEX_LINE_HEIGHT  +';">' +
                                        '<div>'+
                                            '<div style="text-align: center; font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.FOOTER_EMAIL_TEXT_SIZE +'; line-height:' + Hosts.FOOTER_EMAIL_TEX_LINE_HEIGHT  +'; color:' + Hosts.FOOTER_EMAIL_TEXT_COLOR +';">'+
                                                '<span style="text-transform: uppercase;">' + Hosts.webSiteTitle + '</span>'+
                                                '<br/>'+
                                                '<span>' + Hosts.webSiteAdress + '</span>'+
                                                '<br/>'+
                                                '<a alt="' + Hosts.webSiteTitle + '" title="' + Hosts.webSiteTitle + '" href="' + Hosts.EMAIL_WEB_SITE_URL + '" target="_blank" style="font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.FOOTER_EMAIL_TEXT_SIZE +'; line-height:' + Hosts.FOOTER_EMAIL_TEX_LINE_HEIGHT  +';"> '+
                                                    '<span style="color:' + Hosts.FOOTER_EMAIL_TEXT_COLOR +';font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.FOOTER_EMAIL_TEXT_SIZE +'; line-height:' + Hosts.FOOTER_EMAIL_TEX_LINE_HEIGHT  +'; text-decoration: underline" >'+ Hosts.EMAIL_WEB_SITE_URL +'</span>' +
                                                '</a>'+
                                            '</div>'+
                                        '</div>' +
                                    '</td>' +
                                '</tr>' +
                            '</table>' +
                        '</td>' +
                    '</tr>' +
                '</table>' +
            '</div>' +
        '<body>' +
    '<html>'

    let mailOptions = {
        from: "Golden Rock - Alambique Water Spa <" + sendFrom + "",
        // vários emails separados por virgulas
        to: sendTo.join(', '),
        //cc-> opcional
        //cc: sendToCC.join(', '),
        bcc: sendToCC.join(', '),
        subject: subject,
        html: ''
    };

    mailOptions.html = mailHtmlTop + content + mailHtmlBottom

    return await reqPOST(`${Hosts.SIMCore_API_MAIL}`, { data: mailOptions })
        .then((res) => {
            // console.log('functionSendMail res', res)
            return true

        })
        .catch((res) => { 
            console.log('functionSendMail res', res)
            return false

        })
}

/*
module.exports = {
    reqGET,
    reqPOST,
    reqPUT,
    reqDELETE,
    reqFILE,

    obter_cidades_por_distrito,
    obter_distrito_por_cidade,
    obter_freguesias_por_cidade,
    obter_paises_em_pt,
    obter_distritos_pt,



    get_vat_info,

    passwordHash,

    pattern_vat,
    pattern_email,

    set_language,

}
*/

