import React, { useState, useEffect } from 'react'
import { useParams, Redirect } from "react-router-dom";

import Loader from '../../../loader';

import * as Hosts from '../../../Hosts';
import { reqGET } from '../../../Utils';
import { getTokenInfo } from '../../../UseToken';

import NormalContent from './Normal';
import NotFound from './NotFound';

//import './Content.css';

export default function Store() {

    const { product_id, slug, preview_id } = useParams();
    const [isloading, setLoading] = useState(true);

    const data_interface = {
        id: '',
        info: {},
        content: {}
    }
    const [content, set_content] = useState(data_interface);

    useEffect(() => {

        let getPreviewInfoId = async (id) => {
            await reqGET(`${Hosts.SIMCore_API}/store/product/${Hosts.companycode}/${id}`)
                .then(res => { 
                    if(res.data.length > 0) {

                        if(getTokenInfo()['profile'] !== '') {
                            res.data[0].info['preview'] = true
                            set_content(res.data[0])
                            setLoading(false)
                        }
                        else {
                        }
                    }
                })
                .catch(erro => console.log('ERROR', erro))
        }

        let getInfoId = async (id) => {
            await reqGET(`${Hosts.SIMCore_API}/store/product/${Hosts.companycode}/${id}`)
                .then(res => { 
                    if(res.data.length > 0) {
                        set_content(res.data[0])
                        setLoading(false)
                    }
                    else {
                        set_content({id: id, info: {type: 'standard', layout: '404'}, content: []})
                        setLoading(false)
                    }
                })
                .catch(erro => console.log('ERROR', erro))
        }
    
        let getInfoSlug = async (slug) => {
            await reqGET(`${Hosts.SIMCore_API}/store/product/slug/${Hosts.companycode}/${slug}`)
                .then(res => { 
                    if(res.data.length > 0) {

                        var hoje = new Date();
                        let status = 0
                        if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                            var date_p = new Date(res.data[0].info.date.published);

                            status = 1
                            if(hoje >= date_p) 
                                status = 2
                        }

                        if(status !== 2) {
                            //res.data[0].content[0] = {text: 'rascunho ou agendado'}
                            res.data[0].info.layout = '404'
                        }
                        set_content(res.data[0])
                                               

                        setLoading(false)
                    }
                    else {
                        //console.log('slug: ', slug)
                        set_content({id: '', info: {type: slug}})
                                               

                        setLoading(false)
                    }
                })
                .catch(erro => console.log('ERROR', erro))
        }

        if(preview_id !== undefined && preview_id !== 'undefined' && preview_id !== '') {
            getPreviewInfoId(preview_id)
               .then(() => {
               })
        }
        else if(product_id !== undefined && product_id !== 'undefined' && product_id !== '') {
            getInfoId(product_id)
        }
        else if(slug !== undefined && slug !== 'undefined' && slug !== '')  {
            getInfoSlug(slug)
        }
        
    }, [product_id, slug, preview_id]);

    return (isloading ?

		<Loader />
		
		:

        (() => {
            switch (content.info.type) {
                case 'standard':
                    switch (content.info.layout) {
                        
                        case '404':
                            console.log('layout', content.info.layout)
                            return (
                                <NotFound info={{ state: { id: product_id, preview: preview_id, slug: slug } }} />
                            ) 

                        default:
                            return (
                                <NormalContent info={{ state: { id: product_id, preview: preview_id, slug: slug } }} />
                            )
                    }

                default:
                    return (
                        <Redirect to="/" />
                    )
            }
   
        })()
    
    )
}
