import React, { useState, useEffect } from 'react';
import "../Pagina.css";
import "../content-styles.css"
import { set_language, content_language, } from '../../../Utils';
import './GenericStore.css';
import Loader from '../../../loader';
import Navbar from "../Navbar/Navbar";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Footer from "../Footer/Footer";
import { language } from './Language';
import { lista_de_categorias, translate_generic_store } from './utils';
import { WEB_SITE_URI } from '../../../Hosts';

const GenericStore = () => {

  


    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState('pt');
    const [, set_lang_id] = useState('1');

    const [lista_categorias, set_lista_categorias] = useState([])


    const set_info_about_lang = () => {
        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt') // é uma funcao
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }
    }

    useEffect(() => {
        (async () => {
            const func_from_useeffect = async () => {
                try {
                    set_info_about_lang()
                    let list_categories = await lista_de_categorias()
                    set_lista_categorias(list_categories)


                    setLoading(false)

                } catch (error) {
                    console.log('func_from_useeffect error', error)
                    setLoading(false)

                }

            }
            await func_from_useeffect()

        })()

    }, []);

    return (isloading ?

        <Loader />

        :
        <>
            <Navbar />

            <Breadcrumb />

            <div id="tarifas" className="container-fluid my-5">
                <div className="row">
                    <div className="container mb-5 d-none">
                        <h3 className="titulo-primario">{language[lang].store_tarifas}</h3>
                    </div>

                    <div className="container">
                        <div className="row">

                            <div className="col-12">
                                <div className='row'>

                                    {/*<div className='col-12 mb-4 d-none'>
                                        <h5 className='titulo-secundario'>
                                            {language[lang].store_tarifas_info}
                                        </h5>
                                    </div>*/}
                                    {
                                        lista_categorias.length !== 0 ?
                                            lista_categorias.map((el, key) => {
                                                let aux_img_x = el.translate.find((ele) => {
                                                    if (ele[lang.toLowerCase()]) {
                                                        return ele
                                                    }
                                                })
                                                let aux_img = aux_img_x !== undefined ? aux_img_x.img : translate_generic_store(el.translate, 'img')
                                                return (
                                                    <div key={key} className="col-12 col-md-6 card border-0 p-2"
                                                    /* onClick={() => {
                                                        history.push('/store/' + el.codgrupo);
                                                    }} */
                                                    >
                                                        <div className="img-fluid card-img-top background-image" style={{ backgroundImage: `url(` + aux_img + `)` }} title={translate_generic_store(el.translate, lang)} ></div>
                                                        <div className="card-body p-3 bg-color-gray-light d-flex w-100 justify-content-between">
                                                            <div className="info">
                                                                <h4 className="card-title product-name">{translate_generic_store(el.translate, lang)}</h4>
                                                                <p className="card-text"></p>
                                                            </div>
                                                            {/* <div className=" btn btn-primary float-right">
                                                                {language[lang].comprar} <i className="bi bi-arrow-right ml-2 p-2"></i>
                                                            </div> */}

                                                            {/* <Link className="btn btn-primary float-right" to={{ pathname: '/store/' + el.codgrupo/* [{ codgrupo: el.codgrupo }]  }} replace>
                                                            {language[lang].comprar} <i className="bi bi-arrow-right ml-2 p-2"></i>
                                                        </Link> */}
                                                            <a href={WEB_SITE_URI + 'store/' + el.codgrupo} className="btn btn-secondary mt-3" >
                                                                {language[lang].comprar} <i className="bi bi-arrow-right ml-2 p-2"></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            <div style={{
                                                backgroundColor: '#e2dbc9',
                                                display: 'flex',
                                                flex: 1,
                                                flexDirection: 'row',
                                                alignContent: 'center',
                                                justifyContent: 'center',
                                                padding: '5%'
                                            }}>
                                                <label>
                                                    {language[lang].label_nao_existe_info}
                                                </label>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Footer />
        </>

    );

};
export default GenericStore;

