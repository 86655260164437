import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import "../Pagina.css";
import "../content-styles.css"

import * as Hosts from '../../../Hosts';
import {
    set_language,
    reqGET,
    content_language,
    upgrade_cart,
    my_local_storage,

} from '../../../Utils';

import './Entrance.css';

import Loader from '../../../loader';
import Navbar from "../Navbar/Navbar";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Footer from "../Footer/Footer";

import { language } from './Language';

import AssecImage from "../ASSEC_Image/Image"

const Entrance = () => {



    const { pathname } = useLocation();

    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState('pt');
    const [, set_lang_id] = useState('1');

    const [services, set_services] = useState([]);

    const [cart, set_cart] = useState({});

    const [refresh, setRefresh] = useState(0)

    let getServices = async () => {

        reqGET(`${Hosts.SIMCore_API}/platform/products/grupo/SPA`)
            .then(res => {
                set_services(res.data)

                setLoading(false)
                // console.log(res.data)
            })
            .catch(erro => console.log(erro))

    }

    useEffect(() => {

        if (my_local_storage.getItem('cart') !== undefined && my_local_storage.getItem('cart') !== null) {
            //console.log('cart', my_local_storage.getItem('cart'))

            let start = new Date(JSON.parse(my_local_storage.getItem('cart')).date).getTime()
            let end = new Date().getTime()

            var msDiff = start - end;
            //var diffdays = Math.floor(msDiff / (1000 * 60 * 60 * 24));
            var diffhours = Math.floor(msDiff / (1000 * 60 * 60));
            if (diffhours > -2) {
                set_cart(JSON.parse(my_local_storage.getItem('cart')))
            }
            else {
                set_cart({})
                my_local_storage.removeItem('cart')
            }
        }

        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }

        getServices()

    }, [pathname]);


    const get_item_cart_value = (id) => {

        let result = 0
        if (cart['entrance'] !== undefined) {
            cart['entrance'].forEach(el => {
                if (el.id === id) {
                    result = el.quantity
                }
            })
        }
        return result

    }

    const change_quantity = (id, e) => {

        //if(cart['date'] === undefined) {
        cart['date'] = new Date()
        //}

        if (cart['entrance'] === undefined)
            cart['entrance'] = []

        if (e >= 0) {
            const aux = cart['entrance'].filter(el => el.id === id)
            if (aux.length > 0) {
                cart['entrance'].forEach(el => {
                    if (el.id === id) {
                        el.quantity = e
                    }
                })
            }
            else {
                cart['entrance'].push({ id: id, quantity: e })
            }
        }

        // console.log('Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«')
        // console.log('cart:' + JSON.stringify(cart))

        upgrade_cart('cart', JSON.stringify(cart))
        set_cart(cart)

        setRefresh(refresh + 1)
    }

    let translate = (key_list, lang) => {
        let res = ''
        // console.log('Translate:',key_list,':',lang,'=', res)
        try {
            res = key_list.filter(e => e[lang])[0][lang]
            return res
        }
        catch (error) {
            return res
        }
    }

    const get_total_composicao = (arr) => {
        let x = 0.00;

        if (arr !== undefined && arr.length > 0) {
            arr.forEach(el => {
                if (el.valores.preco_liquido !== undefined) {
                    // console.log('parseFloat(el.valores.preco_liquido)', parseFloat(el.valores.preco_liquido))
                    if (el.valores.preco_liquido !== '')
                        x = parseFloat(x) + parseFloat(el.valores.preco_liquido)
                    else
                        x = parseFloat(x) + parseFloat(0.00)
                }
            })
        }

        return x
    }


    return (isloading ?

        <Loader />

        :
        <>
            <Navbar />
            <Breadcrumb />

            <div id="entrance" className="container-fluid my-5">
                <div className="row">
                    <div className='col-12'>

                        <div className="container my-5 d-none">
                            <h3 className="titulo-primario">{language[lang].comprar}</h3>
                        </div>

                        <div className="container">
                            <div className="row">

                                <div className="col-12 col-lg-8 table-responsive">
                                    <table>
                                        <thead>
                                            <tr>
                                                <td></td>
                                                <td className="text-right"><h3 className="titulo-secundario p-4 m-0 w-100 bg-color-gray-light">{language[lang].precos}</h3></td>
                                                <td className="text-right"><h3 className="titulo-secundario p-4 m-0 w-100 bg-color-gray-light">{language[lang].quantidade}</h3></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                services.map((el, key) => {
                                                    return (
                                                        <tr key={key} className={key > 0 ? 'border-bottom' : 'border-bottom'}>
                                                            <td colSpan={3}>
                                                                <table>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td data-toggle="collapse" data-target={"#expand_" + key}>
                                                                                <h3 className="subtitulo-secundario px-4 py-2 m-0 w-100">
                                                                                    <i className="bi bi-plus-circle pr-3"></i>
                                                                                    {translate(el.composicao[0].descricao.nome, 'pt')}
                                                                                    {
                                                                                        translate(el.composicao[0].descricao.observacao, 'pt') !== undefined && translate(el.composicao[0].descricao.observacao, 'pt') !== '' ?
                                                                                            <>
                                                                                                <br />
                                                                                                <small className='pl-4'>
                                                                                                    {translate(el.composicao[0].descricao.observacao, 'pt')}
                                                                                                </small>
                                                                                            </>
                                                                                            :
                                                                                            null
                                                                                    }
                                                                                </h3>
                                                                            </td>
                                                                            <td className="text-right" data-toggle="collapse" data-target={"#expand_" + key}>
                                                                                <h3 className="subtitulo-secundario px-4 py-3 m-0 w-100">
                                                                                    {(get_total_composicao(el.composicao)).toFixed(2)} €
                                                                                </h3>
                                                                            </td>
                                                                            <td className="text-right">
                                                                                <div className="quantity-input float-right">
                                                                                    <button
                                                                                        className="quantity-input__modifier quantity-input__modifier--left"
                                                                                        onClick={() => change_quantity(el.id, parseInt(get_item_cart_value(el.id)) - 1)}
                                                                                    >
                                                                                        &mdash;
                                                                                    </button>
                                                                                    <input
                                                                                        className="quantity-input__screen"
                                                                                        type="text"
                                                                                        value={cart['entrance'] !== undefined ? get_item_cart_value(el.id) : 0}
                                                                                        readOnly
                                                                                    />
                                                                                    <button
                                                                                        className="quantity-input__modifier quantity-input__modifier--right"
                                                                                        onClick={() => change_quantity(el.id, parseInt(get_item_cart_value(el.id)) + 1)}
                                                                                    >
                                                                                        &#xff0b;
                                                                                    </button>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr id={"expand_" + key} className="collapse expand row-child">
                                                                            <td colSpan="3">
                                                                                <div className='px-4 py-3'>

                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>

                                <div className='col-12 d-none'>
                                    {
                                        services.map((el, key) => {
                                            return (
                                                <div key={key} className={'col-4 mb-3'}>
                                                    <div className={'card rounded-0'}>
                                                        <div className='card-header p-0'>
                                                            {
                                                                el.composicao[0].multimedia.link_images.length > 0 ?
                                                                    <AssecImage
                                                                        linkImage={el.composicao[0].multimedia.link_images[0].link}
                                                                        width="450"
                                                                        height="300"
                                                                        fit='crop'
                                                                        type='img'
                                                                        sClass="img-fluid"
                                                                        alt={''}
                                                                    />
                                                                    :
                                                                    <img alt='' className='img-fluid py-3 px-5' src="https://cdn.assec.app/repo/alambique/goldenrock/static/assets/logo/logo.svg" />
                                                            }
                                                        </div>
                                                        <div className='card-body'>
                                                            <h5 className='card-title'>{el.nome}</h5>
                                                            <hp className='card-text'></hp>
                                                        </div>
                                                        <div className='card-footer'>
                                                            <button className='text-right'
                                                            /* onClick={() => {
                                                                history.push('/reservation/service/' + el.id);
                                                            }} */
                                                            >
                                                                {/* Reservar
                                                           <Link className="btn btn-secondary" to={{ pathname: '/reservation/service/' + el.id }} replace>
                                                                Reservar
                                                            </Link>  */}
                                                                <a href={Hosts.WEB_SITE_URI + 'reservation/service/' + el.id} className="btn btn-secondary mt-3" >
                                                                    Reservar
                                                                </a>

                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>

    );

};
export default Entrance;

