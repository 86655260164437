import React, { useState, useEffect } from 'react';
//import {useLocation } from 'react-router-dom';

import "../Pagina.css";

import parse from 'html-react-parser';

import { language } from '../WEBContent/Language';

import {
    set_language,

} from '../../../Utils';

import CookieConsent from "react-cookie-consent";

import * as Hosts from "../../../Hosts";


const Cookies = () => {

    const [lang, set_lang] = useState('pt');


    //const page_location = useLocation().pathname.split('/').pop();

    useEffect(() => {

        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
        }
        else {
            set_lang(localStorage.getItem('lang'))
        }

    }, [])

    return (

        <div>
            <CookieConsent
                /*debug={true}*/
                ariaAcceptLabel={language[lang].cookie_button_text}
                buttonText={language[lang].cookie_button_text}
                buttonClasses={"btn-cookies"}
                containerClasses="containerCookie"
                contentClasses="messageCookie"
            >
                <a className="sr-only sr-only-focusable" href="#navbar">Skip to main content</a>
                <div className="col-12 text-center">
                    {parse(language[lang].cookies_message)}&nbsp;
                    <a href={Hosts.WEB_SITE_URI + "terms-conditions"}>{language[lang].more_information}</a>
                </div>

            </CookieConsent>
        </div >
    )
};
export default Cookies;