
export const SITES = {
    SIMCONFERENCE: {
        ACCESS_TOKEN: "SIMCONFERENCE;I1NiJ9.eyJSb2xlIjoiQWRtaW4iLCJJc3N1ZXIiOiJJc3N1ZXIiLCJVc2VybmFtZSI6IkphdmFJblVzZSIsImV4cCI6MTYzNTQxNzIxMCwiaWF0IjoxNjM1NDE3MjEwfQ.lwwQmI9SGdUZm3tJm2YOmIIuwx82CBcrO_WZmJn3CGQ",
    },
    SIMWEBSITE: {
        ACCESS_TOKEN: "SIMWEBSITE;1ZXI1NiJ9.eyJSb2xlIjoiQWRtaW4iLCJJc3N1ZXIiOiJJc3N1ZXIiLCJVc2VybmFtZSI6IkphdmFJblVzZSIsImV4cCI6MTYzNTQxNzIxMCwiaWF0IjoxNjM1NDE3MjEwfQ.lwwQmI9SGdUZm3tJm2YOmIIuwx82CBcrO_WZmJn3CGQ",
    }
}




export const PRODUCTION = false;

export const GEST_COMPLAINTS_BOOK = 'https://www.livroreclamacoes.pt/Inicio/'
export const GEST_RECOVER_PASSWORD = 'https://goldenrock.pt/gest/'
export const SITE_TERMOS_E_CONDICOES = (language = 'pt') => language === 'pt' ? /*  '': ''//  */ 'https://cdn.assec.app/repo/alambique/goldenrock/upload/content/REGULAMENTO_GOLDEN_ROCK.pdf' : 'https://cdn.assec.app/repo/alambique/goldenrock/upload/content/REGULAMENTO_GOLDEN_ROCK_EN.pdf'
//export const SITE_REEMBOLSO_E_DEVOLUCAO=(language = 'pt') =>language === 'pt' ?  'https://cdn.assec.app/repo/turistrela/estanciaski/upload/content/politica-de-devolucao-e-reembolso.pdf' : 'https://cdn.assec.app/repo/turistrela/estanciaski/upload/content/en-politica-de-devolucao-e-reembolso.pdf'
//export const SITE_POLITICA_DE_UTILIZACAO=(language = 'pt') =>language === 'pt' ?  'https://cdn.assec.app/repo/turistrela/estanciaski/upload/content/condicoes-gerais-de-marcacao-e-utilizacao-de-aulas.pdf' : 'https://cdn.assec.app/repo/turistrela/estanciaski/upload/content/en-condicoes-gerais-de-marcacao-e-utilizacao-de-aulas.pdf'
export const SITE_COFINANACIAMENTO = '' //'https://cdn.assec.app/repo/turistrela/estanciaski/static/site/documents/CENTRO-04-38D7-FEDER-001180.pdf'

//export const SIMCore_API_REAL = 'https://sede.assec.pt/api/simnet_turistrela_estanciaski/';
export const SIMCore_API_REAL = 'https://goldenrock.pt/api/simnet_alambique_goldenrock/';
//export const SIMCore_API_REAL = 'https://sede.assec.pt/api/simnet_goldenrock_hotel/';

//export const SIMCore_API_FAKE = 'https://sede.assec.pt/api/simnet_goldenrock_hotel/';
//export const SIMCore_API_FAKE = 'http://10.0.2.2:3000/api/simnet_goldenrock_hotel/';
export const SIMCore_API_FAKE = 'https://goldenrock.pt/api/simnet_alambique_goldenrock/';
export const SIMCore_API_WS = 'wss://goldenrock.pt/api/simnet_alambique_goldenrock/'
//export const SIMCore_API_WS = 'ws://localhost:3002'
export const SIMCore_API = SIMCore_API_REAL;  // TODO: NOTE: it's fake!
export const nome_empresa = 'goldenrock'

// #####################################################################
