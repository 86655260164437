import React, { useEffect } from 'react';
import './GenericSlider.css';
import '../Pagina.css';
import '../Animate/animate.css';
import { repo_img_link } from '../../../Utils';
import { Navigation, Pagination, Autoplay, Scrollbar, A11y, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import "swiper/swiper-bundle.min.css";

const GenericSlider = ({ list_imagens }) => {

    useEffect(() => {
    }, [])

    return (
        <div>
            <section id="slider-store">
                <div className="swiper swiper-container swiper-cartaz slideshow">
                    <Swiper
                        modules={[Navigation, Autoplay, Pagination, Scrollbar, A11y, EffectFade]}
                        spaceBetween={0}
                        slidesPerView={1}
                        //navigation={banner.length > 1 ? true : false}
                        navigation={{
                            nextEl: '.swiper-cartaz-button-next',
                            prevEl: '.swiper-cartaz-button-prev',
                        }}
                        loop={true}
                        speed={2000}
                        autoplay={{
                            delay: 8000,
                            disableOnInteraction: false
                        }}
                        effect="fade"
                        //pagination={banner.length > 1 ? true : false}
                        pagination={{
                            el: '.swiper-pagination-cartaz',
                            //dynamicBullets: true,
                            //type: "progressbar",
                            clickable: true
                        }}
                        breakpoints={{
                            768: {
                                slidesPerView: 1,
                                //navigation: true,
                            },
                            1200: {
                                slidesPerView: 1,
                                //navigation: true,
                                //loop: true,
                            },
                        }}
                        /*scrollbar={{ draggable: true }}*/
                        //onSwiper={(swiper) => console.log(swiper)}
                        //onSlideChange={() => console.log('slide change')}
                    >
                        {
                        list_imagens.length > 0 ?
                            list_imagens.map((v, key) => {
                                return (
                                    <div key={key}>
                                        <SwiperSlide key={key} className={"slide-image slide-image-" + key} style={{ backgroundImage: "url(" + v.link + ")" }} />
                                    </div>
                                )
                            })
                        :
                            <SwiperSlide className={"slide-image slide-image"} style={{ backgroundImage: "url(" + repo_img_link("nophoto_banner.jpg") + ")" }} />
                        }
                        <div className="swiper-pagination-cartaz mb-3"></div>
                        <div className={"swiper-button-next swiper-cartaz-button-next " + (list_imagens.length > 1 ? 'd-none d-lg-block' : 'd-none')}></div>
                        <div className={"swiper-button-prev swiper-cartaz-button-prev " + (list_imagens.length > 1 ? 'd-none d-lg-block' : 'd-none')}></div>

                        <div className="col-12 d-none">
                            <section id="slider-btn" className="demo mt-5">
                                <span></span>
                            </section>
                        </div>
                    </Swiper>
                </div>
            </section>
        </div>
    )
    //}
};
export default GenericSlider;